import { useCallback, useContext, useRef } from "react";
import { ICloudFilesPanel, ICommand } from "../../AppSchema";
import { CloudFileList } from "../../cloudFiles/cloudFilesList";
import { runCommand } from "../../command/runCommand";
import { IPanelProps } from "./PanelProps";
import { MetaContext } from "../../AppState";
import { ModalContext } from "../../modal/Modal";

export const CloudFilesPanel = (props: IPanelProps) => {
	const panel = props.panel as ICloudFilesPanel;
	const folderField = panel.folderField || "id";
	const filterField = panel.fileFilter;

	const command = useRef<ICommand>({ name: props.context.meta.logicalName + ".cloudFilesLoaded", kind: "custom" });

	const onLoaded = useCallback((path: string, files: any[]) => {
		command.current.event = { cloudFiles: { path: path, files: files } };
		props.context.getService("executeCommand").executeCommand("", command.current);
	}, [props.context, command]);

	const rec = props.context.getRecord();
	let objectFolder = folderField[0] === "'" ? folderField.substring(1, folderField.length - 1) : rec[folderField];
	if (objectFolder && objectFolder.label)
		objectFolder = objectFolder.label;

	if (objectFolder) {
		objectFolder = (objectFolder as string).trim();
	}

	let error = panel.errorMessage;
	if (!error && !objectFolder)
		error = panel.emptyMessage || "No OneDrive folder for this record."

	if (error) {
		const makeMessageBox = (msg: string) => {
			return <div className="objectListContainer" style={{display:"grid"}}> <div style={{ placeSelf: "center center" }}>{msg}</div></div>;
		}
		return makeMessageBox(error);
	}

	if (panel.rootFolder)
		objectFolder = panel.rootFolder + "/" + objectFolder;

	let fileFilter: any = undefined;
	if (filterField) {
		if (filterField[0] === "'") {
			fileFilter = filterField.substring(1, filterField.length - 1);
		}
		else {
			let pp = filterField.split(":")
			fileFilter= rec[pp[0]];
			if (fileFilter && fileFilter.label)
				fileFilter = fileFilter.label;
			if (fileFilter && pp[1] === "d")
				fileFilter = new Date(fileFilter).toISOString().substring(0, 10);
		}
	}

	return <CloudFileList rootFolder={objectFolder} fileFilter={fileFilter} onLoaded={onLoaded} />
}