import { ICalendarEvent } from "./ICalendarEvent";

interface IRow {
	columns: Date[]
}

export const splitEventsToColumns = (events: ICalendarEvent[]) => {

	let currentRow: IRow = { columns: [] };

	for (const event of events) {
		let i = 0;
		let firstIndex = currentRow.columns.length;
		let all = true;
		for (i; i < currentRow.columns.length; i++) {
			if (new Date(event.start) >= currentRow.columns[i]) {
				if (firstIndex > i)
					firstIndex = i;
			} else {
				all = false;
			}
		}
		if (all) { // new row
			currentRow = { columns: [] };
		}
		if (firstIndex >= currentRow.columns.length) {
			currentRow.columns.push();
		}
		currentRow.columns[firstIndex] = new Date(event.end);
		(event as any).row = currentRow;
		event.columnIndex = firstIndex;
	}

	for (const event of events) {
		event.columnCount = (event as any).row?.columns.length || 1;
	}
}