import { useState } from "react";
import { IAppConfig, ICommand } from "../AppSchema";
import { Loading } from "../components/Loading";
import { TitleBox } from "../components/TitleBox";
import { IModalContext, IModalParent, IModalProps } from "../modal/Modal";
import { secureFetch } from "../services/fetchJson";


export const changePassword = (modal: IModalContext, userId: string, showOldPassword: boolean,
	onChange?: () => any) => {

	const ChangePasswordForm = (props: {
		userId: string,
		showOldPassword: string,
		onSave: () => Promise<void>, commandSite: IModalParent
	}) => {

		const [login, setLogin] = useState({ oldPassword: "", password: "", password2: "" });
		const [isSending, setSending] = useState(false);
		const [status, setStatus] = useState({ msg: "", err: false });
		
		const cmds = [
			{ name: "CmdSave", label: "Save", icon: "save" },
			{ name: "CmdClose", label: "Close", icon: "close" },
		];
		
		const onCommand = async (cmd: ICommand) => {
			if (cmd.name === "CmdSave") {
				const { oldPassword, password, password2 } = login;
				//if (!email || !password) return;
				if (props.showOldPassword && !oldPassword || !password || password !== password2) return;
		
				setSending(true);
				const data = new URLSearchParams();
				if ([props.showOldPassword])
					data.append("oldPassword", oldPassword);
				data.append("newPassword", password);
				data.append("userId", props.userId);
				const resp = await secureFetch("/api/changepassword", {
					method: "POST",
					body: data
				});
		
				try {
					const text = await resp.text();
					if (!resp.ok) {
						setStatus({ msg: text || resp.statusText || "Error", err: true });
					}
					else {
						setStatus({ msg: "Password Changed", err: false });
						setTimeout(() => props.commandSite?.closeModal(), 700);
					}
				}
				catch {
					//resp.ok
				}
				setSending(false);
			} else {
				props.commandSite?.closeModal();
			}
		}

		return <div className="objectListContainer" style={{ flex: "1 1 auto", display: "flex" }}>
			<TitleBox title="Change Password" commands={cmds} onCommand={onCommand} />
			<ChangePasswordFields status={status} isSending={isSending} login={login} setLogin={setLogin} showOldPassword={props.showOldPassword} />
		</div>
	}

	//const r = sourceElement.getBoundingClientRect();
	const pp: IModalProps = {
		body: (p) => < ChangePasswordForm {...p} />,
		bodyProps: {
			showOldPassword: showOldPassword,
			userId: userId,
			onSave: () => {
				//Object.assign(field, newField);
				onChange && onChange();
			}
		},
		showTitle: false,
		id: "dlgChangePassword",
		//anchor: { x: r.x, y: r.y, width:400 },
	};
	modal.showModal(pp);
}

export const ChangePasswordFields = (props: {
	isSending: boolean,
	status: { err: boolean, msg: string },
	login: { password: string, password2: string, oldPassword: string }
	setLogin: (newLogin: any) => void,
	showOldPassword: any
}) => {
	const { isSending, status, login, setLogin } = props;

	let pwdMatch = true;
	if (login.password && login.password2 && login.password !== login.password2) {
		pwdMatch = false;
	}

	return (<form style={{ flex: "1 1 auto" }}><fieldset className="loginForm" disabled={isSending}>
		<div className="formItem" style={{ display: props.showOldPassword ? "flex" : "none" }}>
			<label className="formLabel" htmlFor="oldPassword">Old Password:</label>
			<input id="oldPassword" type="password" value={login.oldPassword} onChange={e => setLogin({ ...login, oldPassword: e.target.value })} />
		</div>
		<div className="formItem">
			<label className="formLabel" htmlFor="password">Password:</label>
			<input id="password" type="password" value={login.password} onChange={e => setLogin({ ...login, password: e.target.value })} />
		</div>
		<div className="formItem">
			<label className="formLabel" htmlFor="password">Confirm&nbsp;Password:</label>
			<input id="password" type="password" value={login.password2} onChange={e => setLogin({ ...login, password2: e.target.value })} />
		</div>
		
		{isSending && <Loading />}
		{status.msg && <div style={{ color: status.err ? "red" : "green" }}>{status.msg}</div>}
		{!pwdMatch && <div style={{ color: "red" }}>Passwords do not match.</div>}
	</fieldset></form>)
}

export const sendInvite = async (metadata: IAppConfig, record: any) => {
	const data = new URLSearchParams();
	//const [url, organization] = getOrg();
	data.append("username", record.emailaddress);
	data.append("organization", metadata.orgName);
	// if (context.ret)
	// 	data.append("ret", context.ret);
	data.append("reason", "INVITE");
  
	const resp = await fetch("/api/getmagic", {
		method: "POST",
		body: data
	});
	// if (resp.ok) {
	// 	context.next({ message: "OK_CHECK_EMAIL" }, context);
	// }
	// return resp;
}