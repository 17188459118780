import { IAppGui, IViewRecord } from "./AppSchema";
import { humanize, IAppConfig } from "./AppState";
import { Fetch } from "shared/fetch"
import { IDictionary, IMetaObject, ICurrency, IExecuteRequest, IExecuteResult, MetaPropertyFlags } from "shared/schema";
import { ICustomOwner } from "shared/permissions";
import { fetchJson } from "./services/fetchJson";
import { synchronize } from "./services/sync";
import { executeMultipleOffline, retrieveMultipleOffline, updateRecordOffline } from "./services/offline/offlineService";
import { IDataService } from "./services/baseService";
import { executeMultipleOnline, retrieveMultipleOnline, updateRecordOnline } from "./services/onlineService";
import { calculateRollups, prepareRollups } from "./services/rollups";
import { initOfflineBaseFolder } from "./services/getFileUrl";
import { fetchLocalization, getLanguage } from "./services/localize";

const fetchViews = async () => {
	const props = ["id", "name", "label", "body", "kind", "objectname", "appid", "isprivate"];
		const q: Fetch = {
			entity: {
				name: "inu_view",
				attributes: props.map(x => ({ attribute: x }))
				// ,filter: {
				// 	conditions: [
				// 		{ attribute: "name", operator: "eq", value: "app" }
				// 	]
				// }
			},
			count: 1000,
		};
	const records = await DataService.retrieveMultiple(q);
	return records as IViewRecord[];
}

const fetchGui = async () => {
	// const props = ["id", "name", "body"];
	// 	const q: Fetch = {
	// 		entity: {
	// 			name: "inu_view",
	// 			attributes: props.map(x => ({ attribute: x })),
	// 			filter: {
	// 				conditions: [
	// 					{ attribute: "name", operator: "eq", value: "app" }
	// 				]
	// 			}
	// 		},
	// 		count: 1,
	// 	};
	// const record = await retrieveSingle(q);
	const records = await fetchViews();

	const gui: IAppGui = { objects: records, home: [] }; //JSON.parse(home.body) as IAppGui;

	for (const x of gui.objects) {
		if (x.kind === "view" || x.kind === "form") {
			try {
				x.parsedBody = JSON.parse(x.body);
			}
			catch (e) {
				console.log("ParseView error: Object:" + x.objectname + " Type:" + x.kind + " Name:" + x.name);
			}
		}
	}
	return gui;
}

export const writeErrorLog = (area: string, message: any, severity: string = "ERROR") => {
	try {
		let text = window.sessionStorage.getItem("ERROR_LOG") || "";
		if (text.length > 1000000) {
			const lines = text.split('\n');
			text = lines.slice(-lines.length / 2).join('\n');
		}
		if (text)
			text += "\n";

		let msg = "unknown error";
		try {
			msg = message.toString();
		}
		catch {}
		
		text += (severity + "\t" + new Date().toISOString() + "\t" + area + "\t" + msg.replaceAll("\n", "\\n"));
		window.sessionStorage.setItem("ERROR_LOG", text);
	}
	catch (e) {
		// just ignore the error, this must not fail.
	}
}

const OnlineDataService: IDataService = {
	retrieveMultiple: retrieveMultipleOnline,
	updateRecord: updateRecordOnline,
	executeMultiple: executeMultipleOnline,
};
let OfflineDataService: IDataService;
let Current: IDataService = OnlineDataService;

class InternalDataService {
	public isOnline() {
		return Current === OnlineDataService
	}
	public setOnline(online: boolean) {
		Current = online ? OnlineDataService : OfflineDataService;
	}
	public async retrieveMultiple(q: Fetch) {
		try {
			const result = await Current.retrieveMultiple(q);
			return result;
		}
		catch (e) {
			writeErrorLog("fetch: " + q?.entity?.name, e);
			throw e;
		}
	}
	public async retrieveSingleById(meta: IMetaObject, id: string) {
		const q: Fetch = {
			entity: {
				name: meta.logicalName,
				attributes: meta.properties.map(x => ({ attribute: x.logicalName })),
				filter: {
					conditions: [
						{ attribute: "id", operator: "eq", value: id }
					]
				}
			},
			count: 1,
		};
	
		const rows = await Current.retrieveMultiple(q);
		return rows.length > 0 ? rows[0] : undefined;
	}
	public async retrieveSingle(q: Fetch) {
		const results = await Current.retrieveMultiple(q);
		return results.length > 0 ? results[0] : undefined;
	}
	public async executeMultiple(requests: IExecuteRequest[], throwOnError?: boolean) {
		try {
			//const rollups = await prepareRollups(requests);
			const results = await Current.executeMultiple({ records: requests });
			if (throwOnError !== false && requests.length > 0) {
				if (results.length === 0)
					throw new Error("Unknown Update Error");
				const errorResult = results.find(x => !!x.error);
				if (errorResult)
					throw Error(errorResult.error!);
			}
			//await calculateRollups(rollups);
			return results;
		}
		catch (e) {
			writeErrorLog("executeMultiple: " + (requests[0].operation || "create") + ":" + requests[0]?.name, e);
			throw e;
		}
	}
	public async updateRecord(objectName: string, r: any, operation?: string) {
		const results = await this.executeMultiple([{ name: objectName, object: r, operation: operation as any }]) as IExecuteResult[];
		const result = results[0];
		if (!result)
			throw new Error("Unknown Update Error");
		if (result.error)
			throw new Error(result.error);
		return result.result;
		//return Current.updateRecord(objectName, r, operation);
	}
}

export const DataService = new InternalDataService();

export const initializeService = async () => {

	try {
		let meta: IAppConfig;
		if (window.navigator.onLine) {
			console.log("init ONLINE");

			const ctx = await fetchJson("/api/getusercontext", undefined) as { metaDict: IDictionary<IMetaObject>, currencyDict: IDictionary<ICurrency>, permDict: IDictionary<number>, apps: string[], owners: ICustomOwner[], sas: string, orgName: string, languages?: any[], user: any, buildDate: string };
			meta = { objects: Object.keys(ctx.metaDict).map(x => ctx.metaDict[x]) } as IAppConfig;
			meta.languages = ctx.languages;
			meta.permDict = ctx.permDict;
			meta.apps = ctx.apps;//["Competitor", "Manager", "Customize"];
			meta.customOwners = ctx.owners;
			meta.user = ctx.user;
			meta.currencyDict = ctx.currencyDict;
			meta.sas = ctx.sas;
			meta.orgName = ctx.orgName;
			meta.buildDate = ctx.buildDate; 
			const langId = getLanguage(meta);	
			(meta as any).locMap = await fetchLocalization(DataService.retrieveMultiple, meta, langId);
			applyLocalization(meta, langId);
			
			window.localStorage.setItem("userContext", JSON.stringify(meta));
			//const {age, ...secondObject} = firstObject;
		} else {
			console.log("init OFFLINE");
			const serialized = window.localStorage.getItem("userContext");
			if (!serialized)
				throw new Error("No user context, cannot work offline");
			meta = JSON.parse(serialized) as IAppConfig;
		}
		await initOfflineBaseFolder();
	
		OfflineDataService = {
			retrieveMultiple: (q) => retrieveMultipleOffline(q, meta),
			updateRecord: (o, r, op) => updateRecordOffline(o, r, op, meta),
			executeMultiple: (r) => executeMultipleOffline(r, meta)
		};

		if (!navigator.onLine)
			DataService.setOnline(false);

		const g = await fetchGui();  ///[DefaultUI]; // FIXME: FETCH!
		meta.gui = [g];

		//synchronize(meta.objects);

		return meta;
	}
	catch (e) {
		console.log(e);
		return undefined;
	}
}

export const applyLocalization = (metadata: IAppConfig, langId: string) => {
	//const locMap = await fetchLocalization(DataService.retrieveMultiple, metadata, langId);
	const locMap = (metadata as any).locMap;

	const getStringOrDefault = (name: string) => {
		const p = locMap[name] as any;
		return p;
		// if (p) {
		// 	if (p[langId] && p[langId].text)
		// 		return p[langId].text;
		// 	if (p.en && p.en.text)
		// 		return p.en.text;
		// }
		// return null;
	}

	for (const meta of metadata.objects) {
		meta.displayName = getStringOrDefault(meta.logicalName) || meta.displayName || humanize(meta.logicalName);
		meta.displayNamePlural = getStringOrDefault(meta.logicalName + "+s") || meta.displayNamePlural || meta.displayName;

		for (const propMeta of meta.properties) {
			const propId = meta.logicalName + "." + propMeta.logicalName;
			propMeta.displayName = getStringOrDefault(propId) || propMeta.displayName || humanize(propMeta.logicalName);

			if ((propMeta.flags & MetaPropertyFlags.OptionSet) !== 0 && propMeta.options) {
				const dopts: { value: string, label?: string }[] = propMeta.richOptions || propMeta.options.map(x => ({ value: x }));
				for (const o of dopts) {
					const richLabel = o.label;
					const optionId = richLabel || propId + "." + o.value;
					const label = getStringOrDefault(optionId) || richLabel || humanize(o.value);
					o.label = label;
				}
				propMeta.richOptions = dopts;
			}
		}
	}
}