import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { FetchAttribute, FetchCondition, FetchConditionOperator, FetchEntity, FetchFilter, FetchLink, FetchOrder } from "shared/fetch";
import { Dictionary, IMetadata, IMetaObject, IMetaProperty, MetaPropertyType } from "shared/schema";
import { MetaContext } from "../AppState";
import { useRender } from "../hooks/useRender";

const makeName = (x: { logicalName: string, displayName?: string, alias?: string }) => {
	let ret;
	if (x.displayName)
		ret =  `${x.displayName} [${x.logicalName}]`;
	else
		ret = x.logicalName;
	if (x.alias)
		ret += ` AS ${x.alias}`;
	return ret;
}

const EntitySelectNode = (props: { entity: FetchEntity, meta: IMetaObject}) => {
	const { entity, meta } = props;

	const [isExpanded, setExpanded] = useState(false);
	const render = useRender();

	const addRemoveItem = useCallback((item: IMetaProperty, add: boolean) => {
		if (!add) {
			if (entity.attributes)
				entity.attributes = entity.attributes.filter(x => x.attribute !== item.logicalName);
		}
		else {
			if (!entity.attributes)
				entity.attributes = [];
			entity.attributes = entity.attributes.concat([{ attribute: item.logicalName }]);
		}
		render();
	}, [render, entity]);

	const toggleExpand = useCallback(() => {
		setExpanded(x => !x);
	}, [setExpanded]);

	const toggleAll = useCallback(() => {
		entity.attributes = meta.properties.map(x => ({ attribute: x.logicalName }));
		render();
	}, [entity, render]);
	const toggleNone = useCallback(() => {
		entity.attributes = [];
		render();
	}, [entity, render]);
	
	return (<div className="entityNodeSelect" style={{display:"flex", flexDirection: isExpanded ? "column" : "row" }}>
		<div className="entityNodeSelectHeader">
			<span onClick={toggleExpand}>{isExpanded ? "close" : "select"}</span>
			{isExpanded && <span onClick={toggleAll}>select&nbsp;all</span>} 
			{isExpanded && <span onClick={toggleNone}>select&nbsp;none</span>} 
		</div>
		{meta.properties.map(x => {
			const isSelected = entity.attributes && !!entity.attributes.find(y => y.attribute === x.logicalName)
			const show = isExpanded || isSelected;
			return (<div className="entityNodeSelectAttr" style={{display:show?"flex":"none"}}>
				<input style={{display:isExpanded?"flex":"none"}} type="checkbox" checked={isSelected} onChange={e=>addRemoveItem(x, e.target.checked)} />
				<div>{x.displayName || x.logicalName}</div>
				<div style={{display:isExpanded?"flex":"none", color:"#aaa"}} >{" ["+x.logicalName+"]"}</div>
			</div>)
		})}
	</div>)
}

const EntityOrdersNode = (props: { entity: FetchEntity, meta: IMetaObject, conds: IAvailableProperty[] }) => {

	const { entity } = props;
	const [_, render] = useState(false);

	const onChangeAttribute = useCallback((o: FetchOrder, value: string, descending?:boolean) => {
		if (!entity.orders) entity.orders = [];
		let index = entity.orders.indexOf(o);
		const newOrders = entity.orders.slice();
		if (index >= 0) {
			newOrders.splice(index, 1);
		}
		else {
			index = entity.orders.length;
		}
		if (value) {
			newOrders.splice(index, 0, { ...o, attribute: value, alias: value.indexOf("__") > 0 ? value : undefined, descending: descending });
		}
		entity.orders = newOrders;
		render(x => !x);
	}, [entity, render]);

	return (<div className="entityOrdersNode">
		<div className="entityOrdersName">order</div>
		<div className="entityOrdersChildren">
			<div style={{ display: "flex" }}><button className="filterButton" onClick={e=>onChangeAttribute({attribute:"name"}, "name")}>Add</button></div>
			{entity.orders && entity.orders.map(x => {
				return (<div style={{ display: "flex", backgroundColor: "#eee"}}>
					<div className="entityOrdersAttr">
						<select value={x.attribute} onChange={e=>onChangeAttribute(x, e.target.value, x.descending)}>
							{props.conds.map(y => {
								return <option value={y.fullName.replace(".", "__")}>{y.fullName}</option>	
							})}
						</select>
					</div>
					<button className="filterButton" onClick={e=>onChangeAttribute(x, x.attribute, !x.descending)} >{x.descending?"Descending":"Ascending"}</button>
					<button className="filterButton" onClick={e=>onChangeAttribute(x, "")} >Delete</button>
				</div>)
			})}
		</div>
</div>)
} 

const FetchOperators = ["eq", "ne", "like", "not-like", "eq-userid", "ne-userid", "on", "null", "not-null", "lt", "gt", "le", "ge"];

const EntityConditionNode = (props: { condition: FetchCondition, conds: IAvailableProperty[], onRemove: (c: FetchCondition) => void }) => {
	const { condition } = props;
	const [_, render] = useState(false);
	const condName = condition.entityName ? condition.entityName + "." + condition.attribute : condition.attribute;

	const onChangeAttribute = useCallback((attr: string) => {
		const parts = attr.split('.');
		condition.attribute = parts[parts.length - 1];
		condition.entityName = parts.length > 1 ? parts[0] : undefined;

		// TODO: reset filter and value

		render(x => !x);
	}, [condition, render]);
	const onChangeValue = useCallback((v: string) => {
		condition.value = v;
		render(x => !x);
	}, [condition, render]);
	const onChangeOperator = useCallback((op: string) => {
		condition.operator = op as FetchConditionOperator;
		render(x => !x);
	}, [condition, render]);

	return (<div className="entityConditionNode">
		{/* <div style={{ flex: "0 1 30%" }}>{condition.entityName ? condition.entityName + "." : ""}{condition.attribute}</div> */}
		<div style={{ flex: "1 1 30%" }}><select value={condName} onChange={e=>onChangeAttribute(e.target.value)}>
			{props.conds.map(x => {
				const value = x.fullName;
				return <option value={value}>{value}</option>
			})}
		</select></div>
		<div style={{ flex: "0 1 30%" }}><select value={condition.operator} onChange={e=>onChangeOperator(e.target.value)}>
			{FetchOperators.map(x => <option value={x}>{x}</option>)}
		</select></div>
		<div style={{ flex: "0 1 30%" }}><input type="text" style={{width:"100%", boxSizing: "border-box"}} placeholder="value" value={condition.value||""} onChange={e=>onChangeValue(e.target.value)} /></div>
		<button className="filterButton" style={{ flex: "0 1 auto" }} onClick={x => props.onRemove(condition)}>Delete</button>
	</div>
	)
}

const EntityFilterNode = (props: { filter: FetchFilter, conds: IAvailableProperty[], onRemove?: (f:FetchFilter,ungroup?:boolean)=>void }) => {
	const { filter } = props;

	// we need metadata dictionary with links.

	//actions: toggle-type, addCondition, addFilter, select for grouping, Group, Ungroup

	const [_, render] = useState(false);

	const onAddCondition = useCallback(() => {
		filter.conditions = (filter.conditions || []).concat({ attribute: "name", operator: "eq", value: "" });
		render(x => !x);
	}, [filter, render]);
	const onRemoveCondition = useCallback((cond:FetchCondition) => {
		filter.conditions = filter.conditions?.filter(x => x !== cond);
		render(x => !x);
	}, [filter, render]);
	const onAddFilter = useCallback(() => {
		filter.filters = (filter.filters || []).concat({ conditions: [{ attribute: "name", operator: "eq", value: "" }] });
		render(x => !x);
	}, [filter, render]);
	const onRemoveFilter = useCallback((f:FetchFilter, ungroup?:boolean) => {
		filter.filters = filter.filters?.filter(x => x !== f);
		if (ungroup) {
			if (f.conditions) {
				filter.conditions = (filter.conditions || []).concat(f.conditions);
			}
			if (f.filters) {
				filter.filters = (filter.filters || []).concat(f.filters);
			}
		}
		render(x => !x);
	}, [filter, render]);

	const toggleType = useCallback(() => {
		filter.type = (filter.type || "and") === "and" ? "or" : "and";
		render(x => !x);
	}, [filter, render]);
	
	const selfRemove = props.onRemove;

	return (<div className="entityFilterNode">
		<div onClick={toggleType} className="entityFilterNodeOperator">{filter.type || "and"}</div>
		<div className="entityFilterNodeChildren">
			<div style={{display:"flex", alignSelf: "flex-start"}}>
				<button className="filterButton" onClick={onAddCondition}>Add Condition</button>
				<button className="filterButton" onClick={onAddFilter}>Add Filter</button>
				{selfRemove && <button className="filterButton" onClick={e => selfRemove(filter, true)}>Ungroup</button>}
				{selfRemove && <button className="filterButton"  onClick={e => selfRemove(filter)}>Delete</button>}
			</div>
			{filter.conditions && filter.conditions.map(x => <EntityConditionNode condition={x} conds={props.conds} onRemove={onRemoveCondition} />)}
			{filter.filters && filter.filters.map(x => <EntityFilterNode filter={x} conds={props.conds} onRemove={onRemoveFilter} />)}
		</div>
	</div>
	)
}

interface IAvailableProperty {
	p: IMetaProperty;
	e: IMetaObject;
	fullName: string; // alias + "." + p.logicalName
}

interface ILink {
	label: string;
	entity: IMetaObject;
	to: string;
	from: string;
}
const getAvailableLinks = (meta: IMetaObject|undefined, metadata: IMetadata) => {
	if (!meta)
		return [];
	const metaDict = Dictionary.create(metadata.objects, "logicalName");
	const links: ILink[] = [];
	for (const f of meta.properties) {
		if (f.type === MetaPropertyType.Lookup && f.targets) {
			for (const target of f.targets) {
				const m = metaDict[target];
				if (m) {
					links.push({ entity: m, from: f.logicalName, to: "id", label: "self."+f.logicalName + "->" + m.logicalName });
				}
			}
		}
	}
	for (const source of metadata.objects) {
		for (const f of source.properties) {
			if (f.type === MetaPropertyType.Lookup && f.targets && f.targets.indexOf(meta.logicalName)>=0) {
				links.push({ entity: source, to: f.logicalName, from: "id", label: source.logicalName +"."+ f.logicalName + "->self"  });
			}
		}
	}
	return links;
}
interface ILinkCounter {
	counter: number;
}
const initLinkCounter = (c: ILinkCounter, entity: FetchEntity) => {
	if (entity.links) {
		for (const link of entity.links) {
			if (link.alias && /^j[0-9]+$/.test(link.alias)) {
				const num = +(link.alias.substring(1));
				if (c.counter >= num)
					c.counter = num + 1;
			}
			initLinkCounter(c, link);
		}
	}
	return c;
}

export const EntityNode = (props: {
	entity: FetchEntity | FetchLink;
	onRemoveSelf?: (entity: FetchLink) => void;
	linkCouter?: ILinkCounter;
}) => {

	const { entity } = props;
	const entityLink = entity as FetchLink;
	const isLink = !!entityLink.to && !!entityLink.from;
	const metadata = useContext(MetaContext);
	const meta = metadata.objects.find(x => x.logicalName === entity.name);
	//const [isExpanded, setExpanded] = useState(true);

	const [link, setLink] = useState("-1");
	const availLinks = useMemo(() => getAvailableLinks(meta, metadata), [entity, metadata]);
	const [_, render] = useState(false);
	const [linkCounter] = useState(props.linkCouter || initLinkCounter({ counter: 1 }, entity));

	const addLink = (index: number) => {
		if (index < 0)
			return;
		const ll = availLinks[index];
		entity.links = (entity.links || []).concat([{ name: ll.entity.logicalName, to: ll.to, from: ll.from, alias: "j" + linkCounter.counter++, type: "inner" }]);
		render(x => !x);
	}
	const removeLink = (link: FetchLink) => {
		entity.links = (entity.links || []).filter(x => x !== link);
		render(x => !x);
	}

	const onToggleType = (e: any) => {
		const types = ["inner", "outer"];
		const i = (types.indexOf(entityLink.type || "inner") + 1) % (types.length)

		entityLink.type = types[i] as any;//entityLink.type === "outer" ? "inner" : "outer"; 
		render(x => !x);
	}

	if (!meta) return (<div>Entity not found:{entity.name}</div>);

	if (!entity.filter)
		entity.filter = {};
	
	const conds: IAvailableProperty[] = meta.properties.map(x => ({ p: x, e: meta, fullName: x.logicalName })); // FIXME: plus all attributes from links.
	const vl = (entity: FetchEntity) => {
		
		if (entity.links)
			for (const link of entity.links) {
				const linkMeta = metadata.objects.find(x => x.logicalName === link.name);
				if (linkMeta) {
					for (const x of linkMeta.properties)
						conds.push({ p: x, e: linkMeta, fullName: link.alias + "." + x.logicalName })
				}
				vl(link)
			}
	}
	if (!isLink)
		vl(entity)

	return (<div className="entityNode" style={{ overflow: "auto" }}>
		{!isLink && <div className="entityNodeHeader">{makeName(meta)}</div>}
		{isLink && <div className="entityNodeHeader">{entityLink.alias}|{makeName(meta)}|{entityLink.from}|{entityLink.to}
			<div style={{ flex: "1 1 auto" }} />
			<button className="filterButton" onClick={onToggleType}>{entityLink.type || "inner"}</button>
			<button className="filterButton" onClick={e=>props.onRemoveSelf && props.onRemoveSelf(entityLink)}>Delete</button>
		</div>}

		<div className="entityNodeChildren">
			<EntitySelectNode entity={entity} meta={meta} />
			<EntityFilterNode conds={conds} filter={entity.filter} />
			{!isLink && <EntityOrdersNode entity={entity} meta={meta} conds={conds} />}
		</div>
		<div className="entityNodeLinkSelector entityOrdersNode">
			<select value={link} onChange={e => addLink(+e.target.value)}>
				<option key="-1" value={-1}>Add Link</option>
				{availLinks.map((y, i) => {
					return <option key={i} value={i}>{y.label}</option>
				})}
			</select>
		</div>
		{entity.links && entity.links.length > 0 &&
			<div className="entityNodeLinks entityOrdersNode">
				{entity.links.map(x => {
					return <EntityNode entity={x} onRemoveSelf={removeLink} linkCouter={linkCounter} />
				})}
			</div>
		}
	</div>)
}

export const FetchEditor = () => {
	//return <
}