import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IDictionary, MetaPropertyType } from 'shared/schema';
import { getSessionStateItem, setSessionStateItem, useSessionState } from '../hooks/useSessionState';
import { GridProps } from './GridProps';
import { Loading } from './Loading';

export const useListLoader = (mainRef: React.RefObject<HTMLElement>, onScroll?: (e: React.UIEvent<HTMLElement>) => void) => {
	const lastItemRef = useRef<any>(null);
	useEffect(() => {
		const bodyScroll = mainRef.current && mainRef.current.parentElement?.parentElement?.parentElement === document.body;
		const observeRoot = bodyScroll ? null : mainRef.current; // null is viewport

		const o = new IntersectionObserver((e) => {
			//console.log("interscting:" + e[0].isIntersecting);
			if (e[0].isIntersecting && onScroll) {
				//console.log("item visible");
				onScroll({ target: mainRef.current } as any);
			}
		}, { root: observeRoot });

		if (lastItemRef.current) {
			//console.log("interscting started");
			o.observe(lastItemRef.current);
		} else {
			console.log("no item to observe");
		}
		return () => o.disconnect();
	}, [onScroll]);
	return lastItemRef;
}

var SCROLL_RESTORE: IDictionary<number> = {};

export const useScrollRestore = (mainRef: React.RefObject<HTMLElement>, isLoading: boolean | undefined, sessionKey: string) => {
	const sc = useRef<number>(0);

	const location = useLocation();
	sessionKey = location.key + sessionKey;

	const onScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
		if (sc.current)
			window.clearTimeout(sc.current);
		sc.current = window.setTimeout(() => {
			//setSearchParams({ [sessionKey]: ""+(e.target as HTMLDivElement).scrollTop }, { replace: true });
			//setSessionStateItem(sessionKey, (e.target as HTMLDivElement).scrollTop);
			SCROLL_RESTORE[sessionKey] = (e.target as HTMLDivElement).scrollTop;
		}, 500);
	}, []);

	const bodyScroll = mainRef.current && mainRef.current.parentElement?.parentElement?.parentElement === document.body;

	useLayoutEffect(() => {
		if (mainRef.current && !isLoading) {
			//const scrollPos = getSessionStateItem(sessionKey, 0);
			//const scrollPos = +(searchParams.get(sessionKey) || 0);
			const scrollPos = SCROLL_RESTORE[sessionKey] || 0;
			mainRef.current.scrollTo({ top: scrollPos, behavior: "auto" });
			console.log("restore scroll:" + scrollPos);

			if (bodyScroll) {
				const scrollPos = SCROLL_RESTORE[sessionKey+"_body"] || 0;
				window.scrollTo({ top: scrollPos, behavior: "auto" });
				console.log("body restore scroll:" + scrollPos);
			}
		}
	}, [isLoading]);
	
	useEffect(() => {
		const onBodyScroll = (e: any) => {
			SCROLL_RESTORE[sessionKey + "_body"] = window.scrollY;
		};

		if (bodyScroll) {
			window.addEventListener("scroll", onBodyScroll);
		}
		return () => {
			window.removeEventListener("scroll", onBodyScroll);
		}
	},[bodyScroll, sessionKey]);

	return onScroll;
}

const SimpleGrid = (props: GridProps): JSX.Element => {

	let { fields, rows, formatter, noEdit, columns, isLoading, style, className, noHeader, listColumns, attrsMeta } = props;

	if (!noEdit) {
		rows = [null].concat(rows);
	}

	const loadingStyle = { textAlign: "center", gridColumnStart: "1", gridColumnEnd: "" + (fields.length + 1) } as React.CSSProperties;

	let mainStyle: React.CSSProperties = { display: "grid", position: "relative", textAlign: "left", overflowY: "auto" };
	mainStyle.gridTemplateColumns = props.listColumns ? (props.listColumns.map(x => (x.width || "1fr")).join(" ")) : ("repeat(" + fields.length + ",1fr)");
	mainStyle.gridTemplateRows = rows ? ("repeat(" + (rows.length + 1) + ",auto) 1fr") : "";
	if (style)
		mainStyle = { ...style, ...mainStyle };
		
	const adjustStyle = useCallback((index: number, style: React.CSSProperties) => {
		if (listColumns && attrsMeta) {
			const propMeta = attrsMeta[listColumns[index].attribute];
			if (propMeta && (propMeta.type === MetaPropertyType.Decimal ||
				propMeta.type === MetaPropertyType.Money ||
				propMeta.type === MetaPropertyType.Integer ||
				propMeta.type === MetaPropertyType.Float))
				style.textAlign = "right";
		}
	}, [listColumns, attrsMeta]);
  
	const onHeaderClick = useCallback((field: string) => {
		if (props.onSortClick) {
			props.onSortClick(field);
		}
	}, [props.onSortClick]);

	const sessionKey = props.sessionKey || fields.join(';');
	const mainRef = useRef<HTMLDivElement>(null);
	
	const onScroll = useScrollRestore(mainRef, isLoading, sessionKey);
	const lastItemRef = useListLoader(mainRef, props.onScroll);

	useEffect(() => {
		const d = mainRef.current;
		d && d.classList.toggle("gridFull", rows && rows.length > 0);
	}, [mainRef, rows && rows.length > 0]);

	return (
		<div ref={mainRef} style={mainStyle} className={"simpleGrid " + (className||"")} onScroll={onScroll} >
			{!noHeader && fields.map((h, i) => {
				h = listColumns ? (listColumns[i].label || h) : (columns ? columns[i] : h);
				if (props.sortColumn && fields[i] === props.sortColumn.field)
					h = h + (props.sortColumn.asc ? "▲" : "▼");
				let headerText = h as any;
				if (props.renderHeader)
					headerText = props.renderHeader(i, h);
				//h = h + "▲" + " ▼";
				const hdrStyle = {
					gridRowStart: "1", gridColumnStart: "" + (i + 1)
				} as React.CSSProperties;
				adjustStyle(i, hdrStyle);
				if (i === 0)
					hdrStyle.paddingLeft = "11px";
				if (props.sortColumn)
					hdrStyle.cursor = "pointer";
				return (<div key={"hdr." + i} onClick={e => onHeaderClick(fields[i])} className="simpleGridHeader" style={hdrStyle}>{headerText}</div>)
			})}

			{isLoading && <div key="loading" style={loadingStyle}><Loading /></div>}

			{!rows || rows.length === 0 && props.emptyPlaceholder}

			{!isLoading && rows.map((x, rowIndex) => fields.map((fname, colIndex) => {
				const style = {
					gridRowStart: "" + (rowIndex + (noHeader ? 1 : 2)), gridColumnStart: "" + (colIndex + 1), padding: "4px 2px",
					backgroundColor: ((rowIndex & 1) === 0 ? "var(--back-color2)" : "var(--back-color)"), marginRight: "1px",
					// borderTop: "1px solid #ccc"
				} as any;
				if (colIndex === 0 && !noHeader)
					style.paddingLeft = "11px";
				if (x === props.selectedRow) {
					style.backgroundColor = "#0071eb20";
				}
				adjustStyle(colIndex, style);
				const fieldValue = formatter ? formatter(x, fname, style) : x[fname];
				const text = (fieldValue !== undefined && fieldValue !== null) ? fieldValue : "";
				const key = props.keyFunc ? props.keyFunc(x) : (x ? x[props.keyName || "id"] : "__n");
				let className = "simpleGridRow " + ((listColumns && listColumns[colIndex] && listColumns[colIndex].cssClass) || "");
				if (x === props.selectedRow) {
					className += " selectedRow";
				}
				return (
					<div key={(key) + "." + colIndex} className={className} style={style}>{text}</div>
				)
			}))}

			<div style={{ position:"absolute", top:"50%", width:"100%", textAlign:"center", pointerEvents:"none", opacity: (!isLoading && rows.length === 0) ? "1" : "0", margin: "auto", gridColumn: "1/-1", fontSize: "14px", color: "#ddd", transition: "opacity 0.5s" }} >
				{/* No Records <i className='fa fa-bed' /> */}
				<i style={{fontSize:"30px"}} className='fa fa-seedling' />
			</div>

			{ <div key="lastItemObserver" className="simpleGridLoader" ref={lastItemRef} style={{visibility:props.showLoader && rows && rows.length>0?"visible":"hidden" }} />}

			{props.renderFooter && props.renderFooter(fields)}
		</div>
	);
}

export default SimpleGrid;