import React from "react";
import { useCallback } from "react";
import { useDerivedState } from "../hooks/useDerivedState";
import { IconButton } from "./IconButton";

export const TabControlWizard = (props: {
	left?: any;
	items: string[];
	renderItem?: (item: string, index: number, arr: string[]) => React.ReactNode,
	selectedIndex: number;
	className?: string;
	onChange: (index: number) => void;
	progress?: number
}) => {
	const { items, renderItem, selectedIndex } = props;
	const progress = props.progress !== undefined ? props.progress : items.length;

	return <div className={"tabWizardGrid " + (props.className || "")}>
		{items.map((label, index) => {
			return <div onClick={e=>props.onChange(index)} className={"tabWizardItem" + (index <= progress ? " wizardDone" : "") + (index===selectedIndex?" wizardSelected":"")} >
				<div className="tabWizardLine"/>
				<div className="tabWizardNumber">{index + 1}</div>
				<div className="tabWizardLabel">{(renderItem && renderItem(label, index, items)) || label}</div>
			</div>
		})}
	</div>
}

export const TabbarWizard = (props: {
	tabs: string[],
	renderTabItem?: (item: string, index: number, arr: string[]) => React.ReactNode,
	initIndex?: number,
	className?: string,
	onSelectedChanged?: (selectedIndex: number) => void, 
	renderChild: (selectedIndex: number) => React.ReactNode,
	header?: React.ReactNode,
	progress?: number,
	nextLabel?: string,
	onNextPage?: (index: number) => void;
}) => {
	const initIndex = props.initIndex || 0;
	const [selectedIndex, setSelectedIndex] = useDerivedState(initIndex, [initIndex, props.tabs]);

	const selectedChangedHandler = props.onSelectedChanged || setSelectedIndex;

	return (
		<div className={"tabsWizard tabsMain " + props.className}>
			<TabControlWizard
				progress={props.progress}
				items={props.tabs}
				renderItem={props.renderTabItem}
				selectedIndex={selectedIndex}
				onChange={selectedChangedHandler}
			/>
			<div className="tabsContent">
				{props.renderChild(selectedIndex)}
			</div>
			{(props.progress === undefined || props.progress === selectedIndex) &&
				<div className="tabWizardButtons">
					<IconButton label="Go back" disabled={selectedIndex === 0} className="btnWizardPrev" icon={"arrow-left"} onClick={e => (props.onNextPage || selectedChangedHandler)(selectedIndex - 1)} />
					<IconButton label="Continue" disabled={selectedIndex >= props.tabs.length - 1} className="btnWizardNext" icon={"arrow-right"} onClick={e => (props.onNextPage || selectedChangedHandler)(selectedIndex + 1)} />
				</div>}
		</div>	
	);
}