import React from "react";

export const IconButton = (props: {
	disabled?: boolean,
	icon: any,
	label?: string,
	onClick: (e: React.MouseEvent) => void,
	style?: React.CSSProperties,
	className?: string,
	title?: string,
	children?: any,
}) => {
	let { label, icon, style, children, className, ...buttonProps } = props;
	
	style = style ? { ...style } : {};
	if (props.label && !style.width) {
		style.width = "fit-content";
	}
	if (props.disabled) {
		style.color = "gray";
	}
	let iconClassName = "";
	if (props.icon instanceof Array) {
		iconClassName = props.icon[0] + props.icon[1];
	} else if (props.icon.prefix) {
		iconClassName = props.icon.prefix + props.icon.icon;
	} else if(props.icon) {
		iconClassName = "fa-" + props.icon as string;
	}
	if (iconClassName)
		iconClassName = "fa " + iconClassName;

	return (<button className={"iconButton "+ (className||"")} style={style} {...buttonProps}>
		{children}
		{iconClassName && <i className={iconClassName} />}
		{label && <span className="iconButtonLabel">{label}</span>}
	</button>
	)
}
