import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Path, useNavigate } from "react-router-dom";
import { ICommand, IList, IListColumn, IViewRecord } from "../AppSchema";
import { getFetchAttributes, loadGuiLists, MetaContext, tryLocalize, updateGuiList, getFetchAttributesSimple, checkCommandPermissions, checkPermissions } from "../AppState";
import { Fetch, FetchAttribute, FetchEntity, FetchFilter, FetchOrder, mergeFetch } from "shared/fetch";
import { IDictionary, IMetaProperty, MetaPropertyFlags, MetaPropertyType, IExecuteRequest, IMetaObject, ILookupValue } from "shared/schema";
import { ListSelector } from "./ListSelector";
import { useDerivedState } from "../hooks/useDerivedState";
import { BaseObjectList, prepareListColumns } from "./BaseObjectList";
import { useSessionState } from "../hooks/useSessionState";
import { TitleBox } from "../components/TitleBox";
import { ObjectMap } from "../objectMap/ObjectMap";
import { DataService } from "../service";
import { runCommand, runCommandWithContext, tryCompileCommand } from "../command/runCommand";
import { ObjectCalendar } from "../objectCalendar/ObjectCalendar";
import { executeExportCommand, exportFileFromList } from "../services/export";
import { showImportDialog } from "../services/importDialog";
import { IModalContext, ModalContext, showMenu, showWait } from "../modal/Modal";
import { useSizeQuery } from "../hooks/useSizeObserver";
import { newGuid } from "../utils/guid";
import { CustomizeContext, CustomizeModeHeader, useCustomizeClone } from "../objectForm/customize/customizeContext";
import { configureFormFieldDialog, useConfigureCommands } from "../objectForm/Configure";
import { showFetchDialog } from "../customize/FetchDialog";
import { getDropTarget, useDragProps } from "../components/draggable";
import { ActivityList } from "./ActivityList";
import { QuickFilterPanel, useQuickFilter } from "./QuickFilter";
import { ObjectChart } from "../objectChart/ObjectChart";
import { onMultiActionsCommand } from "../services/massEditDialog";
import { resizeImage, fileToBase64 } from "../services/download";

// import { parseAndPrintExpression } from "../services/rollups";


export interface IOuterEditContext {
	registered: boolean,
	register: () => void,
	onChanged: (prevValue: any, value: any) => void,
	doSave?: () => Promise<boolean>,
}

export interface IObjectListProps {
	objectName: string;
	mergedFetch?: Fetch;
	customizeList?: (listBody: IList) => IList;
	newParams?: IDictionary<any>;
	//selectedListName?: string,
	//selectedListChanged?: (listName: string) => void,
	onRenderCell?: (obj: any, field: string) => React.ReactNode;
	commands?: ICommand[];
	onCommand?: (cmd: any) => boolean;
	allowedViews?: string[];
	initialView?: string;
	excludedColumns?: string;
	ownerid?: ILookupValue;
	editContext?: IOuterEditContext;
}

export const useListSelector = (objectName: string, initialView?: string, allowedViews?: string[]) => {
	const metadata = useContext(MetaContext);
	const availableLists = useMemo(() => loadGuiLists(metadata, objectName, "Public", allowedViews), [objectName, allowedViews]);
	const initialListName = (initialView && availableLists.findIndex(x => x.name === initialView) > 0) ? initialView : availableLists[0].name;
	const [selectedListName, setSelectedListName] = useSessionState(objectName + "_selectedList", initialListName, [objectName, initialListName]);
	const selectedList = availableLists.find(x => x.name === selectedListName) || availableLists[0];

	return { selectedList, setSelectedListName, availableLists };
}

export const ObjectList = (props: IObjectListProps) => {
	const objectName = props.objectName;
	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);
	const meta = metadata.objects.find(x => x.logicalName === objectName);
	if (!meta) throw Error("No Object:" + objectName);

	const { selectedList, setSelectedListName, availableLists } = useListSelector(objectName, props.initialView, props.allowedViews);

	let listSelector = <>
		<ListSelector
		className="listSelector"
		value={selectedList}
		keyName="name" getLabel={(x: IViewRecord) => tryLocalize(metadata, x.label || x.name, x.name)} options={availableLists}
		onChange={x => setSelectedListName(x.name)}
		/>
		<CustomizeModeHeader style={{ width: "180px" }} onSave={()=>updateGuiList(metadata, meta, undefined, selectedList, selectedListBody)}/>
	</>

	const selectedListBody = useCustomizeClone(selectedList.parsedBody) as IList;
	
	return <SingleObjectList {...props} selectedListBody={selectedListBody} title={selectedList.label||selectedList.name} listSelector={listSelector} />
}
export const SingleObjectList = (props: IObjectListProps & { title: string, selectedListBody: IList, listSelector: React.ReactNode }) => {
	const objectName = props.objectName;
	const customize = useContext(CustomizeContext);
	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);
	const meta = metadata.objects.find(x => x.logicalName === objectName);
	if (!meta) throw Error("No Object:" + objectName);

	const navigate = useNavigate();

	const selectedListName = props.title;
	let selectedListBody = props.selectedListBody;

	const calendarOpts = selectedListBody.commands?.find(x => x.name === "CmdCalendar")?.option;
	const [calStartField, calEndField, calIsInitialMode, calInitialView] = (calendarOpts || "").split(';');

	let [search, setSearch] = useSessionState(objectName + "_Search", "", [selectedListName]);
	let [orderBy, setOrderBy] = useSessionState<FetchOrder | undefined>(objectName + "_Order", undefined, [selectedListName]);
	let [viewMode, setViewMode] = useSessionState(objectName + "_viewMode", calIsInitialMode === "initial_mode" ? 2 : 0, [objectName]);
	const [gps, setGps] = useState([] as number[]);
	let [quickState, quickFilter] = useQuickFilter(selectedListBody);
	let [chartFilter, setChartFilter] = useSessionState<FetchFilter | undefined>(objectName + "_chartFilter", undefined as any as FetchFilter, [objectName]);

	const forceReload = useCallback(() => setGps([]), [setGps]); // force reset query

	if (props.customizeList)
		selectedListBody = props.customizeList(selectedListBody);
	const q = useMemo(() => {
		// or find a better way to deep-clone.
		const q = JSON.parse(JSON.stringify(selectedListBody.query)) as Fetch;
		if (search) {
			const sf: FetchFilter = {
				conditions: [
					{ attribute: "name", operator: "like", value: search + "%" }
				]
			};
			q.entity.filter = q.entity.filter ?
				{ filters: [q.entity.filter, sf] } : sf;
		}
		
		mergeFetch(q, props.mergedFetch);
		if (quickFilter)
			mergeFetch(q, quickFilter);

		if (viewMode === 1 && gps && gps.length > 0) {
			const addAttr = (attrs: FetchAttribute[] | undefined, name: string) => {
				if (!attrs?.find(x => x.attribute === name)) {
					attrs?.push({ attribute: name });
				}
			}
			addAttr(q.entity.attributes, "latitude");
			addAttr(q.entity.attributes, "longitude");
			const f2: FetchFilter = {
				conditions: [
					{ attribute: "latitude", operator: "lt", value: gps[0].toString() },
					{ attribute: "latitude", operator: "gt", value: gps[2].toString() },
					{ attribute: "longitude", operator: "lt", value: gps[1].toString() },
					{ attribute: "longitude", operator: "gt", value: gps[3].toString() },
				]
			}
			if (q.entity.filter)
				q.entity.filter = { filters: [q.entity.filter, f2] };
			else
				q.entity.filter = f2;
		}
		if (viewMode === 3 && chartFilter) {
			if (q.entity.filter)
				q.entity.filter = { filters: [q.entity.filter, chartFilter] };
			else
				q.entity.filter = chartFilter;
		}
		if (orderBy) {
			q.entity.orders = (q.entity.orders || []).filter(x => !!x.mandatory).concat(orderBy);
		}
		return q;
	}, [search, JSON.stringify(props.mergedFetch), selectedListBody, selectedListBody.query, orderBy, viewMode, gps, quickFilter, chartFilter]);

	const [changedRecords, setChangedRecords] = useDerivedState([] as any[], [q]);
	const startEditMode = !customize.enabled && selectedListBody.commands && selectedListBody.commands.find(x => x.name === "CmdAutoEdit")
	const [editMode, setEditMode] = useDerivedState(startEditMode ? 1 : 0, [q]);
	if (changedRecords.length > 0) {
		// disable all methods that could reload the list and loose changes
		setOrderBy = nop;
		//setSelectedListName = nop;
		setSearch = nop;
		setViewMode = nop;
		quickState.setSelected = nop;
	}

	if (editMode > 0 && props.editContext) {
		props.editContext.register();
		props.editContext.doSave = () => saveCallback.current();
	}

	const onSortClick = useCallback((field: string) => {
		setOrderBy(x => {
			if (x && x.attribute === field) {
				return { ...x, descending: !x.descending };
			} else {
				let asc = true;
				const propMeta = meta.properties.find(x => x.logicalName === field);
				if (propMeta && (propMeta.type === MetaPropertyType.Decimal ||
					propMeta.type === MetaPropertyType.Money ||
					propMeta.type === MetaPropertyType.Integer ||
					propMeta.type === MetaPropertyType.Float ||
					propMeta.type === MetaPropertyType.DateTime))
					asc = false; // desc
				const alias = field.indexOf("__") > 0 ? field : undefined;
				return { attribute: field, alias: alias, descending: !asc };
			}
		});
	}, [setOrderBy]);

	const onNewRecord = useRef<(record: any) => void>();

	const executableCommands = useMemo(() => {
		let commands = props.commands || selectedListBody.commands || [{ icon: "plus", label: "", name: "CmdNew" }];
		commands = commands.filter(x => {
			let perms = x.permissions;
			if (x.option === "RenderCell") return false;
			if (x.name === "CmdAutoEdit") return false;
			if (!perms && x.name === "CmdNew") perms = objectName + ";0";
			if (!perms && x.name === "CmdEdit") perms = objectName + ";2";
			return !perms || checkCommandPermissions(false, metadata, { "ownerid": props.ownerid }, perms);
		});
		if (editMode) {
			if (!commands.find(x => x.name === "CmdEdit") && !selectedListBody.commands?.find(x=>x.name === "CmdAutoEdit"))
				commands.push({ name: "CmdEdit" });
			if (editMode === 2)
				commands.splice(commands.findIndex(x => x.name === "CmdEdit"), 0, { name: "CmdMultiActions", icon: "hammer", label: "" });
			commands = commands.map(x => x.name === "CmdEdit" ? { icon: "save", label: "", name: "CmdSave" } : x);
		}
		return commands;
	}, [objectName, props.commands, editMode, selectedListBody, props.ownerid]);
	
	const [render, setRender] = useState(false); const forceRender = () => setRender(x => !x);

	const [columns, renderHeader] = useCustomizeHeader(customize.enabled, selectedListBody.columns, selectedListBody, forceRender, editMode === 2, setChangedRecords);
	let [commands, renderCommand] = useConfigureCommands(customize.enabled, executableCommands,
		selectedListBody, forceRender, ["CmdNew", "CmdEdit", "CmdMap", "CmdCalendar", "CmdChart", "CmdImport", "CmdExport"]);

	const saveChangedRecords = async () => {
		if (!changedRecords.length)
			return true;

		try {
			const requests = changedRecords.map(x => {
				const { __operation, ...obj } = x;
				return { operation: __operation || "update", object: obj, name: objectName } as IExecuteRequest
			});
			await DataService.executeMultiple(requests);
			setChangedRecords([]);

			const record = requests[0].object;
			const postsave = { name: objectName + ".postsave", kind: "custom" as any };
			await runCommand(postsave, () => record, record, (x) => { }, metadata, modal);

			forceReload();
			return true;
		}
		catch (e) {
			alert(e);
			return false;
		}
	}
	const saveCallback = useRef<any>(null);
	saveCallback.current = saveChangedRecords;
	
	const onCommand = useCallback((cmd: ICommand, e: React.MouseEvent) => {
		if (props.onCommand && props.onCommand(cmd))
			return;
		if (cmd.name === "CmdMap") {
			setViewMode(x => x === 1 ? 0 : 1);
		} else if (cmd.name === "CmdCalendar") {
			setViewMode(x => x === 2 ? 0 : 2);
		} else if (cmd.name === "CmdChart") {
			setViewMode(x => x === 3 ? 0 : 3);
		} else if (cmd.name === "CmdNew") {
			if (editMode && onNewRecord.current) {
				onNewRecord.current({ id: newGuid(), ...props.newParams, name: meta.displayName!, __operation: "create" });
				return;
			}
			const to: Path = { pathname: "/" + metadata.appId + "/edit/" + objectName + "/0", search: "", hash: "" };
			if (props.newParams) {
				const pp = new URLSearchParams({ init: JSON.stringify(props.newParams) });
				to.search = pp.toString();
			}
			navigate(to);
		}
		else if (cmd.name === "CmdSave") {
			// FIXME: show please wait
			saveChangedRecords();
			setEditMode(0);
		}
		else if (cmd.name === "CmdEdit") {
			setEditMode(cmd.option === "super" ? 2 : 1);
		}
		else if (cmd.name === "CmdExport") {
			executeExportCommand(modal, metadata, e.target as HTMLElement, q, selectedListBody.columns, undefined, cmd.option);
		}
		else if (cmd.name === "CmdImport") {
			showImportDialog(modal, objectName, cmd.option);
		}
		else if (cmd.name === "CmdSort") {
			(async () => {
				const index = await showMenu(modal, e.target as HTMLElement, meta.properties.map(x => x.displayName || x.logicalName));
				const prop =  meta.properties[index].logicalName;
				onSortClick(prop);
			})();
		}
		else if (cmd.name === "CmdMultiActions") {
			(async () => {
				const done = await onMultiActionsCommand(metadata, modal, e.target as HTMLElement, meta, changedRecords, q, columns);
				if (done) {
					setChangedRecords([]);
					setEditMode(0);
					forceReload();
				}
			})();
		}
		else if (cmd.name === "CmdUpload") {
			uploadRef.current?.click();	
		}
		else {
			runCommand(cmd, () => undefined, undefined, (x) => { }, metadata, modal, { newParams: props.newParams });
		}
	}, [objectName, props.newParams, navigate, props.onCommand, changedRecords, setChangedRecords, editMode]);

	const onSetRecord = useCallback((record: any) => {
		setChangedRecords(r => {
			let nr = [...r];
			const prevIndex = r.findIndex(x => x.id === record.id);
			const prev = r[prevIndex]; // -1 => undefined
			if (prev)
				nr.splice(prevIndex, 1);
			if (!(prev?.__operation === "create" && record.__operation === "delete")) // new -> delete, just ignore
				nr = nr.concat(record);
			
			if (props.editContext)
				props.editContext.onChanged(r, nr);
			
			return nr;
		});
	}, [setChangedRecords]);

	let rc = useMemo(() => {
		let rc = props.onRenderCell;
		let renderCell = selectedListBody.commands?.find(x => x.option === "RenderCell")
		if (!renderCell) {
			const defaultRenderCell = { type: "custom", name: meta.logicalName + ".renderCell" };
			if (tryCompileCommand(metadata, defaultRenderCell))
				renderCell = defaultRenderCell;
		}
		if (renderCell) {
			rc = (obj: any, field: string) => {
				let result = props.onRenderCell && props.onRenderCell(obj, field);
				if (result === undefined)
					result = runCommandWithContext(metadata, renderCell!, { obj, field }) as any;
				return result;
			}
		}
		return rc;
	}, [selectedListBody.commands, props.onRenderCell]);
	let onRecordClick: undefined | ((obj: any) => void) = undefined;

	const [mainRef, showMosaic] = useSizeQuery<HTMLDivElement>(420);
	// if (showMosaic)
	// 	commands = [{ name: "CmdSort", icon: "arrow-up-short-wide", label: "" } as ICommand].concat(commands);

	useEffect(() => {
		const keyHandler = (e: KeyboardEvent) => {
			if ((e.metaKey || e.altKey) && e.key === "e") {
				setEditMode(2);
				e.preventDefault();
			}
		}
		if (checkPermissions(metadata, {}, "systemuser", 3))
			window.addEventListener("keydown", keyHandler);
		return () => window.removeEventListener("keydown", keyHandler);
	}, [setEditMode]);

	const ObjectListComponent = objectName === "activity" ? ActivityList : BaseObjectList;

	useEffect(() => {
		if (!editMode) {
			const changeHandler = (e: any) => {
				// this might be too heavyhanded...
				forceReload();
			}
			const eventName = "objectChanged:" + q.entity.name;
			window.addEventListener(eventName, changeHandler);
			return () => window.removeEventListener(eventName, changeHandler);
		}
	}, [q.entity.name, editMode]);

	const onFileDragOver = (e: React.DragEvent) => {
		if (commands?.find(x => x.name === "CmdUpload"))
			e.preventDefault();
	}

	const onUploadFiles = async (e: any) => {
		const uploadCommand = commands?.find(x => x.name === "CmdUpload");
		if (uploadCommand) {
			e.preventDefault();
			await uploadFiles(modal, e.dataTransfer?.files || e.target.files, props.objectName, uploadCommand, props.newParams);
			forceReload();
		}
	}
	const uploadRef = useRef<HTMLInputElement>(null);

	return meta && objectName ? (
		<div className="objectListContainer" ref={mainRef} onDrop={onUploadFiles} onDragOver={onFileDragOver}>
			<TitleBox title={editMode ? tryLocalize(metadata, props.title) : undefined} noSpacer={true} commands={commands} onCommand={onCommand} renderCommand={renderCommand}>
			
				{!editMode && props.listSelector}
				<CustomizeFetch listBody={selectedListBody} onSave={forceRender} />
				<div style={{ flex: "1 1 auto" }} />
				<ListSearchBox search={search} setSearch={setSearch} listColumns={columns} onSortClick={onSortClick} />
			</TitleBox>
			{(selectedListBody.quickFilters || customize.enabled) && <QuickFilterPanel list={selectedListBody} state={quickState} customize={customize.enabled} />}
			{(viewMode === 1) && <ObjectMap query={q} onGpsChanged={bounds => setGps(bounds)} />}
			{(viewMode === 2) && <ObjectCalendar query={q} startField={calStartField} endField={calEndField} initialView={calInitialView} />}
			{(viewMode === 3) && <ObjectChart query={q} chartFilter={chartFilter} setChartFilter={setChartFilter} />}
			{(viewMode !== 2) && <ObjectListComponent editMode={!!editMode} query={q} showMosaic={showMosaic}
				listColumns={columns}
				newParams={props.newParams}
				onSortClick={onSortClick}
				onRenderCell={rc}
				onSetRecord={onSetRecord}
				onNewRecord={onNewRecord}
				onClick={onRecordClick}
				renderHeader={renderHeader}
				excludedColumns={customize.enabled ? undefined : props.excludedColumns}
			/>}
			<input ref={uploadRef} hidden={true} type="file" multiple onChange={e => e.target.value = null as any} 
				onInput={onUploadFiles} />
		</div>
	) :
		(<div>Object not found: {objectName}</div>)
}

const uploadFiles = async (modal: IModalContext, files: FileList, objectName: string, cmd: ICommand, newParams: any) => {
	const opts = (cmd.option && JSON.parse(cmd.option)) || {};
	const resizeHeight = opts.resizeHeight;
	const bodyField = opts.bodyField || "body";
	const nameField = opts.nameField;
	const maxHeight = 0;

	// copy files, because the loop has async code, thus the original array will not be accessible (in upload)
	const fileArray = [];
	for (let i = 0; i < files.length; i++) {
		fileArray.push(files[i]);
	}

	const info = { title: "Uploading...", subTitle:"" };
	const w = showWait(modal, info);

	try {
		const reqs = []
		for(const f of fileArray) {	
			let base64body: string;
			let resize = resizeHeight === "0" ? 0 : (resizeHeight ? (+resizeHeight) : maxHeight);
			if (resize && f.type.match(/image.*/))
				base64body = await resizeImage(f, resize);
			else
				base64body = await fileToBase64(f);
			const newRecord = { ...newParams, [bodyField]: base64body };
			if (nameField)
				newRecord[nameField] = f.name;
			if (objectName === "document")
				newRecord.name = f.name;
			
			reqs.push({ name: objectName, operation: "create", object: newRecord } as IExecuteRequest);
		}

		await DataService.executeMultiple(reqs);
	}
	catch (e) {
		alert(e);
	}
	finally {
		w(null);
	}
}

const useCustomizeHeader = (customize: boolean, columns: IListColumn[], parent: IList, onSave: () => void, massEditMode: boolean, setChangedRecords: (x:any[])=>void):
	[IListColumn[], any] => {
	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);

	useMemo(() => {
		if (customize) {
			const { listColumns } = prepareListColumns(metadata, parent.query, parent.columns, false);
			parent.columns = listColumns;
		}
	}, [customize, parent]);
	
	const configureClick = useCallback(async (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		const item = getDropTarget(e);
		const itemIndex = +(item.dataset["drag_index"] || 0);
		let f = (parent.columns || [])[itemIndex];

		if (!f) {
			const availableColumns = getFetchAttributes(metadata, parent.query);
			const mm = (title: string, sub: string) => {
				return <div style={{display:"flex", justifyContent:"space-between"}}><span>{title}</span><span style={{color:"gray", marginLeft:"2em"}}>{sub}</span></div>
			}
			const c = await showMenu(modal, e.target as any, availableColumns.map(x => mm(x.prop.displayName || x.prop.logicalName, x.attribute)));
			f = availableColumns[c];
		}

		const mb = (name: string) => ({ logicalName: name, type: MetaPropertyType.Boolean, flags: 0 });
		const attrs = [{ logicalName: "attribute", displayName: "Name", type: MetaPropertyType.String, flags: 0, isDisabled: true },
			"label", "width", "cssClass", mb("disabled"), mb("nolink"), "formName", "mobileOrder", "mobileLabel"];
		parent.columns = parent.columns || [];
		configureFormFieldDialog(modal, f, parent, "columns", attrs, e.target as HTMLElement, () => {
			onSave();
		});
	}, [parent, onSave]);
	const dragDropCommand = useCallback((dragIndex: number, dropIndex: number) => {
		const arr = parent.columns || [];
		const field = arr.splice(dragIndex, 1)[0];
		arr.splice(dropIndex, 0, field);
		onSave();
	}, [parent.columns]);

	const cmdDrag = useDragProps(customize, dragDropCommand);

	let render: any = undefined;
	if (customize) {
		//const { listColumns, attrs } = prepareListColumns(metadata, parent.query, parent.columns);
		//parent.columns = listColumns;
		columns = (parent.columns || []).concat({ attribute: "name", width: "40px" });

		render = (index: number, header: string) => {

			if (index === columns.length - 1) {
				return <div  {...cmdDrag} onDragStart={undefined} onClick={configureClick} className="formItemCustomizeAdd" draggable={true} data-kind="commanditem" data-drag_index={index}
					style={{ fontSize: "20px", height: "1em" }}>
					<i className="fa fa-plus"></i></div>
			}

			return <span key={"hdr." + index} {...cmdDrag} onClick={configureClick} data-kind="headeritem" data-drag_index={index}>
				{header}
					<span style={{ position: "relative", top: "0", left: "0" }} className="formItemCustomize"><i className="fa fa-gears" /></span>
				</span>
		}
	}

	if (!customize && massEditMode) {
		const checkBoxAttribute = "selection_checkbox";
		const checkBoxColumn: IListColumn = {
			attribute: checkBoxAttribute,
			width: "40px",
			attr: {
				attribute: checkBoxAttribute,
				prop: { logicalName: "selection_checkbox", displayName: " ", type: MetaPropertyType.Boolean, flags: 0 }
			}
		}
		columns = [checkBoxColumn].concat(parent.columns || [{ attribute: "name" }]);
		render = (index: number, header: string) => {
			if (index === 0) { // fixme: handle click and select all???
				const handleSelectAllClick = (e: React.MouseEvent) => {
					//e.preventDefault();
					e.stopPropagation();
					const cb = e.target as HTMLInputElement;

					// Trigger the click event on checkboxes so that react onChange fires
					// Inspired by https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-change-or-input-event-in-react-js
					// 1. get the native setter for checked, 2. set checked 3. raise click event
					const grid = cb.parentElement?.parentElement;
					if (grid) {
						const nodes = grid.querySelectorAll("input#selection_checkbox");
						for (let i = 0; i < nodes.length; i++) {
							const recordCB = nodes[i] as HTMLInputElement;

							const nativeInputValueSetter = (Object as any).getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "checked").set;
							nativeInputValueSetter.call(recordCB, cb.checked===true);
							var ev2 = new Event('click', { bubbles: true});
							recordCB.dispatchEvent(ev2);
						}
					}
				}
				return <input type="checkbox" onClick={handleSelectAllClick} />
			}
			return header;
		}
	}
	return [columns, render];

}

const CustomizeFetch = (props: { listBody: IList, onSave: () => void }) => {
	const customize = useContext(CustomizeContext);
	const modal = useContext(ModalContext);

	if (!customize.enabled) return null;

	const onEditFilter = (e: any) => {
		const oldAttrs = getFetchAttributesSimple(props.listBody.query.entity);
		const q = JSON.parse(JSON.stringify(props.listBody.query));
		showFetchDialog(modal, q, () => {

			const newAttrs = getFetchAttributesSimple(q.entity);
			const cols = [...props.listBody.columns];
			let changed = false;
			for (const n of newAttrs) {
				const i = oldAttrs.indexOf(n);
				if (i >= 0) {
					oldAttrs.splice(i, 1);
				} else {
					cols.push({ attribute: n });
					changed = true;
				}
			}
			for (const o of oldAttrs) {
				const i = cols.findIndex(col => col.attribute === o);
				if (i >= 0) {
					cols.splice(i, 1);
					changed = true;
				}
			}
			if (changed)
				props.listBody.columns = cols;

			props.listBody.query = q;
			props.onSave();
		});
	}

	return <CustomizeButton onClick={onEditFilter}>Filter</CustomizeButton>
}

const CustomizeButton = (props: { children?: any, onClick: (e: any) => void }) => {
	return <span onClick={props.onClick} style={{ position: "relative", top: "0", left: "0", color:"gray", cursor:"pointer" }} className="formItemCustomize">{props.children}<i className="fa fa-gears" /></span>
}

const nop = () => { alert("Operation not available while editing list.") }

const ListSearchBox = (props: {
	search: string;
	setSearch: (text: string) => void;
	listColumns: IListColumn[];
	onSortClick: (field: string) => void;
}) => {

	const { search, setSearch } = props;

	let className = "formItem objectListSearchBox ";
	if (search)
		className += "hasContent";
	
	const modal = useContext(ModalContext);
	const onSortClick = useCallback(async (e: React.MouseEvent) => {
		const index = await showMenu(modal, e.target as HTMLElement, props.listColumns.map(x => x.label || x.attribute));
		const prop = props.listColumns[index].attribute;
		props.onSortClick(prop);
	},[props.onSortClick, props.listColumns]);

	return <div className={className}>
		{/* <label className="formLabel" htmlFor="listSearch">Search</label> */}
		<input className="formEditor" id="listSearch" type="text" value={search} onChange={e => setSearch(e.target.value)} />
		<i className="searchIcon fa fa-search"></i>
		<i tabIndex={0} className="searchSortIcon fa fa-arrow-up-short-wide" onClick={onSortClick}/>
	</div>
}