import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { To, useLocation, useNavigate, useParams } from "react-router-dom";
import { IAppConfig, ICommand, IForm } from "../AppSchema";
import { MetaContext, tryLocalize, updateGuiForm, useGuiForm, checkCommandPermissions, getGuiForm, makeCreateOnlyForm } from "../AppState";
import { IDictionary, IMetadata, IMetaObject, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { DataService } from "../service";
import { Loading } from "../components/Loading";
import { useDerivedState } from "../hooks/useDerivedState";
import { PERMISSION_CREATE, PERMISSION_DELETE, PERMISSION_UPDATE } from "shared/permissions";
import { IModalContext, IModalProps, ModalContext, showWait } from "../modal/Modal";
import { runCommand } from "../command/runCommand";
import { FieldProps, IFormContext, IPanelProps, PanelProps, PanelPropsEventType } from "./panels/PanelProps";
import { changePassword, sendInvite } from "../login/ChangePasswordForm";
import { Customizable, CustomizeModeHeader, useCustomizeClone } from "./customize/customizeContext";
import { MainFormPanel } from "./panels/MainFormPanel";
import { usePrompt } from "../UsePrompt";
import { invoiceReport } from "../report/invoiceReport";
import { fetchServerMetadata, updateMetadata } from "../customize/Metadata";
import { getLanguage } from "../services/localize";
import { showCalendarDialog } from "../objectCalendar/ObjectCalendar";
import { generateWordFile } from "../report/wordReport";
import { showConfirm, showError, showMessage, showTooltipInfo } from "../modal/alerts";
import { useFormState } from "./useFormState";
import { useFormChangeWatcher } from "./useFormChangeWatcher";
import { exportRecordAsPdf } from "../services/exportRecord";

const isEmptyValue = (value: any) => {
	return value === "" || value === undefined || value === null;
}

export const ObjectForm = () => {
	const objectName = useParams<string>()["name"] || "";
	const initialFormName = useParams<string>()["form"] || "";
	const { search } = useLocation();
	const id = useParams<string>()["id"] || "";

	const metadata = useContext(MetaContext);
	const meta = metadata.objects.find(x => x.logicalName === objectName) as IMetaObject;
	if (!meta) throw Error("No Meta");

	const [formName, __setFormName] = useDerivedState(initialFormName, [objectName]);
	const setFormName = __setFormName;
	//const setFormName = useCallback((fname: string) => setTimeout(() => __setFormName(fname), 0), [__setFormName]);

	const form = useGuiForm(meta, formName);

	const onSave = useCallback((panelProps: PanelProps) => { }, []);
	const onLoad = useCallback((panelProps: PanelProps) => { document.title = (panelProps.meta.displayName || "") + " - " + (panelProps.objectLabel || " * ") }, []);

	return <Customizable>
		<BaseObjectForm id={id} meta={meta} form={form} search={search} onLoad={onLoad} onSave={onSave} formName={formName} setFormName={setFormName} />
	</Customizable>
}

export const showObjectFormDialog = (modal: IModalContext, metadata: IAppConfig, objectName: string, formName: string, search: string, onClose: any) => {
	const meta = metadata.objects.find(x => x.logicalName === objectName)!;
	let form = getGuiForm(metadata, meta, formName);
	if (form.name !== formName) { // fallback -> prune
		form = makeCreateOnlyForm(form);
	}

	const pp: IModalProps = {
		body: (p) => <BaseObjectForm id={"0"} meta={meta} form={form} search={search} onLoad={() => { }} onSave={() => { }}
			onClose={(r) => { p.commandSite.closeModal(); onClose(r); }} />,
		bodyProps: {

		},
		id: "createRecordDialog",
		showTitle: false,
	};
	modal.showModal(pp);
}

// export const InlineObjectForm = (props: { id: string, objectName: string, search?: string }) => {
// 	const metadata = useContext(MetaContext);
// 	const meta = metadata.objects.find(x => x.logicalName === props.objectName) as IMetaObject;
// 	if (!meta) throw Error("No Meta");

// 	const form = useGuiForm(meta);

// 	const onSave = useCallback((panelProps: PanelProps) => { }, []);
// 	const onLoad = useCallback((panelProps: PanelProps) => { }, []);

// 	return <BaseObjectForm id={props.id} meta={meta} form={form} search={props.search || ""} onLoad={onLoad} onSave={onSave} />
// }

export interface IBaseObjectFormProps {
	id: string;
	search: string; // initData
	meta: IMetaObject;
	form: IForm;
	newObjectUrlBase?: string;

	onLoad(props: PanelProps): void;
	onSave(props: PanelProps): void;
	onClose?: (record: any) => void;

	formName?: string;
	setFormName?: (name: string) => void;
}

const runFormCommand = async (command: ICommand, metadata: IAppConfig, modalSite: IModalContext,
	panelProps: IFormContext, prevRecord: any, commands: ICommand[] | undefined = undefined) => {
	
	return runCommand(command, panelProps.getRecord, prevRecord, panelProps.setRecord, metadata, modalSite, {
		fieldProps: panelProps.fieldProps,
		runtime: panelProps, // duplicate, but already used in production :(
		formContext: panelProps, // call it what it is
		getService: panelProps.getService,
		setFormName: (panelProps as any).setFormName,
		commands: commands,
		meta: panelProps.meta,
	});
}
export const runRenderScript = async (onRenderScript: ICommand, metadata: IAppConfig, modalSite: IModalContext,
	panelProps: IFormContext & { prevRecord: any },
	commands: ICommand[] | undefined = undefined) => {
	const exe = async () => {
		onRenderScript.runState = "running";
		const getRecord = panelProps.getRecord;
		const setRecord = panelProps.setRecord;
		try {
			
			const prevRecord = panelProps.prevRecord;
			panelProps.prevRecord = getRecord();
			runFormCommand(onRenderScript, metadata, modalSite, panelProps, prevRecord, commands);
		}
		catch (e) { alert(e); }
		
		const wasScheduled = onRenderScript.runState as any === "scheduled"
		onRenderScript.runState = undefined;

		if (wasScheduled) {
			//console.log("RENDER---RE----SCHEDULE");
			(setRecord as any)({ ...getRecord() }, undefined, true);
		}
	};
	if (!onRenderScript.runState) {
		exe();
	} else {
		// StrictMode will call this twice, ignore.
		// There is a small chance we get called twice in other context, but nothing changed so we don't care
		if (panelProps.prevRecord === panelProps.getRecord()) {
			return;
		}
		onRenderScript.runState = "scheduled";
	}
}

const validateRecord = (metadata: IAppConfig, modal: IModalContext, panelProps: IFormContext) => {
	const rec = panelProps.getRecord();
	const fieldProps = panelProps.fieldProps || {};
	let sb = "";

	for (const propMeta of panelProps.meta.properties) {
		const fieldName = propMeta.logicalName;
		const fp = fieldProps[fieldName];
		const isRequired = (((propMeta.flags & MetaPropertyFlags.Required) !== 0) || fp?.required) && fp?.visible !== false;

		if (isRequired && isEmptyValue(rec[fieldName])) {
			sb += "\n- " + (fp?.label || propMeta.displayName);
		}
	}
	if (sb) {
		const msg = tryLocalize(metadata, "MsgRequiredFieldEmpty", "Please provide a value for")
		showMessage(modal, msg + ": " + sb);
		return false;
	}
	return true;
}
	
export const BaseObjectForm = (props: IBaseObjectFormProps) => {
	const navigate = useNavigate();
	const metadata = useContext(MetaContext);
	const modalSite = useContext(ModalContext);
	const { id, search } = props;
	const isNewRecord = id === "0";
	const form = useCustomizeClone(props.form);
	const meta = useCustomizeClone(props.meta);

	const onSaveNew = useCallback((objectName: string, record: any) => {
		const newId = record["id"];

		// try {
		// 	const modalParentSaveCallback = (window as any)["onSaveCallback"];
		// 	if (modalParentSaveCallback) {
		// 		modalParentSaveCallback({ id: newId, name: record["name"] });
		// 	}
		// } catch (err) {
		// 	console.log(err);
		// }

		if (props.onClose) {
			props.onClose({ id: newId, name: record["name"] });
			return;
		}

		navigate((props.newObjectUrlBase || "/!/edit/" + objectName + "/") + newId, { replace: true });
	}, []);
	
	const formState = useFormState(id, meta, search);
	const { getRecord, setRecord, loading, saving, loadRecord, saveRecord, isDirty } = formState;

	const panelProps = useMemo(() => ({
		panel: form.panel,
		meta: meta,
		id: id || "",
		objectLabel: "",
		getRecord: getRecord,
		setRecord: setRecord,
		prevRecord: undefined as any,
		fieldProps: {} as FieldProps,
		events: [] as ((eventName: PanelPropsEventType) => Promise<boolean>)[],
		session: { version: (new Date().valueOf()) },
		watcher: new Date(),
		formName: props.formName,
		setFormName: props.setFormName,
		getService: (serviceId: string) => undefined as any,
	}), [id, meta]);
	if (panelProps.formName !== props.formName) {
		panelProps.formName = props.formName;
		panelProps.prevRecord = undefined as any;
	}

	useFormChangeWatcher(formState, panelProps, () => showTooltipInfo(modalSite, metadata, "MsgRecordUpdated"));

	const [onRenderScript, onSaveScript, onPostSaveScript] = useMemo(() => {
		return [{ kind: "custom", name: (form.events && form.events.change) || meta.logicalName + ".change" },
		{ kind: "custom", name: (form.events && form.events.validate) || meta.logicalName + ".validate" },
		{ kind: "custom", name: (form.events && form.events.postsave) || meta.logicalName + ".postsave" }] as ICommand[]
	}, [meta.logicalName]);

	const onSave = useCallback(async (saveAndClose?: boolean) => {
		// FIXME: move to FieldsPanel
		if (!validateRecord(metadata, modalSite, panelProps))
			return false;
		const runHandlers = async (eventName: PanelPropsEventType) => {
			for (const handler of panelProps.events) {
				const result = await handler(eventName);
				if (!result)
					return false;
			}
			return true;
		}
		if (! await runHandlers("validate"))
			return false;

		try {
			await runFormCommand(onSaveScript, metadata, modalSite, panelProps, panelProps.getRecord());
			props.onSave(panelProps);

			if (! await runHandlers("beforeSave"))
				return false;
			
			await saveRecord();
			formState.saving = true;//reset flag we are not done yet.
			panelProps.prevRecord = undefined;

			if (! await runHandlers("afterSave"))
				return false;
			
			// call after child components saved
			await runFormCommand(onPostSaveScript, metadata, modalSite, panelProps, formState.loadedRecord);
				//runCommand(onPostSaveScript, panelProps.getRecord, formState.loadedRecord, panelProps.setRecord, metadata, modalSite);
			
			showTooltipInfo(modalSite, metadata, "MsgRecordSaved");

			if (!saveAndClose) {
				if (id === "0") {
					// allow render of isDirty===false so we don't get an spurious discard changes message.
					// 0 timeout breaks on safari for some strange reason. callback is called before render, thus the record is still dirty
					// which will cause the save or discard message to show.
					//setTimeout(() => onSaveNew(meta.logicalName, panelProps.getRecord()), 1);
					onSaveNew(meta.logicalName, panelProps.getRecord());
				} else {
					// fixme: simplify and move code to the same pattern.
					// usePageState (id, init: ()=>T, save: (value:T)=>void) : [T, (newValue:T)=>void, setDirty(boolean)];
					panelProps.session.version = (new Date().valueOf());
					// keep session only!!!
					//panelProps.session = { version: new Date().valueOf() };
					//panelProps.events = [];
					formState.saving = false;
					loadRecord();
				}
			}

			return true;
		}
		catch (e) {
			showError(modalSite, e);
			return false;
		}
		finally {
			console.log("Form Record SAVED. dirty: " + formState.isDirty);
			formState.saving = false;
		}
	}, [panelProps, saveRecord, props.onSave]);

	const checkRecordDirty = useCallback(() => {
		console.log("CHECK DIRTY: " + formState.isDirty);
		return formState.isDirty;
	}, [formState]);
	usePrompt({
		when: checkRecordDirty,
		message: tryLocalize(metadata, "MsgDiscardChanges"),
		cancelMessage: tryLocalize(metadata, "MsgContinueEditing"),
		saveMessage: tryLocalize(metadata, "CmdSaveAndClose"),
		saveAndExit: useCallback(async (continueWith: any) => {
			const result = await onSave(true);
			continueWith(result === true);
		}, [onSave])
		//,id: "ObjectForm_/"+meta.logicalName+"/"+id
	});
	
	const record = getRecord();

	const cmds = useMemo(() => (form.commands && form.commands.length > 0) ? form.commands.map(x => ({ ...x })) : [{ name: "CmdDelete" }, { name: "CmdSave" }], [form]);
	// FIXME: memo all commands... introduce command.isVisible instead of filter
	const commands = cmds.filter(cmd => {
		if (!loading && record) {
			let perms = cmd.permissions;
			if (!perms) {
				switch (cmd.name) {
					case "CmdSave": perms = `allowNew?;1;${meta.logicalName};${id === "0" ? PERMISSION_CREATE : PERMISSION_UPDATE}`; break; //return id === "0" || record.id && checkPermissions(metadata, record, meta.logicalName, PERMISSION_UPDATE);
					case "CmdDelete": perms = `${meta.logicalName};${PERMISSION_DELETE}`; break;//return id !== "0" && checkPermissions(metadata, record, meta.logicalName, PERMISSION_DELETE);
					case "CmdClone": perms = `${meta.logicalName};${PERMISSION_CREATE}`; break;
				}
			}
			if (perms) {
				return checkCommandPermissions(id === "0", metadata, record, perms);
			}
		}
		return record ? true : false;
	});
	if (meta.logicalName === "audit") {
		commands.splice(0, 0, { name: "CmdUndoDelete", icon: "undo" });
	}
	if (props.onClose)
		commands.push({ name: "CmdClose", icon: "close", label: "" });

	for (const sc of commands) {
		if (sc.name === "CmdSave" || sc.name === "CmdSaveAndClose") {
			if (saving) {
				if (!(sc as any).__originalIcon)
					(sc as any).__originalIcon = sc.icon || "save";
				sc.icon = "hourglass";
			} else {
				if ((sc as any).__originalIcon)
					sc.icon = (sc as any).__originalIcon;
			}
			sc.enabled = !saving && (sc.name !== "CmdSave" || isDirty);
		}
	}
	
	const onCommand = useCallback(async (cmd: ICommand) => {
		switch (cmd.name) {
			case "CmdClose":
				props.onClose!(null);
				return;
			case "CmdBack":
				navigate(-1); // let the usePrompt handler ask to save the record.
				break;
			case "CmdSaveAndClose":
				if (!formState.isDirty || await onSave(true))
					navigate(-1);
				return;
			case "CmdSave":
				onSave();
				return;
			case "CmdDelete":
				if (await showConfirm(metadata, modalSite, "MsgAskDelete")) {
					try {
						await DataService.updateRecord(meta.logicalName, { id: getRecord().id }, "delete");

						const prevRecord = getRecord();
						const zombie = { ...prevRecord };
						delete zombie["id"];
						await runCommand(onPostSaveScript, () => zombie, prevRecord, _ => { }, metadata, modalSite);
						//navigate("/!/list/" + meta.logicalName);	//FIXME: go back!
						formState.isDirty = false;
						navigate(-1);
					} catch (err) {
						alert(err);
					}
				}
				return;
			case "CmdClone":
				// const initObj = new URLSearchParams({ init: JSON.stringify({ ...record }) });
				// const cloneUrl: To = { pathname: "/!/edit/" + meta.logicalName + "/0", search: initObj.toString() };
				// navigate(cloneUrl);
				const clone = { ...getRecord() };
				clone["name"] = "Copy of " + clone["name"];
				delete clone["id"];
				const to: To = {
					pathname: "/" + metadata.appId + "/edit/" + meta.logicalName + "/0",
					search: (new URLSearchParams({ init: JSON.stringify(clone) })).toString(),
				};
				navigate(to);
				return;
			case "CmdChangePassword":
				const currentUserId = metadata.user.id;
				const recordId = meta.logicalName === "systemuser" ? id : currentUserId;
				changePassword(modalSite, recordId, recordId === currentUserId);
				return;
			case "CmdSendInvite":
				await sendInvite(metadata, getRecord());
				alert("Invite Sent");
				break;
			case "CmdRunReport":
				invoiceReport(metadata, id, meta.logicalName, meta.logicalName + "line", meta.logicalName + "id");
				break;
			case "CmdPrint":
				exportRecordAsPdf(metadata, form, panelProps.fieldProps, meta, getRecord(), cmd.option ? JSON.parse(cmd.option) : undefined);
				break;
			case "CmdRunWordReport":
				generateWordFile(metadata, modalSite, meta, getRecord());
				break;
			case "CmdUndoDelete":
				try {
					const r = getRecord();
					const deletedRecord = JSON.parse(r.body);
					await DataService.executeMultiple([{ name: r.logicalname, operation: "create", object: deletedRecord }]);
					alert("Record restored");
				}
				catch (e) {
					alert(e);
				}
				return;
		}
		try {
			await runFormCommand(cmd, metadata, modalSite, panelProps, getRecord(), commands);
			//runCommand(cmd, getRecord, getRecord(), setRecord, metadata, modalSite, { getService: panelProps.getService, meta: meta });
		}
		catch (e) { alert(e); }
	}, [id, getRecord, isDirty]);

	panelProps.getService = useMemo(() => {
		const s = (serviceId: string) => {
			switch (serviceId) {
				case "formName": return { formName: props.formName, setFormName: props.setFormName };
				case "executeCommand": return { executeCommand: (name: string, command?: ICommand) => onCommand(command || commands.find(x => x.name === name) || { name: name, kind: "custom" }) }
				case "calendarService": return { showCalendarDialog: (props: any) => showCalendarDialog(modalSite, props) }
				case "showWait": return { show: (info: any) => showWait(modalSite, info) }
				case "objectForm": return {
					showModal: (objectName: string, formName: string, initDict: string, onClose: any) =>
						showObjectFormDialog(modalSite, metadata, objectName, formName, initDict, onClose)
				}
			}
		};
		return s;
	}, [commands, onCommand]);

	if (!record) return (<div className="formContainer formRecordNotFound">
		{tryLocalize(metadata, "MsgRecordNotFound", undefined, "Record not found")}
		<i>{formState.loadError ||""}</i>
	</div>);

	panelProps.objectLabel = record.name;

	if (!loading) {
		props.onLoad(panelProps);

		runRenderScript(onRenderScript, metadata, modalSite, panelProps, commands);
	}

	return (<div className="formContainer">
		<div style={{ display: loading ? "block" : "none", position: "absolute", top: "0px", width: "100%" }}><Loading /></div>
		<CustomizeModeHeader onSave={()=>customizeSaveForm(modalSite, metadata, meta, form, props.formName)} />
		<MainFormPanel key={meta.logicalName} panelProps={panelProps} form={form} saving={saving} commands={commands} onCommand={onCommand} />
	</div>)
}

const customizeSaveForm = async (modal: IModalContext, metadata: IAppConfig, meta: IMetaObject, form: IForm, formName?: string) => {
	let oldMeta = metadata.objects.find(x => x.logicalName === meta.logicalName) as IMetaObject;
	if (JSON.stringify(oldMeta) !== JSON.stringify(meta)) {
		// if lang is not EN (system default) then remove displayName, displayNamePlural and displayOptions
		// store those strings as localizations instead.
		const dispose = showWait(modal, { title: "Loading..." });
		const oldMetadata = await fetchServerMetadata();
		oldMeta = oldMetadata.objects.find(x => x.logicalName === meta.logicalName) as IMetaObject;
		const lang = getLanguage(oldMetadata);
		if (lang !== "en") {
			const sysMeta = oldMetadata.objects.find(x => x.logicalName === meta.logicalName);
			if (!sysMeta) throw new Error("object not found on server");
			// const locStrings: { name: string, text: string, lang: string }[] = [];
			// if (meta.displayName !== oldMeta.displayName)
			// 	locStrings.push({ name: meta.logicalName, text: meta.displayName || "", lang: lang });
			// if (meta.displayNamePlural !== oldMeta.displayNamePlural)
			// 	locStrings.push({ name: meta.logicalName + ".s", text: meta.displayNamePlural || "", lang: lang });
			// for (let i = 0;i<)
			meta = { ...meta };
			meta.properties = meta.properties.map(x => ({ ...x }));
			meta.displayName = sysMeta.displayName;
			meta.displayNamePlural = sysMeta.displayNamePlural;
			const sysProps = sysMeta.properties.reduce((a, p) => (a[p.logicalName] = p, a), {} as { [s: string]: IMetaProperty });
			for (const propMeta of meta.properties) {
				const sysPropMeta = sysProps[propMeta.logicalName];
				if (sysPropMeta) {
					propMeta.displayName = sysPropMeta.displayName;
				}
			}
		}
		dispose(null);
		const newMetadata = { objects: oldMetadata.objects.map(x => (x === oldMeta) ? meta : x), languages: oldMetadata.languages };
		await updateMetadata(modal, newMetadata);
	}

	await updateGuiForm(metadata, meta, formName, form)
}