import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IconButton } from "../components/IconButton"
import SimpleGrid from "../components/SimpleGrid";
import { formatNumericValue } from "../formatters";
import { IModalContext, IModalParent, ModalContext, showMenu, showWait } from "../modal/Modal";
import { PreviewFileElement, showFilePreviewDialog } from "../objectForm/fields/ImageField";
import { resizeImage, fileToBase64, downloadFileUrl } from "../services/download";
import { IDictionary, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { ICommand, IListColumn } from "../AppSchema";
import { executeGraphRequest } from "../objectCalendar/cloudCalendar";
import { TitleBox } from "../components/TitleBox";
import { useSessionState } from "../hooks/useSessionState";
import { MetaContext, tryLocalize } from "../AppState";
import { showConfirm, showError } from "../modal/alerts";
import { useDerivedState } from "../hooks/useDerivedState";

const isImageFile = (name: string) => {
	return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name);
}
const isPdfFile = (name: string) => {
	return (/\.(pdf)$/i).test(name);
}

interface ICloudFile {
	name: string;
	isFolder: boolean;
	createdBy: {
		user: {
			email: string;
			displayName: string;
			id: string;
		}
	}
}

export const CloudFileList = (props: {
	rootFolder?: string;
	fileFilter?: string;
	onLoaded?: (folder: string, rows: ICloudFile[]) => void,
}) => {
	const rootFolder = props.rootFolder || "/me/drive";
	const [loading, setLoading] = useState(true);
	const [folders, setFolders] = useDerivedState([] as string[], [rootFolder]);
	const [rows, setRows] = useState([] as any[]);
	const [display, setDisplay] = useSessionState("!cloudFilesDisplay", "list", []);
	const [showUser, setShowUser] = useSessionState("!cloudFilesShowUser", false, []);

	const access_token = useRef<string>("");
	const fileFilter = props.fileFilter;

	const loadFolder = async () => {
		try {
			const timer = setTimeout(() => setLoading(true), 300);

			if (!access_token.current) {
				const token = await fetch("/api/azure/accesstoken");
				const j = await token.json();
				access_token.current = j["access_token"];
			}

			const newRows = [];
			const path = folders.join("/");
			let folderUrl = rootFolder + (path ? "/" + path : "") + ":/children?$expand=thumbnails";

			for (let i = 0; i < 100 && folderUrl; i++) {
				const displayOptions = "";
				const { items, nextLink } = await listFolder(access_token.current, folderUrl, displayOptions);
				
				folderUrl = nextLink;

				for (const item of items) {
					if (fileFilter && (!item.name || !item.name.startsWith(fileFilter)))
						continue;

					newRows.push({
						name: item.name,
						id: item.id,
						isFolder: item.folder !== undefined,
						childCount: item.folder && item.folder.childCount,
						modifiedon: item.lastModifiedDateTime,
						size: item.size,
						fileUrl: item["@microsoft.graph.downloadUrl"],
						iconUrl: item.thumbnails && item.thumbnails[0]?.small?.url,
						createdBy: item.createdBy,
					});
				}
			}
			setRows(newRows);
			clearTimeout(timer);
			setLoading(false);
		
			if (props.onLoaded)
				props.onLoaded(path, newRows);
		}
		catch (e: any) {
			showError(modal, e)
		}
	}

	useEffect(() => {
		loadFolder();
	}, [folders, fileFilter]);

	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);

	const onFileClick = (e: React.MouseEvent) => {
		e.preventDefault();
		let element = e.target as HTMLElement;
		let fileId = "";
		while (element) {
			fileId = element.dataset["fileid"] as string;
			if (fileId)
				break;
			element = element.parentElement as HTMLElement;
		}
		if (!fileId) return true;

		const item = rows.find(x => x.id === fileId);
		if (item.isFolder) {
			setFolders((f) => (f.concat(item.name)));
		} else {
			const isImage = isImageFile(item.name)
			const isPdf = isPdfFile(item.name);
			showFilePreviewDialog(modal, { name: item.name, imageUrl: item.fileUrl, isImage: isImage, isPdf: isPdf });
		}
	}

	const formatter = useCallback((row: any, field: string, style: any) => {
		let value = row[field];
		if (field === "modifiedon") {
			value = (new Date(value)).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
		} else if (field === "size") {
			if (row.isFolder)
				return "" + row.childCount + " Items";
			let size = +value;
			//let decimalSize = size;
			const g = ["B", "KB", "MB", "GB", "TB", "EB", "PB"];
			let i = 0;
			while (size > 1024) {
				//decimalSize = size / 1024;
				size = (size / 1024);
				i++;
			}
			let sz = size.toFixed(0);
			let sz_rem = size.toFixed(Math.max(0, 3 - sz.length));
			return "" + sz_rem + " " + g[i];
				
			//value = formatNumericValue(0, +value);
		} else if (field === "name") {
			let icon: any = undefined;
			const style = { width: "15px", textAlign: "center", marginRight: "4px" } as any;
			if (display === "thumbs") {
				// do nothing
			} else if (row.isFolder) {
				icon = <i className={"fa fa-folder"} style={{ color: "#ffd356", ...style }} />
			} else {
				const name = value as string
				let fileIcon = "file";
				if (isImageFile(name)) {
					fileIcon = "file-image";
				} else if (isPdfFile(name)) {
					fileIcon = "file-pdf";
				} else if (name.match(/\.xlsx$/i)) {
					fileIcon = "file-excel";
				} else if (name.match(/\.(docx|rtf)$/i)) {
					fileIcon = "file-word";
				} else if (name.match(/\.(avi|mpeg|vid)$/i)) {
					fileIcon = "file-video";
				} else if (name.match(/\.(mp3|wav)$/i)) {
					fileIcon = "file-audio";
				}
				icon = <i className={"fa fa-" + fileIcon} style={{ color: "gray", ...style }} />
			}
			return <a className="lookupLink" href="#" onClick={onFileClick} data-fileid={row.id}>{icon} {value}</a>
		} else if (field === "actions") {
			return <IconButton style={{ width: "1em", height: "1em", alignSelf: "center" }} icon="ellipsis-vertical" label="" onClick={async (e) => {
				const action = await showMenu(modal, e.target as any, ["CmdDownload", "CmdRename", "CmdDelete"].map(x => tryLocalize(metadata, x)));
			
				const path = "/" + [...folders as string[]].concat(row.name).join("/");

				switch (action) {
					case 0:
						downloadFileUrl(row.fileUrl, row.name);
						return; // do not loadFolder
					case 1:
						let oldName = row.name as string;
						if (fileFilter && oldName.startsWith(fileFilter)) {
							oldName = oldName.substring(fileFilter.length);
						}
						let newName = window.prompt("Rename File", oldName);
						if (newName) {
							if (oldName !== row.name) {
								newName = fileFilter + newName;
							}
							await renameFile(access_token.current, rootFolder + path, newName);
						}
						else
							return; // do not reload
						break;
					case 2:
						if (await showConfirm(metadata, modal, "MsgAskDelete")) {
							await deleteFile(access_token.current, rootFolder + path);
						} else
							return; // do not reload
						break;
				}
				loadFolder();
			}} />
		} else if (field === "icon") {
			const iconUrl = row.iconUrl;
			if (iconUrl) {
				value = <img className="objectListImageCell" alt={row["name"]} src={iconUrl} />
			} else if (row.isFolder) {
				const style = { fontSize: "40px", textAlign: "center", marginRight: "4px" } as any;
				value = <i className={"fa fa-folder"} style={{ color: "#ffd356", ...style }} />
			} else {
				value = <div className="fa fa-paw objectListImageCell objectListImagePlaceHolder" />;
			}
		} else if (field === "username") {
			return row.createdBy?.user?.displayName;
		}

		return value;
	}, [setFolders, rows, folders, display]);

	const uploadRef = useRef<HTMLInputElement>(null);
	const onOpenFile = useCallback(() => {
		uploadRef.current?.click();
	}, [uploadRef]);
	const uploadFiles = useCallback(async (files: File[]) => {
		if (files && files.length > 0) {

			const info = { title: "Uploading...", subTitle:"" };
			const w = showWait(modal, info);

			try {
				const folderPath = "/" + [...folders as string[]].join("/") + "/";
				for(const f of files) {
					const path = folderPath + (fileFilter || "") + f.name;
					w({ ...info, subTitle: f.name });
					await uploadSmallFile(access_token.current, rootFolder + path, f);
				}
			}
			catch (e) {
				alert(e);
			}
			finally {
				w(null);
			}
			loadFolder();
		}
	}, [folders, fileFilter]);
	const onUploadFile = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		const ff = (e.target as HTMLInputElement).files;
		if (ff && ff.length) {
			const files: File[] = [];
			for (let i = 0; i < ff.length; i++) {
				files.push(ff[i]);
			}
			uploadFiles(files);
		}
	}, [folders, fileFilter]);

	const { fields, attrsMeta, listColumns } = useMemo(() => {
		const attrsMeta: IDictionary<IMetaProperty> = {};
		attrsMeta["icon"] = { logicalName: "icon", type: MetaPropertyType.String, flags: MetaPropertyFlags.Image };
		attrsMeta["name"] = { logicalName: "name", type: MetaPropertyType.String, flags: 0 };
		attrsMeta["modifiedon"] = { logicalName: "modifiedon",  type: MetaPropertyType.DateTime, flags: 0 };
		attrsMeta["size"] = { logicalName: "size", type: MetaPropertyType.Integer, flags: 0 };
		attrsMeta["username"] = { logicalName: "username", type: MetaPropertyType.String, flags: 0 };
		attrsMeta["actions"] = { logicalName: "actions", type: MetaPropertyType.String, flags: 0 };
		const listColumns: IListColumn[] = [
			{ "attribute": "name", }, { "attribute": "modifiedon" },
			{ "attribute": "size" }, { "attribute": "actions", width: "50px", label: " " }
		];
		if (showUser) {
			listColumns.splice(2, 0, { "attribute": "username", label: tryLocalize(metadata, "@systemuser") });
		}
		if (display === "thumbs") {
			listColumns.splice(0, 0, { "attribute": "icon", width: "70px" });
		}
		listColumns.forEach(c => (c.mobileLabel = " ", c.label = c.label || tryLocalize(metadata, c.attribute)));
		const fields = listColumns.map(x => x.attribute);
		return { fields, attrsMeta, listColumns };
	}, [display, showUser]);

	const onNewFolder = async () => {
		const path = "/" + [...folders as string[]].join("/");
		const text = window.prompt("New folder name", "");
		if (text) {
			await createFolder(access_token.current, rootFolder + path, text);
			loadFolder();
		}
	}

	const onFileDragDrop = async (event: React.DragEvent) => {
		
		// Prevent default behavior (Prevent file from being opened)
		event.preventDefault();

		let files: File[] = [];

		let items = event.dataTransfer.items;
		if (items) {
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				if (item.kind === "file") {
					const file = item.getAsFile();
					if (file) {
						files.push(file);
					}
				}
			}
		} else {
			const ff = event.dataTransfer.files;
			if (ff) {
				for (let i = 0; i < ff.length; i++) {
					files.push(ff[i]);
				}
			}
		}
		uploadFiles(files);
	}

	const onFileDragOver = (event: React.DragEvent) => {
		event.preventDefault();
	}
	
	return <div className="cloudFileMain objectListContainer" onDrop={onFileDragDrop} onDragOver={onFileDragOver}>
		<div className="cloudFileHeader">
			<div>
				<i className="fa fa-home" style={{ color: "var(--acccent-color)" }} onClick={() => setFolders([])} />
				{folders.map((x, i) => (<><span style={{ padding: "3px" }}>/</span><a href="#" style={{ margin: "4px" }} onClick={() => setFolders(fp => fp.filter((k, j) => j <= i))}>{x}</a></>))}
			</div>
			<IconButton icon={display === "thumbs" ? "list" : "images"} label={tryLocalize(metadata, display === "thumbs"?"CmdHideIcons":"CmdShowIcons")} onClick={() => setDisplay(x=>x==="list"?"thumbs":"list")} />
			<IconButton icon={"user-gear"} label={""} onClick={e => setShowUser(x => !x)} />
			<IconButton icon={"folder-plus"} label={""} onClick={onNewFolder} />
			<IconButton icon={"cloud-arrow-up"} label={tryLocalize(metadata, "CmdUpload")} onClick={onOpenFile} />
			<input ref={uploadRef} hidden={true} type="file" multiple onChange={e => e.target.value = null as any} 
				onInput={onUploadFile} />
		</div>
		<SimpleGrid isLoading={loading} attrsMeta={attrsMeta} listColumns={listColumns} noEdit={true} fields={fields} rows={rows} formatter={formatter} />
	</div>
}

const deleteFile = async (access_token: string, path: string) => {
	return executeGraphRequest(access_token, path, "DELETE", undefined, undefined, true);
}

const createFolder = async (at: string, path: string, name: string) => {
	const data = JSON.stringify({
		"name": name,
		"folder": {},
		"@microsoft.graph.conflictBehavior": "rename"
	});
	return executeGraphRequest(at, path + ":/children", "POST", data, { "Content-Type": "application/json" }, true);
}

const renameFile = async (at: string, path: string, name: string) => {
	const data = JSON.stringify({
		"name": name
	});
	return executeGraphRequest(at, path, "PATCH", data, { "Content-Type": "application/json" }, true);
}

const listFolder = async (access_token: string, q: string, options?: string): Promise<{items:any[], nextLink:string}> => {
	const t = await executeGraphRequest(access_token, q, undefined, undefined, undefined, true);
	const json = JSON.parse(t);
	const items = (json.value as any[]) || [];
	const nextLink = json["@odata.nextLink"];
	return { items, nextLink };
}

const uploadSmallFile = async (access_token: string, path: string, file: any): Promise<string> => {
	const headers = { "Content-Type": "application/octet-stream" };
	return executeGraphRequest(access_token, path + ":/content", "PUT", file, headers, true);
}