import { openDB } from "idb";
import { Fetch } from "shared/fetch";
import { getPermLevel, PERMISSION_READ } from "shared/permissions";
import { IMetaObject, MetaPropertyType, IExecuteRequest } from "shared/schema";
import { IAppConfig } from "../AppSchema";
import { fetchJson } from "./fetchJson";
import { uploadBlobFiles } from "./offline/blobs";
import { getSyncAnchors, getUploadRecords, writeSyncRecords } from "./offline/offlineService";
import { executeMultipleOnline, retrieveMultipleOnline, updateRecordOnline } from "./onlineService";

const DB_NAME = "db";

export const openStorage = async (metadata: IMetaObject[]) => {
	const db = await openDB(DB_NAME, 1, {
		upgrade(db, oldVersion, newVersion, transaction) {
		  
			for (const meta of metadata) {
				const kp: {keyPath:string|string[]} = { keyPath: "id" };
				if (meta.type === "many") {
					kp.keyPath = [meta.properties[0].logicalName, meta.properties[1].logicalName];
				}
				const objectStore = db.createObjectStore(meta.logicalName, kp);
				for (const prop of meta.properties) {
					if (prop.type === MetaPropertyType.Lookup) {
						objectStore.createIndex(prop.logicalName, prop.logicalName, { unique: false });
					}
				}
			}
			db.createObjectStore("__sys__upload__", { keyPath: "id", autoIncrement: true });
			db.createObjectStore("__sys__upload__failed", { keyPath: "id" });
		},
		blocked() {
		  // …
		},
		blocking() {
		  // …
		},
		terminated() {
		  // …
		},
	});
	return db;
}

export const getSyncStatus = async (metadata: IMetaObject[]) => {
	const db = await openStorage(metadata);
	const t = db.transaction("__sys__upload__", "readonly");
	const recordToUpload = await t.store.count();
	t.commit();
	await t.done;
	db.close();

	return { recordToUpload };
}

export interface IProgressContent {
	title: string;
	subTitle: string;
	percent: number;
	close?: boolean;
}
export interface IProgressInfo {
	callback: (content: IProgressContent) => void;
}

const sleep = async (ms: number) => {
	return new Promise<void>((res, rej) => {
		setTimeout(() => res(), ms);
	})
}

export const synchronize = async (metaContext: IAppConfig, progress: IProgressInfo) => {
	try {
		await synchronizeInternal(metaContext, progress);
		return "";
	}
	catch (ex) {
		console.log("sync failed: " + ex);
		return "Sync failed: " + ex;
	}
}
const synchronizeInternal = async (metaContext: IAppConfig, progress: IProgressInfo) => {
	const metadata = metaContext.objects;
	// const db = await openStorage(metadata);

	// // upload
	// const t = db.transaction("__sys__upload__", "readonly");
	// const upload = await t.store.getAll() as (IExecuteRequest & { id: number })[];
	// t.commit();
	// await t.done;

	// if (upload.length > 0) {
	// 	//upload.reverse();

	const progressInfo = { title: "Uploading", subTitle: "", cancel: false, percent: 0 };
	let uploadInfo = "Uploaded: 0";
	const { upload, uploadFiles } = await getUploadRecords(metaContext);
	//let failed: (IExecuteRequest & { error: string })[] = [];
	let errCount = 0;
	let success = 0;
	//for (const req of upload) {
	const uploadBatchSize = 20;
	for (let i = 0; i < upload.length; i += uploadBatchSize) {
		try {
			const reqs = upload.slice(i, uploadBatchSize);
			const results = await executeMultipleOnline({ records: reqs });
			for (const r of results) {
				if (r.error) {
					errCount++;
					console.log("Upload failed: " + r.error);
				}
				else {
					success++;
				}
			}
		}
		catch (ex) {
			console.log("Batch upload failed: " + ex);
			throw ex;
		}
		uploadInfo = "Uploaded: " + success + ""
		if (errCount)
			uploadInfo += " Errors: " + errCount;
		
		progressInfo.subTitle = uploadInfo;
		progress.callback(progressInfo);
	}
	if (errCount > 0 && success === 0) {
		throw new Error("Upload failed, stopping sync");
	}

	if (uploadFiles.length > 0) {
		progressInfo.title = "Uploading Files";
		progress.callback(progressInfo);
		// fixme: error handling!
		const files = uploadFiles.map(f => f.fileName);
		await uploadBlobFiles(metaContext, files);
		const updates = uploadFiles.map(f => ({ name: f.objectName, operation: "update" as any, object: { id: f.id, [f.propName]: f.fileName } }));
		await executeMultipleOnline({ records: updates });
	}

	const minRowVersion = await fetchJson("/api/minrowversion", undefined, false) as string;
	const anchors = await getSyncAnchors(metaContext);

	// download
	let metaIndex = 1;
	let downloadedCount = 0;
	const metaToDownload = metadata.filter(x => x.logicalName !== "audit" && getPermLevel(x.logicalName, metaContext.permDict, PERMISSION_READ) > 0);
	for (const meta of metaToDownload) {
		const q: Fetch = {
			entity: {
				name: meta.logicalName,
				attributes: meta.properties.map(x => ({ attribute: x.logicalName })),
				orders: meta.type === "many" ? [] : [{ attribute: "id" }]
			}
		};
		
		if (anchors) {
			const anchor = anchors.find(x => x.name === meta.logicalName);
			if (anchor && anchor.rowversion) {
				q.entity.filter = { conditions: [{ attribute: "versionnumber", operator: "ge", value: anchor.rowversion }] };
			}
		}
	
		progressInfo.title = "Downloading: <b>" + (meta.displayName || meta.logicalName) + "</b> ";
		progressInfo.subTitle = uploadInfo + " Downloaded: " + downloadedCount;
		progress.callback(progressInfo);
		progressInfo.percent = (metaIndex++ * 100) / metaToDownload.length | 0;

		const records = await retrieveMultipleOnline(q);
		if (records.length > 0) {
			downloadedCount += records.length;
			progressInfo.title += " <span style='color:gray;font-size:smaller'>Saving</span>";
			progressInfo.subTitle = uploadInfo + " Downloaded: " + downloadedCount;
			progress.callback(progressInfo);
			await writeSyncRecords(meta.logicalName, records, metaContext, minRowVersion);
			// console.log("DOWNLOAD BEGIN ------");
			// console.log(JSON.stringify(records));
			// console.log("DOWNLOAD END ------");
		}
		//console.log("-----records written------");
		//break;
		//const t = db.transaction(meta.logicalName, "readwrite");
		//const propDict = Dictionary.create(meta.properties, "logicalName");
		//for (const rec of records) {
			// const newRec = {} as any;
			// for (const name of Object.getOwnPropertyNames(rec)) {
			// 	if (propDict[name] && propDict[name].type === MetaPropertyType.Lookup) {
			// 		const value = rec[name];
			// 		if (value !== undefined && value !== null && value !== "") {
			// 			newRec[name] = value["id"];
			// 			newRec[name + "Target"] = value["name"];
			// 		}
			// 	}
			// 	else {
			// 		newRec[name] = rec[name];
			// 	}
			// }
			//await t.store.put(newRec);
		//}
		// t.commit();
		// await t.done;
	}
	progressInfo.cancel = true;
	progress.callback(progressInfo);
	//db.close();
}
