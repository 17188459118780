import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
//mport { CSSTransition } from "react-transition-group";
import { ReactPortal } from "./ReactPortal";
import "./modalStyles.css";
import { IconButton } from "../components/IconButton";
import { TitleBox } from "../components/TitleBox";
import { ICommand } from "../AppSchema";
import { Loading } from "../components/Loading";

export interface IModalProps {
	id: string;
	title?: string;
	bodyProps?: any;
	body: (bodyProps: any) => ReactElement;
	//onCommand: (cmdName: string) => boolean; // return true to close modal.
	commands?: ICommand[];
	showTitle?: boolean;
	anchor?: { x: number, y: number, width?: number };
	size?: { width: string, height: string };
	minHeight?: number;
	style?: any;
	closeOnClickOutside?: boolean;
	onOutsideClick?: () => boolean;
}
export interface IModalContext {
	showModal: (props: IModalProps) => void
}
export interface IModalParent {
	onCommand: (cmdName: string) => boolean; // to be replaced by modal
	closeModal: () => void;
}

export const ModalContext = createContext<IModalContext>({ showModal: (props) => { } });

export const ModalSite = (props: {}) => {
	const [modals, setModals] = useState([] as IModalProps[]);
	const modal = useContext(ModalContext);
	modal.showModal = useCallback((p) => setModals(x => {
		p.id = (p.id || "") + x.length; // since this is used as key, make sure it is unique!
		return x.concat(p);
	}), [setModals]);

	const removeModal = useCallback((m: IModalProps) => {
		setModals(x => x.filter(y => y !== m));
	}, [setModals]);

	const clickIgnore = useRef<boolean>(false);
			
	const onMouseDown = useCallback((e: any) => {
		let t = e.target as (HTMLElement | null);
		while (t) {
			if (t.classList.contains("modal-body")) {
				clickIgnore.current = true;
				return;
			}
			t = t.parentElement;
		}
		clickIgnore.current = false;
	}, []);

	return (<ReactPortal wrapperId="react-portal-modal-container">
		{modals.map(modal => {
			const commandSite: IModalParent = {
				onCommand: (cmdName: string) => true, // to be replaced by modal
				closeModal: () => removeModal(modal)
			};
			let ss: any = modal.anchor ?
				{ width: modal.anchor.width || "fit-content", height: "initial" } : {};
			ss.margin = "0 40px 20px 20px";
			if (modal.style)
				ss = { ...ss, ...modal.style };
			ss.overflow = "hidden";
			const onOutsideClick = (e: MouseEvent) => {
				const div = document.elementFromPoint(e.clientX, e.clientY);
				if (!clickIgnore.current && div && div.classList.contains("modal")) {
					if (modal.onOutsideClick && modal.onOutsideClick())
						return;
					
					removeModal(modal);
				}
			}
			return (
				<AnimatedDiv key={modal.id} className="modal" {...modal} onClick={modal.closeOnClickOutside && onOutsideClick} onMouseDown={onMouseDown}>
					<div className="modal-content" style={ss}>
						{modal.showTitle !== false && <TitleBox title={modal.title} commands={modal.commands}
							onCommand={cmd => commandSite.onCommand(cmd.name) && removeModal(modal)} />}
						<div className="modal-body">
							<modal.body {...modal.bodyProps} commandSite={commandSite} />
						</div>
					</div>
				</AnimatedDiv>)
		})}
	</ReactPortal>)
}

const AnimatedDiv = (props: (IModalProps & { children: any, className: string, onClick: any, onMouseDown: any })) => {
	const r = useRef<HTMLDivElement>(null);
	const [opacity, setOpacity] = useState(0);
	const { children, className, onClick } = props;

	useEffect(() => {
		if (props.anchor && r.current) {
			let { x, y } = props.anchor;
			const bounds = r.current.firstElementChild?.getBoundingClientRect();
			if (bounds && x + bounds.width > window.innerWidth)
				x = window.innerWidth - bounds.width;
			const minHeight = props.minHeight || 300;
			if (bounds && y + minHeight > window.innerHeight)
				y = window.innerHeight - minHeight;
			r.current.style.paddingLeft = x + "px";
			r.current.style.paddingTop = y + "px";
			r.current.style.alignItems = "stretch";
			r.current.style.justifyContent = "flex-start";
			r.current.style.maxHeight = (window.innerHeight - y) + "px";
		}
		setOpacity(1);
	}, [r]);

	const style = {
		opacity: opacity,
		transition: (props.anchor ? "opacity" : "all") + " 0.2s ease",
		transform: opacity ? "translateY(0)" : "translateY(-50px)"
	}

	return <div ref={r} className={className} style={style} onClick={onClick} onMouseDown={props.onMouseDown}>{children}</div>
}

export const Modal = (props: {
	title?: string,
	commands?: { icon: string, label?: string, name: string }[],
	children: React.ReactNode,
	isOpen: boolean,
	handleClose: (cmdName: string) => void
}) => {
	
	const { children, isOpen, handleClose } = props;

	const nodeRef = useRef(null);
	useEffect(() => {
		const closeOnEscapeKey = (e: any) => (e.key === "Escape" ? handleClose("CmdClose") : null);
		document.body.addEventListener("keydown", closeOnEscapeKey);
		return () => {
			document.body.removeEventListener("keydown", closeOnEscapeKey);
		};
	}, [handleClose]);

	if (!isOpen) return null;

	const commands = (props.commands || []).concat({ icon: "close", label: "Close", name: "CmdClose" });

	return (
		<ReactPortal wrapperId="react-portal-modal-container">
			<div className="modal" ref={nodeRef}>
				
				<div className="modal-content">
					<div className="modal-header">
						<h1>{props.title || "Registruj Psov"}</h1>
						<div style={{ flex: "1 1 auto" }} />
						{commands.map(cmd => (
							<IconButton onClick={() => handleClose(cmd.name)} icon={cmd.icon} label={cmd.label} />))}
					</div>
					<div className="modal-body">
						{children}
					</div>
				</div>
			</div>
		</ReactPortal>
	);
}

const MenuElement = (props: { className: string, items: ReactNode[], onSave: (index:number)=>void, commandSite: IModalParent,  }) => {
	const onClick = (index: number) => {
		props.onSave(index);
		props.commandSite.closeModal();
	}
	return <div className={props.className}>
		{props.items.map((x, i) => {
			return <div className="menuItem" key={i} onClick={e=>onClick(i)}>{x}</div>
		})}
	</div>
}

export const showMenu = async (modal: IModalContext, sourceElement: HTMLElement, items: ReactNode[],
	opts?: { largeMenu?: boolean, outsideClickIndex?: number, closeOnClickOutside?: boolean, colors?: {[key: number]: string} }) => {
	
	const r = sourceElement.getBoundingClientRect();

	const itemsColors = opts?.colors;
	if (itemsColors) {
		items = items.map((x, i) => {
			if ([i])
				return <span style={{ color: itemsColors[i] }} >{x}</span>;
			return x;
		});
	}

	let className = "menuContainer";
	if (window.innerWidth <= 500)
		className += " mobileMenuContainer";
	else if (opts?.largeMenu)
		items = items.map(x => <h1 className="promptDialogLine">{x}</h1>);
	
	const pp: IModalProps = {
		body: (p) => <MenuElement {...p} />,
		bodyProps: {
			className: className,
			items: items,
			onSave: (n: number) => { },
		},
		id: "popupMenu",
		showTitle: false,
		anchor: { x: r.x, y: r.y },
		//style: { boxShadow: "5px 0px 6px 0px #0008", margin:"0px" },
		style: {
			boxShadow: "rgba(0, 0, 0, 0.533) 0px 0px 10px 2px",
			borderRadius: "4px",
			margin: sourceElement === document.body ? "auto" : "0px",
		},
		closeOnClickOutside: opts?.closeOnClickOutside !== false,
	};
	return new Promise<number>((res, rej) => {
		pp.bodyProps.onSave = res;
		const outsideClickIndex = opts?.outsideClickIndex;
		if (outsideClickIndex !== undefined)
			pp.onOutsideClick = () => (res(outsideClickIndex), false);
		modal.showModal(pp);
	});
}

interface IWaitInfo {
	title: string;
	subTitle?: string;
	className?: string;
	commands?: ICommand[];
	onCommand?: (cmd: ICommand) => void;
}

interface ITitleSite {
	update: (info: IWaitInfo|null) => void;
}

const PleaseWaitElement = (props: { info: IWaitInfo, titleSite: ITitleSite, commandSite: IModalParent,  }) => {
	
	const [info, setInfo] = useState(props.info || { title: "" });

	useEffect(() => {
		props.titleSite.update = (title) => {
			if (title)
				setInfo(z => ({ ...z, ...title }))
			else
				props.commandSite.closeModal();
		}
	}, []);

	const h2ref = useRef<HTMLHeadingElement>(null);
	if (h2ref.current)
		setTimeout(() => h2ref.current?.scrollTo({ "top": h2ref.current?.scrollHeight, "behavior": "smooth" }),100);

	return <div className={"waitDialog " + (info.className || "")}>
		{!info.onCommand && <h1>{info.title}</h1>}
		{info.onCommand && <TitleBox title={info.title} commands={info.commands} onCommand={info.onCommand}/>}
		{info.subTitle && <h2 ref={h2ref}>{info.subTitle}</h2>}
		<Loading delay={false} />
	</div>
}

export const showWait = (modal: IModalContext, info: IWaitInfo) => {
	const updateInfoSite = {
		update: (s: IWaitInfo|null) => { }
	};
	const updateText = (s: IWaitInfo | string | null) => {
		if (typeof s === "string")
			s = { title: s };
		updateInfoSite.update(s);
	}

	let className = "menuContainer";
	if (window.innerWidth <= 500)
		className += " mobileMenuContainer";
	const pp: IModalProps = {
		body: (p) => <PleaseWaitElement {...p} />,
		bodyProps: {
			info: info,
			titleSite: updateInfoSite
		},
		id: "waitDialog",
		showTitle: false,
		// anchor: { x: r.x, y: r.y },
		// style: { boxShadow: "5px 0px 6px 0px #0008", margin:"0px" },
		// closeOnClickOutside: true,
	};
	modal.showModal(pp);

	return updateText;
}

export const showTooltip = (modal: IModalContext, message: React.ReactNode) => {

	const Tooltip = (props: { message: any, commandSite: IModalParent }) => {
		useEffect(() => {
			setTimeout(() => {
				props.commandSite.closeModal();
			}, 3000);
		}, []);
		return props.message;
	}

	modal.showModal({
		body: (p: any) => <Tooltip {...p} />,
		bodyProps: {
			message: message
		},
		style: { margin: "0px", width: "fit-content" },
		showTitle: false,
		id: "formMessage",
		className: "commandMessage",
	} as any);
}