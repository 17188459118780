import { useContext, useEffect, useMemo, useState, useCallback, useRef } from "react";
import { useRender } from "../hooks/useRender";
import { ModalContext } from "../modal/Modal";
import { IPanelProps } from "../objectForm/panels/PanelProps";
import { ILookupValue, MetaPropertyType } from "shared/schema";
import { newGuid } from "../utils/guid";
import { LookupInput } from "../objectForm/LookupInput";
import { fetchJson } from "../services/fetchJson";
import { dateToString } from "../utils/objectHelpers";
import { DataService } from "../service";
import { InputWithWrap } from "../components/WrapInput";

const initLegs = (record: any) => {
	if (record.legs_json) {
		return JSON.parse(record.legs_json) as ILeg[];
	}

	// new record
	if (true || !record.id) {
		const nl = [] as ILeg[];
		const dt = new Date();
		nl.push({
			id: newGuid(),
			//location: { name: "route_location", label: "Senec", id: "4076D601-608D-4AD8-A46A-FAC6F9E07CED" },
			startdate: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 8, 0, 0).toISOString(),
			enddate: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 8, 0, 0).toISOString(),
			distance: "0",
			masstransit: "no"
		});
		nl.push({
			id: newGuid(),
			//location: { name: "route_location", label: "Senec", id: "4076D601-608D-4AD8-A46A-FAC6F9E07CED" },
			startdate: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 8, 0, 0).toISOString(),
			enddate: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 18, 0, 0).toISOString(),
			distance: "0",
			masstransit: "no"
		});
		return nl;
	}
}
	

export const JourneyPanel = (props: IPanelProps) => {
	
	const record = props.context.getRecord();

	const legs = useMemo(() => initLegs(record), [record.legs_json]);
	const prevOwner = useRef("");

	useEffect(() => {
		const r = props.context.getRecord();
		const owner = r["ownerid"];
		const exe = async () => {
			const q = {
				entity: {
					name: "systemuser",
					allattrs: true,
					filter: { conditions: [{ attribute: "id", operator: "eq", value: owner.id }] },
					links: [{
						name: "route_location", from: "home_location", to: "id", alias: "loc",
						attributes: [{ attribute: "latitude" }, { attribute: "longitude" }]
					}]
				}
			}
			const users = await DataService.retrieveMultiple(q as any);
			const userRec = users?.[0];
			const homeLoc = userRec?.home_location;
			if (homeLoc) {
				const record = props.context.getRecord();
				const legs = initLegs(record);
				const updateLoc = (leg?: ILeg) => {
					if (leg) {
						leg.location = homeLoc;
						leg.latitude = userRec.loc__latitude;
						leg.longitude = userRec.loc__longitude;
					}
				}
				updateLoc(legs[0]);
				updateLoc(legs.at(-1));
				updateLegs(legs, 0, legs[0], false);
			}
		}
		if (owner?.id && (!r.id || (prevOwner.current && owner?.id !== prevOwner.current)))
			exe();
		prevOwner.current = owner?.id;
	}, [record.ownerid?.id])

	const distanceTimer = useRef(0);

	const updateLegs = (arr: ILeg[], index: number, leg: ILeg | undefined, addNew: boolean, skipDistance: boolean = false) => {
		if (addNew && leg) {
			arr.splice(index, 0, leg);
			setOpenIndex(index);
		}
		else if (leg) {
			arr[index] = leg;
		}
		else {
			arr.splice(index, 1);
			setOpenIndex(index - 1);
		}
		writeLegs(arr);

		if (!skipDistance) {
			if (distanceTimer.current)
				window.clearTimeout(distanceTimer.current);
			distanceTimer.current = window.setTimeout(() => calculateDistances(), 2);
		}
	}
	const writeLegs = (arr: ILeg[]) => {

		for (let i = 1; i < legs.length; i++) {
			const seconds = legs[i].duration;
			const prevStart = legs[i - 1].startdate;
			if (prevStart && seconds) {
				const dt = new Date(new Date(prevStart).valueOf() + seconds * 1000);
				legs[i].enddate = dt.toISOString();
			}
		}

		const j = JSON.stringify(arr);
		const r = props.context.getRecord();

		let end = arr.length;
		if (arr[0].location?.id === arr.at(-1)?.location?.id)
			end--;
		const places = arr.slice(1, end).map(x => x.location?.label).join(", ");

		const total_distance = arr.slice(1).filter((leg, idx) => leg.masstransit === "no").reduce((agg, leg) => agg + +leg.distance, 0);

		props.context.setRecord({
			...r,
			legs_json: j,
			name_locations: places,
			total_distance: total_distance,
			startdate: legs[0].startdate,
			enddate: legs.at(-1)?.enddate,
			start_locationid: legs[0].location,
			end_locationid: legs.at(-1)?.location,
		});
	}

	const addLeg = (arr: ILeg[], index: number, leg: ILeg) => {
		if (!leg.location)
			return;

		const prevLeg = arr[index - 1];
		const dt = new Date(prevLeg.startdate);
		updateLegs(arr, index, {
			id: newGuid(),
			location: leg.location,
			latitude: leg.latitude,
			longitude: leg.longitude,
			startdate: new Date(dt.valueOf() + 3 * 3600 * 1000).toISOString(),
			enddate: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 18, 0, 0).toISOString(),
			distance: "0",
			masstransit: prevLeg.masstransit,
		},
			true
		);
	}

	const extraFetch = {
		entity: {
			name: "route_location",
			attributes: [{ attribute: "county" }, { attribute: "longitude" }, { attribute: "latitude" }]
		}
	}

	const calculateDistances = async () => {
		const encodeLeg = (l: ILeg) => l.latitude + "," + l.longitude;

		const rec = props.context.getRecord();
		const legs_json = rec.legs_json;
		if (!legs_json)
			return;
		const legs = JSON.parse(legs_json) as ILeg[];

		// incomplete, bail-out
		for (const l of legs) {
			if (!l.latitude || !l.longitude)
				return;
		}

		if (legs.length < 2 || (legs.length === 2 && legs[0].location === legs[1].location))
			return;
		
		let url = "https://maps.googleapis.com/maps/api/directions/json?";
		url += "origin=" + encodeLeg(legs[0]) + "&destination=" + encodeLeg(legs.at(-1)!);
		if (legs.length > 2) {
			url += "&waypoints=" + legs.slice(1, -1).map(x => encodeLeg(x)).join("|");
		}

		const resp = await fetchJson("/api/distance", { url: url });

		if (props.context.getRecord()["legs_json"] !== legs_json) {
			// something changed, while calculating.. ignore...
			return;
		}

		let i = 1;
		for (const leg of resp.routes[0].legs) {
			const meters = leg.distance.value;
			const seconds = leg.duration.value;
			legs[i].distance = (meters / 1000).toFixed(0);
			legs[i].duration = seconds;
			i++;
		}

		writeLegs(legs);
	}

	const onChangeName = (name: string) => {
		const rec = props.context.getRecord();
		props.context.setRecord({ ...rec, name: name });
	}

	const [openIndex, setOpenIndex] = useState(0);

	return <div className="routesMain objectListContainer">
		<div style={{ display: "grid", columnGap: "4px", gridTemplateColumns: "1fr" }}>
			<div className="routeName">
				<i className="fa fa-pen routeLargeIcon"/>
				<span style={{ visibility: record.name ? "hidden" : "visible" }}>Účel</span>
				<InputWithWrap type="text" value={record.name || ""} onChange={onChangeName} />
				<div className="routeNameTotal">
					<span>{record.total_distance || 0} km</span>
					<span style={{opacity:"0.6", fontSize:"14px"}}>Spolu (AUV)</span>
				</div>
			</div>
			<div className="routeInfo" style={{gridTemplateColumns:"1fr", marginBottom:"10px"}}>
				<div style={{ height: "1px", marginRight: "10px", backgroundColor: "#ccc" }} />
				</div>
			{legs.map((leg, index, arr) => {
				const notLastItem = (index < arr.length - 1);
				const setOpen = notLastItem ? setOpenIndex : () => { };
				return <>

					{(index > 0) &&
						<div className="routeInfo">
							<div style={{height:"1px", marginRight:"10px", backgroundColor:"#ccc"}} />
							<span className="routeLegInfo">
								<span className={"routeVehicle " + (leg.masstransit === "train" ? "routeVehicleTrain" : "")} onClick={() => updateLegs(arr, index, { ...leg, masstransit: leg.masstransit === "train" ? "no" : "train" }, false, true)}>
									<i className={"fa fa-" + (leg.masstransit === "train" ? "train-subway" : "car-side")} />
									&nbsp;
									<span onClick={() => calculateDistances()}>{leg.distance}km</span>&nbsp;
									<span>{leg.duration && formatDuration(leg.duration / 60)}</span>
								</span>
								
								{/* <span>{leg.duration && formatDuration(leg.duration / 60)}</span>&nbsp; */}
							</span>
							<div style={{height:"1px", marginRight:"10px", backgroundColor:"#ccc"}} />
						</div>}
			
					<div className="routeSelector" style={{ display: "grid", columnGap: "4px", gridTemplateColumns: "30px 1fr auto", background:"white" }}>
					
						<LookupInput value={leg.location as any}
							emptyText="Vyber Miesto"
							extraFetch={extraFetch} getRecordProperty={() => undefined}
							canCreate={false}
							canOpen={false}
							focusOnClick={true}
							onChange={(value, rec) => updateLegs(arr, index, { ...leg, location: value, latitude: rec?.latitude, longitude: rec?.longitude }, false)}
							propMeta={{ logicalName: "locationid", type: MetaPropertyType.Lookup, flags: 0, targets: ["route_location"] }} />
						
						{notLastItem &&
								<CustomDateInput prevValue={arr[index - 1]?.startdate} value={leg.startdate} onChange={e => updateLegs(arr, index, { ...leg, startdate: new Date(e.target.value).toISOString() }, false, true)}></CustomDateInput>
						}
						{index === arr.length - 1 && <div>
						</div>}
						<div style={{gridArea:"1/1/3/2", display:"grid", placeItems:"center center",padding:"0"}} onClick={() => setOpen(openIndex === index ? -1 : index)}>
							{index === 0 && <i className="fa fa-rocket routeLargeIcon" style={{ marginBottom: "2px"}}/>}
							{index === arr.length -1 && <i className="fa fa-flag-checkered routeLargeIcon"/>}
							{(notLastItem) && <span>
								{openIndex !== index && <i className="fa fa-circle-chevron-down" />}
								{openIndex === index && <i className="fa fa-circle-chevron-up" />}
							</span>}
						</div>
						<div style={{ opacity: "0.6", whiteSpace: "nowrap" }}>{(index > 0) && <span>Príjazd {formatDay(leg.enddate, arr[index - 1]?.startdate)}</span>}
							{index === 0 && "Štart"}
							</div>
						<span style={{ opacity: "0.6", placeSelf: "center flex-end" }}>{notLastItem && "Odjazd"}
						</span>
						{/* {notLastItem && <>
							<span className="routeVehicle" onClick={() => updateLegs(arr, index, { ...leg, masstransit: leg.masstransit === "train" ? "no" : "train" }, false, true)}>
								<i className={"fa fa-" + (leg.masstransit === "train" ? "train" : "car")} />
							</span>	
						</>
						}	 */}
						{/* {(index > 0 && notLastItem) && <div className="routeDelLeg" onClick={()=>updateLegs(arr,index, undefined, false)}><i className="fa fa-trash"/></div>} */}
						<div className="routeLegButtons">
							{(openIndex === index) &&
								<div className="routeAddButton">
									<LookupInput value={undefined as any}
										emptyText="+ Pridaj"
										extraFetch={extraFetch} getRecordProperty={() => undefined}
										canCreate={false}
										canOpen={false}
										onChange={(value, rec) => addLeg(arr, index + 1, { ...leg, location: value, latitude: rec?.latitude, longitude: rec?.longitude })}
										propMeta={{ logicalName: "locationid", type: MetaPropertyType.Lookup, flags: 0, targets: ["route_location"] }} />
									</div>
							}
						
							{(index > 0 && openIndex === index) && <div onClick={() => updateLegs(arr, index, undefined, false)} 	className="routeDeleteButton">
								<i className="fa fa-trash"/> Odstranit
								</div>}
						</div>
					</div>
					
				</>
			})}
		</div>
	</div>
}

const formatDay = (textValue: string, prevValue?: string) => {

	if (!textValue)
		return "";

	const dt = new Date(textValue);
	textValue = dt.getDate() + "." + (dt.getMonth() + 1) + "."
	if (dt.getFullYear() !== new Date().getFullYear())
		textValue += dt.getFullYear();

	const pp = prevValue;
	if (pp) {
		const prevDt = new Date(pp);
		if (prevDt.getDate() === dt.getDate() && prevDt.getMonth() === dt.getMonth() && prevDt.getFullYear() === dt.getFullYear()) {
			textValue = "";
		}
	}

	if (textValue)
		textValue += " ";
	textValue += dt.getHours() + ":" + (dt.getMinutes() / 100).toFixed(2).substring(2, 4);
	return textValue;
}

const CustomDateInput = (props: { value: string, onChange: (e: any) => void, prevValue?: string } ) => {

	const { value, onChange } = props;
	const inputRef = useRef<HTMLInputElement>(null);

	const textValue = formatDay(value, props.prevValue);;

	const onClick = (e:any) => {
		(inputRef.current as any).showPicker();
	}
	//⏷ 

	return <div className="routeDate" onClick={onClick}>
		<input ref={inputRef} style={{opacity:"0", width:"0px", height:"0px", gridRow:"1/2"}} type="datetime-local" value={dateForInput(value)} onChange={onChange}></input>
		<span style={{whiteSpace:"nowrap"}}>{textValue}</span >
	</div>
}

const formatDuration = (duration: number) => {

	const min = (duration | 0) % 60;
	const hours = (duration | 0) / 60 | 0;

	// let s = "";
	// if (hours > 0) {
	// 	s += new Intl.NumberFormat(undefined, { style: "unit", unitDisplay: "short", unit: "hour" }).format(hours);
	// }
	// if (!s || min) {
	// 	s += " " + new Intl.NumberFormat(undefined, { style: "unit", unitDisplay: "short", unit: "minute" }).format(min);
	// }
	const s = hours + ":" + (min / 100).toFixed(2).substring(2, 4);
	return s;
}

const dateForInput = (dt?: string) => {
	if (dt) {
		const d = new Date(dt);
		return dateToString(d, true);
	}
	return undefined;
}

interface ILeg {
	id: string;
	location?: ILookupValue;
	startdate: string; // arrived
	enddate: string; // leave
	distance: string; // distance to the place before
	duration?: number; // travel duration in minutes
	carid?: ILookupValue;
	masstransit: string; // whether mass-transit instead of car was used.
	latitude?: string;
	longitude?: string;
}