import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { register, unregister } from './serviceWorkerRegistration';

import reportWebVitals from './reportWebVitals';
import { hydrate } from 'react-dom';

//(window as any)['cordova'] = true;

const renderReactDom = () => {
  const rootElement = document.getElementById('root') as HTMLElement;
  const root = ReactDOM.createRoot(rootElement);

  if (rootElement.hasChildNodes()) {
    console.log("hydrating");
    hydrate(<App />, rootElement);
  } else {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
};

if (false && (window as any).cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  renderReactDom();
}

// PWA
unregister();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
