

export const makeRichOptionSpan = (o: {value:string, label?:string, color?:string, icon?:string}, fromList: boolean = false) => {
	let style: any = undefined;
	let icon: any = undefined;
	if (o.color) {
		icon = "●";
		style = { color: o.color };//, border: "1px dotted " + o.color, alignSelf:"flex-start" };
	}
	if (o.icon) {
		icon = <i className={"fa fa-" + o.icon} style={{ marginRight: "2px" }} />;
	}
	if (fromList && style) {
		return <span style={{ width:"fit-content", color: "var(--back-color)", background: style.color, borderRadius: "4px", fontSize: "small", boxShadow:"0 2px 4px 0 #ccc", padding:"1px 4px" }}>
			{icon}{o.label || o.value}
		</span>
	}
	return <span><span style={style}>{icon}</span>{o.label || o.value}</span>
}