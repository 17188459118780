import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Path, useNavigate } from "react-router-dom";
import { MetaContext } from "../AppState";
import { IconButton } from "../components/IconButton";
import { useListLoader, useScrollRestore } from "../components/SimpleGrid";
import { InputWithWrap } from "../components/WrapInput";
import { useDerivedState } from "../hooks/useDerivedState";
import { DataService } from "../service";
import { resizeImage, fileToBase64 } from "../services/download";
import { getFileUrl } from "../services/getFileUrl";
import { dateToString } from "../utils/objectHelpers";
import { ObjectListProps } from "./BaseObjectList";
import { useFetchQuery } from "./useFetchQuery";
import { runCommand } from "../command/runCommand";
import { ModalContext } from "../modal/Modal";
import { useSizeObserver } from "../hooks/useSizeObserver";
import { showFilePreviewDialog } from "../objectForm/fields/ImageField";

export const ActivityList = (props: ObjectListProps) => {
	
	const navigate = useNavigate();
	const metadata = useContext(MetaContext);
	const modal = useContext(ModalContext);
	const objectName = props.query.entity.name;

	const [query, setQuery] = useDerivedState(props.query, [props.query]);

	const { rows, setRows, loading, onScroll } = useFetchQuery(query, 50, true);

	const onAdd = () => {
		const to: Path = { pathname: "/" + metadata.appId + "/edit/" + objectName + "/0", search: "", hash: "" };
		if (props.newParams) {
			const pp = new URLSearchParams({ init: JSON.stringify(props.newParams) });
			to.search = pp.toString();
		}
		navigate(to);
	}

	const [newNote, setNewNote] = useState({ body: "", document: "" } as { id?: string, body: string, document?: string });
	const onSave = async () => {
		const noteObj = {
			...props.newParams,
			...newNote,
		} as any;
		setNewNote({ body: "", document: "" });

		const id = await DataService.updateRecord(objectName, noteObj, noteObj.id ? "update" : "create");
		// reset query
		setQuery(x => JSON.parse(JSON.stringify(props.query)));

		//fixme: focus item.
		noteObj.id = id;
		const postsave = { name: objectName + ".postsave", kind: "custom" as any };
		await runCommand(postsave, () => noteObj, noteObj, (x) => { }, metadata, modal);
	};

	const maxHeight = 1024;
	const uploadRef = useRef<HTMLInputElement>(null);
	const onOpenFile = useCallback(() => {
		uploadRef.current?.click();
	}, [uploadRef]);
	const onUploadFile = useCallback(async (e: { target: any }) => {
		const files = (e.target).files;
		if (files && files.length > 0) {
			const f = files[0];
			let base64body: string;
			if (maxHeight && f.type.match(/image.*/))
				base64body = await resizeImage(f, maxHeight);
			else
				base64body = await fileToBase64(f);
			setNewNote(x => ({ body: (x.body || "") + " [" + f.name + "]", document: base64body }));
		}
	}, [setNewNote]);

	const mainRef = useRef<HTMLDivElement>(null);

	//const sessionKey = "ActivityList";
	//const onDivScroll = useScrollRestore(mainRef, loading, sessionKey + "mosaic");
	const lastItemRef = useListLoader(mainRef, onScroll);

	const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			e.stopPropagation();
			if (newNote.body) {
				onSave();
			}
		}
	}

	const bottomOffset = useRef(0);
	const lastRows = useRef<any[]>([]);

	const onScroll2 = () => {
		const div = mainRef.current;
		if (div) {
			bottomOffset.current = div.scrollHeight - div.scrollTop;
			//console.log("Scroll2: " + bottomOffset.current.toFixed(0));
		}
	}

	const updateScrollPosition = () => {
		const div = mainRef.current;
		if (div) {
			const top = div.scrollHeight - bottomOffset.current;
			console.log("---scrollTo:" + top);
			mainRef.current?.scrollTo({ "top": top, "behavior": "auto" });
		}
	}

	useLayoutEffect(() => {
		if (lastRows.current !== rows) {
			if (lastRows.current[0]?.id !== rows[0]?.id) {
				console.log("bottom row changed: old:" + lastRows.current[0]?.body + " new: " + rows[0]?.body);
				bottomOffset.current = 0;
			}
			lastRows.current = rows;
			updateScrollPosition();
		}
	});

	useSizeObserver(mainRef, (r, rect) => {
		console.log("---sizechanged:" + rect.height);
		updateScrollPosition();
	});

	const onEditComment = (r: { id: string, body: string }) => {
		setNewNote({ id: r.id, document: "", body: r.body });
	}
	const inputPanelRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const onPaste = (e: any) => {
			if (inputPanelRef.current && inputPanelRef.current.querySelector("textarea") === document.activeElement) {
				if (e.clipboardData.files) {
					onUploadFile({ target: { files: e.clipboardData.files } });
					e.preventDefault();
					e.stopPropagation();
				}
			}
		};
		window.addEventListener('paste', onPaste);
		return () => window.removeEventListener('paste', onPaste);
	}, [onUploadFile]);

	return <div className="activityListContainer">
		<div ref={mainRef} className="activityListGrid" onScroll={onScroll2} >
			<div key="lastItemObserver" className="simpleGridLoader" ref={lastItemRef} style={{visibility:(props.query as any).hasMore?"visible":"hidden" }} />
			{rows.slice().reverse().map((r, i) => {
				return <ActivityListRow key={r.id} record={r} onEdit={onEditComment} />
			})}
		</div>
		<div className="activityListCreate" ref={inputPanelRef}>
			<InputWithWrap divClassName="activityListTextArea" type="text" rows={2} value={newNote.body} onKeyDown={onKeyDown} onChange={e=>setNewNote({...newNote, body:e})} />
			{!newNote.body && <span className="activityListTextAreaPlaceholder">Write a comment...<br/><span style={{fontSize:"smaller"}}>(Enter to Send)<br/>(Shift+Enter for new line)</span></span>}
			<IconButton className="saveButton" icon="paper-plane" label="Send" disabled={!newNote.body} onClick={onSave} />
			<IconButton className="attachButton" icon="paperclip" onClick={onOpenFile} />
			<input ref={uploadRef} hidden={true} type="file" onChange={e => e.target.value = null as any} 
			onInput={onUploadFile} />
		</div>
	</div>
}

export const ActivityListRow = (props: { record: any, onEdit: (r:any)=>void }) => {

	const metadata = useContext(MetaContext);
	const modal = useContext(ModalContext);

	const { type, ownerid, createdon, scheduledstart, body, document } = props.record;

	const isImage = !!document && (document.indexOf("image/") >= 0 || document.indexOf(".image") >= 0);
	const isPdf = !!document && (document.indexOf("application/pdf") >= 0 || document.indexOf(".pdf") >= 0);
	const isFile = !!document && !isImage;

	const imageUrl = getFileUrl(metadata, document);

	const dateLabel = createdon ? makeShortDateLabel(createdon) : "";

	const bodyRef = useRef<HTMLDivElement>(null);
	useLayoutEffect(() => {
		const div = bodyRef.current;
		if (div) {
			if (div.scrollHeight > div.offsetHeight) {
				const sm = div.getElementsByClassName("showMore")[0] as HTMLElement;
				if (sm)
					sm.style.display = "inline";
			}
		}
	}, [body]);

	const maxHeight = (1.33 * 8) + "em";

	const onExpand = useCallback((e: React.MouseEvent) => {
		const div = e.target as HTMLElement;
		const parent = div.parentElement;
		if (parent) {
			if (parent.style.maxHeight !== "unset") {
				parent.style.maxHeight = "unset";
				parent.style.paddingBottom = "1.5em";
				div.innerText = "Show Less";
				parent.parentElement?.scrollIntoView();
			}
			else {
				parent.style.maxHeight = maxHeight;
				parent.style.paddingBottom = "5px";
				div.innerText = "Show More";
			}
		}
	}, []);

	const updateReminder = async (date: any) => {
		const d = date ? new Date(date).toISOString() : null;
		props.record.scheduledstart = d;
		await DataService.updateRecord("activity", { id: props.record.id, scheduledstart: d }, "update");
		render(r => !r);
	}

	const [_, render] = useState(false);
	const onReminderClick = async (e: any) => {

		const dateInput = bodyRef.current?.parentElement?.querySelector("input");
		if (dateInput) {
			let n = new Date();
			n = new Date(n.getFullYear(), n.getMonth(), n.getDate() + 1, 6, 0, 0);
			const dt = props.record.scheduledstart;
			const dts = dateToString(dt ? new Date(dt) : n, true);
			dateInput.value = dts;
			//dateInput.defaultValue = "2023-04-01T09:15";//dateToString(n, true);
			const picker = (dateInput as any);
			if (picker.showPicker)
				picker.showPicker();
			dateInput.onchange = (pickerEvent: any) => {
				updateReminder(dateInput.value);
			}
		}
		// //const dateLabel = createdon ? makeShortDateLabel(createdon) : "";
		// const options = ["Tomorrow 9:00", "Tomorrow 10:00", "Tomorrow @13", "Tomorrow @14"]
		// let n = new Date();
		// n = new Date(n.getFullYear(), n.getMonth(), n.getDate() + 1, 9, 0, 0);
		// const d = n.toISOString();
		// props.record.scheduledstart = d;
		// await DataService.updateRecord("activity", { id: props.record.id, scheduledstart: d }, "update");
		// render(r => !r);
	}
	let reminderLabel;
	if (scheduledstart) {
		const remDateLabel = makeShortDateLabel(scheduledstart);
		const className = new Date(scheduledstart) < new Date() ? "activityReminderOverdue activityReminderOn" : "activityReminderOn";
		reminderLabel = <span onClick={onReminderClick} className={className}>{remDateLabel}<i className="fa fa-bell" /></span>
	} else {
		reminderLabel = <span onClick={onReminderClick}	className="activityReminderOff"><i className="fa fa-bell-slash" /></span>
	}

	const onFileClick = () => {
		showFilePreviewDialog(modal, { imageUrl, isImage, isPdf, name: body });
	}
 
	return <div className="activityListRow">
		<div className="activityListRowHeader">
			<span className="activityListRowHeaderType">{type}</span>
			<span className="activityListRowHeaderOwner">{ownerid && ownerid.label}</span>
			<span className="activityListRowHeaderDate">{dateLabel} <i className="fa fa-pen" style={{color:"lightgray"}} onClick={()=>props.onEdit(props.record)} /></span>
			<span className="activityListRowReminder">{reminderLabel}
				<input type="datetime-local" style={{ width:"0", height:"0",opacity:"0", position:"absolute", right:"0", bottom:"0" }}></input></span>
		</div>
		<div className="activityListRowBody" style={{ maxHeight: maxHeight }} ref={bodyRef}>
			{body}
			<span className="showMore" style={{ display: "none" }} onClick={onExpand}>Show More</span>
		</div>
		{isImage && <div className="activityListRowImage" onDoubleClick={onFileClick}><img src={imageUrl} alt={props.record.name} /></div>}
		{isFile && <div className="activityListRowFile" style={{padding:"5px", textDecoration:"underline", color:"var(--acccent-color)"}} onClick={onFileClick}><i className="fa fa-paperclip" /> View</div>}
	</div>
}

const makeShortDateLabel = (createdon: any) => {
	let dateLabel = "";
	const dt = new Date(createdon);
	let totalSeconds = ((new Date().valueOf()) - (dt.valueOf())) / 1000;
	if (totalSeconds < 0)
		totalSeconds = -totalSeconds;
	if (totalSeconds < 86400) {
		if (totalSeconds < 60)
			dateLabel = "1 min ago";
		else if (totalSeconds < 3600)
			dateLabel = ((totalSeconds / 60) | 0) + " min ago";
		else
			dateLabel =  ((totalSeconds / 3600) | 0) + " hours ago";
	} else {
		const opts = { day: "numeric", month: "short" } as any;
		if (dt.getFullYear() !== (new Date()).getFullYear())
			opts.year = "2-digit";
		dateLabel = dt.toLocaleString([], opts );
	}
	return dateLabel;
}