import React, { useCallback, useState } from "react";
import { IFormField } from "../../AppSchema"
import { PanelProps } from "../panels/PanelProps"
import { IconButton } from "../../components/IconButton";


export const ButtonField = (props: PanelProps & { field: IFormField }) => {

	//const modal = useContext(ModalContext);
	const fieldConfig = (props.field.customComponent as string).split(';');
	const [icon, label, commandName] = fieldConfig.slice(1);

	const [style, setStyle] = useState(undefined as any);

	const onButtonClick = useCallback(async (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();

		const service = props.getService("executeCommand");
		if (service) {
			try {
				service.executeCommand(commandName);
				setStyle({ color: "#080"});
				setTimeout(() => setStyle(undefined), 500);
			}
			catch (e) {
				alert(e);
			}
		}

	}, [props.field.customComponent, props.getService]);

	return <div className="buttonField" style={{transition:"color 0.2s"}}>
		<IconButton icon={icon} label={label} onClick={onButtonClick} style={style} />
	</div>
}