// declare module '!!raw-loader!*' {
// 	const content: string;
// 	export default content;
//   }

// const webpackMarkdownLoader = require.context(
// 	'!raw-loader!./markdownFiles',
// 	false,
// 	/\.md$/,
//   );

import RobotoBold from './font/Roboto-Bold.ttf';
import RobotoLight from './font/Roboto-Light.ttf';
//import raw from 'raw.macro';
/*
import doc2 from "pdfkit"; 
type idoc = typeof doc2;
/*/
type idoc = any;
//*/

export const renderDocument = async (mainGrid: IPdfGrid, styles: { [key: string]: IPdfStyle }, docTitle?: string, docSize?: any) => {
	const PDFDocument = window["PDFDocument" as any] as unknown as idoc;
	docSize = docSize ? { ...docSize } : {};
	if (!docSize.size)
		docSize.size = "A4";
	if (!docSize.margins)
		docSize.margins = { left: 40, right: 40, top: 40, bottom: 40 };
	let doc = new PDFDocument(docSize);
	let stream = doc.pipe((window["blobStream" as any] as any)());

	let fontUrl = RobotoLight; //"./pdfkit/font/roboto/Roboto-Light.ttf";//"https://fonts.cdnfonts.com/s/12165/Roboto-Thin.woff";
	let fontData = await (await fetch(fontUrl)).arrayBuffer();
	doc.registerFont("roboto", fontData);
	fontUrl = RobotoBold;  //"./pdfkit/font/roboto/Roboto-Bold.ttf";//"https://fonts.cdnfonts.com/s/12165/Roboto-Thin.woff";
	fontData = await (await fetch(fontUrl)).arrayBuffer();
	doc.registerFont("roboto-Bold", fontData);
	// doc.page.margins.left = 20;
	// doc.page.margins.right = 20;
	const docWidth = doc.page.width - doc.page.margins.left - doc.page.margins.right;
	measureHeight(doc, docWidth, mainGrid, styles);
	render(doc, [docWidth, 10000], mainGrid, styles);

	doc.info['Title'] = docTitle || 'Faktura';
	doc.info['Author'] = 'Inuko.com';
	doc.info['Producer'] = 'Inuko.net';
	doc.info['Creator'] = 'Inuko.net';
	// doc.x = 0
	// doc.y = doc.page.height - doc.page.margins.bottom - doc.page.margins.top;
	// doc.fontSize(20).text("Ďakujeme!", { align: "right", baseline:"bottom" });

	doc.end();
	const p = new Promise<Blob>((res, rej) => {
		stream.on('finish', function () {
			res(stream.toBlob("application/pdf"));
			//const blobUrl = stream.toBlobURL('application/pdf');
			//console.log("blob:" + blobUrl);
			//window.open(blobUrl);
			//iframe.src =
		});
	});
	return p;
}

export const createGrid = (x: number, y: number, cols: string[], rows: string[], children?: IPdfObject[]) => {
	const grid = {
		pos: [x, y],
		type: "grid",
		cols: cols,
		rows: rows,
		className: "grid",
		measuredCols: [], measuredRows: [],
		objects: children || [],
	} as IPdfGrid;
	return grid;
}
export const addGrid = (parent: IPdfGrid, x: number, y: number, cols: string[], rows: string[], children?: IPdfObject[]) => {
	const grid = createGrid(x, y, cols, rows, children);
	addObject(parent, x, y, grid);
	return grid;
}
export const addObject = (grid: IPdfGrid, x: number, y: number, obj: IPdfObject) => {
	if (y < 0)
		y = grid.rows.length;
	if (grid.rows.length <= y) {
		grid.rows.push("auto");
	}
	obj.pos = [x, y];
	grid.objects.push(obj);
}
export const addText = (grid: IPdfGrid, x: number, y: number, className: string, text: string, style?: IPdfStyle) => {
	const textObj = {
		pos: [x, y],
		type: "text",
		text: text == null ? "" : text,
		style: style,
		className: className
	} as IPdfText;
	addObject(grid, x, y, textObj);
	return textObj;
}
export const addImage = (grid: IPdfGrid, x: number, y: number, image: any, size: [number, number], className: string, style?: IPdfStyle) => {
	const img = {
		pos: [x, y],
		type: "image",
		image: image,
		size: size,
		style: style,
		className: className
	} as IPdfImage;
	addObject(grid, x, y, img);
	return img;
}

export interface IPdfStyle {
	borderThickness?: number[];
	borderColor?: string;
	borderStyle?: string;
	margin?: number[];
	padding?: number[];
	background?: string;
	color?: string;
	horzAlign?: "start" | "end" | "center" | "stretch";
	vertAlign?: "start" | "end" | "center" | "stretch";
	horzTextAlign?: "start" | "end" | "center" | "stretch";
	vertTextAlign?: "start" | "end" | "center" | "stretch";
	fontFamily?: string;
	fontSize?: number;
	pageBreakAfter?: boolean;
}

export interface IPdfObject {
	type: "text" | "image" | "grid" | "border";
	className?: string;
	style?: IPdfStyle;
	pos?: [number, number];
	colSpan?: number;
	measuredSize?: [number, number];
}
export interface IPdfText extends IPdfObject {
	type: "text";
	text: string;
	link?: string;
}

export interface IPdfImage extends IPdfObject {
	type: "image";
	image: any; //base64, arrayBuffer;
	size: [number, number];
}

export interface IPdfGrid extends IPdfObject {
	type: "grid";
	cols: string[]; // x fr, px, fit-content
	rows: string[]; // auto or px
	measuredCols: number[];
	measuredRows: number[]; 
	objects: IPdfObject[];
} 

const measureGrid = (doc: idoc, parentWidth: number, obj: IPdfGrid, styles: { [key: string]: IPdfStyle }) => {
	const rows = (obj.rows && obj.rows.length && obj.rows) || ["auto"];
	const cols = (obj.cols && obj.cols.length && obj.cols) || ["1fr"];

	const measuredCols: number[] = [];
	
	let availWidth = parentWidth;
	let lastFr = 0;
	let fr = 0;
	for (const c of cols) {
		const num = + (c.substring(0, c.length - 2));
		if (c.endsWith("px")) {
			availWidth -= num;
		} else {
			fr += num;
			lastFr = measuredCols.length;
		}
		measuredCols.push(num);
	}
	if (availWidth < 0)
		availWidth = 0;
	
	let spentFr = 0;
	
	for (let i = 0; i < measuredCols.length; i++) {
		const c = cols[i];
		if (c.endsWith("fr")) {
			if (i === lastFr) {
				measuredCols[i] = availWidth - spentFr; // round up last column
			} else {
				const w = ((availWidth / fr) * measuredCols[i]) | 0;
				measuredCols[i] = w;
				spentFr += w;
			}
		}
	}
	const measuredRows: number[] = [];
	for (const r of rows) {
		let rowHeight = 0;
		//if (r === "auto") {
		for (const child of obj.objects) {
			const pos = child.pos || [0, 0];
			if (pos[1] === measuredRows.length) {
				let parentWidth = measuredCols[pos[0]];
				if (child.colSpan) {
					parentWidth = measuredCols.slice(pos[0], pos[0] + (child.colSpan || 1)).reduce((prev, agg) => agg + prev, 0);
				}
				const h = Math.ceil(measureHeight(doc, parentWidth, child, styles));
				if (rowHeight < h)
					rowHeight = h;
			}
		}
		//} else {
		if (r !== "auto") {
			rowHeight = +r;
		}
		measuredRows.push(rowHeight);
	}
	obj.measuredCols = measuredCols;
	obj.measuredRows = measuredRows;
	const width = measuredCols.reduce((acc, c) => (acc + c), 0);
	const height = measuredRows.reduce((acc, c) => (acc + c), 0);
	obj.measuredSize = [width, height];

	return height;
}

const renderGrid = (doc: idoc, size: [number, number], obj: IPdfGrid, styles: { [key: string]: IPdfStyle }) => {
	const pageHeight = doc.page.height - doc.page.margins.top - doc.page.margins.bottom;
	const docX = doc.x;
	const docY = doc.y;
	let startX = docX;
	let startY = docY;

	const rows = obj.measuredRows;
	const cols = obj.measuredCols;
	for (let y = 0; y < rows.length; y++) {
		startX = docX;

		// do we have a single grid in row -> extra case for page break.
		let singleGrid: IPdfGrid | undefined | null  = undefined;
		for (let x = 0; x < cols.length; x++) {
			for (const c of obj.objects) {
				const p = c.pos || [0, 0];
				if (p[0] === x && p[1] === y) {
					if (singleGrid === undefined && c.type === "grid")
						singleGrid = c as IPdfGrid;
					else
						singleGrid = null; // null => not a grid, undefined => not found,
				}
			}
		}
		if (singleGrid) {
			const [x, y] = singleGrid.pos || [0, 0];
			doc.x = startX;
			render(doc, [cols[x], rows[y]], singleGrid, styles);

			if (styles[singleGrid.className || ""]?.pageBreakAfter || singleGrid.style?.pageBreakAfter) {
				doc.addPage();
			}

			startY = doc.y;
			continue;
		}

		if (doc.y + rows[y] > pageHeight) {
			doc.addPage();
			startY = doc.y;
		}

		for (let x = 0; x < cols.length; x++) {
			for (const c of obj.objects) {
				const p = c.pos || [0, 0];
				if (p[0] === x && p[1] === y) {
					doc.x = startX;
					doc.y = startY;
					//doc.moveTo(startX, startY);
					let parentWidth = cols[x];
					if (c.colSpan) {
						parentWidth = cols.slice(x, x + (c.colSpan || 1)).reduce((prev, agg) => agg + prev, 0);
					}
					render(doc, [parentWidth, rows[y]], c, styles);
				}
			}
			startX += cols[x];
		}
		//doc.moveTo(x, startY);
		//doc.strike(docX, startY, startX - docX, 1, { "color": "black" });
		//doc.lineTo()
		startY += rows[y];
		doc.y = startY; // ensure doc.Y is set correctly after this call.
	}
	doc.x = docX;
	//doc.strike(docX, startY, startX - docX, 1, { "color": "black" });
}

const measureHeight = (doc: idoc, parentWidth: number, obj: IPdfObject, styles: { [key: string]: IPdfStyle }) => {
	
	const style = getObjStyle(obj, styles);
	const textObj = obj as IPdfText;

	const padding = style.padding || [0, 0, 0, 0];
	const margin = style.margin || [0, 0, 0, 0];
	const border = style.borderThickness || [0, 0, 0, 0];

	const outerWidth = padding[1] + padding[3] + margin[1] + margin[3] + border[1] + border[3];
	const outerHeight = padding[0] + padding[2] + margin[0] + margin[2] + border[0] + border[2];

	parentWidth -= outerWidth;

	let sz: [number, number];// = [0, 0];
	let height = 0;
	switch (obj.type) {
		case "text":
			doc.font(style.fontFamily || "Helvetica");
			doc.fontSize(style.fontSize || 12)
			height = doc.heightOfString(textObj.text, { "width": parentWidth, height: Infinity });
			height = Math.ceil(height);
			//const width = doc.widthOfString(textObj.text, { "width": parentWidth, height: Infinity });
			const padRight = doc._fontSize / 6;
			const width = Math.min(parentWidth, Math.ceil(doc.widthOfString(textObj.text, { "width": parentWidth, height: Infinity }) + padRight));
			sz = [width, height];
			break;
		case "image":
			const img = obj as IPdfImage;
			sz = img.size || [100, 100];
			if (sz[0] >= parentWidth) {
				sz[1] = sz[1] * parentWidth / sz[0];
				sz[0] = parentWidth;
			}
			//obj.measuredSize = [sz[0] + outerWidth, sz[1] + outerHeight];
			break;
		case "grid":
			height = measureGrid(doc, parentWidth, obj as IPdfGrid, styles) + outerHeight;
			sz = obj.measuredSize || [0, 0];
			break;
		default:
			throw Error("unknown type:" + obj.type);
	}
	obj.measuredSize = [sz[0] + outerWidth, sz[1] + outerHeight];
	return obj.measuredSize[1];
}

const getObjStyle = (obj: IPdfObject, styles: { [key: string]: IPdfStyle }) => {
	let style = { ...((obj.className && styles[obj.className]) || {}) };
	if (obj.style)
		style = { ...style, ...obj.style };
	return style;
}

const render = (doc: idoc, size: [number, number], obj: IPdfObject, styles: { [key: string]: IPdfStyle }) => {

	const style = getObjStyle(obj, styles);
	const padding = style.padding || [0, 0, 0, 0];
	const margin = style.margin || [0, 0, 0, 0];
	const border = style.borderThickness || [0, 0, 0, 0];
	const halign = style.horzAlign || "stretch";
	const valign = style.vertAlign || "stretch";

	let objSize = obj.measuredSize as [number, number];
	//objSize = [objSize[0] - margin[1] - margin[3], objSize[1] - margin[0] - margin[2]];
	//let sz = [size[0] - margin[1] - margin[3], size[1] - margin[0] - margin[2]];
	let sz = [size[0], size[1]];
	const align = (align: string, pos: number, i: number) => {
		switch (align) {
			case "center":
				pos = pos + (sz[i] - objSize[i]) / 2;
				break;
			case "end":
				pos = pos + sz[i] - objSize[i];
				break;
			case "start":
			default:
				break;
		}
		if (align !== "stretch")
			sz[i] = objSize[i];
		return pos;
	}
	doc.x = align(halign, doc.x, 0);
	doc.y = align(valign, doc.y, 1);

	doc.x += margin[3];
	doc.y += margin[0];
	sz[0] -= margin[3] + margin[1];
	sz[1] -= margin[2] + margin[0];

	const borderStyle = style.borderColor || "black";//{ color: style.borderColor || "black" };
	if (style.borderStyle === "dotted")
		doc.dash(1, { space: 2 });
	else 
		doc.undash();
	if (border[0] && border[0] === border[1] && border[0] === border[2] && border[0] === border[3]) {
		const h = border[0] / 2;
		doc.lineWidth(border[0]).rect(doc.x+h, doc.y+h, sz[0]-border[0], sz[1]-border[0]).stroke(borderStyle);
	} else {
		const right = doc.x + sz[0] - border[1];
		const bottom = doc.y + sz[1];
		if (border[0])
			doc.lineWidth(border[0]).polygon([doc.x, doc.y], [right, doc.y]).stroke(borderStyle);
			//doc.rect(doc.x, doc.y, sz[0], border[0]).fill(borderStyle);
		if (border[1])
			doc.lineWidth(border[1]).polygon([right, doc.y], [right, bottom]).stroke(borderStyle);
		if (border[2])
			doc.lineWidth(border[2]).polygon([doc.x, bottom], [right, bottom]).stroke(borderStyle);
			//doc.rect(doc.x, doc.y + sz[1] - border[2], sz[0], border[2]).fill(borderStyle);
		if (border[3])
			doc.lineWidth(border[3]).polygon([doc.x, doc.y], [doc.x, bottom]).stroke(borderStyle);
			//doc.rect(doc.x, doc.y, border[3], sz[1]).fill(borderStyle);
	}

	doc.x += border[3];
	doc.y += border[0];
	sz[0] -= border[3] + border[1];
	sz[1] -= border[2] + border[0];

	if (style.background)
		doc.lineWidth(0).rect(doc.x, doc.y, sz[0], sz[1]).fillAndStroke(style.background, style.background);

	doc.fill(style.color || "black");
	
	doc.x += padding[3];
	doc.y += padding[0];
	sz[0] -= padding[3] + padding[1];
	sz[1] -= padding[2] + padding[0];

	const textObj = obj as IPdfText;
	switch (obj.type) {
		case "text":
			doc.font(style.fontFamily || "Helvetica");
			doc.fontSize(style.fontSize || 12)

			const f = (doc as any)._font;
			doc.y = doc.y + ((-f.descender + f.lineGap / 2) * doc._fontSize / 1000);
			let textAlign = "left";
			switch (style.horzTextAlign || "start") {
				case "start": textAlign = "left"; break;
				case "end": textAlign = "right"; break;
				case "center": textAlign = "center"; break;
				case "stretch": textAlign = "justify"; break;
			}
			const realHeight = (objSize[1]) - padding[0] - padding[2] - border[0] - border[2] - margin[0] - margin[2]; 
			switch (style.vertTextAlign || "start") {
				case "end": doc.y = doc.y + sz[1] - realHeight; break;
				case "center": doc.y = doc.y + (sz[1] - realHeight) / 2; break;
			}
			const options = { width: sz[0], "baseline": "top", align: textAlign } as any;
			if (textObj.link) {
				options.link = textObj.link;
				options.underline = true;
			}
			return doc.text(textObj.text, options); //(textObj.text, { "width": parentWidth, height: Infinity }); // fixme: border+padding
		case "image":
			const imgObj = obj as IPdfImage;
			return doc.image(imgObj.image, { width: Math.min(sz[0],imgObj.size[0]) });
		case "grid":
			return renderGrid(doc, size, obj as IPdfGrid, styles);
		default:
			throw Error("unknown type:" + obj.type);
	}
}