import { useCallback } from "react";
import { useDerivedState } from "./useDerivedState";

export const useSessionState = <T extends unknown>(key: string, initial: T, monitor: any[]): [T, (v: T | ((prev: T) => T)) => void] => {
	const [value, setValue] = useDerivedState<T>(() => getSessionStateItem(key, initial) , monitor);

	const mySetValue = useCallback((value: T | ((prev: T) => T)) => {
		setValue(oldValue => {
			if (typeof value === "function") {
				value = (value as ((prev: T) => T))(oldValue);
			}
			
			setSessionStateItem(key, value);
			
			return value;
		});
	}, [setValue]);

	return [value, mySetValue];
}

export const getSessionStateItem = (key: string, initial: any) => {
	const location = key[0] === "!" ? key : window.location.pathname + key;
	const saved = sessionStorage.getItem(location);
	if (saved != null) {
		try {
			return JSON.parse(saved);
		}
		catch { }
	}
	return initial;
}

export const setSessionStateItem = (key: string, value: any) => {
	const location = key[0] === "!" ? key : window.location.pathname + key;
	try {
		if (value != null) {
			const saved = JSON.stringify(value);
			sessionStorage.setItem(location, saved);
		} else {
			sessionStorage.removeItem(location);
		}
	}
	catch {
		// ignore
	}
}
