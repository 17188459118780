import { IMetaProperty, MetaPropertyType, MetaPropertyFlags } from "shared/schema"

export const isTagProperty = (prop: IMetaProperty) => {
	return prop.type === MetaPropertyType.String && prop.targets && prop.targets.length > 0 &&
		(prop.flags & (MetaPropertyFlags.MultiSelect | MetaPropertyFlags.OptionSet)) === MetaPropertyFlags.MultiSelect
}

export const formatTags = (tags: string) => {
	const t = (tags || "").split(';').filter(x => !!x);
	return t.join(', ');
}

export const TagList = (props: { tags: string[], maxItems?: number, onDelete?: (x: string) => void }) => {

	const maxItems = props.maxItems || props.tags.length;
	let tags = props.tags;

	if (tags.length > maxItems) {
		const rem = tags.length - maxItems;
		tags = tags.slice(0, maxItems);
		tags.push(rem + " more");
	}
	
	const tagClassName = "tagListItem " + (props.onDelete ? "tagListItemCanDelete" : "");

	return <div className="tagList" style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
		{tags.map(x => {
			return <span className={tagClassName} style={{ padding: "1px 5px", border:"1px solid var(--ac-color3)",  background: "#f8f8f8", borderRadius: "3px" }}>
				<>
					{x}
					{props.onDelete && <i style={{paddingLeft:"5px", color:"#888", cursor:"pointer"}} className="fa fa-circle-xmark" onClick={()=>props.onDelete && props.onDelete(x)}></i>}
				</>
			</span>;
		})}
	</div>;
}