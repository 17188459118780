export class NetworkError extends Error {
	constructor(msg: string, status: number, statusText: string) {
		super(msg);
		this.status = status;
		this.statusText = statusText;
	}
	status: number;
	statusText: string;
}

const AUTHTOKEN = "x-accessptoken";
let BASE_URL = ""; //"http://localhost:3001";
let authToken = "";

const isElectron = () => {
	return /electron/i.test(navigator.userAgent);
}
if (isElectron()) {
	BASE_URL = "https://inuko.net/"//"http://localhost:3001";
}

export const isAppAuthorized = async () => {

	if (!authToken && isElectron()) {
		try {
			const auth = window.localStorage.getItem("electronLogin");
			if (auth) {
				if (!navigator.onLine) return true; // let's go offline
				const j = JSON.parse(auth);
				const resp = await loginToServer(j.organization, j.email, j.password);
				if (resp.ok)
					return true;
			}
		}
		catch (e) {
			console.log("isAppAuthorized failed:" + e);
		}
	} 

	return !!authToken;
}

export const fetchWithRetry = async (url: string, init: RequestInit | undefined, tries: number = 3, timeoutInSeconds = 5): Promise<Response> => {
	let response: Response;
	let controller: AbortController;

	for (let n = 0; n < tries; n++) {
		let tid;

		try {
			controller = new AbortController();

			tid = setTimeout(() => controller.abort(), timeoutInSeconds * 1000);

			response = await fetch(url, { ...init, signal: controller.signal });

			clearTimeout(tid);
			return response;
		} catch (error) {
			if (tid)
				clearTimeout(tid);

			if (!(error instanceof DOMException)) {
				// Only catch abort exceptions here. All the others must be handled outside this function.
				throw error;
			}
		}
	}

	// None of the tries finished in time.
	throw new Error(`Request timed out (tried it ${tries} times, but none finished within ${timeoutInSeconds} second(s)).`);
}

export const fetchJson = async (url: string, q: any, parseResult: boolean = true, tries: number = 1) => {
	const opts = {
		headers: {
			[AUTHTOKEN]: authToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		method: "GET"
	}
	if (q) {
		opts.method = "POST";
		(opts as any).body = JSON.stringify(q);
	}
	const resp = await fetch(BASE_URL + url, opts);//fetchWithRetry(BASE_URL+url, opts, tries);
	if (!resp.ok) {
		let message = resp.statusText;
		try {
			message = (await resp.text()) || resp.statusText || "";
		}
		catch {}
		throw new NetworkError("Server Error: " + message, resp.status, message); //throw Error(resp.statusText || "retrieveError");
	} else {
		if (BASE_URL)
			authToken = resp.headers.get(AUTHTOKEN) || "";
		if (parseResult)
			return await resp.json();
		else
			return resp.text();
	}
}

export const secureFetch = async (url: string, opts?: any) => {
	opts = opts || {};
	opts.headers = opts.headers || {};
	opts.headers[AUTHTOKEN] = authToken;
	return fetch(BASE_URL + url, opts);
}

export const loginToServer = async (organization: string, email: string, password: string, url?: string) => {
	if (url) {
		BASE_URL = url;
	}
	const data = new URLSearchParams();
	data.append("username", email);
	data.append("password", password);
	data.append("organization", organization);
	const resp = await fetch(BASE_URL + "/api/login", {
		method: "POST",
		body: data
	});
	if (resp.ok && (url || BASE_URL)) {
		authToken = resp.headers.get(AUTHTOKEN) || "";
		if (isElectron()) {
			window.localStorage.setItem("electronLogin", JSON.stringify({ organization: organization, email: email, password: password }));
		}
	}
	return resp;
}
