import React, { useContext } from "react";
import { FieldsPanel } from "./FieldsPanel";
import { IPanelProps } from "./PanelProps";
import { AssociatedTabPanel } from "./AssociatedListPanel";
import { isProductListPanel, ProductListPanel } from "./ProductListPanel";
import { SplitPanel } from "./SplitPanel";
import { TabsPanel } from "./TabsPanel";
import { FlexPanel } from "./FlexPanel";
import { CloudFilesPanel } from "./CloudFilesPanel";
import { BudgetPanel } from "../../filip/BudgetPanel";
import { CommunityChart } from "../../filip/CommunityChart";
import { ScriptComponent } from "../../command/ScriptComponent";
import { SurveyResponsePanel } from "../../survey/SurveyResponsePanel";
import { SurveyDesignPanel } from "../../survey/SurveyDesignPanel";
import { JourneyPanel } from "../../journey/JourneyPanel";

export const Panel = (props: IPanelProps) => {
	const { panel } = props;
	switch (props.panel.type) {
		case "Fields": return <FieldsPanel {...props} />
		case "Split": return <SplitPanel {...props} />
		case "Tabs": return <TabsPanel {...props} />
		case "AssociatedList": {
			const PanelElement = isProductListPanel(props.panel) ? ProductListPanel : AssociatedTabPanel; 
			return <PanelElement {...props} />
		}
		case "Flex": return <FlexPanel {...props} />
		case "CloudFiles": return <CloudFilesPanel {...props} />
		default:
			const create = props.panel.createMethod;
			if (create) {
				return React.createElement(create, { ...props.context, panel: props.panel });
			}
			const customComponent = props.panel.customComponent;
			if (customComponent) {
				switch (customComponent) {
					case "FilipBudgetPanel": return <BudgetPanel {...props} />
					case "FilipCommunityChart": return <CommunityChart {...props} />
					case "SurveyResponsePanel": return <SurveyResponsePanel {...props} />
					case "SurveyDesignPanel": return <SurveyDesignPanel {...props} />
					case "JourneyPanel": return <JourneyPanel {...props} />
				}
				return  <ScriptComponent componentProps={props.context} customComponent={customComponent} />
			}
			return <div>Unknown panel type: {panel.type}</div>
	}
}