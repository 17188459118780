import JSZip from "jszip";
import { IAppConfig } from "../AppSchema";
import { IMetaObject, MetaPropertyType } from "shared/schema";
import { formatMoneyValueWithCurrency } from "../formatters";
import { simpleCellFormatterText } from "../objectList/useCellFormatter";
import { makeFetch } from "shared/fetch";
import { DataService } from "../service";
import { download } from "../services/download";
import { getFileUrl } from "../services/getFileUrl";
import { IModalContext, showMenu } from "../modal/Modal";

export const generateWordFile = async (metadata: IAppConfig, modal: IModalContext, meta: IMetaObject, record: any, records?: any[]) => {
	try {
		const f = makeFetch("hr_doc_template");
		f.entity.orders = [{ attribute: "name" }];
		const templates = await DataService.retrieveMultiple(f);
		//const tt = templates[0];

		const names = templates.map(x => <h1>{x.name}</h1>);
		const idx = await showMenu(modal, document.body, names);
		const tt = templates[idx];

		const r = await fetch(getFileUrl(metadata, tt.body));
		const dataBlob = await r.blob();

		const t = {
			name: tt.name,
			data: dataBlob,
			format: "blob",
		}

		if (!records && record)
			records = [record]
		// if (records && records.length === 1) {
		// 	record = records[0];
		// 	records = undefined;
		// }

		if (records) {
			const conds = records.map(x => ({ attribute: "id", operator: "eq", value: x.id }));
			const f2 = makeFetch(meta.logicalName);
			f2.entity.filter = { type: "or", conditions: conds as any };
			f2.entity.orders = [{ attribute: "name" }];
			const recordsToGenerate = await DataService.retrieveMultiple(f2) as any[];

			const zip = new JSZip();
			for (const r of recordsToGenerate) {
				const fname = t.name + "_" + r.name + ".docx";
				const blob = await generateWordFileFromTemplate(meta, r, t);
				if (recordsToGenerate.length === 1) {
					download(blob as any, t.name + "_" + r.name + ".docx", "application/octet-stream");
					return;
				}
				zip.file(fname, blob, { "compression": "STORE" });
			}
			const zipBlob = await zip.generateAsync({ type: "blob" });
			download(zipBlob as any, t.name + ".zip", "application/octet-stream");
		}
	}
	catch (e) {
		alert(e);
	}
}

export interface IWordTemplate {
	data: any;
	format: string;
}

export const generateWordFileFromTemplate = async (meta: IMetaObject, record: any, t: IWordTemplate, metadata?: IAppConfig) => {
	const zip = await JSZip.loadAsync(t.data);
	const fname = "word/document.xml";
	const docXml = await zip.file(fname)?.async("text");
	if (!docXml)
		throw Error("Invalid template format");
	
	const dataMap: { [key: string]: string } = {};
	for (const propMeta of meta.properties) {
		const pattern = "<" + meta.logicalName + "." + propMeta.logicalName + ">";
		dataMap[pattern] = simpleCellFormatterText(record, propMeta.logicalName, propMeta, metadata);;
	}

	const parser = new DOMParser();
	let xmlDoc = parser.parseFromString(docXml, "text/xml");
	let textStack: Element[] = []
	let textAccum = "";
	let textProlog = "";
	const resetText = () => {
		textAccum = "";
		textStack = [];
		textProlog = "";
	}
	let node: Element | null = xmlDoc.documentElement;
	const wordNS = "http://schemas.openxmlformats.org/wordprocessingml/2006/main";
	while (node) {
		// para -> reset
		if (node.localName === "p" && node.namespaceURI === wordNS) {
			resetText(); // unterminated tag, ignore.
		}
		if (node.localName === "t" && node.namespaceURI === wordNS) {
			let text = node.textContent;
			if (text) {
				const end = text.indexOf(">");
				let start = text.indexOf("<");

				if (textAccum && end >= 0 && start > 0 && end < start) {
					start = -1;
					// Case (>....<)
				} else if (start >= 0) {
					textProlog = text.substring(0, start);
					textStack = [node];
					textAccum = text.substring(start);
					//console.log("<<TAG:" + textAccum);
				}
				
				if (textAccum && end >= 0) {
					const e_length = ">".length;
					if (start >= 0)
						textAccum = text.substring(start, end + e_length);
					else
						textAccum += text.substring(0, end + e_length);

					const replace = dataMap[textAccum] || "";
					//console.log(">>TAG:" + textAccum +"->"+replace);

					const restOfText = text.substring(end + e_length);

					const textElement = textStack[0];
					textElement.textContent = textProlog + replace;
					textElement.setAttribute("xml:space", "preserve");
					if (node === textElement)
						node.textContent = node.textContent + restOfText;
					else
						node.textContent = restOfText;

					for (let i = 1; i < textStack.length; i++) {
						textStack[i].textContent = "";
						//console.log("!!TAG" + i);
					}

					// fix newlines
					const newText = textElement.textContent; 
					if (newText.indexOf('\n') >= 0) {
						const lines = newText.split('\n');
						textElement.textContent = lines[0];
						const parent = textElement.parentElement!;
						const insertPoint = textElement.nextElementSibling;
						for (let i = 1; i < lines.length; i++) {
							if (lines[i]) {
								const brElement = xmlDoc.createElementNS(wordNS, "br");
								parent.insertBefore(brElement, insertPoint);
								const lineElement = xmlDoc.createElementNS(wordNS, "t");
								const lineTextNode = xmlDoc.createTextNode(lines[i]);
								lineElement.appendChild(lineTextNode);
								parent.insertBefore(lineElement, insertPoint);
							}
						}
					}

					resetText();

					// DO NOT go to the next node!
					// Check again if there is another < following. There might be one or even many...
					continue;
				}
				else if (start < 0 && textAccum) {
					textAccum += text;
					//console.log("==TAG:" + text);
					textStack.push(node);
				}
			}
		}

		if (node.firstElementChild)
			node = node.firstElementChild;
		else { // get down or up
			while (node) {
				if (node.nextElementSibling) {
					node = node.nextElementSibling;
					break;
				}
				node = node.parentElement;
			}
		}
	}

	const s = new XMLSerializer();
	const resultXml = s.serializeToString(xmlDoc);

	zip.file(fname, resultXml);

	const blob = await zip.generateAsync({ type: "blob" });
	return blob;
}
