import { useEffect, useContext } from "react";
import { useBlocker } from "react-router-dom";
import { ModalContext, showMenu } from "./modal/Modal";


export function usePrompt(p: {
	when: (boolean | (() => boolean)),
	message: string,
	cancelMessage?: string,
	saveMessage?: string,
	saveAndExit?: (continueWith: (doNavigate: boolean) => void) => Promise<void>;
}) {
	const { when, message, cancelMessage } = p;
	const blocker = useBlocker(when);
  
	useEffect(() => {
		// message in latest Chrome will be ignored anyway - it uses its own
		if (when) window.onbeforeunload = () => {
			if (typeof (when) === "function") {
				if (!when()) {
					return undefined;
				}
			}
			return message;
		}
  
		return () => {
			window.onbeforeunload = null;
		};
	}, [when]);
  
	const modal = useContext(ModalContext);
	useEffect(() => {
		if (blocker.state === "blocked") {
			const exe = async () => {
				let body = document.body as any;
				let cancel = cancelMessage || "Stay on this page"
				let items: any[] = [message, cancel];
				if (p.saveMessage)
					items.splice(0, 0, p.saveMessage);

				const colors = { [items.length - 2]: "red" };
				let response = await showMenu(modal, body, items, { largeMenu: true, colors });
				if (!p.saveMessage)
					response++;

				if (response === 0) {
					p.saveAndExit!(z => {
						if (z)
							blocker.proceed();
						else
							blocker.reset();
					})
				} else if (response === 1) {
					blocker.proceed();
				}  else
					blocker.reset();
			}
			if (document.getElementsByClassName("menuContainer").length === 0)
				exe();
		}
	}, [blocker]);
}