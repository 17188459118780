import { useState, useRef } from "react";
import { IFormField } from "../AppSchema";

export const DropList = (props: {
	fields: unknown[];
	getName: (obj: any) => string;
	getLabel: (obj: any) => { label: string; sub: string }
	createItem: (name: string) => any;
	configureItem?: (obj: any) => React.ReactNode;
	columns?: string;
	title?: string;
	insertItemSite?: { insertItem?: (name: string) => void };
}) => {
	const { fields, getName, getLabel, createItem } = props;

	let [dragOver, setDragOver] = useState(undefined as unknown);
	const [, render] = useState(false);
	
	const dropZone = useRef<HTMLDivElement>(null);
	const dragEnter = useRef(0);

	const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {

		let propName = e.dataTransfer.types.find(x => x.startsWith("logicalname"));
		if (!propName)
			return;
		propName = propName.substring("logicalname".length);

		e.dataTransfer.dropEffect = "copy";
		e.preventDefault();

		let index = 0;
		let formItem = dropZone.current?.firstElementChild as HTMLElement;
		while (formItem) {
			const rect = formItem.getBoundingClientRect();
			if (rect.top > e.clientY)
				break;
			if (getName(fields[index]) !== "-" && propName !== "-" &&
				rect.bottom > e.clientY && rect.right > e.clientX)
				break;

			formItem = formItem.nextElementSibling as HTMLElement;
			index++;
		}
		
		if (dragOver) {
			if (index >= 0 && fields[index] === dragOver)
				return;
			fields.splice(fields.indexOf(dragOver), 1);
			render(x => !x);
		}
		else {
			dragOver = props.createItem(propName);
			setDragOver(dragOver);
		}
	
		fields.splice(index >= 0 ? index : fields.length, 0, dragOver);
	}
	const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		dragEnter.current++;
	};
	const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		dragEnter.current--;
		if (dragEnter.current === 0) {
			fields.splice(fields.indexOf(dragOver), 1);
			//fieldsPanel.fields = fieldsPanel.fields.filter(x => x !== dragOver);
			setDragOver(undefined as any as IFormField);
		}
	};
	const onDragDrop = (e: React.DragEvent<HTMLDivElement>) => {
		dragEnter.current = 0;
		setDragOver(undefined as any as IFormField);
	};

	const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
		let itemIndex = 0;
		let formItem = dropZone.current?.firstElementChild;
		while (formItem && formItem !== e.target) {
			formItem = formItem.nextElementSibling;
			itemIndex++;
		}
		if (formItem) {
			dragOver = fields[itemIndex];
			e.dataTransfer.setData("logicalname" + getName(dragOver), "");
			setDragOver(dragOver);
		}
	}

	if (props.insertItemSite) {
		props.insertItemSite.insertItem = (propName: string) => {
			const newItem = props.createItem(propName);
			fields.push(newItem);
			render(x => !x);
		}
	}

	const [openArr, setOpen] = useState([] as any[]);
	const toggleOpen = (x: any) => {
		setOpen(arr => {
			if (arr.indexOf(x) >= 0)
				return arr.filter(y => y !== x);
			else
				return arr.concat(x);
		});
	}

	const gearsButton = (props: any) => <i style={{ gridArea: "1 / 2 / 3 / 2", alignSelf: "end"}} onClick={props.onClick} className='fa fa-gear' />
	const configureItem = props.configureItem;

	return (<div className="formFieldsAvailList">
		<div className="formFieldsListHeader">{props.title||"Fields"}</div>
		<div className="formBody">
			<div className="formFieldsList formGrid" style={{gridTemplateColumns:props.columns||"1fr 1fr"}} ref={dropZone} onDragOver={onDragOver} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDragDrop}>
				{fields.map((x, index) => {
					const itemName = getName(x);
					const isSeparator = itemName === '-';
					const desc = props.getLabel(x);
					return (<div style={{ gridColumnEnd: isSeparator ? "span 2" : "span 1" }} className="formFieldsListItem formItem" draggable={true} onDragStart={onDragStart} >
						<div className={isSeparator ? "formSeparator" : "formLabel"}>{desc.label}</div>
						{!isSeparator && <input type="text" disabled={true} className="formFieldsListEditor" value={desc.sub}/>}
						{/* {configureItem && <PopupButton caption="" button={gearsButton} renderChild={() => configureItem(x)} style={{ gridRow: "1", gridColumn: "2" }}></PopupButton>} */}
						{configureItem && gearsButton({onClick:()=>toggleOpen(x)})}
						{configureItem && openArr.indexOf(x) >= 0 && configureItem(x)}
					</div>);
				})}
			</div>
		</div>
	</div>);
}
