

const STORAGE_KEY = "application-color-theme";

export const getColorScheme = () => {
	const result = localStorage[STORAGE_KEY];
	if (result === undefined || result === null || result === "")
		return 2;
	return +result;
}

// 0 == sys, 1 == dark, 2 == light
export const setColorScheme = (mode: number) => {
	localStorage[STORAGE_KEY] = mode;
	applyColorScheme(mode);
}

export const getDarkMode = (mode?: number) => {
	if (mode === undefined) {
		mode = getColorScheme() || 0;
	}
	
	let isDarkMode = mode === 1;
	if (mode === 0 && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
		isDarkMode = true;
	}
	return isDarkMode;
}

export const applyColorScheme = (mode?: number) => {
	const isDarkMode = getDarkMode(mode);
	const themeColor = document.querySelector('meta[name="theme-color"]');//?.getAttribute('content');
	if (themeColor)
		themeColor.setAttribute("content", isDarkMode ? "black" : "white");
	document.documentElement.classList.toggle("darkmode", isDarkMode);
}