import { Fetch } from "shared/fetch";
import { IExecuteRequest, IExecuteRequests, IExecuteResult } from "shared/schema";
import { fetchJson } from "./fetchJson";


export const retrieveMultipleOnline = async (q: Fetch): Promise<any[]> => {
	const newRows = await fetchJson("/api/fetch", q) as any[];
	return newRows;
}

export const updateRecordOnline = async (objectName: string, record: any, operation?: string) => {
	const uploadRequest: IExecuteRequest = {
		operation: operation as any,
		name: objectName,
		object: record
	};
	const newId = await fetchJson("/api/update", uploadRequest, false);
	return newId;
}
export const executeMultipleOnline = async (req: IExecuteRequests): Promise<IExecuteResult[]> => {
	const results = await fetchJson("/api/executemultiple", req, true) as IExecuteResult[];
	return results;
}