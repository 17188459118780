import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { IAppConfig } from "../AppSchema";
import { DataService } from "../service";

export const BLOB_PREFIX = "azblob://";

export const getBlobUrlFromFileName = (fileName: string) => {
	return BLOB_PREFIX + fileName;
}

export const getFileNameFromBlobUrl = (url: string) => {
	const prefix = BLOB_PREFIX;
	if (url && url.startsWith(prefix)) {
		return url.substring(prefix.length);
	}
	return null;
}

export const getOnlineFileUrl = (metadata: IAppConfig, url: string) => {
	const fileName = getFileNameFromBlobUrl(url);
	if (fileName) {
		const orgName = metadata.orgName; //"hoopers"; // get from metadata
		const sasToken = metadata.sas;// "sp=r&st=2023-01-11T10:01:24Z&se=2023-01-11T18:01:24Z&spr=https&sv=2021-06-08&sr=c&sig=%2FxqsTLYkL%2FF3fc9mvtLbMBZ%2BG%2FdKPygtqSGfvzxKEoY%3D";
		url = "https://inuko.blob.core.windows.net/" + orgName + "/" + fileName + "?" + sasToken;// + "&UU=" + (new Date().valueOf());
	}
	return url;
}


let BASE_PATH: string = "";
export const initOfflineBaseFolder = async () => {
	if (Capacitor.isNativePlatform()) {
		BASE_PATH = (await Filesystem.getUri({
			path: "hoopers",
			directory: Directory.Documents
		})).uri;
	}
}

export const getOfflineFileUrl = (metadata: IAppConfig, url: string) => {
	const fileName = getFileNameFromBlobUrl(url);
	if (fileName) {
		url = BASE_PATH + fileName;
		url = Capacitor.convertFileSrc(url);// + "?d=" + (new Date().valueOf());
		//console.log("read offline file:" + url);
	}
	return url;
}

export const getFileUrl = (metadata: IAppConfig, url: string) => {
	if (DataService.isOnline()) {
		return getOnlineFileUrl(metadata, url);
	} else {
		return getOfflineFileUrl(metadata, url);
	}
}