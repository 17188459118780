import { ComputedDatum, ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Fetch, FetchCondition, FetchEntity, FetchFilter } from "shared/fetch";
import { IMetaObject, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { IChart, loadGuiCharts, MetaContext } from "../AppState";
import { IconButton } from "../components/IconButton";
import { formatMoneyValueWithCurrency, formatNumericValue } from "../formatters";
import { useDerivedState } from "../hooks/useDerivedState";
import { useSessionState } from "../hooks/useSessionState";
import { ModalContext, showMenu } from "../modal/Modal";
import { configureFormFieldDialog, showEditObjectsDialog } from "../objectForm/Configure";
import { DataService } from "../service";
import { getColorScheme } from "../utils/colorScheme";
import randomColor from "../utils/randomColor";

const CleanChart: IChart = {
	// aggregateField: "",
	// aggregateMethod: "count",
	series: [{ aggregateField: "", aggregateMethod: "count" }],
	groups: [{ field: "", method: "column" }]
};
const addNewChartPlaceHolder = (charts: any[], objectName: string) => {
	charts.push({ "name": "New Chart", id: "", kind: "chart", objectname: objectName, body: "", "parsedBody": {...CleanChart } as any });
}

export const ObjectChart = (props: {
	query: Fetch;
	chartFilter?: FetchFilter;
	setChartFilter: (filter: FetchFilter | undefined) => void;
}) => {
	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);
	const objectName = props.query.entity.name;

	const meta = metadata.objects.find(x => x.logicalName === objectName);
	if (!meta) throw Error("Object not found");

	const charts = useMemo(() => {
		const charts = loadGuiCharts(metadata, objectName);
		addNewChartPlaceHolder(charts, objectName);
		return charts;
	}, [metadata, objectName]);

	const [selectedIndex, _setSelectedIndex] = useSessionState(objectName + "_chartIndex", 0, []);
	const setSelectedIndex = (index: number) => {
		_setSelectedIndex(index);
		//setSelectedBar("");
		//setData([]);
		props.setChartFilter(undefined);
	}

	const onSaveChart = (e: React.MouseEvent) => {
		let chartItem = charts[selectedIndex];
		const isNew = !chartItem.id;
		const aggregate = meta.properties.find(x => x.logicalName === chart.series[0].aggregateField);
		const group =  meta.properties.find(x => x.logicalName === chart.groups[0].field);
		if (isNew && aggregate && group) {
			chartItem.name = (aggregate.displayName + "By" + group.displayName).replaceAll(" ", "");
		}

		//show dialog to get the name...
		const fakeParent = { charts: [chartItem] };
		configureFormFieldDialog(modal, chartItem, fakeParent, "charts", ["name"], e.target as HTMLElement, async () => {
			if (fakeParent.charts.length === 0) {
				//delete...
				if (chartItem.id) {
					await DataService.updateRecord("inu_view", chartItem, "delete");
					charts.splice(selectedIndex, 1);
				}
			} else {
				let r = fakeParent.charts[0] as any;
				r.body = JSON.stringify(chart);
				r.parsedBody = undefined;
				if (isNew)
					r = { ...r, id: undefined }; // remove id for new
				const newId = await DataService.updateRecord("inu_view", r, isNew ? "create" : "update");
				if (isNew) {
					r.id = newId;
					addNewChartPlaceHolder(charts, objectName);
				}
				r.parsedBody = JSON.parse(r.body);
				charts[selectedIndex] = r;
			}
			setChart({ ...charts[selectedIndex].parsedBody as any as IChart });//force render
		}, "configureMetaProperty", "Chart");
	}

	const [chart, setChart] = useDerivedState(() => {
		const cbody = charts[selectedIndex].parsedBody as any;
		const c = cbody as IChart;
		if (!c.series || c.series.length === 0)
			c.series = [{ aggregateField: cbody.aggregateField || "", aggregateMethod: cbody.aggregateMethod || "count" }];
		if (!c.groups || c.groups.length === 0)
			c.groups = [{ field: cbody.groupField || "", method: cbody.groupMethod || "count" }];
		return c;
	}, [selectedIndex]);

	const chartSelector = (props: { children: any }) => <div className="objectChartSelector">
		<select value={selectedIndex} onChange={e => setSelectedIndex(+e.target.value)} style={{ fontWeight: "600", marginLeft: "9px" }}>
			{charts.map((x, index) => {
				return <option key={x.name} value={index}>{x.name}</option>
			})}
		</select>
		{props.children}
		<IconButton icon="save" label="" onClick={onSaveChart} />
	</div>

	return <SingleObjectChart key={charts[selectedIndex].name} meta={meta} chart={chart} setChart={setChart} ChartSelector={chartSelector} {...props} />
}

export const SingleObjectChart = (props: {
	meta: IMetaObject;
	chart: IChart;
	setChart: (c: IChart) => void;

	ChartSelector: any;

	query: Fetch;
	chartFilter?: FetchFilter;
	setChartFilter: (filter: FetchFilter | undefined) => void;
}) => {
	
	const modal = useContext(ModalContext);
	const { meta, chart, setChart } = props;

	const updateFirstSeries = (aggregateField: string | undefined, aggregateMethod: string | undefined) => {
		const c = { ...chart, series: [...chart.series] };
		if (aggregateField)
			c.series[0].aggregateField = aggregateField;
		if (aggregateMethod)
			c.series[0].aggregateMethod = aggregateMethod;
		setChart(c);
	}
	const updateFirstGroup = (field: string | undefined, method: string | undefined) => {
		const c = { ...chart, groups: [...chart.groups] };
		if (field) {
			c.groups[0].field = field;
			if (!method) {
				const propMeta = props.meta.properties.find(x => x.logicalName === field);
				if (propMeta && propMeta.type !== MetaPropertyType.DateTime)
					method = "column";
			}
		}
		if (method)
			c.groups[0].method = method;
		setChart(c);
	}
	const aggregate = meta.properties.find(x => x.logicalName === chart.series[0].aggregateField);
	const setAggregate = (p: IMetaProperty) => updateFirstSeries(p.logicalName, undefined);
	const aggMethod = chart.series[0].aggregateMethod;
	const setAggMethod = (p: string) => updateFirstSeries(undefined, p);

	const groupFields = useMemo(() => {
		// fixme: we should instead have a extra fetch definition on the chart and use any linked fields from it
		// because if we let the user choose a linked attribute from one view, and then the view changes, there will be errors...
		// we also have to adapt chartFilter to expose a Fetch instead of just a filter (so we can add the extra-fetch links)
		const arr = meta.properties.slice();
		for (const link of props.query.entity.links || []) {
			if (link.attributes) {
				for (const attr of link.attributes) {
					arr.push({ logicalName: link.alias + "__" + attr.attribute, displayName: link.alias + "-" + attr.attribute, type: 0, flags: 0 });
				}
			}
		}
		return arr;
	},[props.query])
	const group = groupFields.find(x => x.logicalName === chart.groups[0].field);
	const setGroup = (p: IMetaProperty) => updateFirstGroup(p.logicalName, undefined);
	const groupMethod = chart.groups[0].method;
	const setGroupMethod = (p: string) => updateFirstGroup(undefined, p);

	const chartKind = chart.type || "bar";
	const setChartKind = (p: string) => setChart({ ...chart, type: p });

	const [data, setData] = useState([] as any[]);
	const virtualSeriesInfo = useRef<any>(undefined);
 
	const chooseField = async (e: React.MouseEvent, setValue: (value: IMetaProperty) => void, arr?: IMetaProperty[]) => {
		arr = arr || meta.properties;
		const index = await showMenu(modal, e.target as HTMLElement, arr.map(x => x.displayName || x.logicalName));
		if (index >= 0)
			setValue(meta.properties[index]);
	}

	const yClick = async (e: React.MouseEvent) => {
		chooseField(e, setAggregate);
	}

	const xClick = async (e: React.MouseEvent) => {
		chooseField(e, setGroup, groupFields);
	}

	useEffect(() => {

		let token = { cancel: false };

		if (aggregate && aggMethod && group && groupMethod) {
			const exe = async () => {
				if (token.cancel) return;

				const q = JSON.parse(JSON.stringify(props.query)) as Fetch;
				const clearAttrs = (e: FetchEntity) => {
					if(e.attributes)
						e.attributes = [];
					if (e.allattrs)
						e.allattrs = false;
					if (e.links) {
						for (const l of e.links)
							clearAttrs(l);
					}
				}
				if (props.chartFilter && q.entity.filter) {
					const js = JSON.stringify(props.chartFilter);
				
					const clearFilters = (f: FetchFilter) => {
						if (f.filters) {
							for (const ff of f.filters)
								clearFilters(ff);
						} else if ( JSON.stringify(f) === js) {
							f.conditions = [];
						}
					}
					clearFilters(q.entity.filter);
				}
				clearAttrs(q.entity);
				q.page = undefined;
				q.pageSize = undefined;
				q.count = undefined;
				q.entity.attributes = [
					//{ attribute: aggregate.logicalName, aggregate: aggMethod as any, alias: "y" },
					//{ attribute: group.logicalName, groupBy: groupMethod as any, alias: "x" },
				];
				q.entity.orders = [];

				const g = chart.groups;
				for (let j = 0; j < g.length; j++) {
					let groupField = g[j].field;
					const linkedGroup = groupField.split('__');
					let fe = q.entity;
					if (linkedGroup.length > 1) {
						fe = fe.links!.find(x => x.alias === linkedGroup[0])!;
						groupField = linkedGroup[1];
					}
					
					fe.attributes!.push({ attribute: groupField, groupBy: g[j].method as any, alias: j > 0 ? "x" + j : "x" });
				}
				const series = chart && chart.series;
				if (series && series.length > 0) {
					for (let j = 0; j < series.length; j++) {
						q.entity.attributes.push({ attribute: series[j].aggregateField, aggregate: series[j].aggregateMethod as any, alias: "y" + j });
					}
				}

				if (g[0].method !== "" && g[0].method !== "column") {
					const firstAttr = q.entity.attributes[0];
					q.entity.orders.push({ attribute: firstAttr.attribute, alias: firstAttr.alias });
				}

				const results = await DataService.retrieveMultiple(q) as ({ x: any, y: any })[];
				if (!token.cancel) {
					let data = results.map((z, index) => {
						if (z.x === false || z.x === 0)
							return { ...z, x_label: "" + z.x, x: "ZERO" };

						if (z.x && z.x.label) {
							return { ...z, x_label: z.x.label };
						} else if (z.x != null) {
							let label = z.x?.id ? ("Unknown " + index) : z.x; // deleted lookup has no label:(
							return { ...z, x_label: "" + label };
						} else {
							return { ...z, x_label: "None", x: "NULL" };
						}
					});

					if (group && group.richOptions && (group.flags & MetaPropertyFlags.OptionSet) !== 0) {
						for (const d of data) {
							let x = d.x;
							if (x === "ZERO")
								x = 0;
							else if (x === true)
								x = 1;
							const xstr = "" + x;
							const ro = group.richOptions.find(x => x.value === xstr);
							if (ro && ro.label)
								d.x_label = ro.label 
						}
					}
					
					if (g.length > 1) {
						const map = {} as any;
						const virtualSeries = {} as any;
						let seriesCount = 0;
						let newData = [];
						for (const r of data) {
							let point = map[r.x_label];
							if (!point) {
								point = map[r.x_label] = { ...r };
								//r.y0 = 0;
								point.y0 = 0;
								newData.push(point);
							}
							for (let k = 1; k < g.length; k++) {
								const rr = r as any;
								let xN = (r as any)["x" + k];
								if (xN && xN.label)
									xN = xN.label;
								if (!xN)
									xN = "None";
								{
									let index = virtualSeries[xN];
									if (index === undefined) {
										index = seriesCount++;
										virtualSeries[xN] = index;
									}
									point["y" + index] = (r as any)["y0"];
									//if( index === 0)
								}
							}
						}
						for (const r of newData) {
							for (let k = 0; k <= seriesCount; k++) {
								let xN = (r as any)["y" + k];
								if (!xN) {
									(r as any)["y" + k] = 0;
								}
							}
						}
						data = newData;
						const colors = randomColor({ count: seriesCount + 1, hue: 'blue', seed: 32452334 });
						virtualSeriesInfo.current = { count: seriesCount, colors: colors, labels: Object.keys(virtualSeries) };
					} else {
						virtualSeriesInfo.current = undefined;
					}
					setData(data);
				}
			}

			exe();
		}
		return () => {
			token.cancel = true;
		}
	}, [chart.series, group, groupMethod, props.query]);

	let formatValue: ((v: any) => string) | undefined = undefined;
	if (aggMethod === "count" || aggMethod === "countdistinct") {
		formatValue = v => formatNumericValue(0, v);
	} else if (aggregate && aggregate.type === MetaPropertyType.Money) {
		formatValue = v => formatMoneyValueWithCurrency(undefined, v);
	} else if (aggregate && aggregate.precision !== undefined && !isNaN(+aggregate.precision)) {
		let p = +aggregate.precision;
		formatValue = v => formatNumericValue(p, v);
	}

	const [selectedBar, setSelectedBar] = useState("");
	if (data && data.length > 0 && group && props.chartFilter && !selectedBar) {
		// reconstruct selection from filter...
		const sourceFilter = props.chartFilter;
		for (const r of data) {
			const f = makeChartFilter(group, groupMethod, r.x);
			if (f.conditions?.length && f.conditions?.length == sourceFilter.conditions?.length) {
				let isEqual = true;
				for (let i = 0; i < f.conditions.length; i++) {
					if (f.conditions[i].value !== sourceFilter.conditions[i].value)
						isEqual = false;
				}
				if (isEqual) {
					setSelectedBar(r.x);
					break;
				}
			}
		}
	}

	const isSameBarValue = (a: any, b: any) => ((a === b || (!!a && !!b && !!a.id && a.id === b.id)));

	const onChartBarClick = (bar: any, e: React.MouseEvent) => {
		//console.log(JSON.stringify(bar));
		let sel = bar.data.x;
		if (isSameBarValue(sel, selectedBar))
			sel = "";
		setSelectedBar(sel);
		if(props.setChartFilter) {
			if (sel && group && groupMethod) {
				const chartFilter = makeChartFilter(group, groupMethod, sel);
				props.setChartFilter(chartFilter);
			} else {
				props.setChartFilter(undefined);
			}
		}
	};
	const getChartBarColor = (bar: any) => {
		if (virtualSeriesInfo.current) {
			const seriesIndex = +bar.id.substring(1);
			// const color = 100 + (155 / virtualSeriesInfo.current.count) * seriesIndex;
			// return "#00ffff" + (color|0).toString(16);
			return virtualSeriesInfo.current.colors[seriesIndex];
		}
		let color = "0071ebff";
		if (bar.id && chart && chart.series) {
			let index = 0;
			if (bar.id.startsWith("y"))
				index = +bar.id.substring(1);
			color = chart.series[index].color || "#0071ebff";
		}
		if (bar.data) {
			if (selectedBar && !isSameBarValue(bar.data.x, selectedBar) && color.length === 9)
				color = color.substring(0, 7) + "aa";
		}
		return color;
	}

	const multiSeriesClick = () => {
		const allFields = meta.properties;
		const aggFieldMeta: IMetaProperty = {
			logicalName: "aggregateField",
			displayName: "Field",
			type: MetaPropertyType.String,
			flags: MetaPropertyFlags.OptionSet,
			options: allFields.map(x => x.logicalName),
			richOptions: allFields.map(x => ({ "value": x.logicalName, label: x.displayName })),
		}
		const aggMethodMeta: IMetaProperty = {
			logicalName: "aggregateMethod",
			displayName: "Aggregate",
			type: MetaPropertyType.String,
			flags: MetaPropertyFlags.OptionSet,
			options: ["count", "countdistinct", "min", "max", "sum", "average"],
			//richOptions: allFields.map(x => ({ "value": x.logicalName, label: x.displayName })),
		}
		const opts = { minCount: 1 };
		showEditObjectsDialog("Edit Series", modal, opts, [aggFieldMeta, aggMethodMeta, "color"], chart.series || [], s => setChart({ ...chart, series: s }));
	}
	const multiGroupClick = () => {
		const allFields = meta.properties;
		const fieldMeta: IMetaProperty = {
			logicalName: "field",
			displayName: "Field",
			type: MetaPropertyType.String,
			flags: MetaPropertyFlags.OptionSet,
			options: allFields.map(x => x.logicalName),
			richOptions: allFields.map(x => ({ "value": x.logicalName, label: x.displayName })),
		}
		const methodMeta: IMetaProperty = {
			logicalName: "method",
			displayName: "Group",
			type: MetaPropertyType.String,
			flags: MetaPropertyFlags.OptionSet,
			options: ["column", "year", "quarter", "month", "week", "day"],
			//richOptions: allFields.map(x => ({ "value": x.logicalName, label: x.displayName })),
		}
		const opts = { minCount: 1 };
		showEditObjectsDialog("Edit Group By", modal, opts, [fieldMeta, methodMeta], chart.groups || [], s => setChart({ ...chart, groups: s }));
	}
	const getSeriesLabel = useCallback((id: string, long = false) => {
		if (virtualSeriesInfo.current) {
			let label = virtualSeriesInfo.current.labels[+id.substring(1)] as string;
			if (label && label.length > 10 && !long)
				label = label.substring(0, 10) + "...";
			return label;
		}
		let f = aggregate;
		if (chart.series && id !== "y") {
			const l = chart.series[+id.substring(1)].aggregateField;
			f = meta.properties.find(x => x.logicalName === l);
		}
		return f?.displayName || f?.logicalName || id;
	}, [aggregate, chart.series]);

	const getChartValueScale = () => {
		if (aggregate && aggregate.type === MetaPropertyType.Integer && aggregate.options) {
			return [+aggregate.options[0], +aggregate.options.slice(-1)[0]];
		}
		return undefined;
	}

	let seriesCount = virtualSeriesInfo.current ? virtualSeriesInfo.current.count : chart.series.length;

	return <div className="objectChart">
		<div style={{gridArea:"2/1/3/2", width:"3em", justifySelf: "center", display: "flex", position:"relative"}}>
			<div className="objectChartY">
				<select value={aggMethod} onChange={e=>setAggMethod(e.target.value)} style={{ width: "auto" }}>
					{["count", "countdistinct", "min", "max", "sum", "average"].map(x => {
						return <option key={x} value={x}>{x}</option>
					})}
				</select>
				<IconButton icon="" label={aggregate ? (aggregate.displayName || aggregate.logicalName) : "Choose Y"} onClick={yClick} />
				<IconButton icon="list-ul" onClick={multiSeriesClick} />
			</div>
		</div>
		<props.ChartSelector>
			<select value={chartKind} onChange={e=>setChartKind(e.target.value)} style={{ width: "auto" }}>
				{["bar", "line"].map(x => {
					return <option key={x} value={x}>{x}</option>
				})}
			</select>
		</props.ChartSelector>
		<div className="objectChartArea" style={{position: 'relative'}}>
			{data && data.length > 0 &&
				<div style={{ position: 'absolute', width: '100%', height: '100%' }}>
					<ChartComponent
						seriesCount={seriesCount}
						data={data}
						getSeriesLabel={getSeriesLabel}
						onChartBarClick={onChartBarClick}
						getChartBarColor={getChartBarColor}
						formatValue={formatValue}
						type={(chart && chart.type) || ""}
						getValueScale={getChartValueScale}
					/>
				</div>}
			{(!data || data.length === 0) && <i className="fa fa-chart-simple" style={{ fontSize: "60px", color: "var(--fore-color2)" }}></i>} 
		</div>
		<div className="objectChartX">
			<IconButton icon="" label={group ? (group.displayName || group.logicalName) : "Choose X"} onClick={xClick} />
			<select value={groupMethod} onChange={e=>setGroupMethod(e.target.value)}>
				{["column", "year", "quarter", "month", "week", "day"].map(x => {
					return <option key={x} value={x}>{x}</option>
				})}
			</select>
			<IconButton icon="list-ul" onClick={multiGroupClick} />
		</div>
	</div>
}

const ChartComponent = (props: {
	seriesCount?: number, data: any[],
	formatValue: any,
	getSeriesLabel: (id: string, long?: boolean) => string,
	getChartBarColor: any,
	onChartBarClick: any,
	type: string,
	getValueScale?: () => (number[]|undefined), 
}) => {
	
	const { seriesCount, type, data, onChartBarClick, getChartBarColor, formatValue } = props;

	const theme = {
		textColor: "var(--fore-color)",
	};
	const keys = ["y0"];
	if (seriesCount)
		for (let j = 1; j < seriesCount; j++)
			keys.push("y" + j);
	const legends: any = [{
		dataFrom: "keys",
		data: keys.map(k => ({ id: k, label: props.getSeriesLabel(k), color: getChartBarColor({ id: k }) })),
		anchor: "top-left",
		direction: "row",
		justify: false,
		translateY: -30,
		itemWidth: 100,
		itemHeight: 20,
	}];

	let valueScale: any = undefined;
	if (props.getValueScale) {
		const range = props.getValueScale();
		if (range) {
			valueScale = { type: 'linear', min: range[0], max: range[1], clamp: true }
		}
	}

	const getSeriesLabel = props.getSeriesLabel;

	const CustomSliceTooltip = (props: any) => {
		return <div style={{
			background: 'white', color: 'inherit', borderRadius: '2px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
			padding: '5px 9px'
		}}>
			<div>
				<table style={{ width: '100%', borderCollapse: 'collapse' }}>
					<tbody>
						{props.slice.points.map((point: any) => {
							return <tr>
							<td style={{ padding: '3px 5px' }}>
								<span style={{
									display: 'block', width: '12px', height: '12px', background: point.serieColor, marginRight: '7px'
								}}></span>
							</td>
								<td style={{ padding: '3px 5px' }}>{getSeriesLabel(point.serieId)}</td>
							<td style={{ padding: '3px 5px' }}>
									<span style={{ fontWeight: 'bold' }}>{point.data.yFormatted}</span>
							</td>
						</tr>
						})}
					</tbody>
				</table>
			</div>
		</div>
	}

	if (type === "line") {
		const lineData = [];
		for (const skey of keys) {
			lineData.push({ id: skey, data: data.map(d => ({ x: d.x_label, y: d[skey] })) });
		}
		return <ResponsiveLine
			data={lineData}
			theme={theme}
			margin={{ top: 40, right: 60, bottom: 50, left: 50 }}
			onClick={onChartBarClick}
			colors={getChartBarColor}
			axisLeft={{
				"tickValues": 4,
				format: formatValue,
			}}
			// enablePointLabel={true}
			// pointLabel={"y"}
			//enableCrosshair={true}
			//useMesh={true}
			enableSlices={"x"}
			sliceTooltip={CustomSliceTooltip}
			// pointLabelYOffset={-12}	
			yScale={valueScale}
			lineWidth={2}
			areaOpacity={0.3}
			enableArea={true}
			curve="monotoneX"
			axisBottom={{
				//   legend: 'day',
				//   legendOffset: -12,
				"tickSize": 5,
				"tickPadding": 5,
				"tickRotation": 35
			}}
			legends={legends}
		/>
	}

	return <ResponsiveBar margin={{ top: 40, right: 20, bottom: 50, left: 60 }}
		data={data}
		theme={theme}
		onClick={onChartBarClick}
		colors={getChartBarColor}
		label={v => formatValue ? formatValue(v.value) : v.formattedValue}
		axisLeft={{
			"tickValues": 4,
			format: formatValue,
		}}
		valueScale={valueScale}
		axisBottom={{
			//   legend: 'day',
			//   legendOffset: -12,
			"tickSize": 5,
			"tickPadding": 5,
			"tickRotation": 15
		}}
		groupMode="grouped"
		keys={keys}
		indexBy="x_label"
		legends={legends}
		tooltipLabel={(v:any)=>props.getSeriesLabel(v.id, true)}
					
	/>
}

const makeChartFilter = (propMeta: IMetaProperty, groupMethod: string, value: any): FetchFilter => {
	const conds = [] as FetchCondition[];
	
	const linkedGroup = propMeta.logicalName.split('__');
	const entityName = linkedGroup.at(-2);
	const groupField = linkedGroup.at(-1)!;

	if (groupMethod === "column") {
		if (value && value.id)
			value = value.id;
		if (value === "ZERO")
			value = 0;
		
		conds.push({ attribute: groupField, entityName: entityName, operator: value === "NULL" ? "null" : "eq", value: value });
	} else {
		const p = (value.toString()).split("_");
		let start, end;
		switch (groupMethod) {
			case "year":
				start = new Date(+p[0], 0, 1);
				end = new Date((+p[0] + 1), 0, 1);
				break;
			case "quarter":
				let quarter = (+p[1] - 1) * 3;
				start = new Date(+p[0], quarter, 1);
				end = new Date(+p[0], quarter + 3, 1);
				break;
			case "month":
				start = new Date(+p[0], +(p[1]) - 1, 1);
				end = new Date(+p[0], +(p[1]), 1);
				break;
			case "week":
				start = getDateOfIsoWeek(p[1], p[0]);
				end = new Date(start);
				end.setDate(end.getDate() + 7);
				break;
			case "day":
				start = new Date(+p[0], +(p[1]) - 1, +(p[2]));
				end = new Date(+p[0], +(p[1]) - 1, +(p[2]) + 1);
				break;
		}
		if (start && end) {
			conds.push({ attribute: groupField, entityName: entityName,  operator: "ge", value: start.toISOString() });
			conds.push({ attribute: groupField, entityName: entityName, operator: "lt", value: end.toISOString() });
		}
	}

	return { conditions: conds };
}

function getDateOfIsoWeek(input_week: any, input_year: any) {
	let week = parseFloat(input_week);
	let year = parseFloat(input_year);
	const errDate = new Date(input_year);
  
	if (week < 1 || week > 53) {
		return errDate
	} else if (!Number.isInteger(week)) {
		return errDate
	} else if (!Number.isInteger(year)) {
		return errDate
	}
  
	const simple = new Date(year, 0, 1 + (week - 1) * 7);
	const dayOfWeek = simple.getDay();
	const isoWeekStart = simple;

	// Get the Monday past, and add a week if the day was
	// Friday, Saturday or Sunday.
  
	isoWeekStart.setDate(simple.getDate() - dayOfWeek + 1);
	if (dayOfWeek > 4) {
		isoWeekStart.setDate(isoWeekStart.getDate() + 7);
	}

	// The latest possible ISO week starts on December 28 of the current year.
	if (isoWeekStart.getFullYear() > year ||
		(isoWeekStart.getFullYear() == year &&
			isoWeekStart.getMonth() == 11 &&
			isoWeekStart.getDate() > 28)) {
		return errDate
	}
  
	return isoWeekStart;
}