import { IMetaProperty, MetaPropertyFlags } from "shared/schema";

export const dateToString = (dt: Date, time: boolean = false) => {
	const pad = (x: number, len: number = 2) => {
		let s = x.toString();
		while (s.length < len) {
			s = "0" + s;
		}
		return s;
	}
	let ret = pad(dt.getFullYear(), 4) + "-" +
		pad(dt.getMonth() + 1) + "-" +
		pad(dt.getDate());
	if (time) {
		ret += "T" + pad(dt.getHours()) + ":" + pad(dt.getMinutes());
	}
	return ret;
}

export const objectDateToString = (value: any, prop: IMetaProperty) => {

	const dateInputType = prop.flags && (prop.flags & MetaPropertyFlags.DateOnly) !== 0;
	if (value) {
		const ds = value as string;
		//let dt = new Date(value); // will be UTC from DB
		if (dateInputType) {
			value = ds.substring(0, ds.indexOf("T"));
		} else { //props.flags & MetaPropertyFlags.UTC
			value = value.indexOf("Z") < 0 ? value + "Z" : value;
			const dt = new Date(value);
			value = dateToString(dt, true); //dt.toISOString().replace("Z", "");
		}
	}
	return value;
}