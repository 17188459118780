import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Tabbar } from "../components/Tabbar";
import { ObjectList } from "../objectList/ObjectList";
import { CustomizeMetadata } from "./Metadata";

export const Customize = () => {

	const [search, setSearch] = useSearchParams();
	const selectedList = +(search.get("view") || "0");
	const tabs = useMemo(() => ["Metadata", "Views", "Permissions", "Apps"], []);

	return (
		<div className="columnFlex">
			<div><h1>Customize</h1></div>
			<Tabbar tabs={tabs} initIndex={selectedList} renderChild={
				(i) => {
					if (selectedList !== i) {
						setSearch({ view: "" + i }, { replace: true });
					}
					if (i === 0) return <CustomizeMetadata />;
					if (i === 1) return <ObjectList objectName="inu_view" />
					if (i === 2) return <ObjectList objectName="inu_role" />
					if (i === 3) return <ObjectList objectName="inu_app"/>
				}
			} />
		</div>
	);
}