import { useContext, useEffect, useState } from "react";
import { IFormField } from "../AppSchema";
import { AppLink } from "../components/AppLink";
import { PanelProps } from "../objectForm/panels/PanelProps";
import { IWhatsNextRule, loadWhatsNext } from "./NextList";
import { MetaContext } from "../AppState";

export const WhatsNextField = (props: PanelProps & { field: IFormField }) => {

	const metadata = useContext(MetaContext);
	const bodyField = props.field.name;
	const propMeta = props.meta.properties.find(x => x.logicalName === bodyField);
	if (!propMeta) throw Error("field not found");

	const [rule, setRule] = useState(undefined as any as IWhatsNextRule);

	useEffect(() => {
		const cancelToken = { cancel: false };
		const exe = async () => {
			try {
				let today = new Date();
				const obj = { id: props.id, name: props.meta.logicalName, label: "" };
				const records = await loadWhatsNext(metadata, cancelToken, today, "", obj);
				if (!cancelToken.cancel) {
					if (records && records.length > 0)
						setRule(records[0].rule);
					//setRecords(records);
					//setLoading(false);
				}
			} catch (e) {
				console.log("whatsnextfield failed:" + e);
				//setLoading(false);
			}
		};
		//setLoading(true);
		exe();
		return () => { cancelToken.cancel = true; }
	}, [props.id]);

	if (rule)
		return <AppLink target="_blank" style={{ textDecoration: "none" }} to={"/edit/inu_nextrule/" + rule.id}>
			<span className="activityReminderOn activityReminderOverdue"><i className="fa fa-bell"/>{rule.description}</span>
		</AppLink>
	return <span className="activityReminderOn"><i className="fa fa-thumbs-up"/>All OK</span>;
}