import { useContext, useCallback, useState, useMemo, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { IDictionary, IMetaObject, MetaPropertyType } from "shared/schema";
import { IRolePermissions, ICustomOwner, parsePermissionsJson, PERMISSION_UPDATE } from "shared/permissions";
import { IAppConfig, IForm, IFormContainerPanel, IFormFieldsPanel } from "../../AppSchema";
import { MetaContext, checkPermissions } from "../../AppState";
import { Loading } from "../../components/Loading";
import SimpleGrid from "../../components/SimpleGrid";
import { useDerivedState } from "../../hooks/useDerivedState";
import { BaseObjectForm } from "../../objectForm/ObjectForm";
import { PanelProps } from "../../objectForm/panels/PanelProps";
import { ModalContext } from "../../modal/Modal";
import { showFetchDialog } from "../FetchDialog";

interface PanelPropsEx extends PanelProps {
	body: IRolePermissions;
}

const CustomOwnerPanel = (props: PanelPropsEx) => {

	const modal = useContext(ModalContext);
	const owners = props.body ? props.body.customOwners : [];

	const [items, changeItems] = useDerivedState(owners, [props.body]);
	const setItems = useCallback((newItems: ICustomOwner[]) => {
		props.body.customOwners = newItems;
		changeItems(newItems);
		props.setRecord({ ...props.getRecord() });
	}, [props.body]);
	const metadata = useContext(MetaContext).objects;
	const [newObj, setNewObj] = useState<ICustomOwner>({ objectName: "", fieldName: "", operation: "update"});
	const id = useRef<string>();

	const onChangeObject = (old: ICustomOwner, newItem: ICustomOwner) => {
		if (old === newObj) {
			setNewObj(newItem)
			return;
		}

		const home = items.map(x => x === old ? newItem : x);
		setItems(home);
	}
	const onAddObject = () => {
		const home = items.concat([{ ...newObj }]);
		setItems(home);
	}
	const onDeleteObject = (obj: ICustomOwner) => {
		const home = items.filter(x => x !== obj);
		setItems(home);
	}

	const onSetupQuery = (obj: ICustomOwner) => {
		let f = obj.extraFetch;
		if (!f || f.entity.name !== obj.objectName) {
			f = { entity: { name: obj.objectName } };
		}
		showFetchDialog(modal, f, () => {
			// fixme: check empty
			obj.extraFetch = f;
			props.setRecord({ ...props.getRecord() });
		});
	}

	// const onMoveObject = (obj: IHomeItem, up: boolean) => {

	// 	const home = items.slice();
	// 	const index = home.indexOf(obj);
	// 	if (index === 0 && up) return;
	// 	if (index === home.length - 1 && !up) return;
	// 	home.splice(index, 1);
	// 	home.splice(index + (up ? -1 : 1), 0, obj);
	// 	setItems(home);
	// }

	return (
		<div className="columnFlex">
			<SimpleGrid noEdit={false} className="customizeEntityList" fields={["objectName", "fieldName", "operation", "actions"]} rows={items} formatter={(x: ICustomOwner, fname) => {
				if (!x) {
					switch (fname) {
						case "actions":
							return (<button onClick={e => onAddObject()}>Add</button>);
					}
					x = newObj;
				}
				{
					switch (fname) {
						case "actions":
							return (<div style={{ display: "flex", flexDirection: "row" }}>
								<button style={{ flex: "1 1 auto" }} onClick={e => onDeleteObject(x)}>Delete</button>
								<button style={{color:!!x.extraFetch ? "green":"black"}} onClick={e=>onSetupQuery(x)}>Query</button>
							</div>
							);
						case "objectName":
							return (<select value={x.objectName} onChange={e => onChangeObject(x, { ...x, objectName: e.target.value })}>
								{metadata.map(x => (<option value={x.logicalName}>{x.logicalName} [{x.displayName}]</option>))}
							</select>);
						case "fieldName":
							const meta = metadata.find(f => f.logicalName === x.objectName);
							if (!meta) return <div>Select object first</div>;
							return (<select value={x.fieldName} onChange={e => onChangeObject(x, { ...x, fieldName: e.target.value })}>
								{meta.properties.filter(x => x.type === MetaPropertyType.Lookup).map(x => (<option value={x.logicalName}>{x.displayName}</option>))}
							</select>);
						case "operation":
							return (<select value={x.operation} onChange={e => onChangeObject(x, { ...x, operation: e.target.value as any })} >
								<option value="read">Read</option>
								<option value="update">Update</option>
								<option value="delete">Delete</option>
							</select>);			
					}
				}
				return "";
			}} />
		</div>
	)
}

const PermissionButton = (props: { body: IDictionary<number>, logicalName: string, perm: number, onClick:()=>void }) => {
	
	const { body, logicalName, perm } = props;

	const selector = perm * 4;
	const mask = body[logicalName] || 0;
	const x = mask >> selector & 3;

	const onClick = (e: any) => {
		e.preventDefault();
		const y = (x + 1) % 4;
		const newMask = (mask & ~(15 << selector)) | y << selector;
		body[logicalName] = newMask;
		props.onClick();
	}

	const icon = "fa " + ['fa-ban', 'fa-user', 'fa-building', 'fa-globe'][x];
	const color = ["#ccc", "black", "black", "green"][x];
	//return <a href="#" onClick={onClick}>{["No Access", "Owned", "BusinessUnit", "All"][x]}</a>;
	return <a href="#" style={{background:"none", color:color, paddingRight:"20px"}} onClick={onClick}><i className={icon}/></a>;
}

const PermissionsPanel = (props: PanelPropsEx) => {
	const metadata = useContext(MetaContext);
	const [, render] = useState(false);

	const body = props.body ? props.body.permissions : {};

	const onChanged = () => {
		props.setRecord({ ...props.getRecord() });
	}

	const renderCell = (meta: IMetaObject, field: string) => {

		const logicalName = meta.logicalName;
		const perm = body[logicalName] || 0;

		switch (field) {
			case "name": return meta.displayName || meta.logicalName;
			case "logicalName": return <span onDoubleClick={() => {
				const y = ((body[logicalName]&3) + 1) % 4;
				const newMask = y | y << 4 | y << 8 | y << 12;//(mask & ~(15 << selector)) | y << selector;
				body[logicalName] = newMask;
				onChanged();
			}}>{logicalName}</span>
			default: {
				const index = "crud".indexOf(field);
				return <PermissionButton body={body} logicalName={logicalName} perm={index} onClick={onChanged} />
			}
		}
	}

	const [sortColumn, setSortColumn] = useState({ field: "logicalName", asc: true });
	const onSortClick = useCallback((field:string) => {
		if (field === "name" || field === "logicalName")
			setSortColumn(x => ({ field: field, asc: x.field === field ? !x.asc : true }));
	}, [setSortColumn]);

	const rows = useMemo(() => {
		const rows = metadata.objects.slice();
		rows.sort((a, b) => {
			const sa: string = (sortColumn.field === "name" ? (a.displayName || a.logicalName) : a.logicalName);
			const sb: string = (sortColumn.field === "name" ? (b.displayName || b.logicalName) : b.logicalName);

			const ret = sa.localeCompare(sb, undefined, { "sensitivity": "accent" });
			if (sortColumn.asc)
				return ret;
			return -ret
		});
		return rows;
	}, [sortColumn, metadata.objects]);

	if(!props.body) return <Loading/>

	return (<SimpleGrid rows={rows} noEdit={true} formatter={renderCell}
		sortColumn={sortColumn}
		onSortClick={onSortClick}
		fields={["name", "logicalName", "c", "r", "u", "d"]}
		columns={["Name", "Logical", "Create", "Read", "Update", "Delete"]} />)
}

const makeForm = (metadata: IAppConfig, meta: IMetaObject) => {
	if (!checkPermissions(metadata, {}, "inu_role", PERMISSION_UPDATE)) {
		return {
			name: meta.logicalName,
			commands: [],
			panel: {
				name: "main",
				type: "Split",
				panels: [
					{
						name: "LeftTabs",
						type: "Tabs",
						panels: [{
							name: "fields",
							type: "Fields",
							fields: ["name"].map(x => ({ name: x })),
						} as IFormFieldsPanel
						]
					} as IFormContainerPanel
				]
			} as IFormContainerPanel
		} as IForm;
	}
	const form: IForm = {
		name: meta.logicalName,
		panel: {
			name: "main",
			type: "Split",
			panels: [
				{
					name: "LeftTabs",
					type: "Tabs",
					panels: [{
						name: "fields",
						type: "Fields",
						fields: ["name", "appid", "isdefault"].map(x => ({ name: x })),
					} as IFormFieldsPanel
					]
				} as IFormContainerPanel,
				{
					name: "Details",
					type: "Tabs",
					panels: [
						{ name: "Permissions", type: "Custom", label: "Permissions", createMethod: PermissionsPanel },
						{ name: "Owners", type: "Custom", label: "Owners", createMethod: CustomOwnerPanel },
						{ name: "systemuser", type: "AssociatedList", label: "", field: "roleid.inu_userroles.systemuserid" },
					]
				} as IFormContainerPanel
			]
		} as IFormContainerPanel,
		commands: [{ name: "CmdClone" }, { name: "CmdDelete" }, { name: "CmdSave" }],
	};
	return form;
}

// const makeInitialRole = (objectName: string) => {
// 	return {
// 		name: objectName,
// 		commands: [],
// 		panel: {
// 			name: "main",
// 			type: "Split",
// 			panels: [
// 				{
// 					name: "fields",
// 					type: "Fields",
// 					fields: ["name"].map(x => ({ name: x })),
// 				} as IFormFieldsPanel,
// 				{
// 					name: "Associated",
// 					type: "Tabs",
// 					panels: [ 
// 					]
// 				} as IFormContainerPanel
// 			]
// 		} as IFormContainerPanel,
// 	};
// }

export const RoleEditorForm = () => {
	const objectName = "inu_role";
	const { search } = useLocation();
	const id = useParams<string>()["id"] || "";

	const metadata = useContext(MetaContext);
	let meta = metadata.objects.find(x => x.logicalName === objectName) as IMetaObject;
	if (!meta) throw Error("No Meta");

	const form = makeForm(metadata, meta);

	const onSave = useCallback((panelProps: PanelProps) => {
		const ext = panelProps as PanelPropsEx;
		const record = ext.getRecord();
		record.body = JSON.stringify(ext.body);
	}, [id]);

	const onLoad = useCallback((panelProps: PanelProps) => {

		// loading = panel.id !== "0" && panel.getRecord().id === undefined

		const ext = panelProps as PanelPropsEx;
		const record = ext.getRecord();
	
		if (!ext.body && (ext.id === "0" || record.id)) {
			const body = record.body;
			ext.body = body ? parsePermissionsJson(body) : { permissions: {}, customOwners: [] };
		}
	}, [id]);

	return <BaseObjectForm id={id} meta={meta} form={form} search={search} onLoad={onLoad} onSave={onSave} />
}
