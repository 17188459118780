import { useState, useRef } from "react";
import { formatNumericValue, formatNumericWith } from "../formatters";
import { useDerivedState } from "../hooks/useDerivedState";

export const NumberInput = (props: {
	value: any, onChange: (value: any) => void, precision: string, inputProps: any, disabled?: boolean,
	customFormatter?: (str:string)=>string, allowEmpty?: boolean, formatString?: string,
}) => {
	const decimalPlaces = +(props.precision || "0");
	const step = decimalPlaces > 0 ? Math.pow(10, -decimalPlaces) : "1";

	const [hasFocus, setFocus] = useState(false);
	const [editValue, setEditValue] = useDerivedState(props.value, [props.value]);
	
	const inputRef = useRef<HTMLInputElement>(null);

	let str = editValue;
	if (str === undefined || str === null)
		str = "";
	
	if (str !== "" && !hasFocus) {
		if (props.customFormatter)
			str = props.customFormatter(str);
		else if (props.formatString)
			str = formatNumericWith(str, props.formatString);
		else 
			str = formatNumericValue(decimalPlaces, str);
	}
	const inputType = hasFocus ? "number" : "text";

	return (<input
		ref={inputRef}
		{...props.inputProps}
		type={inputType}
		disabled={props.disabled}
		onFocus={e => {
			setFocus(true);
			let str = props.value;
			if (str !== undefined && str !== "" && str !== null) {
				str = parseFloat(str).toFixed(decimalPlaces);
				setEditValue(str);
			}
		}}
		onBlur={(e => {
			setFocus(false);
			let str = e.target.value;
			const isEmpty = str === undefined || str === "" || str === null;
			if (props.allowEmpty && isEmpty) {
				props.onChange("");
			}
			else if (str !== editValue && !isEmpty) {
				str = parseFloat(str).toFixed(decimalPlaces);
				props.onChange(str);
			}
		})}
		step={step} value={str} onChange={e => props.onChange(e.target.value)}/>);

}
