import { useContext, useState } from "react";
import { IForm, ICommand } from "../../AppSchema";
import { MetaContext } from "../../AppState";
import { TitleBox } from "../../components/TitleBox";
import { ModalContext } from "../../modal/Modal";
import { configurePanel, useConfigureCommands } from "../Configure";
import { CustomizeContext } from "../customize/customizeContext";
import { Panel } from "./Panel";
import { IFormContext } from "./PanelProps";

export const MainFormPanel = (props: { panelProps: IFormContext, form: IForm, saving: boolean, onCommand: (cmd: ICommand) => void, commands: ICommand[] }) => {
	const form = props.form;
	const customize = useContext(CustomizeContext);
	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);
	const [render, setRender] = useState(0);
	const forceRender = () => setRender(x => x + 1);

	const configureClick = (e: React.MouseEvent) => {
		const fakeParent = { name: "", type: "" as any, panels: form.panel ? [form.panel] : [] };
		configurePanel(modal, metadata, props.panelProps.meta, fakeParent, e, () => {
			form.panel = fakeParent.panels[0];
			forceRender();
		});
	}

	const [commands, renderCommand] = useConfigureCommands(customize.enabled, props.commands, form, forceRender, ["CmdSave", "CmdDelete", "CmdClone"]);

	// fixme: move all of this into panelProps -> IFormContext
	const record = props.panelProps.getRecord();
	const meta = props.panelProps.meta;
	const loading = props.panelProps.id !== "0" && !record.id;
	const isNewRecord = props.panelProps.id === "0";

	return <>
		<TitleBox
			title={record["name"] || (isNewRecord ? "New " + meta.displayName : (loading ? "Loading..." : "(Unknown)"))}
			disabled={props.saving}
			onCommand={props.onCommand}
			commands={commands}
			renderCommand={renderCommand}
		/>
		{customize.enabled && <span style={{ position: "relative", top: "0", left: "0" }} className="formItemCustomize" data-kind="formmainpanel" data-drag_index={"0"} draggable={true}
			onClick={configureClick}>Root <i className="fa fa-gears" /></span>}

		<div className="formMain">
			{form.panel && <Panel context={props.panelProps} panel={form.panel} />}
		</div>
	</>
}