import { useRef, useCallback, useEffect, useMemo } from "react";
import { Fetch } from "shared/fetch";
import { useDerivedState } from "../hooks/useDerivedState";
import { DataService } from "../service";
import { useLocation } from "react-router-dom";

// how far we have to load data when reloading.
const SCROLL_CACHE: { [key: string]: number } = {};

const useSessionKey = (q: Fetch) => {
	const location = useLocation();
	const key = useMemo(() => {
		// fixme: we should get this from the caller!
		return q.entity.name + (q.entity.attributes || []).map(x => x.attribute).join('.');
	}, [q]);
	return key;
}

export const useFetchQuery = (q: Fetch, pageSize: number = 50, reversed: boolean = false) => {

	const sessionKey = useSessionKey(q); // fixme: this should be passed by caller!!!
	const [loading, setLoading] = useDerivedState(true, [q]);
	const [rows, setRows] = useDerivedState<any[]>([], [q.entity.name]); // reset only if entity changed, otherwise wait for load to complete
	const scrollInfo = useRef({ lastHeight: 0, q: q });
	if (scrollInfo.current.q !== q) {
		scrollInfo.current.q = q;
		scrollInfo.current.lastHeight = 0;
	}
	
	const loadData = useCallback(async (q: Fetch, cancelToken: { cancel: boolean }, sleep?: number) => {
		console.log("ListLoad:" + q.entity.name + " START");
		let newRows: any[];
		const pageIndex = (q.page || 1);
		try {
			newRows = await DataService.retrieveMultiple(q);
			q.page = pageIndex + 1;
			if (newRows.length < (q.pageSize || 0))
				(q as any).hasMore = false;
			
			// if (sleep) {
			// 	await new Promise((res, rej) => setTimeout(() => res(1), sleep));
			// }
		}
		catch (err) {
			alert((err as Error).message);
			setLoading(false);
			return;
		}
		if (cancelToken.cancel) {
			//console.log("ListLoad:" + q.entity.name + " CANCELED");
			return;
		}
		// multiple async calls started we are old:( abandon ship.
		if (scrollInfo.current.q !== q) {
			//long query that completed after the query was changed. Ignore
			console.log("ListLoad:" + q.entity.name + " IGNORED");
			return;
		}
		console.log("ListLoad:" + q.entity.name + " DONE " + pageIndex + " - " + newRows.length);
		setRows((oldRows) => {
			let ret = newRows;
			if (pageIndex > 1) {
				ret = oldRows.concat(newRows);
				SCROLL_CACHE[sessionKey] = ret.length;
			}
			return ret;
		});
		setLoading(false);
	}, [q, setRows, setLoading]);

	const onScroll = useCallback((e: React.UIEvent<HTMLElement>) => {
		const div = e.target as HTMLElement;
		if (!loading && scrollInfo.current.lastHeight < div.scrollHeight) {
			if ((reversed && div.scrollTop < div.offsetHeight) ||
				(!reversed && div.scrollTop >= div.scrollHeight - div.offsetHeight * 2)) {
				scrollInfo.current.lastHeight = div.scrollHeight;
				//console.log("load next")
				//console.log("begin next");
				setLoading(true);
				loadData(q, { cancel: false });
			}
			else {
				console.log(`not down enough Y:${div.scrollTop} MAX:${div.scrollHeight} WIN:${div.offsetHeight} `);
			}
		} else {
			if (scrollInfo.current.lastHeight < div.scrollHeight) {
				//if(loading)
					console.log("Still loading, can't load more")
			}
			else {
				console.log("size not changed:" + div.scrollHeight + "saved: " + scrollInfo.current.lastHeight);
			}
		}
	}, [q, loading]);

	useEffect(() => {
		const cancelToken = { cancel: false };
		setLoading(true);
		q.page = 1;
		q.pageSize = Math.max(SCROLL_CACHE[sessionKey] || 0, pageSize);
		(q as any).hasMore = true;
		scrollInfo.current.lastHeight = 0;
		loadData(q, cancelToken);
		return () => { cancelToken.cancel = true; }
	}, [q]);

	return { rows, setRows, loading, onScroll, loadData };
}