import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IAppConfig, IHomeItem } from '../AppSchema';
import { MetaContext, tryLocalize, useGuiHome } from '../AppState';
import { ProfileButton } from '../login/ProfileButton';
import { IModalParent, ModalContext } from '../modal/Modal';
import { DataService } from '../service';
import { TabControl } from './TabControl';

const useHome = (metadata: IAppConfig): [IHomeItem[], number] => {
  let home = useGuiHome() as IHomeItem[];
  const originalLocation = useLocation().pathname;
  let locationPath = originalLocation.substring(1);
  locationPath = locationPath.substring(locationPath.indexOf('/') + 1); // cut the app name
  if (locationPath.startsWith("edit/")) { // turhn form url to list url.
    locationPath = "list/" + locationPath.split('/')[1];
  }
  let selectedIndex = home.findIndex(x => x.url === locationPath);
  if (selectedIndex < 0 && locationPath.startsWith("list/")) {
    const objectName = locationPath.split('/')[1];
    const meta = metadata.objects.find(x => x.logicalName === objectName);
    if (meta) {
      selectedIndex = home.length;
      home = home.concat({ label: meta.displayName, url: originalLocation } as IHomeItem);
    }
  }

  if (home[selectedIndex]) {
    document.title = tryLocalize(metadata, home[selectedIndex].label);
  }

  return [home, selectedIndex];
}

const WebHeader = (props: { showBack?: boolean, commandSite?: IModalParent }) => {
  const metadata = useContext(MetaContext);
  const navigate = useNavigate();

  const [home, selectedIndex] = useHome(metadata);
 
  let leftElement = <ProfileButton />;
  if (props.showBack)
    leftElement = (<div style={{ display: "flex" }}>
      <button onClick={() => navigate(-1)} className="profileButtonInner">
        <i className='fa fa-arrow-left' />
      </button>

      <ProfileButton />
    </div>)

  const apps = metadata.apps;

  const [showMenu, setShowMenu] = useState(true);

  const scrollRef = useRef<HTMLDivElement>(null);

  const showElement = useCallback((showMenu: boolean, animate = true) => {
    if (scrollRef.current)
      (showMenu ? scrollRef.current.lastElementChild : scrollRef.current.firstElementChild)?.scrollIntoView({ "behavior": animate ? "smooth" : "auto" });
  }, [scrollRef]);

  const onSwitchClick = useCallback((e: React.MouseEvent) => {
    const b = e.target as HTMLButtonElement;
    const showMenu = b && b.dataset["button"] === "menu";
    showElement(showMenu);
  }, [setShowMenu]);

  const onAppChange = useCallback((appIndex: number) => {
    const app = apps[appIndex];
    navigate("/" + app + "/");
  }, [apps, navigate]);

  const onScroll = useCallback((e: React.UIEvent) => {
    const d = scrollRef.current;
    if (d && d.offsetWidth > 0) {
      setShowMenu(((d.scrollLeft + d.offsetWidth / 2) / d.offsetWidth) > 1);
    }
  }, [scrollRef]);

  useEffect(() => {
    showElement(true, false);
  }, []);
  
  return (
    <div className="appMenu">
      <div className="appMenuSwitch">
        <button className={showMenu ? "" : "appMenuSwitchSelected"} data-button="apps" onClick={onSwitchClick}>Apps</button>
        <button className={!showMenu ? "" : "appMenuSwitchSelected"} data-button="menu" onClick={onSwitchClick}>Menu</button>
      </div>
      <div className="appMenuSwitcher" ref={scrollRef} onScroll={onScroll}>
        <TabControl
          className={"appHeader appMenuItems"}
          // left={<div className="appLogo">INUKO</div>}
          //right={<ProfileButton/>}
          // left={leftElement}
          items={apps}
          selectedIndex={apps.indexOf(metadata.appId as string)}
          onChange={onAppChange}
        />
        <TabControl
          className={"appHeader appMenuItems"}
          // left={<div className="appLogo">INUKO</div>}
          //right={<ProfileButton/>}
          // left={leftElement}
          renderItem={(itemName, itemIndex) => {
            const item = home[itemIndex];
            if (item.kind === "separator")
              return <b className="appHeaderSeparator">{item.label}</b>
            let icon: any = undefined;
            if (item.icon) {
              icon = <i className={"appHeaderIcon fa " + item.icon} />
            }
            return <span>{icon}{tryLocalize(metadata, item.label)}</span>
          }}
          items={home.map(x => x.uniqueName || x.label || x.name)}
          selectedIndex={selectedIndex}
          onChange={(itemIndex) => {
            const item = home[itemIndex];
            if (item.kind === "separator")
              return;
            if (item.url.startsWith("http")) {
              window.location.href = item.url;
              return;
            }
              
            navigate(item.url);
            if (props.commandSite)
              props.commandSite.closeModal();
          }}
        />
      </div>
      <div style={{ justifySelf: "center" }}>{leftElement}</div>
    </div>
  );
}

const Header = (props: { showBack: boolean }) => {
  const element = document.documentElement;
  const [isMobile, setMobile] = useState(element.offsetWidth < 500);

  useLayoutEffect(() => {
	
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length) {
        setMobile(element.offsetWidth < 500);
      }
    });

    resizeObserver.observe(element);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  return isMobile ? <MobileHeader /> : <WebHeader/>
}

export default Header;

export const MobileHeader = (props: {}) => {

  // popup menu button + 4 first items of menu
  const metadata = useContext(MetaContext);
  const modal = useContext(ModalContext);
  const [home, selectedIndex] = useHome(metadata);
  const navigate = useNavigate(); 

  const statusColor = DataService.isOnline() ? "#4f4" : "black";

  const onProfileClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const h = document.getElementById("root")?.offsetHeight + "px";

    modal.showModal({
      "body": (p) => <div className="popupAppHeader" style={{height:h}}> <WebHeader {...p} /></div>,
      "bodyProps": {},
      "closeOnClickOutside": true,
      "showTitle": false,
      "anchor": { x: 0, y: 0 },
      style: { boxShadow: "5px 0px 6px 0px #0008", margin:"0px" },
      "id": "profileHeader",
    });
  }, []);

	const ProfileButton = (props: any) => (<button onClick={onProfileClick} className="profileButtonInner" style={{position:"relative"}}>
    <i className="appIcon" />
		<div style={{ position: "absolute", bottom: "-2px",right: "-2px", width:"8px", height:"8px", borderRadius:"5px", background:statusColor, border:"1px solid white" }}/>
	</button>)

  return <div className="appMobileHeader">
    <div className="appMobileHeaderItem" onClick={onProfileClick}><ProfileButton /></div>
    {home.filter(x => x.kind !== "separator").slice(0, 4).map((x, i) => {
      let icon = findHomeIcon(x);
      if (icon && !icon.startsWith("fa-"))
        icon = "fa-" + icon;
      return <div onClick={e => navigate(x.url)} className={"appMobileHeaderItem" + ((x === home[selectedIndex]) ? " appMobileHeaderItemSelected" : "")} >
        <i className={'fa ' + icon} />
        <span>{tryLocalize(metadata, x.label)}</span>
      </div>
    })}
  </div>
}

const findHomeIcon = (item: IHomeItem) => {
  if (item.icon)
   return item.icon;
  if (item.kind === "entity") {
    switch (item.name) {
      case "workorder": return "calendar"
      case "account": return "building";
      case "contact": return "address-book";
      case "lead": return "phone";
      case "opportunity": return "star";
      case "equipment": return "toolbox";
      case "invoice": return "file-invoice";
    }
  } else {
    switch (item.name) {
      case "whatsnext": return "bell";
    }
  }
  return "person";
}