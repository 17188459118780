import { useRef, useCallback, useContext, useMemo, useEffect, useState } from "react";
import { ICommand, IFormField } from "../../AppSchema";
import { MetaContext, tryLocalize } from "../../AppState";
import { IconButton } from "../../components/IconButton";
import { download, downloadFileUrl, fileToBase64, resizeImage } from "../../services/download";
import { getFileUrl } from "../../services/getFileUrl";
import { PanelProps } from "../panels/PanelProps";
import { useDerivedState } from "../../hooks/useDerivedState";
import { showConfirm } from "../../modal/alerts";
import { IModalContext, IModalParent, ModalContext } from "../../modal/Modal";
import { TitleBox } from "../../components/TitleBox";
import { IMetaObject } from "shared/schema";

export interface IImageFieldProps {
	getRecord: () => any;
	setRecord: (r: any) => void;
	field: IFormField;
	meta: IMetaObject;
}

export const ImageField = (props: IImageFieldProps) => {

	const metadata = useContext(MetaContext);
	const modalSite = useContext(ModalContext);
	const disabled = (props.field as any)?.enabled === false;

	const bodyField = props.field.name;
	const fieldConfig = (props.field.customComponent as string).split(';');
	const [nameField, resizeHeight, preview] = fieldConfig.slice(1);

	let imageStyle = undefined;
	let maxHeight = 0;
	const gridRow = props.field.gridRow;
	if (gridRow) {
		const pp = gridRow.split('/');
		if (pp.length > 1 && pp[1].indexOf("span") >= 0) {
			const height = + pp[1].replace("span", "");
			if (!isNaN(height)) {
				maxHeight = (height * 47) - ((props.field.label === " ") ? 0 : 18);
				imageStyle = { maxHeight: maxHeight };
			}
		}
	}

	const uploadRef = useRef<HTMLInputElement>(null);
	const onOpenFile = useCallback(() => {
		uploadRef.current?.click();
	}, [uploadRef]);

	const r = props.getRecord();
	let isImage = false;
	let imageUrl = "";
	if (r && r[bodyField]) {
		imageUrl = r[bodyField];
		isImage = imageUrl.indexOf("image/") >= 0 || imageUrl.endsWith(".image");
	}
	imageUrl = useMemo(() => {
		if (imageUrl)
			return getFileUrl(metadata, imageUrl);//"data:image/png;base64" + r["body"];
		return imageUrl;
	}, [imageUrl]);

	const onUploadFile = useCallback(async (e: any) => {
		const files = e.target.files;
		if (files && files.length > 0) {
			const f = files[0];
			let base64body: string;
			let resize = resizeHeight === "0" ? 0 : (resizeHeight ? (+resizeHeight) : maxHeight);
			if (resize && f.type.match(/image.*/))
				base64body = await resizeImage(f, resize);
			else
				base64body = await fileToBase64(f);
			const record = props.getRecord();
			const newRecord = { ...record, [bodyField]: base64body };
			if (nameField)
				newRecord[nameField] = f.name;
			if (props.meta.logicalName === "document")
				newRecord.name = f.name;
			props.setRecord(newRecord);
		}
	}, [props.getRecord, props.setRecord, resizeHeight, nameField]);
	const onClearFile = async () => {
		const propLabel = props.meta.properties.find(x => x.logicalName === bodyField)?.displayName || "File";
		if (await showConfirm(metadata, modalSite, tryLocalize(metadata, "CmdDelete") + " - " + propLabel)) {
			const record = props.getRecord();
			const newRecord = { ...record, [bodyField]: null };
			props.setRecord(newRecord);
		}
	}
	const onDownloadFile = () => {
		const record = props.getRecord();
		let body = record[bodyField] as string;
		if (body) {
			// this will set the correct name of the file
			// downloading the link directly will use a garbage name.
			const du = getFileUrl(metadata, body)
			const exe = async () => {
				try {
					const r = await fetch(du);
					const b = await r.blob();
					download(b as any, (nameField && record[nameField]) || record.name, "application/octet-stream");
				}
				catch (e) {
					alert(e);
				}
			}
			exe();
		}
	}

	const onMaximizeClick = (e: any) => {
		let url = imageUrl;//(e.target as HTMLImageElement).src;
		if (isImage) {
			const w = window.open("", "_blank");
			if (w) {
				const html = `<html><body><img src="` + url + `"/></body><html>`;
				w.document.write(html);
				w.document.close();
				w.focus();
			}
		}
		else {
			if (imageUrl.indexOf(".pdf") < 0) {
				url = getPreviewUrl(url, preview);
			}
			const w = window.open(url, "_blank");
			if (w)
				w.focus();
		}
	}
	const onFileDragDrop = async (event: React.DragEvent) => {
		if (disabled)
			return;
		// Prevent default behavior (Prevent file from being opened)
		event.preventDefault();

		let files: File[] = [];

		let items = event.dataTransfer.items;
		if (items) {
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				if (item.kind === "file") {
					const file = item.getAsFile();
					if (file) {
						files.push(file);
					}
				}
			}
		} else {
			const ff = event.dataTransfer.files;
			if (ff) {
				for (let i = 0; i < ff.length; i++) {
					files.push(ff[i]);
				}
			}
		}
		onUploadFile({ target: { files: files } });
		//uploadFiles(files);
	}
	const onFileDragOver = (event: React.DragEvent) => {
		event.preventDefault();
	}

	const [showToolbar, setShowToolbar] = useDerivedState(false, [imageUrl]);
	//const toolbarRow = isImage ? "2/1" : "1/1";

	return <div className="imageField" onDrop={onFileDragDrop} onDragOver={onFileDragOver} >
		{imageUrl && isImage && <img className="imageFieldPreview" style={imageStyle} src={imageUrl} onDoubleClick={onMaximizeClick} />}
		{/* {imageUrl && !isImage && <i className="imageFieldPreview fa fa-light fa-file-invoice" style={{ fontSize: "40px" }} />} */}
		{imageUrl && !isImage && <IFrame preview={preview} src={imageUrl} className="imageFieldPreview" style={{ height: "80vh", width: "100%", border: "1px solid #ccc" }} />}
		{!imageUrl && <IconButton disabled={disabled} className="imageFieldButtonUploadNew" icon="file-circle-plus" label=""
			onClick={onOpenFile} style={{ fontSize: "40px", color: disabled ? "#eee" : "", border: disabled ? "none" : "4px dashed #ccc", borderRadius: "10px" }} />}
		{/* {!disabled && <IconButton className={imageUrl ? "imageFieldButtonUpload" : "imageFieldButtonUploadNew"} icon="upload" onClick={onOpenFile} />} */}
		{/* {imageUrl && <IconButton className="imageFieldButtonDownload" icon="download" onClick={onDownloadFile} />} */}
		<input ref={uploadRef} hidden={true} type="file" onChange={e => e.target.value = null as any}
			onInput={onUploadFile} />
		
		{/* {imageUrl && !showToolbar && <IconButton style={{gridArea: toolbarRow}} className="imageFieldButtonGears" icon="edit" onClick={e => setShowToolbar(true)} />} */}
		{(showToolbar || imageUrl) && <div className="commandBox imageFieldToolbar">
			{showToolbar && imageUrl && !disabled && <IconButton icon="upload" label={tryLocalize(metadata, "CmdUpload")} onClick={onOpenFile} />}
			{showToolbar && imageUrl && <IconButton icon="download" label="" onClick={onDownloadFile} />}
			{showToolbar && imageUrl && !disabled && <IconButton icon="trash" label={""} onClick={onClearFile} style={{ color: "red" }} />}
			{showToolbar && imageUrl && <IconButton icon="maximize" label={""} onClick={onMaximizeClick} />}
			<IconButton key="editBtn" icon="ellipsis" label={""} onClick={e => setShowToolbar(x=>!x)} />
		</div>}
	</div>;
}

export const PreviewFileElement = (props: { imageUrl: string, isImage: boolean, isPdf?: boolean }) => {
	const { imageUrl, isImage } = props;
	return <div>
		{imageUrl && isImage && <img className="imageFieldPreview" src={imageUrl} />}
		{/* {imageUrl && !isImage && <i className="imageFieldPreview fa fa-light fa-file-invoice" style={{ fontSize: "40px" }} />} */}
		{imageUrl && !isImage && <IFrame src={imageUrl} isPdf={props.isPdf} className="imageFieldPreview" style={{ height: "80vh", width: "100%", border: "1px solid #ccc" }} />}
	</div>
}

export const showFilePreviewDialog = (modal: IModalContext, file: { imageUrl: string, name: string, isImage: boolean, isPdf: boolean }) => {
	const PreviewDialog = (props: { file: { imageUrl: string, name: string, isImage: boolean, isPdf: boolean }, onSave: () => void, commandSite: IModalParent }) => {

		const file = props.file;
		const cmds = [
			{ name: "CmdSave", label: "", icon: "save" },
			{ name: "CmdClose", label: "", icon: "close" },
		];
		
		const onCommand = async (cmd: ICommand) => {
			if (cmd.name === "CmdSave") {
				downloadFileUrl(file.imageUrl, file.name);
				return;
				//props.onSave();
			}
			props.commandSite.closeModal();
		}

		return (<div className="objectListContainer filePreviewDialog" style={{ flex: "1 1 auto", display: "flex" }}>
			<TitleBox title={file.name} commands={cmds} onCommand={onCommand} />
			<PreviewFileElement {...file} />
		</div>)
	}

	modal.showModal({
		body: (p) => < PreviewDialog {...p} />,
		bodyProps: {
			file: file,
		},
		//anchor: { width: 0, x: 0, y: 0 },
		style: { margin: "10px", height: "calc(100% - 20px)", width: "calc(100% - 20px)" },
		showTitle: false,
		id: "CloudFilePreview",
	});
}

const IFrame = (props: { src: string, className: string, style: any, isPdf?: boolean, preview?: string }) => {

	const [src, setSrc] = useState("");
	const srcRef = useRef<string>("");

	useEffect(() => {
		const load = async () => {
			const imageUrl = props.src;
			if (imageUrl && props.preview !== "none" && (props.isPdf || imageUrl.indexOf(".pdf") > 0)) {
				try {
					// if this fails in iOS debug with capacitor running web:localhost modify this file with proper cors headers
					// WebViewAssetHandler.swift 
					// var headers =  [
					// 	"Content-Type": mimeType,
					// 	"Cache-Control": "no-cache",
					// 	"Access-Control-Allow-Origin": "*",
					// 	"Access-Control-Allow-Methods": "GET, OPTIONS"
					// ]
					const r = await fetch(imageUrl);
					const blob = await r.blob();
					const b = new Blob([blob], { "type": "application/pdf" });
					//console.log("FILE_LOADED:" + b.size + "TYPE: " + b.type);
					const u = window.URL.createObjectURL(b);
					setSrc(u);
					srcRef.current = u;
				}
				catch (e) {
					//console.log("ERR_FILE_DOWNLOAD:" +window.location.href +"\n\tFILE:" + imageUrl + "\n\tERR:" + e);
				}
			} else {
				//const u = `https://docs.google.com/gview?url=${encodeURIComponent(imageUrl)}&embedded=true`;
				//setSrc(u); //"https://docs.google.com/viewer?url=" + encodeURIComponent(imageUrl));
			}
		}
		load();
		return () => {
			if (srcRef.current)
				window.URL.revokeObjectURL(srcRef.current);
			//console.log("REVOKE:" + srcRef.current);
		}
	}, [props.src])
	
	const showPreview = () => {
		const u = getPreviewUrl(props.src, props.preview);
		setSrc(u);
	}

	useEffect(() => {
		if (props.src && props.preview && props.preview.indexOf("auto") >= 0)
			showPreview();
	}, [props.src])
	
	if (src) {
		return <iframe {...props} src={src} />;
	}
	
	if (props.src.indexOf(".avi") >= 0 || props.src.indexOf(".mp4") >= 0 || props.src.indexOf("video/") >= 0) {
		return <video id="myVideo" controls muted style={{height:"80vh", width:"100%"}}>
			<source src={props.src} type="video/mp4" />
		</video>
	}
	
	return <div className="imageFieldPreviewFile">
		<i className="imageFieldPreview fa fa-light fa-file-invoice" style={{ fontSize: "40px" }} />
		{props.preview !== "none" && < IconButton icon="preview" label="Preview Online" onClick={showPreview} />}
		</div>;
}

const getPreviewUrl = (src: string, preview?: string) => {
	if (src) {
		const viewer = (preview && preview.startsWith("ms")) ?
			"https://view.officeapps.live.com/op/embed.aspx?src=" :
			"https://docs.google.com/gview?embedded=true&url=";
		const u = viewer + encodeURIComponent(src);
		return u;
	}
	return src;
}