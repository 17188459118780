import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SimpleFieldSet } from "../components/SimpleFieldSet";
import { IPanelProps } from "../objectForm/panels/PanelProps";
import { showError } from "../modal/alerts";
import { ModalContext } from "../modal/Modal";
import { DataService } from "../service";
import { IMetaObject } from "shared/schema";
import { Loading } from "../components/Loading";
import { newGuid } from "../utils/guid";


export const SurveyResponsePanel = (props: IPanelProps) => {
	
	const modal = useContext(ModalContext);

	const survey = props.context.getRecord();

	const [template, setTemplate] = useState(undefined as any);

	useEffect(() => {
		if (survey?.templateid?.id) {
			const exe = async () => {
				try {
					const t = await DataService.retrieveMultiple({
						entity: {
							name: "inu_survey_template",
							allattrs: true,
							filter: { conditions: [{ attribute: "id", operator: "eq", value: survey.templateid.id }] }
						}
					});
					setTemplate(t[0]);
				}
				catch (e) {
					showError(modal, e);
				}
			};
			exe();
		}
	}, [survey?.templateid]);

	const { propsDict, panel } = useMemo(() => {
		if (template) {
			const metaObject = JSON.parse(template.meta) as IMetaObject;
			const panel = JSON.parse(template.panel)
			return {
				propsDict: metaObject.properties.reduce((agg, prop) => (agg[prop.logicalName] = prop, agg), {} as any),
				panel: panel,
			}
		} else {
			return { propsDict: undefined, panel: undefined };
		}
	}, [template]); 

	const getRecord = useCallback(() => {
		return survey.body ? JSON.parse(survey.body) : {};
	}, [survey.body]);

	const setRecord = useCallback((data: any) => {
		//props
		const prev = props.context.getRecord();
		props.context.setRecord({...prev, body: JSON.stringify(data) });
	}, [props.context]);

	const onCopyToTarget = useCallback(async () => {
		const survey = props.context.getRecord();
		const body = survey.body ? JSON.parse(survey.body) : {};

		const targetid = survey.targetid?.id;
		const targetName = template.object_name;
		
		const obj: any = {};
		for (const field of panel.fields) {
			const fieldName = field.name;
			if (fieldName === "-")
				continue;
			const value = body[fieldName];
			if (value !== "" && value != null) {
				obj[fieldName] = value;
			}
		}
		const newId = newGuid();
		
		obj["id"] = targetid || newId;

		const req: any = {
			name: targetName,
			operation: targetid ? "update" : "create",
			object: obj
		}
		const req2: any = {
			name: "inu_survey_record",
			operation: "update",
			object: {
				id: survey.id,
				status: "Processed",
			}
		}
		if (!targetid) {
			req2.object.targetid = { id: obj.id, name: targetName };
		}
		try {
			await DataService.executeMultiple([req, req2]);
		}
		catch (e) {
			showError(modal, e);
		}

	}, [template, props.context]);

	if (!template)
		return <div><Loading /></div>

	return <SimpleFieldSet id={"0"} getRecord={getRecord} setRecord={setRecord} propsDict={propsDict} panel={panel}
			submitButton="Save to Target Record" onSubmit={onCopyToTarget} />
}