import { useEffect, useState } from 'react';
import './Loading.css';

export const Loading = (props: { delay?: boolean }) => {

	const [isVisible, setVisible] = useState(props.delay === false);

	useEffect(() => {
		let id = window.setTimeout(() => (setVisible(true), id = 0), 500);
		return () => window.clearTimeout(id);
	});

	return !isVisible ? null : (<div className="spinner">
		<div className="bounce1"></div>
		<div className="bounce2"></div>
		<div className="bounce3"></div>
	</div>)
}