import { useEffect, useRef, useState, useCallback, MutableRefObject, useLayoutEffect } from "react";

export const useSizeQuery =  <T extends HTMLElement>(smallWidth: number) : [MutableRefObject<T>, boolean] => {
	const [isSmall, setSmall] = useState(false);
	const mainRef = useRef<T>(null as any);
	const updateShowTime = useCallback(() => setSmall(mainRef.current?.getBoundingClientRect().width as any < smallWidth ? true : false), [mainRef, setSmall]);
	useSizeObserver(mainRef, (a, b) => updateShowTime());
	return [mainRef, isSmall];
}

export const useSizeObserver = (ref: React.RefObject<Element>, callback: (element: Element, boundingRect: DOMRect) => void) => {
	
	// use the fresh callback!
	const callbackHandle = useRef(callback);
	callbackHandle.current = callback;

	useLayoutEffect(() => {
		const node = ref.current;
		if (node) {
			const resizeObserver = new ResizeObserver((entries) => {
				if (entries.length) {
					const target = entries[0].target;
					callbackHandle.current(target, target.getBoundingClientRect());
				}
			});

			resizeObserver.observe(node);

			callbackHandle.current(node, node.getBoundingClientRect());

			return () => {
				resizeObserver.unobserve(node);
				resizeObserver.disconnect();
			};
		}
	}, [ref.current]);
}