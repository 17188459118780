import { base64DecToArr, base64EncArr, bytesToHex } from "./base64";

export const sha256 = async (message: string) => {
	const encoder = new TextEncoder();
	const data = encoder.encode(message);
	const hash = await crypto.subtle.digest('SHA-256', data);
	const arr = new Uint8Array(hash);
	return bytesToHex(arr);
}

export const pbkdf2_makeHash = async (password: string, salt?: Uint8Array): Promise<{ hash: string, salt: string }> => {

	salt = salt || window.crypto.getRandomValues(new Uint8Array(32));
	const enc = new TextEncoder();
	const keyMaterial = await window.crypto.subtle.importKey(
		"raw",
		enc.encode(password),
		{ name: "PBKDF2" },
		false,
		["deriveBits", "deriveKey"]
	);

	const derivedBits = await window.crypto.subtle.deriveBits(
		{
			"name": "PBKDF2",
			salt: salt,
			"iterations": 100000,
			"hash": "SHA-256"
		},
		keyMaterial, 256);
	const hash = new Uint8Array(derivedBits);
	return { salt: base64EncArr(salt), hash: base64EncArr(hash) };
}

export const pbkdf2_verifyHash = async (password: string, hh: { hash: string, salt: string }) => {
	const salt = base64DecToArr(hh.salt);
	const pwdHash = await pbkdf2_makeHash(password, salt);
	return pwdHash.hash === hh.hash;
}