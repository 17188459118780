import { Fetch, FetchEntity } from "shared/fetch";
import { ILookupValue, IMetaObject, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { getFetchAttributes, IAppConfig, IListColumn } from "../AppState";
import { DataService } from "../service";
import { download } from "./download";
import { IPdfStyle, addGrid, addText, createGrid, renderDocument } from "../report/pdfReport";
import { simpleCellFormatter, simpleCellFormatterText } from "../objectList/useCellFormatter";
import { formatMoneyValue } from "../formatters";
import { IModalContext } from "../modal/Modal";
import { configureFormFieldDialog } from "../objectForm/Configure";
import { makeButtonType } from "../customize/ConfigureFormField";
import { showFetchDialog } from "../customize/FetchDialog";
import { PERMISSION_READ, getPermLevel } from "shared/permissions";

export interface IExportOptions {
	format?: "pdf" | "csv" | "json";
	formats?: string[];
	records: "All" | "Selected";
	fileName?: string;
	header?: string;
	columns?: (string | { n: string; w: string })[];
	totals?: string[];
	showDialog?: boolean;
	decimalSeparator?: string;
	csvHeader?: string;
}


export const exportFileFromList = async (metadata: IAppConfig, q: Fetch, listColumns: IListColumn[], options?: string) => {

	// TODO: dialog with tree field selection
	let opts: IExportOptions | undefined = undefined;
	try {
		opts = options && JSON.parse(options);
	}
	catch (e) { alert(e) }

	exportRecordsToFile(metadata, q, listColumns, opts);
}

export const executeExportCommand = (modal: IModalContext, metadata: IAppConfig, menuTarget: HTMLElement, query: Fetch,
		listColumns: IListColumn[], selectedIds?: string[], options?: string) => {

	const opts: IExportOptions = (options && JSON.parse(options)) || {
		records: "Selected",
		format: "csv",
		fileName: makeExportName(metadata.objects.find(x => x.logicalName === query.entity.name)!),
		showDialog: true
		//fetch: query,
	};

	query = JSON.parse(JSON.stringify(query));

	const parent: { fields: IExportOptions[] } = { fields: [opts] };

	const dialogMeta: IMetaProperty[] = [
		{
			logicalName: "records", displayName: "Export Records", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: ["All", "Selected"]
		},
		{
			logicalName: "format", displayName: "Format", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: opts.formats || ["csv", "pdf"]
		},
		{
			logicalName: "fileName", displayName: "File Name", type: MetaPropertyType.String, flags: 0
		},
		{
			logicalName: "totals", displayName: "Calculate totals", type: MetaPropertyType.String, flags: 0
		}
	];
	const showCustomizeFetchDialog = async (e: any, info: IMetaProperty, item: IExportOptions, modal: IModalContext) => {
		let fetch = JSON.parse(JSON.stringify(query));
		showFetchDialog(modal, fetch, () => query = fetch);
	}
	dialogMeta.push(makeButtonType("fetch", "Edit Query", (a, b, c) => showCustomizeFetchDialog(a, b, c, modal)));

	const execute = (opts: IExportOptions) => {
		if (opts.records === "Selected" && selectedIds) {
			const oldFilter = query.entity.filter;
			query.entity.filter = { filters: [{ type: "or", conditions: selectedIds.map(id => ({ attribute: "id", operator: "eq", value: id })) }] };
			if (oldFilter)
				query.entity.filter!.filters!.push(oldFilter);
		}

		exportRecordsToFile(metadata, query, [], opts);
	}

	if (opts && opts.showDialog) {

		if (!selectedIds || !selectedIds.length)
			dialogMeta.splice(0, 1); // remove the option to choose all/selected

		if (listColumns) {
			const dict = listColumns.reduce((agg, x) => (agg[x.attribute] = 1, agg), {} as { [x: string]: number });

			const filterAttrs = (e: FetchEntity, alias: string, dict: { [x: string]: number }) => {
				e.attributes = e.attributes && e.attributes.filter(x => !!dict[alias + x.attribute]);
				if (e.links)
					for (const l of e.links)
						filterAttrs(l, l.alias + "__", dict);
			} 

			filterAttrs(query.entity, "", dict);
		}

		configureFormFieldDialog(modal, parent.fields[0], parent, "fields", dialogMeta, menuTarget, () => {
			const opts = parent.fields[0];
			execute(opts);
		}, undefined, "Export", [{name: "CmdSave", label: "", icon:"download"}]);
	}
	else {
		execute(opts);
	}
}

const exportRecordsToFile = async (metadata: IAppConfig, q: Fetch, listColumns: IListColumn[], opts?: IExportOptions) => {

	const defaultOpts = await fetchDefaultOptions(metadata);
	opts = { ...opts, ...defaultOpts as IExportOptions };

	const allAttrs = getFetchAttributes(metadata, q);

	let attrs = allAttrs;
	if (opts && opts.columns) {
		attrs = opts.columns.map((x: any) => allAttrs.find(y => y.attribute === ((x && x.n) ? x.n : x))).filter(x => x !== undefined) as any;
	} else if (listColumns && listColumns.length > 0) {
		attrs = listColumns.map(x => allAttrs.find(y => y.attribute === x.attribute)).filter(x => x !== undefined) as any;
	}

	const meta = metadata.objects.find(x => x.logicalName === q.entity.name)!;
	let propMetas = attrs.map(x => ({ ...x.prop, logicalName: x.attribute }));
	const visibleMetas = propMetas;
	if (!propMetas.find(x => x.logicalName === "id") && meta)
		propMetas = meta.properties.filter(x => x.logicalName === "id").concat(propMetas);
	
	const fileName = (opts && opts.fileName) || makeExportName(meta);
	
	if (opts && opts.format === "pdf") {
		const result = await exportPdfFile(metadata, q, visibleMetas, opts);
		download(result as any, fileName + ".pdf", "application/pdf");
	} else if (opts?.format === "json") {
		const result =  await exportJsonFile(q, propMetas);
		download(result as any, fileName + ".json", "application/json");
	} else {
		const result = await exportCsvFile(q, propMetas, opts);
		download("\ufeff" + result, fileName + ".csv", "text/csv");
	}
}

export const makeExportName = (meta: IMetaObject) => {
	let entityLabel = (meta?.displayNamePlural || meta.logicalName);
	const docTitle = (document.title || "").split("-").slice(-1)[0].trim();
	if (entityLabel !== docTitle)
		entityLabel = entityLabel + " " + docTitle;
	return entityLabel;
}

const fetchRecords = async (q: Fetch) => {
	// todo page all results.
	q = JSON.parse(JSON.stringify(q)) as Fetch;
	q.page = 1;
	q.pageSize = 5000;
	q.count = 5000;
	const items = await DataService.retrieveMultiple(q) as any[];
	return items;
}

const exportJsonFile = async (q: Fetch, propMetas: IMetaProperty[]) => {
	const items = await fetchRecords(q);
	return JSON.stringify(items, undefined, 2);
}

const fetchDefaultOptions = async (metadata: IAppConfig) => {
	if (getPermLevel("inu_orgsettings", metadata.permDict, PERMISSION_READ)) {
		const results = await DataService.retrieveMultiple({ entity: { name: "inu_orgsettings", allattrs: true } });
		if (results && results[0] && results[0].export_options)
			return JSON.parse(results[0].export_options) as IExportOptions;
	}
	return undefined;
}

const exportCsvFile = async (q: Fetch, propMetas: IMetaProperty[], opts?: IExportOptions) => {

	const items = await fetchRecords(q);
	const decimalSep = opts?.decimalSeparator;

	const lines = [];
	if (opts?.csvHeader)
		lines.push("sep=;");
	let hdr = "";
	for (const propMeta of propMetas) {
		if (hdr.length > 0)
			hdr += ";";
		const name = propMeta.logicalName;
		hdr += name;
		if (propMeta.type === MetaPropertyType.Lookup) {
			hdr += ";" + name + "Target;" + name + "Label";
		}
	}
	lines.push(hdr);

	for (const item of items) {
		
		const arr: string[] = [];
		for (const propMeta of propMetas) {
			const name = propMeta.logicalName;
			let value = item[name];
			if (propMeta.type === MetaPropertyType.Lookup) {
				const link = value as ILookupValue;
				if (link) {
					arr.push(link.id);
					arr.push(link.name);
					arr.push(link.label);
				} else {
					arr.push(...["", "", ""]);
				}
			}
			else {
				if (value !== null && value !== undefined) {
					if (propMeta.type === MetaPropertyType.DateTime) {
						value = new Date(value).toISOString();
						// todo: UTC, date only.
					} else if (decimalSep && (propMeta.type === MetaPropertyType.Decimal || propMeta.type === MetaPropertyType.Money ||
						propMeta.type === MetaPropertyType.Float)) {
						value = ("" + value).replace(".", decimalSep);// no thousand separator... //formatNumericValue(propMeta.precision || 2, value, )
					}
						
					arr.push("" + value);
				}
				else
					arr.push("");
			}
		}
		for (let i = 0; i < arr.length; i++) {
			const v = arr[i];
			if (v && (v.indexOf("\"") >= 0 || v.indexOf("\n") >= 0)) {
				arr[i] = '"' + v.replaceAll("\"", "\"\"").replaceAll("\\", "\\\\").replaceAll("\n", "\\n") + '"';
			}
		}
		lines.push(arr.join(';'));
	}
	return lines.join('\n');
}

const exportPdfFile = async (metadata: IAppConfig, q: Fetch, propMetas: IMetaProperty[], opts: IExportOptions) => {

	const items = await fetchRecords(q);
	const textFontSize = (opts && (opts as any).fontSize) || 12;

	const styles: { [key: string]: IPdfStyle } = {
		title: {
			margin: [2, 2, 4, 2],
			fontSize: 24,
			fontFamily: "roboto",
			color: "navy",
		},
		subTitle: {
			margin: [2, 2, 20, 2],
			fontSize: 15,
			fontFamily: "roboto",
			color: "navy",
		},
		tableHeader: {
			margin: [0, 0, 0, 0],
			padding: [2, 2, 7, 2],
			fontSize: textFontSize,
			fontFamily: "roboto-Bold",
			color: "navy",
			vertTextAlign: "center",
			// borderColor: "black",
			// borderStyle: "solid",
			// borderThickness: [1, 1, 1, 1]
		},
		text: {
			margin: [0, 0, 0, 0],
			padding: [2, 2, 7, 2],
			fontSize: textFontSize,
			fontFamily: "roboto",
			color: "black",
			vertTextAlign: "start",
			// borderColor: "black",
			// borderStyle: "solid",
			// borderThickness: [0, 1, 1, 1]
		}
	};

	let docSize: any = undefined;
	// if (propMetas.length >= 10) {
	// 	styles.tableHeader.fontSize = 6;
	// 	styles.text.fontSize = 6;
	// 	docSize = { layout: 'landscape', margins: { left: 10, right: 10, top: 10, bottom: 10 } };
	// }
	if ((opts as any)?.orientation === "landscape") {
		docSize = { layout: 'landscape', margins: { left: 10, right: 10, top: 10, bottom: 10 } };
	}

	const mainGrid = createGrid(0, 0, ["1fr"], ["auto"]);
	addText(mainGrid, 0, 0, "title", document.title);
	const dateStr = new Date().toLocaleString("sk-SK", { "day": "numeric", "month": "short", "year": "numeric" });
	addText(mainGrid, 0, 1, "subTitle", metadata.objects.find(x=>x.logicalName === q.entity.name)!.displayNamePlural! + " " + dateStr);
	const itemsGrid = addGrid(mainGrid, 0, 2, propMetas.map(x => "1fr"), ["auto"]);
	
	let i = 0;
	for (const propMeta of propMetas) {
		const name = propMeta.displayName || propMeta.logicalName;
		addText(itemsGrid, i++, 0, "tableHeader", name);
	}
	let totals = {} as any;
	for (const r of items) {
		i = 0;
		const j = itemsGrid.rows.length;
		const altBack = (j & 1) ? { background: "#F8F8F8" } : undefined; 
		for (const propMeta of propMetas) {
			const logicalName = propMeta.logicalName;
			const value = simpleCellFormatterText(r, logicalName, propMeta, metadata);
			addText(itemsGrid, i++, j, "text", value, altBack);

			if (opts && opts.totals && opts.totals.indexOf(logicalName)>=0) {
				let old = totals[logicalName] || 0;
				let add = 1; //count
				if (propMeta.type === MetaPropertyType.Money || propMeta.type === MetaPropertyType.Decimal || propMeta.type === MetaPropertyType.Integer)
					add = +r[logicalName] || 0;
				totals[logicalName] = old + add;
			}
		}
	}
	if (opts && opts.totals) {
		i = 0;
		const j = itemsGrid.rows.length;
		const altBack = (j & 1) ? { background: "#F8F8F8" } : undefined; 
		for (const propMeta of propMetas) {
			const logicalName = propMeta.logicalName;
			let value = totals[logicalName];
			if (value === undefined)
				value = "";
			else {
				if (propMeta.type === MetaPropertyType.Money) {
					value = formatMoneyValue(metadata, items[0], value);
				} else {
					//fixme: use simpleCellFormatterText and meta is always decimal as this is a count or sum
					value = simpleCellFormatter(totals, logicalName, propMeta);
				}
			}
			addText(itemsGrid, i++, j, "text", value, { ...altBack, borderThickness: [1, 0, 0, 0], borderColor: "black" });
		}
	}
	
	const blob = await renderDocument(mainGrid, styles, "Export", docSize);
	return blob;
}