import { IFormFlexPanel } from "../../AppSchema";
import { useCustomizePanel } from "../Configure";
import { Panel } from "./Panel";
import { IPanelProps } from "./PanelProps";

export const FlexPanel = (props: IPanelProps) => {
	const panel = props.panel as IFormFlexPanel;
	
	const { customize, configureClick, dragEvents } = useCustomizePanel(props.context, panel);

	const de = { ...dragEvents, onDragStart: undefined };

	return <div className="flexPanel" style={{ flexDirection: panel.direction as any }} >
		{panel.panels.map((p, i) => {
			const style = {} as any;
			if (panel.sizes && panel.sizes[i])
				style.flex = "1 1 " + panel.sizes[i];
			return <div key={p.name} {...dragEvents} style={style} className="flexPanelItem" data-kind="flexpanel" data-drag_index={i}>
				{customize.enabled && <span {...de} className="formItemCustomize" data-kind="tabflex" data-drag_index={i}
					onClick={configureClick}>{p.name} <i className="fa fa-gears" /></span>}
				<Panel context={props.context} panel={p} />
			</div>
		})}
		{customize.enabled &&
			<div {...de} className="formItemCustomizeAdd" data-kind="flexpanel" data-drag_index={panel.panels.length}
				onClick={configureClick}><i className="fa fa-plus" /></div>}
	</div>
}