import { makeRichOptionSpan } from "./RichOptionSpan";

export const SelectInput = (props: { value: string, opts: string[], richOpts: any[], onChange: (value: any) => void, disabled?: boolean }) => {
	const { value, opts, onChange } = props;
	const ro = props.richOpts;
	
	let renderValue: any = value;
	if (ro) {
		const o = ro.find(x => x.value === value);
		if (o)
			renderValue = makeRichOptionSpan(o);
	}

	return <div className="selectInput">
		<div className="selectInputValue">{renderValue}</div>
		{!props.disabled && <div className="selectInputDropdown"><i className="fa fa-chevron-down"></i></div>}
		<select disabled={props.disabled} style={{opacity:"0", gridArea:"1/1"}} value={value} onChange={e => onChange(e.target.value)} >
			{opts.map((x, i) => (<option key={"o" + x} value={x}>{(ro && ro[i]) ? (ro[i].label||ro[i].value) : x}</option>))}
		</select>
	</div>
}
