import React, { useContext } from "react";
import { IAppConfig, IForm, IFormContainerPanel, IFormFieldsPanel, IFormPanel, IHomeItem, IList, IViewRecord, ListType } from "./AppSchema";
import { Fetch, FetchEntity, FetchFilter } from "shared/fetch";
import { IDictionary, IMetadata, IMetaObject, IMetaProperty, IUser, MetaPropertyType } from "shared/schema";
import { getPermLevel, ICustomOwner, PERMISSION_DELETE, PERMISSION_LEVEL_OWNER, PERMISSION_UPDATE } from "shared/permissions";
import { DataService } from "./service";
export * from "./AppSchema";

export const MetaContext = React.createContext<IAppConfig>({ objects: [], user: { id: "", name: "", emailaddress: "" }, gui: [], apps: [], permDict: {}, customOwners:[], currencyDict:{}, orgName: "", sas: ""});

export const checkPermissions = ( metadata: IAppConfig, record: any, objectName: string, opIndex: number) => {
	const perm = getPermLevel(objectName, metadata.permDict, opIndex);
	const customOwners = metadata.customOwners;
	const user = metadata.user;
	
	let owners: string[]|undefined = undefined;
	if (!perm || perm === PERMISSION_LEVEL_OWNER) {
		const operation = ["create", "read", "update", "delete"][opIndex];
		// do we have an owner field???
		owners = customOwners ? customOwners.filter(x => x.objectName === objectName && x.operation === operation).map(x => x.fieldName) : [];

		if (perm === PERMISSION_LEVEL_OWNER)
			owners.push(objectName === "systemuser" ? "id" : "ownerid");

		if (owners.length === 0)
			return false;
	}

	if (opIndex === PERMISSION_UPDATE || opIndex === PERMISSION_DELETE) {

		if (owners) {
			let ok = false;
			for (let j = 0; j < owners.length && !ok; j++) {
				const ownerId = record[owners[j]]
				if (ownerId && (ownerId === user.id || ownerId["id"] === user.id))
					ok = true;
			}
			return ok;
		}
	}
	return true;
}

export const checkCommandPermissions = (isNew: boolean, metadata: IAppConfig, record: any, permissionsConfig: string) => {
	const pp = permissionsConfig.split(';');
	let allowNew = false;
	for (let i = 0; i < pp.length - 1; i += 2){
		const objectName = pp[i];
		const opIndex = +pp[i + 1];
		if (objectName === "allowNew?") {
			allowNew = true;
			continue;
		}
		if (!checkPermissions(metadata, record, objectName, opIndex)) {
			return false;
		}
	}
	if (isNew && !allowNew)
		return false;
	return true;
}

export const humanize = (s: string) => {
	switch (s) {
		case "createdby": return "Created By";
		case "createdon": return "Created On";
		case "modifiedby": return "Modified By";
		case "modifiedon": return "Modified On";
		case "ownerid": return "Owner";
	}

	return s[0].toUpperCase() + s.slice(1);
}
const pluralize = (s: string) => {
	return s + "s";
}
export const tryLocalize = (metadata: IAppConfig, text?: string, fallback?: string, fallbackIfNotFound?: string) => {
	const locMap = (metadata as any).locMap;

	if (text && text[0] === "@") {
		let plural = text.endsWith("+s");
		let id = text.substring(1, text.length - (plural ? 2 : 0));
			
		const p = metadata.objects.find(x => x.logicalName === id)
		if (p) {
			return (plural ? p.displayNamePlural : p.displayName) || p.logicalName;
		}
		//return metadata.localize(text.substring(1));
		if (locMap && locMap[id])
			return locMap[id];
	}
	
	if (text && locMap && locMap[text])
		return locMap[text];
	if (fallbackIfNotFound)
		return fallbackIfNotFound;
	if (text)
		return text;
	return fallback ? humanize(fallback) : (text as string);
}

export const useGuiHome = () => {
	const metadata = useContext(MetaContext);
	if (!metadata) return undefined;
	//const gui = metadata.gui[0];
	// if (!gui.home || gui.home.length === 0) {
	// 	gui.home = metadata.objects.map(x => ({
	// 		kind: "entity", name: x.logicalName, label: x.displayNamePlural || "", url: x.listLink || ""
	// 	}));
	// 	gui.home.push({ kind: "custom", name: "customize", label: "Customize", url: "customize" });
	// }

	const appId = metadata.appId || "__default__";
	const homeCache = metadata.gui[0].homeCache;
	if (homeCache && homeCache.appId === appId)
		return homeCache.items;

	let guiHome: IHomeItem[] = [];
	const home = metadata.gui[0].objects.find(x => x.kind === "home" && x.appid?.label === metadata.appId);
	if (home) {
		guiHome = JSON.parse(home.body)["home"] as IHomeItem[];
	}
	else if (metadata.appId === "Customize") {
		guiHome = [
			{ kind: "separator", name: "cat1", label: "Configure", url: "/" },
			{ kind: "custom", name: "customizemetadata", label: "Metadata", url: "customizemetadata" },
			{ kind: "entity", name: "inu_view", label: "Views", url: "list/inu_view" },
			{ kind: "entity", name: "inu_app", label: "Apps", url: "list/inu_app" },
			{ kind: "custom", name: "localize", label: "Localize", url: "customizelocalization" },
			{ kind: "entity", name: "inu_orgsettings", label: "Settings", url: "list/inu_orgsettings" },
			{ kind: "separator", name: "cat2", label: "Administration", url: "/" },
			{ kind: "entity", name: "systemuser", label: "Users", url: "list/systemuser" },
			{ kind: "entity", name: "team", label: "Teams", url: "list/team" },
			{ kind: "entity", name: "inu_role", label: "Roles", url: "list/inu_role" },
			{ kind: "entity", name: "audit", label: "Audit", url: "list/audit" },
			{ kind: "separator", name: "cat3", label: "Data", url: "/" },
			{ kind: "entity", name: "inu_autonumber", label: "Auto Number", url: "list/inu_autonumber" },
			{ kind: "entity", name: "inu_changehandler", label: "Triggers", url: "list/inu_changehandler" },
			{ kind: "entity", name: "inu_asyncaction", label: "Triggers Log", url: "list/inu_asyncaction" },
			{ kind: "entity", name: "inu_nextrule", label: "Next Rules", url: "list/inu_nextrule" },
			{ kind: "custom", name: "import", label: "Import", url: "import" },
			{ kind: "custom", name: "export", label: "Export", url: "export" },
		];
	}
	else {
		guiHome = [{ kind: "custom", name: "systemuser", label: "User", url: "edit/systemuser/" + metadata.user.id }];
	}

	const makeLinkAndLabel = (link: IHomeItem) => {
		if (link.kind === "entity") {
			if (!link.url) {
				if (link.uniqueName)
					link.url = "n/" + link.uniqueName;
				else
					link.url = "list/" + link.name;
			}
			if (!link.label) {
				const meta = metadata.objects.find(x => x.logicalName === link.name);
				if (meta) {
					link.label = meta.displayNamePlural || meta.displayName || link.name;
				}
			}
		}
	}
	
	for (let i = 0; i < guiHome.length; i++) {
		const link = guiHome[i];
		makeLinkAndLabel(link);

		if (link.permissions) {
			link.visible = checkCommandPermissions(false, metadata, {}, link.permissions);
			if (link.kind === "separator") {  // section -> controls visiblity of all children.
				for (; i < guiHome.length - 1; i++) {
					const nextLink = guiHome[i + 1];
					if (nextLink.kind === "separator")
						break;

					makeLinkAndLabel(nextLink);
					nextLink.visible = nextLink.permissions ?
						checkCommandPermissions(false, metadata, {}, nextLink.permissions) :
						link.visible;
				}
			}
		}
	}
	guiHome = guiHome.filter(h => h.visible !== false);

	metadata.gui[0].homeCache = {items: guiHome, appId: appId}
	return guiHome;
}

const internalLoadGuiItems = (metadata: IAppConfig, kind: "view" | "chart", objectName: string, allowedViews?: string[]) => {
	const gui = metadata.gui[0];
	const allApps = allowedViews && allowedViews[0] === "*"//metadata.appId === "Customize";
	let lists = gui.objects.filter(x => x.objectname === objectName &&
		x.kind === kind &&
		(allApps || !x.appid || x.appid.label === metadata.appId));
	// if (allowedViews && typeof allowedViews === "string")
	// 	allowedViews = [allowedViews as any as string] as string[];
	if (allowedViews) {
		if (allApps) // all
			lists = lists;
		else
			lists = lists.filter(x => (allowedViews as string[]).indexOf(x.name) >= 0);
	}
	else {
		lists = lists.filter(x => !x.isprivate);
	}
	for (const l of lists) {
		if (!l.parsedBody)
			l.parsedBody = JSON.parse(l.body);
	}
	return lists;
}

export const loadGuiCharts = (metadata: IAppConfig, objectName: string, allowedViews?: string[]) => {
	return internalLoadGuiItems(metadata, "chart", objectName, allowedViews);
}

export const loadGuiLists = (metadata: IAppConfig, objectName: string, useCase: ListType, allowedViews?: string[]) => {
	const gui = metadata.gui[0];
	const lists = internalLoadGuiItems(metadata, "view", objectName, allowedViews);
	//const lists = o.lists.filter(x => x.type === useCase || x.type === "Public");
	if (lists.length === 0) {
		// add fallback
		const label = metadata.objects.filter(x => x.logicalName === objectName).map(x => x.displayNamePlural)[0] || objectName;
		const list = simpleList("All " + label, objectName);
		
		const fallback: IViewRecord = { objectname: objectName, id: "", name: label, kind: "view", body: "", parsedBody: list };
		gui.objects.push(fallback);
		lists.push(fallback);
	}
	return lists;
}
// attributes and meta information
export const getFetchAttributes = (metadata: IAppConfig, query: Fetch) => {

	const result: { attribute: string, prop: IMetaProperty, objectName: string }[] = [];

	const r = (e: FetchEntity, prefix: string) => {
		if (e.attributes) {
			const meta = metadata.objects.find(x => x.logicalName === e.name);
			for (const attr of e.attributes) {
				const fullName = prefix + attr.attribute;
				const propMeta = meta && meta.properties.find(x => x.logicalName === attr.attribute);
				if (propMeta)
					result.push({ attribute: fullName, prop: propMeta, objectName: e.name });
					//propMetaDict[fullName] = propMeta;
			}
		}
		if (e.links) {
			for (const l of e.links)
				r(l, l.alias + "__");
		}
	}
	r(query.entity, "");
	return result;
}
// no meta information
export const getFetchAttributesSimple = (e: FetchEntity, prefix: string = "", attrs: string[] = []) => {
	if (e.attributes)
		attrs.push(...e.attributes.map(x => prefix + x.attribute));
	if (e.links)
		for (const l of e.links)
			getFetchAttributesSimple(l, l.alias + "__", attrs);
	return attrs;
}

const simpleList = (listName:string , objectName: string, columns?: string[], filter?: FetchFilter) => {
	const list: IList = {
		name: listName,
		type: "Public",
		columns: (columns || ["name"]).map(c => ({ attribute: c })),
		query: simpleQuery(objectName, columns, filter)
	};
	return list;
}

const simpleQuery = (objectName: string, columns?: string[], filter?: FetchFilter) => {
	if (!columns) {
		columns = [];
	}
	if (!columns.includes("id")) {
		columns.push("id")
	}
	if (!columns.includes("name")) {
		columns.push("name")
	}
	const query: Fetch = {
		entity: {
			name: objectName,
			attributes: columns.map(c => ({ attribute: c })),
			filter: filter,
			orders: [{ attribute: "name" }]
		}
	};
	return query;
}

const findGuiForm = (metadata: IAppConfig, meta: IMetaObject, name?: string) => {
	const objectName = meta.logicalName;
	const gui = metadata.gui[0];

	const findForm = (x: IViewRecord) => x.kind === "form" && x.objectname === objectName && !x.isprivate;
	
	let o: IViewRecord | undefined = undefined;
	if (name) // find by name
		o = gui.objects.find(x => x.kind === "form" && x.objectname === objectName && x.name === name);
	if (!o) // find first non-private in the current app
		o = gui.objects.find(x => findForm(x) && x.appid && x.appid.label === metadata.appId);
	if (!o) // find first anywhere
		o = gui.objects.find(x => !x.appid && findForm(x));
	
	if (!o) {
		if (name)
			return undefined;
		o = { name: "Form", objectname: objectName, kind: "form", id: "", body: "" };
		gui.objects.push(o);
	}
	return o as IViewRecord;
}

export const useGuiForm = (meta: IMetaObject, name?: string) => {
	const metadata = useContext(MetaContext);
	return getGuiForm(metadata, meta, name);
}
export const getGuiForm = (metadata: IAppConfig, meta: IMetaObject, name?:string) => {
	const o = findGuiForm(metadata, meta, name);
	if (o === undefined)
		return undefined as any as IForm;
	if (!o.body) {
		o.parsedBody = simpleForm(metadata, meta);
	}
	if (!o.parsedBody) {
		o.parsedBody = JSON.parse(o.body);
	}
	return o.parsedBody as IForm;
}
export const makeCreateOnlyForm = (form: IForm) => {
	form = JSON.parse(JSON.stringify(form)) as IForm;
		
	// remove any associated panels...
	const prunePanel = (p: IFormPanel) => {
		if (p.type === "Fields") return p;
		const pp = p as IFormContainerPanel;
		if (pp.panels) {
			const newPanels = [];
			for (const child of pp.panels) {
				const newChild = prunePanel(child);
				if (newChild)
					newPanels.push(newChild);
			}
			if (newPanels.length > 0) {
				pp.panels = newPanels;
				return pp;
			}
		}
		return null;
	}
	form.panel = prunePanel(form.panel)!;
	return form;
}

export const updateGuiForm = async (metadata: IAppConfig, meta: IMetaObject, name: string|undefined, form: IForm) => {
	const o = findGuiForm(metadata, meta, name);
	if (o) {
		o.body = JSON.stringify(form);
		o.parsedBody = form;

		const { parsedBody, ...obj } = o;
		if (!obj.id)
			delete (obj as any).id;
		const req = { name: "inu_view", operation: obj.id ? "update" : "create", object: obj } as any;
		DataService.executeMultiple([req]);
	}
}

export const updateGuiList = async (metadata: IAppConfig, meta: IMetaObject, name: string|undefined, o: IViewRecord, listBody: IList) => {
	o.body = JSON.stringify(listBody);
	o.parsedBody = listBody;

	const { parsedBody, ...obj } = o;
	if (!obj.id)
		delete (obj as any).id;
	const req = { name: "inu_view", operation: obj.id ? "update" : "create", object: obj } as any;
	DataService.executeMultiple([req]);
}

export const getObjectChildren = (metadata: IMetadata, target: string) => {
	const tabs = [];
	for (const meta of metadata.objects) {
		for (const propMeta of meta.properties) {
			if (propMeta.targets && propMeta.targets.includes(target)) {
				if (meta.type === "many") {
					const otherLookup = meta.properties.filter(x => x.type === MetaPropertyType.Lookup && x !== propMeta)[0];
					if (otherLookup && otherLookup.targets && otherLookup.targets.length > 0) {
						const targetName = otherLookup.targets[0];
						const targetMeta = metadata.objects.find(x => x.logicalName === targetName);
						if (targetMeta)
							tabs.push({ meta: targetMeta, field: propMeta.logicalName + "." + meta.logicalName + "." + otherLookup.logicalName });
					}
				}
				else {
					tabs.push({ meta: meta, field: propMeta.logicalName });
				}
			}
		}
	}
	return tabs;
}

const simpleForm = (metadata: IMetadata, meta: IMetaObject) => {
	const childObjects = getObjectChildren(metadata, meta.logicalName);
	const form: IForm = {
		name: meta.logicalName,
		panel: {
			name: "main",
			type: "Split",
			panels: [
				{
					name: "fields",
					type: "Fields",
					fields: meta.properties.filter(x => x.logicalName !== "id" && x.logicalName !== "versionnumber").map(x => ({ name: x.logicalName })),
				} as IFormFieldsPanel,
				{
					name: "Associated",
					type: "Tabs",
					panels: childObjects.map(x => ({ name: x.meta.logicalName, type: "AssociatedList", field: x.field, label: x.meta.displayNamePlural }))
				} as IFormContainerPanel
			]
		} as IFormContainerPanel,
		commands: [],
	}
	return form;
}
/*
export const DefaultUI: IAppGui = {
	home: [],
	// home: [
	// 	{ kind: "entity", name: "contact", label: "Contacts", url: "list/contact" },
	// 	{ kind: "entity", name: "animal", label: "Dogs", url: "list/animal" },
	// 	{ kind: "entity", name: "visit", label: "Visits", url: "list/visit" },
	// 	{ kind: "custom", name: "customize", label: "Customize", url: "customize" }
	// ],
	objects: [
		{
			name: "account",
			lists: [simpleList("All Accounts", "account"), simpleList("Active Accounts", "account")],
			forms: []
		},
		{
			name: "contact",
			lists: [simpleList("All Contacts", "contact", ["name", "emailaddress1", "mobilephone1", "address1_city"])],
			forms: []
		},
		{
			name: "animal",
			lists: [simpleList("All Dogs", "animal", ["name", "contactid", "size"])],
			forms: []
		},
		{
			name: "visit",
			lists: [
				simpleList("All Visits", "visit", ["name", "animalid", "contactid", "scheduledstart", "visitstatus"]),
				simpleList("Scheduled Visits", "visit", ["name", "animalid", "contactid", "scheduledstart", "visitstatus"],
					{ conditions: [{ attribute: "visitstatus", operator: "like", value: "Scheduled" }] }),
				simpleList("Completed Visits", "visit", ["name", "animalid", "contactid", "scheduledstart", "visitstatus"],
					{ conditions: [{ attribute: "visitstatus", operator: "like", value: "Completed" }] }),
			],
			forms: []
		}
	]
}
*/