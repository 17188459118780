import { useState, useEffect } from "react";
import { fetchJson } from "../services/fetchJson";
import SimpleGrid from "../components/SimpleGrid";
import { TitleBox } from "../components/TitleBox";

export const ServerCacheList = (props: {}) => {
	const [results, setResults] = useState([] as any[]);
  
	const loadResults = async () => {
		let json = await fetchJson("/api/cache_debug", undefined, true) as any[];
		//const text= JSON.stringify(json, null, 2)
		json = json.filter(x => x.name !== "HEAD" && x.name !== "TAIL");
		setResults(json);
	}
	
	useEffect(() => {
		loadResults();
	},[]);

	const deleteCacheLine = async (row: { name: string }) => {
		const json = await fetchJson("/api/cache_debug_delete", { name: row.name }, true);
		setResults(x => x.filter(y => y !== row));
	}

	const formatCell = (row: any, fieldName: string, style: any) => {
		let value = row[fieldName];
		if (fieldName === "object") {
			return <pre>{JSON.stringify(row.object, null, 2)}</pre>
		}
		else {
			if (fieldName === "username") {
				value = row.object.passport?.user.username;
			} else if (fieldName === "orgname") {
					value = row.object.passport?.user.orgName;
			} else if (fieldName === "agent") {
				value = row.object.last_request_agent;
			} else if (fieldName === "ip") {
				value = row.object.last_request_ip;
			} else if (fieldName === "created" || fieldName === "modified" || fieldName === "read") {
				value = new Date(row.object["__" + fieldName]).toLocaleString();
			} else {
				return <span>{value}<i className="fa fa-trash" style={{ color: "red" }} onClick={() => deleteCacheLine(row)} /></span>
			}
			return value;
		}
		//return value;
	}

	const fields = ["name", "orgname", "username", "created", "modified", "read", "ip", "agent"];
	const columns = fields;
  
	return <div className="objectListContainer">
		<TitleBox title="Sessions" onCommand={e=>{}} />
		<SimpleGrid rows={results} columns={columns} noEdit={true} fields={fields} formatter={formatCell} />
		</div>
	//return <pre style={{overflow:"auto"}}>{results}</pre>
  }
  