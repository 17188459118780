import React, { EventHandler } from "react";
import { useState, useRef, useCallback, useEffect } from "react";
import { AppLink } from "./AppLink";
import { useDerivedState } from "../hooks/useDerivedState";

export const BaseDropDown = (props: {
	value: any;
	onChange: (newValue: any, optional?: any) => void;
	valueToLink?: (value: any) => { label: React.ReactNode, url: string } | any | undefined;
	disabled?: boolean;
	emptyText?: string;
	dropDownProps?: any;
	searchText?: string;
	clearSearchOnClose?: boolean;
	onBlur?: (e: Event) => void;
	DropDownElement: (p: any) => JSX.Element;
}) => {
	const [search, setSearch] = useDerivedState(props.searchText || "", [props.searchText]);
	const [isOpen, setOpen] = useState(false);

	const showPopup = (open: boolean) => {
		setOpen(x => {
			if (x && x !== open && props.clearSearchOnClose !== false)
				setTimeout(() => { setSearch("") }, 100);
			return open;
		});
	}

	const ValueElement = (innerProps: {value: any}) => {

		const value = innerProps.value;
		const linkProps = value && (props.valueToLink ? props.valueToLink(value) : { label: "" + value, url: "#" });
		const isElement = React.isValidElement(linkProps);
		return <>
			{isElement && linkProps}
			{!isElement && linkProps && !linkProps.url && <span style={{ flex: "3 0.2 100%" }}
				onClick={e => isOpen ? showPopup(false) : showPopup(true)/*onSearchButtonClick(e)*/}>{linkProps.label}</span>}
			{!isElement && linkProps && linkProps.url && (
				<AppLink to={linkProps.url}
					className="lookupInputLink lookupLink">{linkProps.label || "Unknown"}</AppLink>
			)}
		</>
	}

	return <InputWithDropDown ValueElement={ValueElement} search={search} setSearch={setSearch} isOpen={isOpen} setOpen={showPopup} {...props} />
}

	
export const InputWithDropDown = (props: {
	value: any;
	onChange: (newValue: any, optional?: any) => void;
	disabled?: boolean;
	emptyText?: string;
	dropDownProps?: any;
	search: string;
	setSearch: (value: string) => void;
	isOpen: boolean;
	setOpen: (value: boolean) => void;
	DropDownElement: (p: any) => JSX.Element;
	ValueElement: (p: any) => JSX.Element;
}) => {
	const { value, search, setSearch, isOpen, setOpen } = props;
	const parentRef = useRef<HTMLDivElement>(null);
	const popupRef = useRef<HTMLDivElement>(null);
	//const focusCount = useRef(0);

	// useEffect(() => { // pushed to LookupInput
	// 	// If re-rendered close.
	// 	setOpen(false);
	// 	focusCount.current = 0;
	// }, [props.propMeta]);

	const showPopup = (open: boolean) => {
		// if ((focusCount.current === 0 && open) ||
		// 	(focusCount.current === 1 && !open)) {
		// 	if (!open) {
		// 		setSearch("");
		// 	}
		// 	setOpen(open);
		// }
		//focusCount.current += open ? 1 : -1;
		setOpen(open);
	}

	const onClick = (ret: any, optionalParam?: any) => {
		//const ret = { id: o.id, name: objectName, label: o.name };
		props.onChange(ret, optionalParam);
		if (isOpen) {
			showPopup(false);
			setTimeout(() => {
				const link = (parentRef.current?.getElementsByClassName("lookupInputSearchButton")[0] as HTMLElement);
				if (link)
					link.focus();
			}, 100);
		} else {
			//console.log("LOOKUP WAS NOT OPEN!")
		}
	};
	const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Backspace" && !search)
			props.onChange(null);
		else if (e.key === "Enter") {
			if (isOpen) {
				// choose first item in list... dunno how:(
			} else {
				setOpen(true);
			}
		}
		else if (e.key === "Escape") {
			if (isOpen) {
				// if (props.clearSearchOnClose !== false)
				// 	setSearch("");
				setOpen(false);
			}
		}
	}, [search, isOpen, props.onChange]);
	
	const popupStyle: React.CSSProperties = {};
	if (isOpen) {
		const parentDiv = parentRef.current as HTMLElement;
		const box = parentDiv.getBoundingClientRect();
		let popupHeight = 190;
		let y = box.bottom;
		const maxHeight = window.innerHeight;
		if (y + popupHeight > maxHeight) {
			if (box.top - popupHeight > 0) {
				y = box.top - popupHeight;
			}  else if (box.top > maxHeight - y) {
				y = 0;
				popupHeight = box.top;
			} else {
				popupHeight = maxHeight - y;
			}
			if (popupHeight < 30) {
				y = 0;
				popupHeight = maxHeight;
			}
		}
		popupStyle.display = "flex";
		popupStyle.flexDirection = "column";
		popupStyle.overflow = "hidden";
		popupStyle.position = "fixed";
		popupStyle.left = box.left - 1 + "px";
		popupStyle.width = "min-content";
		popupStyle.maxWidth = "calc(100vw - " + (box.left - 1) + "px)";
		popupStyle.minWidth = box.width + "px";
		popupStyle.top = y + "px";
		popupStyle.height = popupHeight + "px";
	} else {
		popupStyle.display = "none";
	}

	const [_, render] = useState(false);
	useEffect(() => {
		const onResize = () => render(x => !x);
		window.addEventListener("resize", onResize);
		return () => {
			window.removeEventListener("resize", onResize);
		}
	}, [render]);

	const onSearchButtonClick = (e: React.MouseEvent) => {
		const div = parentRef.current;
		if (div)
			(div.getElementsByClassName("lookupInputText")[0] as HTMLElement).focus();
	}

	useEffect(() => {
		const focusHandler = (e: Event) => {
			const target = document.activeElement;//e.target as HTMLElement | null;
			let focusWithin = false;
			if (target) {
				let p = target as Element | null;
				while (p) {
					if (p === parentRef.current) {
						focusWithin = true;
						break;
					}
					p = p.parentElement;
				}
			}
			if (!focusWithin) {
				showPopup(false);
			} else {
				if (target!.classList.contains("lookupInputOpen"))
					showPopup(true);
			}
			// console.log("focus " + focusWithin);
			// console.log(target);
		}
		document.addEventListener("focus", focusHandler, true);
		//document.addEventListener("focusout", focusHandler, true);
		return () => {
			document.removeEventListener("focus", focusHandler, true)
			//document.removeEventListener("focusout", focusHandler)
		}
	}, [isOpen, setOpen]);

	return (
		<div ref={parentRef} className="lookupInputDiv">
			<props.ValueElement value={value} />
			<button className="iconButton lookupInputSearchButton" disabled={props.disabled} style={{opacity:props.disabled===true?"0":"1"}} onClick={onSearchButtonClick}>
				<i className="fa fa-search" />
			</button>
			{/* {!value && props.emptyText && <span style={{ color: "lightgray", whiteSpace:"nowrap" }}>{props.emptyText}</span> } */}
			<input tabIndex={isOpen?0:-1} key="input" disabled={props.disabled} placeholder={value ? "" : props.emptyText} className="lookupInputText lookupInputOpen" type="text" value={search} onChange={e => setSearch(e.target.value)}
				onKeyDown={onKeyDown}

			/>
			<div

				key="popup" ref={popupRef} className="lookupDropDown lookupInputOpen" style={popupStyle}>
				<div className="lookupBackDrop" onClick={e=>showPopup(false)}></div>
				{isOpen && <props.DropDownElement search={search} onClick={onClick} {...props.dropDownProps} />}
			</div>
		</div>
	);
}