import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { TitleBox } from "../components/TitleBox";
import { useDerivedState } from "../hooks/useDerivedState";
import { ICommand } from "../AppSchema";
import { NavLink } from "react-router-dom";
import { AppLink } from "../components/AppLink";
import { newGuid } from "../utils/guid";
import { ICalendarEvent } from "./ICalendarEvent";
import { splitEventsToColumns } from "./eventColumns";

/* todo

cleanup:
- centralize command handling (with month calendar)
- move styles to css classes
features:
- create events
- month -> day calendar on day label click
- scroll left/right move page
- drag&drop reschedule, prevent completed
- quick-view?
- in-popup mode
- drag and drop regarding to create events (like schedulo)
*/


export interface IWeekCalendarBasicProps {
	days: number; // 1,5,7 or even 3..., if 5 "next" page will skip weekend
	startOfWeek: number;
	onChangeViewMode: (e: HTMLElement) => void,
	initialDate?: Date,
	onDateChanged?: (v: Date | ((prev: Date) => Date)) => void,

	rows?: ICalendarEvent[],
	onNewEvent?: (r: ICalendarEvent) => void,
	onMoveEvent?: (r: ICalendarEvent) => void,

	commands: ICommand[],
	onCommand: (cmd: ICommand, e: any) => void;
}

const getStartOfWeek = (d: Date, firstDayOfWeek: number) => {
	while (d.getDay() !== firstDayOfWeek) {
		d = new Date(d.valueOf() - 86400 * 1000);
	}
	return d;
}

const getDayRows = (events: ICalendarEvent[] | undefined, startDate: Date) => {
	const endDate = new Date(startDate.valueOf() + 86400 * 1000);
	const dayEvents: ICalendarEvent[] = [];

	if (events) {
		for (let event of events) {
			if (!event.start)
				continue;

			const eventStart = new Date(event.start);
			let eventEnd = new Date(event.end || event.start);

			// events with no end, are one hour
			if (!event.end || event.end <= event.start) {
				eventEnd = new Date(eventStart.valueOf() + 3600 * 1000);
				event = { ...event, end: eventEnd.toISOString() }
			}	
		
			if (eventEnd > startDate && eventStart < endDate) {
				if (eventStart < startDate || eventEnd > endDate) {
					event = { ...event };
					if (eventStart < startDate)
						event.start = startDate.toISOString();
					if (eventEnd > endDate)
						event.end = endDate.toISOString();
				}
				dayEvents.push(event);
			}
		}
		dayEvents.sort((a, b) => new Date(a.start).valueOf() - new Date(b.start).valueOf());
		splitEventsToColumns(dayEvents);
	}

	return dayEvents;
}

export const WeekCalendar = (props: IWeekCalendarBasicProps) => {

	const [currentDate, setCurrentDate] = useDerivedState(props.initialDate || new Date(), [props.initialDate]);
	const firstDayOfWeek = 1; // hardcoded monday for now
	const firstDay = props.days >= 5 ? getStartOfWeek(currentDate, firstDayOfWeek) : currentDate;

	const title = props.days > 1 ?
		firstDay.toLocaleString(undefined, { month: 'short', year: 'numeric' }) :
		firstDay.toLocaleString(undefined, { day: 'numeric', month: 'short', year: 'numeric' });

	const onCommand = useCallback((cmd: ICommand, e: any) => {
		const onSetDate = props.onDateChanged || setCurrentDate;
		const days = props.days === 5 ? 7 : props.days;
		switch (cmd.name) {
			case "CmdBack": onSetDate(d => new Date(d.valueOf() - (days * 86400 * 1000))); break;
			case "CmdFwd": onSetDate(d => new Date(d.valueOf() + (days * 86400 * 1000))); break;
			default: props.onCommand(cmd, e); break;
		}
	}, [props.onDateChanged, setCurrentDate]);

	return <div className="weekCalendarContainer" style={{overflow:"hidden", display:"flex", flexDirection:"column"}}>
		<div key="title" className="calendarTitle"><TitleBox title={title} commands={props.commands} onCommand={onCommand} /></div>

		<WeekCalendarGrid {...props} initialDate={firstDay} />
	</div>
}

export const WeekCalendarGrid = (props: IWeekCalendarBasicProps) => {

	const init = props.initialDate || new Date();
	const startDay = new Date(init.getFullYear(), init.getMonth(), init.getDate());

	const draggedEvent = useRef<HTMLElement | null>(null);
	const isDragResize = useRef<boolean>(false);

	const dragImagePlaceholder = useMemo(() => {
		const img = new Image(1, 1);
		img.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==";
		return img;
	}, []);

	const onDragStart = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		const eventBox = e.target as HTMLElement;
		if (eventBox) {
			const rowid = eventBox.dataset["recordid"];
			const event = props.rows?.find(x => x.id === rowid);
			if (!event || event.canEdit === false) {
				e.preventDefault();
				return;
			}
			draggedEvent.current = eventBox;
			isDragResize.current = e.clientY > eventBox.getBoundingClientRect().bottom - 10;
			eventBox.dataset["original_position"] = JSON.stringify({ r: eventBox.style.gridRowStart, c: eventBox.style.gridColumnStart, r2: eventBox.style.gridRowEnd });
			e.dataTransfer.setDragImage(dragImagePlaceholder, 0, 0);
			setTimeout(() => {
				eventBox.style.pointerEvents = "none";
			}, 1);
		}
	}, [draggedEvent, props.rows]);
	const onDragOver = useCallback((e: React.DragEvent<HTMLElement>) => {
		e.preventDefault();
		const box = e.target as HTMLElement;
		const eventBox = draggedEvent.current;
		if (eventBox) {
			if (isDragResize.current) {
				eventBox.style.gridRowEnd = "span " + Math.max(1, (+box.style.gridRowStart - +eventBox.style.gridRowStart + 1));
			} else {
				eventBox.style.gridRowStart = box.style.gridRowStart;
				eventBox.style.gridColumn = box.style.gridColumn;
			}
		}
	}, [draggedEvent]);
	const onDragEnd = useCallback((e: React.DragEvent<HTMLElement>) => {
		e.preventDefault();
		const eventBox = draggedEvent.current;
		if (eventBox) {
			//console.log("onDragEnd");
			const original = JSON.parse(eventBox.dataset["original_position"]||"");
			eventBox.style.gridRowStart = original.r;
			eventBox.style.gridColumn = original.c;
			eventBox.style.pointerEvents = "";
			draggedEvent.current = null;
		}
	},[draggedEvent]);
	const onDrop = useCallback((e: React.DragEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();

		const eventBox = draggedEvent.current;
		if (eventBox) {
			const col = +eventBox.style.gridColumnStart;
			const row = +eventBox.style.gridRowStart;
			const newStart = new Date(startDay.valueOf() + (col - 2) * 86400 * 1000 + (row - 2) * 15 * 60 * 1000);
			const rowid = eventBox.dataset["recordid"];
			const event = props.rows?.find(x => x.id === rowid);
			if (event) {
				//const updatedEvent = { id: rowid } as any;
				if (isDragResize.current) {
					event.end = new Date(startDay.valueOf() + (col - 2) * 86400 * 1000 +
						(row - 2 + +(eventBox.style.gridRowEnd.substring(5))) * 15 * 60 * 1000).toISOString();
				}
				else {
					const diff = newStart.valueOf() - new Date(event.start).valueOf();
					event.start = newStart.toISOString();	
					event.end = new Date(new Date(event.end).valueOf() + diff).toISOString();
				}
				if (rowid !== newRecordPlaceholderId && props.onMoveEvent) {
					props.onMoveEvent(event);
				}
			}
			eventBox.style.pointerEvents = "";
			draggedEvent.current = null;
		}
	}, [draggedEvent, props.rows, startDay.valueOf()]);


	const [_, render] = useState(false);
	const newRecordPlaceholderId = "__new";
	const onBoxDoubleClicked = (e: React.MouseEvent<HTMLElement>) => {

		if (!props.onNewEvent) return;

		const box = e.target as HTMLElement;
		let r: any = undefined;
		if (props.rows && (props.rows.length === 0 || props.rows[0].id !== newRecordPlaceholderId)) {
			r = {
				id: newRecordPlaceholderId,
				name: "New Event"
			};
			props.rows.splice(0, 0, r);
		} else if (props.rows && props.rows.length > 0 && props.rows[0].id === newRecordPlaceholderId) {
			//props.rows[0] = { ...props.rows[0] };
			r = props.rows[0];
		}
		const start = new Date(startDay.valueOf() +
			(+box.style.gridColumnStart - 2) * 86400 * 1000 +
			(+box.style.gridRowStart - 2) * 15 * 60 * 1000);
		r.start = start;
		r.end = new Date(start.valueOf() + 3600 * 1000);
		render(z => !z);
	}

	const rows = 24 * 4 + 1; // grid top row sticky
	const minHeight = (rows) * 1.1 + "em"; // 4 15min blocks per hour, ensure we fit a text line in each lock. +1 is header
	
	const gridColumns = "auto repeat(" + props.days + ", 1fr)"; // hour | day 1 | ... | day N
	const gridRows = "repeat(" + rows + ",1fr)";
	
	const today = new Date();
	const headers: any = [];
	for (let i = 0; i < props.days; i++) {
		const style: React.CSSProperties = {
			gridColumn: "" + (2 + i),
			gridRow: "1",
			position: "sticky",
			top: "0px",
			background: "var(--back-color)",
			borderBottom: "1px solid var(--border-color3)",
			borderBottomColor: "var(--border-color3)",
			borderLeft: "1px solid var(--border-color3)",
			borderLeftColor: "var(--border-color3)",
			fontWeight: "600",
			padding: "2px"
		};
		const d = (new Date(startDay.valueOf() + (1000 * 86400 * i)));
		if (d.getFullYear() === today.getFullYear() && d.getMonth() === today.getMonth() && d.getDate() === today.getDate()) {
			style.color = "red";
			style.borderBottomColor = "red";
		}
		const weekDayLabel = d.toLocaleString(window.navigator.language, { weekday: 'short' }) + " " + d.getDate();
		const hdr = <div key={"hdr"+i} className="weekCalendarDayHeader" style={style}>{weekDayLabel}</div>
		headers.push(hdr);
	}
	const boxes: any = [];
	for (let i = 0; i < props.days; i++) {
		for (let j = 0; j < 24 * 4; j++) {
			const style: React.CSSProperties = {
				gridColumn: "" + (2 + i),
				gridRow: "" + (2 + j),
				borderLeft: "1px solid var(--border-color3)",
				borderBottom: "1px solid var(--border-color3)",
			};
			if ((j % 2) === 0)
				style.borderBottomColor = "transparent"
			else if ((j % 4) === 1)
				style.borderBottomStyle = "dashed";
			const d = (new Date(startDay.valueOf() + (1000 * 86400 * i)));
			if (d.getDay() === 6 || d.getDay() === 0)
				style.background = "var(--border-color2)";
			const box = <div onDoubleClick={onBoxDoubleClicked} onDragOver={onDragOver} onDrop={onDrop} key={"box" + boxes.length} data-weekday={i} data-weekminute={j * 15} className="weekCalendarBox" style={style}></div>
			boxes.push(box);
		}
	}
	const timeBoxes: any = [];
	for (let j = 1; j < 24; j++) {
		const style: React.CSSProperties = {
			gridColumn: "1",
			gridRow: "" + (2 + j * 4),
			alignSelf: "flex-start",
			justifySelf: "flex-end",
			marginTop: "-0.6em",
			marginLeft: "1ch",
			marginRight: "1ch",
		};
		const box = <div key={"timeBox" + j} className="weekCalendarTimeBox" style={style}>{"" + j}</div>
		timeBoxes.push(box);
	}

	const onNewRecordClick = useCallback((e: any) => {
		e.preventDefault();
		if (props.rows && props.rows[0] && props.rows[0].id === newRecordPlaceholderId) {
			if (props.onNewEvent) {
				props.onNewEvent(props.rows[0]);
			}
		}
	}, [props.rows]);

	const rowBoxes: any[] = [];
	const d = startDay;
	const end = new Date(startDay.valueOf() + 86400 * 1000 * props.days);
	
	for (let dayIndex = 0; dayIndex < props.days; dayIndex++) {
		const dayRows = getDayRows(props.rows, new Date(startDay.valueOf() + 86400 * 1000 * dayIndex));

		for (const x of dayRows) {
			const s = x.start;
			const e = x.end || s;
			const sd = new Date(s);
			let ed = new Date(e);
			
			const label = x.customElement || x.name || "Event";
			const url = x.url || "#";
			const isNewRecord = x.id === newRecordPlaceholderId;
			const style: React.CSSProperties = {
				gridRowStart: "" + (2 + sd.getHours() * 4 + (sd.getMinutes() / 15) | 0),
				gridColumn: "" + (2 + ((sd.valueOf() - d.valueOf()) / 86400 / 1000) | 0),
				border: "3px solid transparent",
				borderRadius: "5px",
				backgroundColor: "#0071eb22",
				overflow: "hidden",
				boxSizing:"border-box"
			}
			if (x.color) {
				style.borderLeftColor = x.color;
				style.background = x.color + "22";
			}
			if (isNewRecord) {
				style.borderLeftColor = "var(--acccent-color)";
				style.borderStyle = "dashed";
			}
			const maxEnd = new Date(sd.getFullYear(), sd.getMonth(), sd.getDate() + 1);
			if (ed > maxEnd)
				ed = maxEnd;
			let length = ed.valueOf() - sd.valueOf();
			style.gridRowEnd = "span " + (length / (1000 * 60 * 15) | 0);
			// const color = getColor(x["familyid"]?x["familyid"].id:"");
			// style.borderLeft = "5px solid " + color;
			let icon: any = undefined;
			const linkStyle: React.CSSProperties = { paddingLeft: "3px", color: "var(--acccel-color)" };//, color: x.color || "var(--acccent-color)"};
			if (x.icon) {
				const iconStyle = {};
				icon = <i className={"fa fa-" + x.icon} style={{ ...iconStyle, width: "1em", textAlign: "center" }}></i>;
			}
			if (x.columnCount && x.columnCount > 1) {
				const units = 100 / x.columnCount;
				style.marginLeft = ((x.columnIndex || 0) * units) + "%";
				style.width = units + "%";
			}
			// } else if (x.color)
			// 	linkStyle.borderLeft = "1em solid " + x.color;
			const key = (isNewRecord ? newGuid() : x.id) + "|" + dayIndex; // multiday events.
			const eventBox = <div className="weekCalendarEventBox" key={key} data-recordid={x.id} style={style} onDragEnd={onDragEnd} onDrop={onDrop} onDragStart={onDragStart} onDragOver={e => { e.preventDefault() }} draggable={true}>
				{icon}{!isNewRecord && <NavLink draggable={false} to={url} style={linkStyle}>{label}</NavLink>}
				{isNewRecord && <AppLink style={{color: "var(--acccel-color)"}} onClick={onNewRecordClick} to={"#"} >{label}</AppLink>}
			</div>
			rowBoxes.push(eventBox);
		}
	}

	const sc = useRef<number>(0);
	const mainRef = useRef<HTMLDivElement>(null);
	const onScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
		if (sc.current)
			window.clearTimeout(sc.current);
		sc.current = window.setTimeout(() => {
			const div = (e.target as HTMLDivElement);
			const boxHeight = div.scrollHeight / (24 * 4 + 1);
			const minutes = div.scrollTop / boxHeight;
			sessionStorage.setItem("WeekCalendarMinutes", minutes.toString());
			console.log("CalendarScroll" + minutes);
		}, 500);
	}, []);

	useLayoutEffect(() => {
		const s = sessionStorage.getItem("WeekCalendarMinutes") || (8 * 4);
		const minutes = +s;
		const div = (mainRef.current as HTMLDivElement);
		if (div) {
			const boxHeight = div.scrollHeight / (24 * 4 + 1);
			div.scrollTo({ top: minutes * boxHeight, behavior: "auto" });
			console.log("CalendarScrollRestore" + minutes);
		}
	});

	return <div className="weekCalendarMain" ref={mainRef} onScroll={onScroll}>
		<div className="weekCalendarContainerGrid" style={{
			height: minHeight, position: "relative",
			gridTemplateColumns: gridColumns,
			gridTemplateRows: gridRows,
			display: "grid",
		}}>
			{headers}
			{timeBoxes}
			{boxes}
			{rowBoxes}
		</div>
	</div>
}

const getColor = (s: string) => {
	
	const hashCode = function (s: string) {
		var hash = 0,
			i, chr;
		if (s.length === 0) return hash;
		for (i = 0; i < s.length; i++) {
			chr = s.charCodeAt(i);
			hash = ((hash << 5) - hash) + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}
	const x = hashCode(s) / 2147483647;
	return "hsl(" + 360 * x + ',' +
		(25 + 70 * x) + '%,' +
		(85 + 10 * x) + '%)'
}