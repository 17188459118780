import { useState } from "react";
import { IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { FieldEditor } from "../objectForm/panels/FieldsPanel";

export interface IMetaPropertyEx extends IMetaProperty {
	onClick?: (e: any, self: IMetaPropertyEx, context: any, setContext: (c: any) => void) => void;
	subGridAttrs?: (string | IMetaPropertyEx)[];
	getEditValue?: (self: IMetaPropertyEx, item: any) => any;
	setEditValue?: (self: IMetaPropertyEx, item: any, value: any) => void;
}

export const makeArrayType = (name: string) => ({ logicalName: name, type: MetaPropertyType.String, flags: MetaPropertyFlags.WriteIn });
export const makeBoolType = (name:string) => ({ logicalName: name, type: MetaPropertyType.Boolean, flags: 0 });
export const makeButtonType = (name: string, label: string, onClick: (e: any, self: IMetaProperty, context: any, setContext: (s: any) => void) => void, linkLabel: string = "Edit...") => ({ logicalName: name, displayName: label, type: MetaPropertyType.String, flags: 0, options: [linkLabel], onClick: onClick });

export const ConfigureFormField = (props: { item: any, attrs: (string|IMetaPropertyEx)[], className?: string, setItem?: (item: any) => void }) => {
	const item = props.item;
	const [config, setConfig] = useState({} as any);
	const setProperty = (prop: IMetaPropertyEx, value: string) => {
		let setEditValue = prop.setEditValue || ((s, item, value) => item[s.logicalName] = value);
		if (props.setItem) {
			const newItem = { ...item };
			setEditValue(prop, newItem, value);
			props.setItem(newItem);
		} else {
			setConfig({});
			setEditValue(prop, item, value);
		}
	}
	Object.assign(config, item);

	const formItem = (item: any, propNameOrProp: string | IMetaPropertyEx) => {
		const prop = ((propNameOrProp as any).logicalName ? propNameOrProp : { logicalName: propNameOrProp as string, type: MetaPropertyType.String, flags: 0 }) as IMetaPropertyEx;
		const propName = prop.logicalName;
		const propLabel = prop.displayName || (propName[0].toLocaleUpperCase() + propName.substring(1));
		const propEditValue = prop.getEditValue ? prop.getEditValue(prop, item) : item[propName];
		const setPropValue = (v: any) => setProperty(prop, v);
		const onClick = prop.onClick;
		return (<div key={"x_" + propName} className="formItem">
			<label className="formLabel">·{propLabel}</label>
			{prop.subGridAttrs && <ConfigureFormField item={propEditValue} attrs={prop.subGridAttrs} setItem={x => setProperty(prop, x)} />}
			{onClick && <a href="#" onClick={e => { e.preventDefault(); onClick(e, prop, item, props.setItem || setConfig) }} >{(prop.options && prop.options[0]) || propLabel}</a> }
			{/* <input type="text" value={item[propName] || ""} onChange={e => setProperty(propName, e.target.value)} /> */}
			{!onClick && !prop.subGridAttrs && FieldEditor(prop, propEditValue, {}, setPropValue)}
		</div>);
	}

	return <div className={"formBody " + (props.className||"")} style={{gridArea: "3/1/4/3", border:"0", padding:"0", margin:"0"}}>
		<div className="formGrid configureFormFieldGrid">
			{props.attrs.map(x => (formItem(item, x)))}
		</div>
	</div>
}
