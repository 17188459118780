

export const ListSelector = (props: {
	value: any,
	keyName: string,
	onChange: (newValue: any) => void,
	options: any[],
	getLabel: (o: any) => string,
	labelName?: string,
	className?: string
}) => {

	const { labelName, options } = props;
	const selectedIndex = options.indexOf(props.value);

	const getLabel = props.getLabel || ((o: any) => (labelName ? o[labelName] : o));

	return (<select className={props.className} value={selectedIndex} onChange={e=>props.onChange(options[+e.target.value])}>
		{options.map((x,i) => {
			return <option key={i} value={i}>{getLabel(x)}</option>	
		})
		}
	</select>);
}