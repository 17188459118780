import React, { useState, useRef, useCallback, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IconButton } from "../components/IconButton";
import { Loading } from "../components/Loading";
import { loginToServer } from "../services/fetchJson";
import { Capacitor } from '@capacitor/core';
import { pbkdf2_verifyHash, pbkdf2_makeHash } from "../utils/crypto";
import { humanize } from "../AppState";
import { ChangePasswordFields } from "./ChangePasswordForm";

export const LoginWithGoogle = (props: { label: string, onClick: (e: React.MouseEvent) => void, disabled?: boolean, className?: string, children?: any }) => {
	return (<IconButton className={"btnLoginWithGoogle " + (props.className || "")} style={{ width: "100%", backgroundColor:"var(--back-color2)" }} onClick={props.onClick} disabled={props.disabled} icon=""
		label={props.label}>
		{props.children ||
			<svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18"><path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18Z" fill="#4285F4"></path><path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17Z" fill="#34A853"></path><path d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07Z" fill="#FBBC05"></path><path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3Z" fill="#EA4335"></path></svg>
		}
	</IconButton>)
}

export const tryGetOrgName = () => {
	const domains = window.location.hostname.split('.');
	if (domains.length > 2)
		return domains[0];

	const query = new URLSearchParams(window.location.search);
	return query && query.get("org");
}

export const LoginPanel = () => {
	const [searchParams] = useSearchParams();
	const initOrg = tryGetOrgName();

	const orgName = initOrg || localStorage.getItem("loginOrganization") || "";
	const orgUser = localStorage.getItem("loginEmail") || "";

	const context = {
		url: "", organization: orgName, email: orgUser,
		ret: searchParams.get("returnUrl"),
		showOrg: !!initOrg,
	} as ILoginContext;

	return <LoginStateMachine context={context} ChildElement={LoginPanelInternal} />
}

const LoginPanelInternal = (props: { context: ILoginContext }) => {

	const { context } = props;
	const showOrgField = context.showOrg !== false;
	const [login, setLogin] = useState({ email: context.email, password: "", organization: context.organization });
	const [isSending, setSending] = useState(false);
	const [status, setStatus] = useState({ msg: "", err: false });
	const navigate = useNavigate();
	const isNative = Capacitor.isNativePlatform();

	const updateContext = () => {
		let { organization, email, password } = login;
	
		let url: string = Capacitor.isNativePlatform() ? "http://localhost:3001" : "";
		if (organization && organization.indexOf("#") > 0) {
			[url, organization] = organization.split("#");
		}

		context.url = url;
		context.organization = organization;
		context.email = email;
		context.password = password;
	}

	const handleSubmit = async (e?: any) => {
		if (e)
			e.preventDefault();
	
		const orginalOrg = login.organization;
		if (!orginalOrg || !login.email || !login.password) return;

		// if (isNative) {
		// 	const pw = localStorage.getItem("loginPwd");
		// 	if (pw) {
		// 		const pwHash = JSON.parse(pw);
		// 		const ok = await pbkdf2_verifyHash(password, pwHash);
		// 		if (!ok) {
		// 			alert("invalid password");
		// 			return;
		// 		}
		// 	}
		// }

		setSending(true);

		// FIXME: check navigator isOnline and try offline login. Save hash of password in service.init

		updateContext();
		const err = await handleResponseAndNext(context, (c) => loginToServer(c.organization, c.email, c.password, c.url), false);
		if (err) {
			setStatus({ msg: err.reason || err.message, err: true });
		} else {
			setStatus({ msg: "Welcome back!", err: false });
			localStorage.setItem("loginOrganization", orginalOrg);
			if (isNative) {
				localStorage.setItem("loginEmail", context.email);

				const hash = await pbkdf2_makeHash(context.password) as any;
				hash["org"] = orginalOrg;
				hash["email"] = context.email;
				localStorage.setItem("loginPwd", JSON.stringify(hash));
			}
				
			const to = context.ret || "/";
			console.log("navigate after login:[" + to + "]");
			setTimeout(() => navigate(to, { replace: true }), 700);
			return; // do not re-enable form
		}

		setSending(false);
	}
	const onKey = (e: any) => {
		if (e.key === "Enter") {
			handleSubmit(e)
		}
	};

	const onLoginWithGoogle = (e:any) => {
		doFederatedLogin(e, "google");
	}
	const onLoginWithFacebook = (e:any) => {
		doFederatedLogin(e, "facebook");
	}
	const onLoginWithAzure = (e: any) => {
		doFederatedLogin(e, "azure");
	}
	const onLoginWithApple = (e: any) => {
		doFederatedLogin(e, "apple");
	}

	const doFederatedLogin = (e: any, authProvider: string) => {
		if (e)
			e.preventDefault();
	
		updateContext();
		let redirect = context.ret || "/";
		let origin = window.location.origin;
		const originParam = "&origin=" + encodeURIComponent(origin);
		if (window.location.hostname === "localhost") {
			redirect = origin + redirect;
			origin = "http://localhost:3001"; // proxy will try to handle localhost:3000 and just do nop.
		}
		const url = origin + "/auth/" + authProvider + "/" + context.organization + "?redirectUrl=" + encodeURIComponent(redirect) + originParam;
		localStorage.setItem("loginOrganization", login.organization);
		window.location.assign(url);
	}

	const getMagic = async (e:any, forgotten?:boolean) => {
		if (e)
			e.preventDefault();
		if (!login.organization || !login.email) return;
		
		updateContext();
		if (forgotten)
			context.reason = "USER_FORGOT";
		context.next({ message: "OK_SEND_MAGIC" }, context);
	}
	const getMagicForgotten = (e:any) => {
		getMagic(e, true);
	}

	return (<form><fieldset className="loginForm" disabled={isSending}>
		<div className="formItem" style={{display:showOrgField?"none":""}} >
			<label className="formLabel" htmlFor="org">Organization</label>
			<input id="org" type="text" value={login.organization} onChange={e => setLogin({ ...login, organization: e.target.value })} />
		</div>
		<div style={{ height: "1em", display:showOrgField?"none":"" }} ></div>
		<div className="formItem" style={{position:"relative"}}>
			<label className="formLabel" htmlFor="email">Email</label>
			<input autoFocus={true} id="email" type="email" value={login.email} onChange={e => setLogin({ ...login, email: e.target.value })} />
		</div>
		<div className="formItem" style={{position:"relative"}}>
			<label className="formLabel" htmlFor="password">Password</label>
			<input onKeyDown={onKey} id="password" type="password" value={login.password} onChange={e => setLogin({ ...login, password: e.target.value })} />
		</div>
		<IconButton icon="" style={{width:"100%", backgroundColor:"var(--back-color2)"}} disabled={!login.email || !login.password || !login.organization} onClick={handleSubmit} label="Sign in"></IconButton>
		<div className="formItem" style={{ position: "relative", display:"grid", gridTemplateColumns:"1fr 1fr", background:"unset" }}>
			<button style={{position:"relative", textDecoration:"underline"}} tabIndex={-1} className="loginForgottenPassword buttonReset" disabled={!login.email || !login.organization} onClick={getMagic}>Send Magic Link</button>	
			<button style={{position:"relative", textDecoration:"underline"}} tabIndex={-1} className="loginForgottenPassword buttonReset" disabled={!login.email || !login.organization} onClick={getMagicForgotten}>Forgot Password?</button>
		</div>
		{/* <IconButton icon="magic" style={{width:"100%"}} disabled={!login.email || !login.organization} onClick={getMagic} label="Sign in with Magic Link"></IconButton> */}

		{/* <IconButton icon="fa-face-frown" style={{width:"100%"}} disabled={!login.email || !login.organization} onClick={getMagicForgotten} label="Forgotten password"></IconButton> */}

		<div style={{ height: "1em" }} ></div>
		<LoginWithGoogle label="Sign in with Google" className="loginWithGoogle" onClick={onLoginWithGoogle} disabled={!login.organization} />
		<LoginWithGoogle label="Sign in with Facebook" className="loginWithFacebook" onClick={onLoginWithFacebook} disabled={!login.organization}>
		<svg aria-hidden="true" className="svg-icon iconFacebook" width="18" height="18" viewBox="0 0 18 18"><path fill="#4167B2" d="M3 1a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm6.55 16v-6.2H7.46V8.4h2.09V6.61c0-2.07 1.26-3.2 3.1-3.2.88 0 1.64.07 1.87.1v2.16h-1.28c-1 0-1.2.48-1.2 1.18V8.4h2.39l-.31 2.42h-2.08V17z"></path></svg>
		</LoginWithGoogle>
		<LoginWithGoogle label="Sign in with Apple" className="loginWithApple" onClick={onLoginWithApple} disabled={!login.organization}>
		<svg height="44" viewBox="0 0 14 44" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m13.0729 17.6825a3.61 3.61 0 0 0 -1.7248 3.0365 3.5132 3.5132 0 0 0 2.1379 3.2223 8.394 8.394 0 0 1 -1.0948 2.2618c-.6816.9812-1.3943 1.9623-2.4787 1.9623s-1.3633-.63-2.613-.63c-1.2187 0-1.6525.6507-2.644.6507s-1.6834-.9089-2.4787-2.0243a9.7842 9.7842 0 0 1 -1.6628-5.2776c0-3.0984 2.014-4.7405 3.9969-4.7405 1.0535 0 1.9314.6919 2.5924.6919.63 0 1.6112-.7333 2.8092-.7333a3.7579 3.7579 0 0 1 3.1604 1.5802zm-3.7284-2.8918a3.5615 3.5615 0 0 0 .8469-2.22 1.5353 1.5353 0 0 0 -.031-.32 3.5686 3.5686 0 0 0 -2.3445 1.2084 3.4629 3.4629 0 0 0 -.8779 2.1585 1.419 1.419 0 0 0 .031.2892 1.19 1.19 0 0 0 .2169.0207 3.0935 3.0935 0 0 0 2.1586-1.1368z"></path></svg>
		</LoginWithGoogle>
		<LoginWithGoogle label="Sign in with Microsoft" className="loginWithMS" onClick={onLoginWithAzure} disabled={!login.organization}>
		<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><title>MS-SymbolLockup</title><rect x="1" y="1" width="9" height="9" fill="#f25022"/><rect x="1" y="11" width="9" height="9" fill="#00a4ef"/><rect x="11" y="1" width="9" height="9" fill="#7fba00"/><rect x="11" y="11" width="9" height="9" fill="#ffb900"/></svg>
		</LoginWithGoogle>

		<div style={{display:"grid"}}>
			<div style={{ gridArea:"1/1/2/2", height: "20px", marginTop:"10px" }}>{isSending && <Loading />}</div>
			<div style={{ gridArea:"1/1/2/2", height: "1.4em", color: status.err ? "red" : "green", textAlign: "center" }}>{status.msg}</div>
		</div>
		{/* <div style={{marginTop:"50px"}}>
			<NavLink to="/trial">I want my own organization. Click to Start Trial</NavLink>
		</div> */}
	</fieldset></form>);
}


export type LoginStateMessage = "ERR_MUST_CHANGE_PWD" | "ERR_MAGIC_TIMEOUT" | "OK_SEND_MAGIC" | "OK_CHECK_EMAIL" | "ERR_SERVER" | "ERR_MAGIC_BROKEN" | "ERR_UNKNOWN";

export interface ILoginContext {
	url: string;
	organization: string;
	email: string;
	password: string;
	reason?: string;
	showOrg?: boolean;
	ret?: string; // redirect
	next: (action: { message: LoginStateMessage, reason?: string }, context: ILoginContext) => void;
}

export const handleResponseAndNext = async (context: ILoginContext, action: (context: ILoginContext) => Promise<Response>, handleAllErrors = true) => {
	
	let err: { message: string, reason?: string }|undefined = undefined;
	
	try {
		const resp = await action(context);
		if (resp.ok) {
			return undefined;
			// we assume OK was handled in action.
		} else {
			try {
				const serverError = await resp.json() as { message: string, reason?: string };
				if (serverError) {
					if (serverError.reason)
						context.reason = serverError.reason;
					context.next(serverError as any, context);
					return serverError;
				}
			}
			catch {
				err = { message: "ERR_SERVER", reason: resp.statusText };
			}
		}
	}
	catch {
	}
	if (handleAllErrors)
		context.next(err as any || { message: "ERR_UNKNOWN" }, context);
	return err;
}

export const LoginStateMachine = (props: { context: ILoginContext, ChildElement: (props: { context: ILoginContext }) => JSX.Element }) => {
	const navigate = useNavigate();
	const [render, setRender] = useState<any>(<Loading />);
  
	const next = useCallback((action: { message: string, reason?: string }, context: ILoginContext) => {
		let Element: (props: any) => JSX.Element = undefined as any;
		switch (action.message) {
			case "OK_SEND_MAGIC": Element = SendMagicLink; break;
			case "OK_CHECK_EMAIL": Element = CheckEmailMessage; break;
			case "ERR_MUST_CHANGE_PWD": Element = MustChangePasswordForm; break;
			case "ERR_MAGIC_TIMEOUT": Element = ShowMagicLinkExpired; break;
			default:
				setRender(<div className="magicContainer">
					<i className='fa fa-face-frown' style={{ fontSize: 40, color: "#eebb99" }}></i>
					<h1>Something went wrong</h1>
					<span style={{ color: "red", fontWeight: "bold", margin: "auto", padding: "20px" }}>{action.message}<br />{action.reason}</span>
					<span>Please contact support.</span>
				</div>);
				setTimeout(() => navigate("/login", { replace: true }), 10000);
				return;
		}
		setRender(<Element context={context} />);
	}, [setRender, navigate]);
	const context = props.context;
	context.next = next;

	useEffect(() => {
		setRender(<props.ChildElement context={props.context} />);
	},[])

	return render;
}

const SendMagicLink = (props: { context: ILoginContext }) => {

	const { context } = props;

	const sendMagic = async () => {
  
		const data = new URLSearchParams();
		//const [url, organization] = getOrg();
		data.append("username", context.email);
		data.append("organization", context.organization);
		if (context.ret)
			data.append("ret", context.ret);
		if (context.reason)
			data.append("reason", context.reason);
  
		handleResponseAndNext(context, async () => {
			const resp = await fetch(context.url + "/api/getmagic", {
				method: "POST",
				body: data
			});
			if (resp.ok) {
				context.next({ message: "OK_CHECK_EMAIL" }, context);
			}
			return resp;
		});
	}

	useEffect(() => {
		sendMagic();
	}, [])

	return <Loading />;
}

const CheckEmailMessage = (props: { context: ILoginContext }) => {
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => navigate("/"), 10000);
	},[])

	return <div className="magicContainer">
		<i className='fa fa-envelope' style={{ fontSize: 40, color: "#eebb99" }}></i>
		<h1>Link Sent</h1>
		<span>Please check your email to sign in.</span>
	</div>
}

const ShowMagicLinkExpired = (props: { context: ILoginContext }) => {
	const { context } = props;
	const body = <div className="magicContainer">
		<i className='fa fa-face-frown' style={{ fontSize: 40, color: "#eebb99" }}></i>
		<h1>{context.reason === "INVITE" ? "Invitation link has expired" : "Sign in link has expired"}</h1>
		<span>Magic links work only for 5 minutes.<br />Please click below to get a fresh one.</span>
		<div style={{ height: "15px" }} />
		<IconButton label='Send a new Magic Link' icon="magic" onClick={e => context.next({ message: "OK_SEND_MAGIC" }, context)} />
	</div>
	return body;
}

const MustChangePasswordForm = (props: { context: ILoginContext }) => {

	const { context } = props;
	const navigate = useNavigate();

	const [login, setLogin] = useState({ oldPassword: "", password: "", password2: "" });
	const [isSending, setSending] = useState(false);
	const [status, setStatus] = useState({ msg: "", err: false });

	const handleSubmit = async (e: any) => {
		if (e)
			e.preventDefault();
		const { password, password2 } = login;
		//if (!email || !password) return;
		if (!password || password !== password2) return;
		
		setSending(true);
		const data = new URLSearchParams();
		data.append("username", context.email);
		data.append("password", context.password);
		data.append("organization", context.organization);
		data.append("newPassword", password);
		
		const err = await handleResponseAndNext(context, async () => {
			const resp = await fetch("/api/mustchangepassword", {
				method: "POST",
				body: data
			});
			return resp;
		}, false);
		if (err) {
			setStatus({ msg: err.reason || err.message, err: true });
			setSending(false);
		} else {
			setStatus({ msg: "Password Changed", err: false });
			setTimeout(() => navigate(context.ret || "/"), 1000);
		}
	};

	let msg: any = "";
	let button = "Change Password";
	if (context.reason === "INVITE") {
		msg = <><h1>Welcome to {humanize(context.organization)}!</h1><span>Please choose a password and you are all set.</span></>
		button = "Continue";
	} else if (context.reason === "USER_FORGOT") {
		msg = <><h1>Change forgotten password</h1><span>You are seeing this message beacase you have <br/> forgotten your password and ask to change it.<br />
			If you have not, please ignore.
		</span></>;
	} else if (context.reason === "ADMIN_CHANGE") {
		msg = <><h1>Choose a new Password</h1>
			<span>You are seeing this message beacase<br />your administrator requested you choose a new password.
		</span></>;
	} else {
		msg = <><h1>Choose a new Password</h1>
			<span>You are here because <br />
			You have been invited, congrats! <br />
			You have forgotten your password, no worries.<br />
			Your administrator has decided your need a new one.<br />
			<i>Please contact your administrator, if none of the above applies to you.</i>
		</span>
		</>
	}

	return <div className="magicContainer">
		<i className='fa fa-key' style={{ fontSize: 40, color: "#eebb99" }}></i>
		{msg}
		<ChangePasswordFields status={status} isSending={isSending} login={login} setLogin={setLogin} showOldPassword={false} />
		<IconButton label={button} onClick={handleSubmit} icon="password" disabled={!login.password || !login.password2} />
	</div>
}