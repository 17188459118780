import { Dictionary, ICurrency, IDictionary, IExecuteRequest, IExecuteRequests, IExecuteResult, ILookupValue, IMetadata, IMetaObject, MetaPropertyType, Schema } from 'shared/schema';
import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite, SQLiteDBConnection, SQLiteConnection, capSQLiteSet,
capSQLiteChanges, capSQLiteValues, capEchoResult, capSQLiteResult,
capNCDatabasePathResult } from '@capacitor-community/sqlite';

export interface SqlParam {
	name: string;
	type: string;
	value: any;
}

export interface IQueryResult {
	values?: any[];
}

export interface IDbConnection {
	execute(cmd: string, inTransaction: boolean): Promise<any>;
	query(sql: string, values: any[]): Promise<IQueryResult>;
	delete(): Promise<any>;
}

class ElectronConnection implements IDbConnection {
	execute(cmd: string, inTransaction: boolean): Promise<any> {
		return (window as any).electronAPI.execute(cmd);
	}
	query(sql: string, values: any[]): Promise<IQueryResult> {
		return (window as any).electronAPI.query(sql, values);
	}
	delete(): Promise<any> {
		throw Error("not implemented")
	}

}

export class DatabaseConnection {

	public async beginTransaction() {
		await this.dbConnection?.execute("BEGIN TRANSACTION;",false);
	}
	public async finishTransaction(commit: boolean) {
		await this.dbConnection?.execute(commit ? "COMMIT;" : "ROLLBACK;",false);
	}

	public async executeNonQuery(sql: string, values: SqlParam[]) {
		return this.dbConnection?.query(sql, values.map(v => v.value));
	}

	public async insertRow(tableName: string, values: SqlParam[]) {
		const sql = "INSERT INTO " + tableName + " ( " +
			values.map((v) => v.name).join(",") + " ) VALUES (" +
			values.map((v, i) => "?").join(",") + ")";
		return this.executeNonQuery(sql, values);
	}

	public async updateRowWithId(tableName: string, id: SqlParam[], values: SqlParam[]) {
		const sql = "UPDATE " + tableName + " SET " + values.map((v, i) => v.name + "=?").join(", ");
		return this.executeNonQueryWhere(sql, id, values);
	}

	public async deleteRowWithId(tableName: string, id: SqlParam[]) {
		const sql = "DELETE FROM " + tableName;
		return this.executeNonQueryWhere(sql, id, []);
	}

	private async executeNonQueryWhere(sql: string, conds: SqlParam[], values: SqlParam[]) {

		values = values.slice() || [];
		if (conds && conds.length > 0) {
			sql += " WHERE ";
			for (let i = 0; i < conds.length; i++){
				if (i > 0)
					sql += " AND "
				const id = conds[i];
				sql += id.name + "=?";
				values.push(id);
			}
		}
		return this.executeNonQuery(sql, values);
	}

	constructor() {
		this.isService = true;
	}
	inuse: boolean = false;

	async connect() {

		//this.dbName = "testDB";
	  
		if (!this.isDBConnected) {
			this.dbConnection = await this.createConnection(this.dbName, false, "no-encryption", 1);
		}
	}

	async query(sql: string, params: any[]) {
		
		try {
			const ret = await this.dbConnection?.query(sql, params);
			return ret;
		}
		catch (e) {
			console.log("ERRQUERY: " + sql + "\n\tPARAMS:" + JSON.stringify(params));
			throw e;
		}
	}
	async executeSql(sql: string) {
		return this.dbConnection?.execute(sql, false);
	}
	
	async createConnection(database: string, encrypted: boolean, mode: string, version: number): Promise<IDbConnection | null> {

		if ((window as any).electronAPI)
			return new ElectronConnection();

		//let statusDB = (await this.sqlite.isConnection(database, false)).result;
		
		// if (!this.sqlite.isDatabase(database)) {
		// 	//this.sqlite.
		// }
		/*
		await CapacitorSQLite.checkConnectionsConsistency({});
		let db;
		try {
			db = await this.sqlite.retrieveConnection(database, false);
		}
		catch {}
		if (!db) {
			db = await this.sqlite.createConnection(database, encrypted, mode, version, false);
			await db.open();
		}*/
		//this.platform = Capacitor.getPlatform();
		const sqlitePlugin: any = CapacitorSQLite;
		const sqlite = new SQLiteConnection(sqlitePlugin);
		const ret = await sqlite.checkConnectionsConsistency();
		const isConn = (await sqlite.isConnection(database, false)).result;
		var db: SQLiteDBConnection
		if (ret.result && isConn) {
			db = await sqlite.retrieveConnection(database, false);
		} else {
			db = await sqlite.createConnection(database, false, "no-encryption", 1, false);
		}
		if (db) {
			await db.open();
			this.isDBConnected = true;
			return Promise.resolve(db);
		} else {
			return Promise.reject(new Error(`no db returned is null`));
		}
	}

	isDBConnected: boolean = false;
	isService: boolean = false;
	dbConnection: IDbConnection|null = null;
	dbName: string = "database";
}