import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Fetch } from "shared/fetch";
import { PanelProps } from "../objectForm/panels/PanelProps";
import { DataService } from "../service";
import './sofi.css';
import { IExecuteRequest, ILookupValue } from "shared/schema";
import { IModalContext, IModalParent, IModalProps, ModalContext } from "../modal/Modal";
import { ICommand } from "../AppSchema";
import { TitleBox } from "../components/TitleBox";
import { fileToBase64, resizeImage } from "../services/download";
import { DefaultEditor } from 'react-simple-wysiwyg';
import { newGuid } from "../utils/guid";
import { onDragEnter, onDragLeave, onDragOver, onDragOverInternal, onDragStart } from "../components/draggable";
/*
course
	- team_course
		- team_member [systemuserid eq-userid]
	- course_progress (outer)
*/

interface IBlock {
	name: string;
	content_type: string;
	body: string;
	id: string;
	seqno: number;
	pageid?: ILookupValue;
	courseid?: ILookupValue;
}
interface IPage {
	id: string;
	name: string;
	seqno: number;
	blocks: IBlock[];
	lessonid?: ILookupValue;
	courseid?: ILookupValue;
}
interface ILesson {
	id: string;
	name: string;
	seqno: number;
	pages: IPage[];
	courseid?: ILookupValue;

	page__name?: string;
	page__seqno?: number;
	page__lessonid?: number;
	page__id?: string;
}
interface ICourse {
	lessons: ILesson[];
}

export const configureCourseObject = (modal: IModalContext,
	obj: { name: string },
	isNew: boolean,
	objectName: string,
	sourceElement: HTMLElement,
	onChange: () => any) => {

	const ConfigureField = (props: {
		obj: { name: string },
		isNew: boolean,
		objectName: string,
		onSave: () => Promise<void>, commandSite: IModalParent
	}) => {

		const obj = props.obj;
		const [name, setName] = useState(obj.name);

		const cmds = [
			{ name: "CmdDelete", label: "", icon: "trash" },
			{ name: "CmdSave", label: "", icon: "save" },
			{ name: "CmdClose", label: "Close", icon: "close" },
		];
		if (props.isNew)
			cmds.splice(0, 1);
		const onCommand = async (cmd: ICommand) => {
			if (cmd.name === "CmdSave") {
				if (!name) {
					alert("Please write a name");
					return;
				}
				obj.name = name;
				await DataService.updateRecord(props.objectName, obj, props.isNew ? "create" : "update");
				await props.onSave();
			}
			else if (cmd.name === "CmdDelete") {
				await DataService.updateRecord(props.objectName, obj, "delete");
				obj.name = "";
				await props.onSave();
			}
			props.commandSite?.closeModal();
		}

		return (<div className="objectListContainer" style={{ flex: "1 1 auto" }}>
			<TitleBox title={props.isNew?"Create":"Update"} commands={cmds} onCommand={onCommand} />
			<label className="courseEditorContainer">
				<span>Name</span>
				<input type="text" value={name} onChange={e => setName(e.target.value)} />
			</label>
		</div>)
	}

	const r = sourceElement.getBoundingClientRect();
	const pp: IModalProps = {
		body: (p) => < ConfigureField {...p} />,
		bodyProps: {
			obj: obj,
			isNew: isNew,
			objectName: objectName,
			onSave: () => {
				//Object.assign(field, newField);
				onChange();
			}
		},
		showTitle: false,
		id: "dlgConfigureCourseObject",
		anchor: { x: r.x, y: r.y, width:400 },
	};
	modal.showModal(pp);
}
interface IBlockElementProps {
	editMode: boolean;
	text: string;
	setText: (text: string) => void;
}

const TextBlock = (props: IBlockElementProps) => {
	const text = props.text;
	const setText = props.setText;
	const editMode = props.editMode;
	const areaRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (editMode && areaRef.current) {
			areaRef.current.focus();
		}
	}, [editMode, areaRef]);
	const content = { __html: text };
	return <>
		{!editMode && <div dangerouslySetInnerHTML={content} />}
		{/* {editMode && <textarea ref={areaRef} className="courseTextArea" value={text} onChange={e => setText(e.target.value)} />} */}
		{editMode && <DefaultEditor value={text} onChange={e => setText(e.target.value)} />}
	</>
}

const uploadBlockFile = async (e: React.FormEvent<HTMLInputElement>, setText: (text: string) => void) => {
	const files = (e.target as HTMLInputElement).files;
	const maxHeight = 2000;
	if (files && files.length > 0) {
		const f = files[0];
		let base64body: string;
		if (maxHeight && f.type.match(/image.*/))
			base64body = await resizeImage(f, maxHeight);
		else
			base64body = await fileToBase64(f);
			
		setText(base64body);
	}
}

const ImageBlock = (props: IBlockElementProps) => {
	
	const setText = props.setText;
	const editMode = props.editMode;

	const onUploadFile = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {
		uploadBlockFile(e, setText);
	}, [setText]);
	
	return <>
		{!editMode && <img alt={""} src={props.text} draggable={false} />}
		{editMode && <div>Image File
			<input type="file" onChange={e => e.target.value = null as any}
				onInput={onUploadFile} />
		</div>}
		</>
}
const VideoBlock = (props: IBlockElementProps) => {
	const setText = props.setText;
	const editMode = props.editMode;

	const onUploadFile = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {
		uploadBlockFile(e, setText);
	}, [setText]);
	
	return <>
		{!editMode && <video controls src={props.text} playsInline>
			{/* <source src="movie.mp4" type="video/mp4" />
			<source src="movie.ogg" type="video/ogg" /> */}
		</video>}
		{editMode && <div>Video File
			<input type="file" onChange={e => e.target.value = null as any}
				onInput={onUploadFile} />
		</div>}
		</>
}
const PdfBlock = (props: IBlockElementProps) => {
	const setText = props.setText;
	const editMode = props.editMode;

	const onUploadFile = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {
		uploadBlockFile(e, setText);
	}, [setText]);
	
	return <>
		{!editMode && <iframe src={props.text}/>}
		{editMode && <div>PDF File
			<input type="file" onChange={e => e.target.value = null as any}
				onInput={onUploadFile} />
		</div>}
		</>
}

const BlockElement = (props: { index: number, page: IPage, canEdit: boolean, onChange: (self: IBlock) => void }) => {

	const blockIndex = props.index;
	const page = props.page;
	const block = page.blocks[blockIndex];

	const [text, setText] = useState(block.body);
	const [editMode, setEditMode] = useState(!block.id);

	const stopEditMode = (save: boolean | null) => {
		setEditMode(false);

		// delete empty block on close
		if (save === false && !block.id)
			save = null;
		
		if (save === false) {
			setText(block.body);
			return;
		}
		else if (save) {
			block.body = text;
	
			let method = block.id ? "update" : "create";
			if (!block.id)
				block.id = newGuid();
			
			DataService.updateRecord("sc_block", block, method);
		} else {
			//block.body = "";
			if (block.id)
				DataService.updateRecord("sc_block", block, "delete");
			page.blocks = page.blocks.filter(x => x !== block);
		}
		props.onChange(block);
	}

	const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
		onDragOverInternal(e, async (dragIndex, dropIndex, kind, elm) => {
			if (kind) {
				onDragLeave(e);

				const arr: any = page.blocks.slice()
				executeDragDrop(arr, dragIndex, dropIndex, kind); // don't await update screen
				
				page.blocks = arr;
				props.onChange(block);
			}
		})
	}

	const dragHandlers = { onDragEnter, onDragLeave, onDragOver, onDragStart, onDrop, draggable:!editMode && props.canEdit };

	const className = "courseBlock" + (props.canEdit ? " courseBlockEditMode" : "") + (editMode ? " editMode" : "");

	let EditorElement: undefined|((props: any) => JSX.Element) = TextBlock;
	if (block.content_type === "image")
		EditorElement = ImageBlock;
	else if (block.content_type === "video")
		EditorElement = VideoBlock;
	else if (block.content_type === "pdf")
		EditorElement = PdfBlock;

	return <div className={className} {...dragHandlers} data-kind="sc_block" data-drag_index={blockIndex}>
		<EditorElement text={text} editMode={editMode} setText={setText} />

		{!editMode && props.canEdit && <div className="fa fa-edit" onClick={e => setEditMode(true)} />}
		{editMode && <div style={{ display: "flex", gap: "5px" }}>
			{block.id && <div className="fa fa-trash" onClick={e => stopEditMode(null)} />}
			<div className="fa fa-save" onClick={e => stopEditMode(true)} />
			<div className="fa fa-close" onClick={e => stopEditMode(false)} />
		</div>}
	</div>
}


const executeDragDrop = async (arr:{seqno:number, id:string}[], srcIndex:number, dstIndex:number, objectName:string) => {

	const item = arr[srcIndex];
	arr.splice(srcIndex, 1);
	arr.splice(dstIndex, 0, item);

	for (let i = 0; i < arr.length; i++){
		arr[i].seqno = i + 1;
	}

	const reqs: IExecuteRequest[] = arr.map(x => ({ name: objectName, operation: "update", object: x as any }));
	await DataService.executeMultiple(reqs);
}


export const CoursePlayer = (props: PanelProps) => {

	const [course, setCourse] = useState<ICourse>({ lessons: [] });

	const [lesson, setLesson] = useState(0);
	const [page, internalSetPage] = useState(0);

	const [pageContent, setPageContent] = useState<IPage | undefined>(undefined);	

	const changePage = async (lessonIndex: number, pageIndex: number) => {
		internalSetPage(pageIndex);

		if (lessonIndex < course.lessons.length && pageIndex < course.lessons[lessonIndex].pages.length) {
			const pageContent = course.lessons[lessonIndex].pages[pageIndex];
			
			const q: Fetch = {
				entity: {
					name: "sc_block",
					attributes: ["name", "id", "body", "content_type", "seqno"].map(x => ({ attribute: x })),
					filter: {
						conditions: [
							{ attribute: "pageid", operator: "eq", value: pageContent.id }
						]
					},
					orders: [{ attribute: "id" }]
				}
			}
			const blocks = await DataService.retrieveMultiple(q) as IBlock[];
			blocks.sort((a, b) => (+a.seqno) - (+b.seqno));
			setPageContent({ ...pageContent, blocks: blocks });
		}
		else {
			setPageContent(undefined);
		}
	}

	const changeLesson = (index: number) => {
		setLesson(index);
		changePage(index, 0);
	}

	useEffect(() => {
		const loadData = async () => {
			const q: Fetch = {
				entity: {
					name: "sc_lesson",
					attributes: ["name", "id", "seqno"].map(x => ({ attribute: x })),
					filter: {
						conditions: [
							{ attribute: "courseid", operator: "eq", value: props.id }
						]
					},
					orders: [{ attribute: "id" }],
					links: [
						{
							name: "sc_chapter", type: "outer", from: "id", to: "lessonid", alias: "page",
							attributes: ["name", "seqno", "id"].map(x => ({ attribute: x })),
						}
					]
				}
			}

			const lessons = await DataService.retrieveMultiple(q) as ILesson[];

			course.lessons = [];
			let lastId: string = "";
			for (const l of lessons) {
				if (lastId !== l.id) {
					lastId = l.id;
					l.pages = [];
					course.lessons.push(l);
				}

				if (l.page__id) {
					const p = { id: l.page__id, name: l.page__name || "", seqno: l.page__seqno || 1, blocks: [] };
					course.lessons[course.lessons.length - 1].pages.push(p);
				}
			}
			course.lessons.sort((a, b) => (+a.seqno) - (+b.seqno));
			for (const l of course.lessons) {
				l.pages.sort((a, b) => (+a.seqno) - (+b.seqno));
			}
			
			setCourse(course);
			setLesson(0);
			changePage(0, 0);
		}
		loadData();
	}, [props.id]);

	const modal = useContext(ModalContext);
	const addPage = async (e: React.MouseEvent) => {
		const l = course.lessons[lesson];
		const p: IPage = { id: newGuid(), name: "New Page", seqno: l.pages.length > 0 ? (1 + +l.pages[l.pages.length - 1].seqno) : 1, blocks: [] };
		p.lessonid = { id: l.id, name: "sc_lesson", label: "" };
		p.courseid = { id: props.id, name: "sc_course", label: "" };

		configureCourseObject(modal, p, true, "sc_chapter", e.target as HTMLElement, () => {
			l.pages.push(p);
			changePage(lesson, l.pages.length - 1);
		});
	}
	const editPage = async (e: React.MouseEvent) => {
		const arr = course.lessons[lesson].pages;
		const p = arr[page];
		configureCourseObject(modal, p, false, "sc_chapter", e.target as HTMLElement, () => {
			if (!p.name) {
				arr.splice(page, 1);
			}
			changePage(lesson, Math.min(page, arr.length - 1));
		});
	}
	const addLesson = async (e: React.MouseEvent) => {
		const arr = course.lessons;
		const l: ILesson = { id: newGuid(), name: "New Lesson", seqno: arr.length > 0 ? (1 + +arr[arr.length - 1].seqno) : 1, pages: [] };
		l.courseid = { id: props.id, name: "sc_course", label: "" };

		configureCourseObject(modal, l, true, "sc_lesson", e.target as HTMLElement, () => {
			arr.push(l);
			changeLesson(arr.length - 1);
		});
	}
	const editLesson = async (e: React.MouseEvent) => {
		const arr = course.lessons;
		const l = arr[lesson];
		configureCourseObject(modal, l, false, "sc_lesson", e.target as HTMLElement, () => {
			if (!l.name)
				arr.splice(lesson, 1);
			changeLesson(Math.min(lesson, arr.length - 1));
		});
	}
	const addBlock = async (e: React.MouseEvent) => {
		if (pageContent) {
			const contentType = (e.target as HTMLElement).dataset["kind"] || "text";
			let lastBlock = pageContent.blocks[pageContent.blocks.length - 1];

			// if last block is new and empty then replace
			if (lastBlock && !lastBlock.id && lastBlock.content_type !== contentType) {
				pageContent.blocks.splice(pageContent.blocks.length - 1, 1);
				lastBlock = pageContent.blocks[pageContent.blocks.length - 1];
			}
				
			if (!lastBlock || lastBlock.id) // no blocks or last block is not new
				pageContent.blocks.push({
					id: "",
					name: "",
					body: "",
					content_type: contentType,
					seqno: lastBlock ? (1 + +lastBlock.seqno) : 1,
					pageid: { id: pageContent.id, name: "sc_chapter", label: "" },
					courseid: { id: props.id, name: "sc_course", label: "" },
				})
			setPageContent({ ...pageContent });
		}
	}
	const clickHandler = (e: React.MouseEvent) => {
		const ds = (e.target as HTMLElement).dataset;
		const type = ds["kind"];
		const index = + (ds["drag_index"] || 0);
		if (type === "sc_lesson")
			changeLesson(index);
		else
			changePage(lesson, index);
		(e.target as HTMLElement).scrollIntoView({ "behavior": "smooth" });
	}

	const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
		onDragOverInternal(e, async (dragIndex, dropIndex, kind, elm) => {
			if (kind) {
				onDragLeave(e);

				let arr: any = course.lessons;
				if (kind === "sc_chapter")
					arr = course.lessons[lesson].pages;
				executeDragDrop(arr, dragIndex, dropIndex, kind); // don't await, update screen
				
				if (kind === "sc_chapter")
					changePage(lesson, dropIndex);
				else
					changeLesson(dropIndex);
			}
		})
	}

	const [editMode, setEditMode] = useState(false);
	useEffect(() => {
		// Listen for the event.
		const eventHandler = (e: any) => setEditMode(x => !x);
		document.body.addEventListener("edit_course", eventHandler, false);
		return () => {
			document.body.removeEventListener("edit_course", eventHandler);
		}
	});

	const dragHandlers = { onDragEnter, onDragLeave, onDragOver, onDragStart, onDrop, draggable: editMode };
	const newBlocks = ["text", "image", "video", "pdf"];

	return <div className="coursePlayer">
		{/* <div onClick={e => setEditMode(m => !m)} className={editMode ? "courseEditButtonON" : "courseEditButtonOFF"}>Edit Course</div> */}
		<div className="lessonHeader">Lessons</div>
		<div className="lessonList courseList">
			{course.lessons.map((l, idx, arr) => {
				const className = "courseListItem" + ((idx === lesson) ? " courseListItemSelected" : "");
				return <div style={{display:"flex", alignItems:"center"}}>
					<div className={className} {...dragHandlers} data-kind="sc_lesson" data-drag_index={idx} onClick={clickHandler}>{l.name}</div>
					{idx < arr.length - 1 && <div className="courseListSep">&gt;</div>}
					</div>
			})}
			{editMode && <div className="courseListItem courseListPlus" onClick={addLesson}>+</div>}
		</div>
		{course.lessons[lesson] &&
			<div className="lessonHeader">
				{course.lessons[lesson].name}
				{editMode && <div onClick={editLesson} className="courseEditButton fa fa-edit"></div>}
			</div>

		}

		<div className="pageList courseList">
			{course.lessons[lesson] && course.lessons[lesson].pages.map((p, idx,arr) => {
				const className = "courseListItem" + ((idx === page) ? " courseListItemSelected" : "");
				return <div style={{display:"flex", alignItems:"center"}}>
					<div className={className} {...dragHandlers} data-kind="sc_chapter" data-drag_index={idx} onClick={clickHandler}>{p.name}</div>
					{idx < arr.length - 1 && <div className="courseListSep">&gt;</div>}
					</div>

			})}
			{editMode && <div className="courseListItem courseListPlus" onClick={addPage}>+</div>}
		</div>
		{pageContent &&
			<div className="pageContent">
				<div className="pageHeader">{pageContent.name}
					{editMode && <i onClick={editPage} className="courseEditButton fa fa-edit"></i>}
				</div>
				{pageContent.blocks.map((b,i) => {
					return <BlockElement key={b.id || "new_record"} index={i} page={pageContent} canEdit={editMode} onChange={(self) => {
						setPageContent({ ...pageContent });
					}} />
				})}
				{editMode &&
					<div className="courseNewBlockButtons">
						<div className="courseNewBlocksHeader">
							<div className="br"/>
							<div className="courseNewBlocksText">Add</div>
							<div className="br"/>
						</div>
						<div>
						{newBlocks.map(kind => {
							const label = kind[0].toUpperCase() + kind.substring(1);
							let image = "fa-plus";
							switch (kind) {
								case "text": image = "fa-font"; break;
								case "image": image = "fa-image"; break;
								case "video": image = "fa-video"; break;
								case "pdf": image = "fa-file-pdf"; break;
							}
							return <span className="courseNewBlockButton" onClick={addBlock} data-kind={kind}><i data-kind={kind} className={"fa " + image} />&nbsp;{label}</span>;
						})}
						</div>
					</div>}
				</div>
		}
	</div>
}
