import React, { useCallback, useContext, useMemo } from "react";
import { Fetch, FetchCondition, applyMacros, mergeFetch } from "shared/fetch";
import { PERMISSION_CREATE } from "shared/permissions";
import { ILookupValue, IMetaProperty } from "shared/schema";
import { checkPermissions, MetaContext, getGuiForm } from "../AppState";
import { BaseDropDown } from "../components/BaseDropDown";
import { ModalContext } from "../modal/Modal";
import { BaseObjectList } from "../objectList/BaseObjectList";
import { showObjectFormDialog } from "./ObjectForm";

export const LookupInput = (props: {
	value: ILookupValue,
	onChange: (newValue: any, optional?: any) => void,
	propMeta: IMetaProperty,
	disabled?: boolean,
	emptyText?: string,
	extraFetch?: Fetch,
	getRecordProperty?: (name: string) => any,
	canCreate?: boolean;
	canOpen?: boolean;
	focusOnClick?: boolean;
}) => {

	const valueToLink = useCallback((r: ILookupValue) => {
		if (props.focusOnClick) {
			const onLinkClick = (e: React.MouseEvent) => {
				((e.target as HTMLElement).parentElement!.getElementsByClassName("lookupInputText")[0] as HTMLElement).focus();
			}
			return <span style={{ flex: "3 0.2 100%" }} onClick={onLinkClick}>{r?.label || ""}</span>
		}
		if (r) {
			const label = r.label || "Unknown";
			const url = (props.canOpen !== false) ? ("/edit/" + r.name + "/" + r.id) : "";
			// if (r.name === "account") {
			// 	return { label: <span><i className="fa fa-building" style={{marginRight:"4px"}} />{label}</span>, url };
			// }
			return { label, url };
		}
		return undefined;
	}, [props.canOpen]);

	const key = useMemo(() => new Date().valueOf(), [props.propMeta]);

	return <BaseDropDown key={key} value={props.value} disabled={props.disabled}
		onChange={props.onChange}
		valueToLink={valueToLink}
		emptyText={props.emptyText}
		dropDownProps={{ propMeta: props.propMeta, getRecordProperty: props.getRecordProperty, canCreate: props.canCreate, extraFetch: props.extraFetch }}
		DropDownElement={LookupDropDown}
	/>
}

export const LookupDropDown = (props: {
	search: string,
	onClick: (value: any, optional?: any) => void,

	propMeta: IMetaProperty,
	extraFetch?: Fetch,
	getRecordProperty?: (name: string) => any,

	canCreate?: boolean;
}) => {

	const { search } = props;
	const objectName = props.propMeta.targets ? props.propMeta.targets[0] : "error";

	const query = useMemo(() => {
		const q: Fetch = {
			entity: {
				name: objectName,
				attributes: [{ attribute: "name" },{ attribute: "id" }],
				orders: [{ attribute: "name" }]
			}
		};
		if (search) {
			q.entity.filter = {
				conditions: [
					{ attribute: "name", operator: "like", value: search + "%" }
				]
			};
		}
		const propMeta = props.propMeta;
		if (propMeta && propMeta.options && props.getRecordProperty) {
			if (!q.entity.filter)
				q.entity.filter = { conditions: [] };
			const c = q.entity.filter.conditions as FetchCondition[];
			const extraFilter = propMeta.options;
			for (let i = 0; i < extraFilter.length; i += 2) {
				let v = props.getRecordProperty(extraFilter[i + 1]);
				if (v && v.id) // deconstruct lookup
					v = v.id;
				c.push({ attribute: extraFilter[i], operator: "eq", value: v });
			}
		}
		if (props.extraFetch && props.getRecordProperty) {
			const mf = JSON.parse(JSON.stringify(props.extraFetch)) as Fetch;
			mergeFetch(q, mf);
			if (mf.entity.attributes) {
				const newAttrs = mf.entity.attributes;
				let attrs = q.entity.attributes!;
				attrs = attrs.filter(x => !newAttrs.find(y => y.attribute === x.attribute));
				attrs.push(...mf.entity.attributes);
				q.entity.attributes = attrs;
			}
			applyMacros(q.entity, props.getRecordProperty);
		}
		
		return q;
	}, [props.propMeta, search, props.getRecordProperty, props.extraFetch]);

	const onClick = useCallback((o: any) => {
		if (o) {
			const ret = { id: o.id, name: objectName, label: o.name };
			props.onClick(ret, o);
		}
	}, [props.onClick]);
 
	const metadata = useContext(MetaContext);
	const meta = metadata.objects.find(x => x.logicalName === objectName)!;
	const modal = useContext(ModalContext);
	const quickCreateForm = getGuiForm(metadata, meta, "quick");
	const canCreate = useMemo(() => props.canCreate !== false && !!quickCreateForm && checkPermissions(metadata, undefined, objectName, PERMISSION_CREATE), [objectName]);

	const onNewClick = useCallback((e: React.MouseEvent) => {

		e.preventDefault();

		//const form = makeCreateOnlyForm(quickCreateForm);

		const newParams: any = {};
		const extraFilter = props.propMeta.options;
		if (extraFilter && props.getRecordProperty) {
			for (let i = 0; i < extraFilter.length; i += 2) {
				let v = props.getRecordProperty(extraFilter[i + 1]);
				// if (v && v.id) // deconstruct lookup
				// 	v = v.id;
				// fixme: if v is ID, construct the lookup
				newParams[extraFilter[i]] = v;
			}
		}
		if (search)
			newParams.name = search;
		const pp = new URLSearchParams({ init: JSON.stringify(newParams) });
		showObjectFormDialog(modal, metadata, meta.logicalName, "quick", pp.toString(), onClick);
		// const childWindow = window.open("/!/edit/" + objectName + "/" + "0");
		// if (childWindow) {
		// 	(childWindow as any)['onSaveCallback'] = onClick;
		// }
	}, [props.propMeta, search]);

	const listColumns = query.entity.attributes!.filter(x => x.attribute !== "id").map(x => ({ attribute: x.attribute }));

	return <>
	<BaseObjectList query={query} onClick={onClick} listColumns={listColumns} noHeader={true} disableLookupClick={true} />
		{canCreate && <div style={{ minHeight: "28px" }} />}
		{canCreate && <div style={{position:"absolute", bottom:"0px"}}><a href={"/!/edit/" + objectName + "/" + "0"} onClick={onNewClick} className="fa fa-plus" style={{display:"flex", textDecoration:"none", alignSelf:"flex-end", fontSize:"20px", margin:"4px", textAlign:"center", color:"green"}}></a></div>}
	</>
}
