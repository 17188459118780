import { formatDuration } from "../formatters";
import { useDerivedState } from "../hooks/useDerivedState";

export const DurationInput = (props: { className?: string, value: number, onChange: (value: number) => void }) => {
	
	const [value, setValue] = useDerivedState(() => formatDuration(props.value), [props.value]);

	const onTimeChange = () => {
		const pp = value.split(':');
		let v = 0;
		for (const p of pp) {
			v = v * 60;
			v += (+(p)) || 0;
		}
		props.onChange(v);
		setValue(formatDuration(v));
	}

	return <input className={props.className} type="text" value={value} onChange={e => setValue(e.target.value)}
		onBlur={onTimeChange} />
}
