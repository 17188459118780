import { Draggable, Map, Point } from "pigeon-maps";
import { useEffect, useRef, useState } from "react";
import { Loading } from "../components/Loading";
import { useSizeObserver } from "../hooks/useSizeObserver";


export interface ICivicAddress {
	street: string;
	city: string;
	zip: string;
	country: string;
	latitude: string;
	longitude: string;
	customLatitude: string;
	customLongitude: string;
}

const FormField = (props: { name?:string, label: string, value: any, onChange: (newValue: any) => void }) => {
	
	const id = props.name || props.label;
	
	return (<div className="formItem">
		<label className="formLabel" htmlFor={id}>{props.label}</label>
		<input id={id} key={id} type="text" value={props.value || ""} onChange={e => props.onChange(e.target.value)} />
	</div>);
}

export const AddressPicker = (props: {
	addr: ICivicAddress,
	onSave: (addr: ICivicAddress) => void,
	commandSite: { onCommand: (cmdName: string) => boolean, closeModal: () => void }
}) => {

	const [addr, setAddr] = useState({ ...props.addr, customLatitude: props.addr.latitude, customLongitude: props.addr.longitude, latitude: "", longitude: "" });
	const center = useRef(undefined as any as [number, number]);

	if (addr.latitude === addr.customLatitude && addr.longitude === addr.customLongitude) {
		addr.customLatitude = "";
		addr.customLongitude = "";
	}

	props.commandSite.onCommand = (cmdName) => {
		if (cmdName === "CmdSave") {
			const ret = { ...addr };
			ret.latitude = addr.customLatitude || addr.latitude;
			ret.longitude = addr.customLongitude || addr.longitude;
			props.onSave(ret);
		}
		return true;
	}

	const pos = addr.customLatitude && addr.customLongitude ?
		[addr.customLatitude, addr.customLongitude] :
		[addr.latitude, addr.longitude];

	const gps = (pos[0] && pos[1] ? [+pos[0], +pos[1]] : undefined) as [number, number];
	if (gps && !center.current) {
		center.current = gps;
	}

	const fetchGps = async (addr: ICivicAddress) => {
		let q = [addr.street, addr.city, addr.zip, addr.country].map(x => x || "").join("+");
		q = encodeURIComponent(q);
		const resp = await fetch("https://nominatim.openstreetmap.org/search.php?q=" + q + "&format=jsonv2");
		const json = await resp.json() as { category: string, type: string, lat: string, lon: string }[];
		if (json && json.length > 0) {
			return [json[0].lat, json[0].lon];
		}
		return undefined;
	}

	const [statusMsg, setStatusMsg] = useState("");
	const updateTimer = useRef(0);
	const updateAddr = (newObj: ICivicAddress, timeout = 3000) => {
		setAddr(newObj);
		if (updateTimer.current)
			window.clearTimeout(updateTimer.current);
		updateTimer.current = window.setTimeout(() => {
			if (newObj.street && newObj.city) {
				const exe = async () => {
					const newGps = await fetchGps(newObj);
					if (newGps) {
						center.current = undefined as any;
						setAddr(a => ({ ...a, latitude: newGps[0], longitude: newGps[1] }));
						setStatusMsg("");
					} else {
						setStatusMsg("Address not found");
					}
					updateTimer.current = 0;
				}
				exe();
			}
		}, timeout);
	}

	const bodyRef = useRef<HTMLDivElement>(null);
	const [mapHeight, setMapHeight] = useState(300);

	useSizeObserver(bodyRef, (e, r) => {
		setMapHeight(r.height);
	});

	const setCustomGps = (p: Point) => {
		setAddr(a => ({ ...a, customLatitude: p[0].toString(), customLongitude: p[1].toString() }));
	}
	const resetGps = () => {
		center.current = undefined as any;
		setAddr(a => ({ ...a, customLatitude: "", customLongitude: "" }));
	}

	useEffect(() => {
		if (addr.street && addr.city) {
			if (!addr.latitude || !addr.longitude) {
				// no gps, fetch now
				updateAddr(addr, 0);
			}
		}
	}, []);

	return <div className="formBody" ref={bodyRef}>
		<div className="formGrid" style={{gridTemplateColumns:"1fr", width:"230px", marginRight:"4px"}}>
			<FormField label="Street" value={addr.street} onChange={v => updateAddr({ ...addr, street: v })} />
			<FormField label="City" value={addr.city} onChange={v => updateAddr({ ...addr, city: v })} />
			<FormField label="ZIP" value={addr.zip} onChange={v => updateAddr({ ...addr, zip: v })} />
			<FormField label="Country" value={addr.country} onChange={v=>updateAddr({...addr, country:v})} />
			<FormField label="Latitude" value={addr.customLatitude || addr.latitude} onChange={v => updateAddr({ ...addr, customLatitude: v })} />
			<FormField label="Longitude" value={addr.customLongitude || addr.longitude} onChange={v => updateAddr({ ...addr, customLongitude: v })} />
			{addr.customLatitude && <button onClick={resetGps}>Use Address GPS</button>}
			{(updateTimer.current !== 0) && <Loading />}
			{(!updateTimer.current && statusMsg) && <div style={{color:"red"}}>{statusMsg}</div> }
		</div>
		
		<Map boxClassname="mapClass" height={mapHeight} center={center.current || [0,0]} defaultZoom={16} >
			{gps && <Draggable anchor={gps} onDragEnd={e=>setCustomGps(e)} offset={[50, 40]}>
				<div className="mapItemOverlay" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							<div className="mapItemText">Drag Me</div>
							<div className="arrow-down" />
						</div>
			</Draggable>}
		</Map>
			
	</div>;
}