import { useCallback, useContext, useRef, useState } from "react";
import { IForm, IFormContainerPanel, IFormFieldsPanel, IFormPanel, IHomeItem } from "../AppSchema";
import { loadGuiLists, MetaContext } from "../AppState";
import { Loading } from "../components/Loading";
import SimpleGrid from "../components/SimpleGrid";
import { PanelProps } from "../objectForm/panels/PanelProps";
import { IDictionary, IMetaObject, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { useDerivedState } from "../hooks/useDerivedState";
import { ConfigureFormField } from "./ConfigureFormField";
import { DropList } from "./DropList";
import { DragList } from "./DragList";

interface PanelPropsEx extends PanelProps {
	body: { home: IHomeItem[] };
}

const createHomePanel = (props: PanelPropsEx) => {

	if (!props.body) return <Loading />
	
	//const [_, render] = useState(false);

	return (<div className="formFieldsEditor">
	{/* <div>Properties</div> */}
	<HomeItemsList {...props} />
	<HomeItemAvailableList {...props}/>
</div>)
	//return <HomeEditor body={props.body} />
}

export const makeCustomizeHomeForm = (meta: IMetaObject) => {
	const form: IForm = {
		name: meta.logicalName,
		panel: {
			name: "main",
			type: "Split",
			panels: [
				{
					name: "fields",
					type: "Fields",
					fields: ["name", "appid"].map(x => ({ name: x })),
				} as IFormFieldsPanel,
				{
					name: "Home",
					type: "Custom",
					createMethod: createHomePanel
				} as IFormPanel
			]
		} as IFormContainerPanel,
		commands: [],
	};
	return form;
}


export const HomeEditor = (props: { body: { home: IHomeItem[] } }) => {

	const [items, changeItems] = useDerivedState(props.body.home, [props.body]);
	const setItems = useCallback((newItems: IHomeItem[]) => {
		props.body.home = newItems;
		changeItems(newItems);
	}, [props.body]);
	const metadata = useContext(MetaContext).objects;
	const [newObj, setNewObj] = useState<IHomeItem>({ kind: "entity", name: "", label: "", url: "" });
	const id = useRef<string>();

	const onChangeObject = (old: IHomeItem, newItem: IHomeItem) => {
		if (old === newObj) {
			setNewObj(newItem)
			return;
		}

		const home = items.map(x => x === old ? newItem : x);
		setItems(home);
	}
	const onAddObject = () => {
		const home = items.concat([{ ...newObj }]);
		setItems(home);
	}
	const onDeleteObject = (obj: IHomeItem) => {
		const home = items.filter(x => x !== obj);
		setItems(home);
	}
	const onMoveObject = (obj: IHomeItem, up: boolean) => {
	
		const home = items.slice();
		const index = home.indexOf(obj);
		if (index === 0 && up) return;
		if (index === home.length - 1 && !up) return;
		home.splice(index, 1);
		home.splice(index + (up ? -1 : 1), 0, obj);
		setItems(home);
	}

	return (
		<div className="columnFlex">
		  <SimpleGrid noEdit={false} className="customizeEntityList" fields={["kind", "name", "label", "url", "actions"]} rows={items} formatter={(x, fname) => {
				if (!x) {
					switch (fname) {
						case "actions":
							return (<button onClick={e => onAddObject()}>Add</button>);
					}
					x = newObj;
				}
				{
					switch (fname) {
						case "kind": return (<select value={x.kind} onChange={e => onChangeObject(x, { ...x, kind: e.target.value })}>
							<option value="entity">Entity</option>
							<option value="custom">Custom</option>
						</select>);
			
						case "actions":
							return (<div style={{display:"flex", flexDirection:"row"}}>
								<button style={{flex:"1 1 auto"}} onClick={e => onMoveObject(x,true)}>Move UP</button>
								<button style={{flex:"1 1 auto"}} onClick={e => onMoveObject(x, false)}>Move DOWN</button>
								<button style={{flex:"1 1 auto"}} onClick={e => onDeleteObject(x)}>Delete</button>
								</div>
							);
						case "name":
							if (x.kind === "entity")
								return (<select value={x.name} onChange={e => onChangeObject(x, { ...x, name: e.target.value })}>
									{metadata.map(x => (<option value={x.logicalName}>{x.displayName}</option>))}
							</select>);
							return (<input placeholder="Name" type="text" value={x.name} onChange={e => onChangeObject(x, { ...x, name: e.target.value })} />);
						case "url":
							return (<input placeholder="URL" type="text" value={x.url} onChange={e => onChangeObject(x, { ...x, url: e.target.value })} />);
						case "label":
							return (<input placeholder="Label" type="text" value={x.label} onChange={e => onChangeObject(x, { ...x, label: e.target.value })} />);
					}
				}
			return "";
		  }} />
	  </div>
	)
}


const HomeItemsList = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const metadata = useContext(MetaContext);

	if (!props.body || !props.body.home) return <Loading />

	if (!props.body.home) {
		props.body.home = [];
	}
	const cols = props.body.home;

	const metaDict = metadata.objects.reduce((acc, meta) => (acc[meta.logicalName] = meta, acc), {} as IDictionary<IMetaObject>);
	
	const getName = (x: IHomeItem) => (x.name);
	const getLabel = (item: IHomeItem) => {
		const name = item.name;
		let label = item.label;
		if (!label) {
			if (item.kind === "entity") {
				const m = metaDict[item.name];
				if (m && m.displayName)
					label = m.displayName;
			}
			else {
				label = "Custom Url";
			}
		}

		label = label || item.name;
		return { label: label, sub: name + " " +((item.kind === "entity") ? "entity" : item.url) }
	};
	const createItem = (x: string): IHomeItem => {
		if (x === "url") {
			return { name: "url", url: "https://inuko.net", kind: "custom" } as IHomeItem;
		}
		return { name: x, kind: "entity" } as IHomeItem
	}

	const configureItem = (item: IHomeItem) => {
		let attrs;
		if (item.kind === "entity") {
			const views = loadGuiLists(metadata, item.name, "Public", ["*"]);
			const initField = { logicalName: "initialView", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: views.map(x => x.name) } as IMetaProperty;
			const allowedField = { logicalName: "allowedViews", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet|MetaPropertyFlags.MultiSelect, options: views.map(x => x.name) } as IMetaProperty;	
			attrs = ["uniqueName", "label", "icon", initField, allowedField, "permissions"];
		} else {
			const kindField = { logicalName: "kind", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: ["custom", "separator"] } as IMetaProperty;
			attrs = [kindField, "label", "icon", "name", "url", "permissions"];
		}
		return <ConfigureFormField item={item} attrs={attrs} />
	}

	return (<DropList columns="1fr" configureItem={configureItem} fields={cols} getName={getName} getLabel={getLabel} createItem={createItem} />);
}
const HomeItemAvailableList = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const metadata = useContext(MetaContext);

	if (!props.body || !props.body.home) return <Loading/>

	const items = metadata.objects.filter(x => x.type !== "many");
	items.sort((a, b) => {
		const sa = a.displayName || a.logicalName;
		const sb = b.displayName || b.logicalName;
		return sa.localeCompare(sb); // todo case-less compare
	})

	const cols = items.map(({ logicalName, displayName }) => {
		return { logicalName: logicalName, displayName: displayName || logicalName };
	});
	cols.splice(0, 0, { logicalName: "url", displayName: "Custom Url" });

	return <DragList fields={cols} />
}