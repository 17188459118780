import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Fetch } from "shared/fetch";
import { ILookupValue, IMetaObject, IMetaProperty, MetaPropertyType } from "shared/schema";
import { ICommand, IFormContainerPanel, IFormField, IFormFieldsPanel, IFormPanel } from "../../AppSchema";
import { MetaContext, useGuiForm } from "../../AppState";
import { useSessionState } from "../../hooks/useSessionState";
import { DataService } from "../../service";
import { LookupInput } from "./../LookupInput";
import { Panel } from "../panels/Panel";
import { FieldProps, PanelProps, PanelPropsEventType } from "../panels/PanelProps";
import { runRenderScript } from "../ObjectForm";
import { ModalContext } from "../../modal/Modal";
import { useRender } from "../../hooks/useRender";
import { initializeRecord } from "../useFormState";

class SubPanelProps implements PanelProps {
	internalRecord: { id?: string, name?:string };
	parentProps: PanelProps;
	prevRecord: any = undefined;
	meta: IMetaObject;
	panel: IFormPanel;
	objectLabel: string = "";
	fieldProps: FieldProps;
	_onUpdate: () => void;
	id: string;
	isDirty: boolean;
	events: ((eventName: PanelPropsEventType) => Promise<boolean>)[]; 
	session = { version: 1 };
	constructor(parent: PanelProps, meta: IMetaObject, panel: IFormPanel, onUpdate: ()=>void) {
		this.parentProps = parent;
		this.meta = meta;
		this.panel = panel;
		this.fieldProps = {};
		this.internalRecord = {};
		this._onUpdate = onUpdate;
		this.id = "";
		this.events = [];
		this.isDirty = false;
	}
	getRecord = () => this.internalRecord;
	setRecord = (r: any, c?: any, notDirty?: boolean) => {
		this.id = r.id;
		this.internalRecord = r;
		this._onUpdate();
		
		if (!notDirty) {
			this.isDirty = true;
			// set parent dirty
			const parent = this.parentProps;
			parent.setRecord({ ...parent.getRecord() });
		}
	}
	getService = (serviceId: string) => undefined;
	loading = true;
}

export const SubFormField = (props: PanelProps & { field: IFormField }) => {

	const lookupField = props.field.name;
	const fieldConfig = (props.field.customComponent as string).split(';');
	const gui = useContext(MetaContext);
	const modal = useContext(ModalContext);
	const propMeta = props.meta.properties.find(x => x.logicalName === lookupField) as IMetaProperty;

	const render = useRender();
	const lookupMeta = gui.objects.find(x => x.logicalName === (propMeta.targets as string[])[0]) as IMetaObject;
	let form = useGuiForm(lookupMeta, "quick");
	if (!form) {
		form = {
			"commands": [],
			"name": "quick",
			"panel": {
				"type": "Fields",
				"name": "Fields",
				"fields": [{name:"name","type":"Field"}]
			} as IFormFieldsPanel
		}
	};
	const extraFilter = propMeta.options;
	// let extraFilter: string[] = [];
	// if (lookupMeta.logicalName === "contact" && props.meta.logicalName === "account") {
	// 	extraFilter = ["parentcustomerid", props.getRecord()["id"]];
	// }

	const subPanelProps = useMemo(() => {
		let panel = form.panel;
		const findFieldsPanel = (panel: IFormPanel): IFormPanel | null => {
			if (panel.type === "Fields")
				return panel;
			const cont = panel as IFormContainerPanel;
			if (cont.panels) {
				for (const child of cont.panels) {
					const found = findFieldsPanel(child);
					if (found)
						return found;
				}
			}
			return null;
		}
		panel = findFieldsPanel(panel) || panel;
		return new SubPanelProps(props, lookupMeta, panel, render);
	}, [props.meta.logicalName]);

	const lookupId = (props.getRecord() || {})[lookupField];
	const isParentLoading = !(props.getRecord() || {}).id && props.id !== "0";

	useEffect(() => {
		if (isParentLoading)
			return;
		
		const eventHandler = async (eventName: PanelPropsEventType) => {

			for (const eh of subPanelProps.events) {
				if (! await eh(eventName))
					return false;
			}

			const subRec = subPanelProps.internalRecord;
			if (eventName === "beforeSave" && subRec.name && subPanelProps.isDirty) {
				try {
					const id = await DataService.updateRecord(subPanelProps.meta.logicalName, subRec);
					if (id) { // Created?
						const parentRec = props.getRecord();
						parentRec[lookupField] = { id: id, name: subPanelProps.meta.logicalName, label: subRec.name };
					}
					subPanelProps.isDirty = false;
				}
				catch (e) {
					alert(e);
					return false;
				}
			}
			return true;
		}
		let cancel = false;
		const exe = async () => {
			subPanelProps.loading = true;
			const record = props.getRecord();
			if (record && record[lookupField]) {
				try {
					const r = await DataService.retrieveSingleById(subPanelProps.meta, record[lookupField].id);
					if (!cancel) {
						subPanelProps.setRecord(r, undefined, true);
					}
				}
				catch (e) {
					alert((e as Error).message);
				}
			} else if (record && !record.id && props.id !== "0") {
				// parent not loaded...
				return;
			} else if (record) {
				// todo: replace the bottom call too!
				const newRec: any = initializeRecord(gui, undefined, subPanelProps.meta);
				if (extraFilter) {
					for (let i = 0; i < extraFilter.length; i += 2) {
						const p = extraFilter[i];
						let v: any = record[extraFilter[i + 1]];
						if (v && !v.id) {
							const pm = subPanelProps.meta.properties.find(x => x.logicalName === p)
							if (pm && pm.type === MetaPropertyType.Lookup) {
								v = { name: (pm.targets as string[])[0], id: v };
							}
						}
						newRec[p] = v;
					}
				}
				subPanelProps.setRecord(newRec, undefined, true);
			}
			subPanelProps.loading = false;
		}
		exe();
		const events = subPanelProps.parentProps.events || [];
		events.push(eventHandler);
		const unregister = () => events.splice(events.indexOf(eventHandler), 1);
		return () => {
			cancel = true;
			unregister();
		};
	}, [subPanelProps, lookupId, isParentLoading]);

	const newLookup = subPanelProps.internalRecord.name ? { id: undefined, name: lookupMeta.logicalName, label: "* " + subPanelProps.internalRecord.name } : undefined;

	const [visible, setVisible] = useSessionState("!SubFormField." + props.meta.logicalName + "." + lookupField, true, []);

	const onRenderScript = useMemo(() => ({ kind: "custom", name: subPanelProps.meta.logicalName + ".change" } as ICommand), [subPanelProps]);

	if (!subPanelProps.loading) {
		runRenderScript(onRenderScript, gui, modal, subPanelProps);
	}

	const showField = fieldConfig.indexOf("hiddenLookup") < 0;
	const fieldClass = "subFormField" + (showField ? "" : " hiddenLookup");

	return <div className={fieldClass}>
		<div className="subFieldLookup" style={{ marginLeft: "20px", position:"relative" }}>
			<div style={{ position: "absolute", width: "15px", height: "15px", top: "3px", left: "-15px", border: "1px solid black", borderWidth: "0 0 0px 0px" }} >
				<i className={"fa fa-caret-up"} style={{ color: "var(--fore-color)", fontSize: "14px", transition: "all 0.2s", transform: visible ? "" : "rotateZ(180deg)" }} onClick={e=>setVisible(x=>!x)} />
				</div>
		<LookupInput value={lookupId || newLookup} onChange={v => {
			props.setRecord({ ...props.getRecord(), [lookupField]: v });
			if (!v) {
				subPanelProps.setRecord({});
			}
			}
		}
			getRecordProperty={(name:string)=>props.getRecord()[name]}
			propMeta={propMeta}
			emptyText={"Click to select"} /> 
		</div>
		<AnimatedDiv visible={visible||!showField}>
			<Panel context={subPanelProps} panel={subPanelProps.panel} />
		</AnimatedDiv>
		{/* <div tabIndex={visible ? undefined : -1} style={{transition:"all 0.2s", maxHeight:visible?"1000px":"0px", opacity:visible?1:0, pointerEvents:visible?"auto":"none"}}> <Panel {...subPanelProps} /></div>
		 */}
	</div>
}

const AnimatedDiv = (props: { visible: boolean, children: any }) => {
	const visible = props.visible;
	const divRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const div = divRef.current;
		if (div) {
			if (visible !== (div.style.display !== "none")) {
				if (!visible)
					setTimeout(() => div.style.display = "none", 200);
				else
					div.style.display = "block";
			}
		}
	}, [visible]);

	return <div ref={divRef} style={{transition:"all 0.2s", maxHeight:visible?"2000px":"0px", opacity:visible?1:0}}>
		{props.children}
	</div>
}


const f: Fetch = {
	entity: {
		allattrs: true,
		name: "pricelistitem",
		filter: {
			conditions: [
				{ attribute: "productid", operator: "eq", value: "XXXX" },
			]
		},
		links: [
			{
				name: "pricelist",
				from: "pricelistid",
				to: "id",
				alias: "PL",
				links: [
					{
						name: "workorder",
						from: "pricelistid",
						to: "id",
						alias: "WO",
						filter: {
							conditions: [{attribute:"id", operator:"eq", value:"YYY"}]
						}
					}
				]
			}
		]
	}
}