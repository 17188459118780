import { ILookupValue, ICurrency, IMetaProperty, MetaPropertyFlags } from "shared/schema";
import { IAppConfig } from "./AppSchema";

export const formatNumericValue = (precision: any, value: any, locale?: string) => {
	let str = value === undefined || value === null ? "" : value as string;
	const decimalPlaces = +(precision || "0");
	if (str !== "") {
		locale = locale || Intl.NumberFormat().resolvedOptions().locale;
		const fmt = new Intl.NumberFormat(locale, {
			style: "decimal",
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces
		});
		str = fmt.format(parseFloat(str));
	}
	return str;
}

export const formatNumericWith = (value: any, fmtString: string, locale?: string) => {
	const re = /(\{\d+\})/.exec(fmtString);
	if (re) {
		const decimalPlaces = +re[0];
		const numericValue = formatNumericValue(decimalPlaces, value, locale);
		return fmtString.substring(0, re.index) + numericValue + fmtString.substring(re.index + re[0].length);
	}
	return "";
}

export const formatDuration = (duration: number) => {
	let timeValue = "";
	const value = duration;
	if (value) {
		const num = (n: number) => { return n < 10 ? ("0" + n) : "" + n };
		let v = +value;
		timeValue = "";
		while (v > 0) {
			const rem = num(v % 60);
			v = (v / 60) | 0;
			if (timeValue.length > 0)
				timeValue = rem + ":" + timeValue;
			else
				timeValue = rem;
		}
	}
	return timeValue;
}

export const formatAge = (d: Date) => {
	const now = new Date();
	let yd = now.getFullYear() - d.getFullYear();
	let md = now.getMonth() - d.getMonth();
	const dd = now.getDate() - d.getDate();

	if (md === 0 && dd < 0)
		md--;
	if (md < 0)
		yd--;
	
	if (yd < 2)
		md = yd * 12 + (md < 0 ? (11 + md) : md);
	
	try {
		return new Intl.NumberFormat(undefined, {
			style: "unit", unitDisplay: "long",
			unit: yd >= 2 ? "year" : "month",
		}).format(yd >= 2 ? yd : md);
	} catch { }
	
 	let year = "years";
	let months = "months"
	if (yd >= 2)
		return yd + " " + year;
	else
		return md + " " + months;
}

const defaultCurrency: ICurrency = { id: "", name: "Euro", code: "EUR", symbol: "€", precision: 2, mode: 0, exchangerate: 1, isdefault: 1 };

export const getRecordCurrency = (metadata: IAppConfig, record: any) => {
	const cid = record["currencyid"] as ILookupValue;
	const currency: ICurrency = metadata.currencyDict[cid?.id || ""] || defaultCurrency;
	return currency;
}

export const formatMoneyValue = (metadata: IAppConfig, record: any, value: any) => {
	const currency = getRecordCurrency(metadata, record);
	const str = formatNumericValue(currency.precision, value);
	if (str === "")
		return str;
	switch (currency.mode || 0) {
		case 1: return str + " " + currency.symbol;
		case 2: return currency.code + " " + str;
		case 3: return str + " " +currency.code;
		default:
			return currency.symbol + " " + str;
	}
}
export const formatMoneyValueWithCurrency = (currency: ICurrency | undefined, value: any, locale?: string) => {
	currency = currency || defaultCurrency;
	const str = formatNumericValue(currency.precision, value, locale);
	if (str === "")
		return str;
	const fmt = currency.formatstring
	if (fmt) {
		return fmt.replace("CODE", currency.code).replace("SYM", currency.symbol).replace("VAL", str);
	}
	switch (currency.mode || 0) {
		case 1: return str + " " + currency.symbol;
		case 2: return currency.code + " " + str;
		case 3: return str + currency.code;
		default:
			return currency.symbol + " " + str;
	}
}

export const propertyValueAsLink = (x: IMetaProperty, url: string) => {
	const flags = x.flags;
	if ((flags & (MetaPropertyFlags.Url | MetaPropertyFlags.Email | MetaPropertyFlags.Phone)) !== 0) {
		let prefix = "";
		if (flags & MetaPropertyFlags.Email) {
			prefix = "mailto:";
			//icon = "fa-at";
		}
		if (x.flags & MetaPropertyFlags.Phone) {
			prefix = "tel:";
			//icon = "fa-phone";
		}
		if (url) {
			if (!prefix && !url.startsWith("http"))
				prefix = "http://"
			url = prefix + url;
		}
		else {
			url = "#";
		}
		return url;
	}
	return undefined;
}