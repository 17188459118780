import { useContext, useMemo, useState } from "react";
import { IMetaObject } from "shared/schema";
import { MetaContext } from "../AppState";
import { createReactComponentFromScript } from "./runCommand";
import { DataService } from "../service";
import { newGuid } from "../utils/guid";
import { ModalContext } from "../modal/Modal";

export interface ICustomComponentProps {
	customComponent?: string;
	customComponentParamters?: string;

	componentProps?: any;

	fallback?: (props: ICustomComponentProps) => any;
}

export const ScriptComponent = (props: ICustomComponentProps) => {
	const metadata = useContext(MetaContext);
	const modal = useContext(ModalContext);

	const cc = props.customComponent!;
	
	const [Component, context] = useMemo(() => {
		const pp = props.componentProps as any;
		const context = {
			executeMultiple: DataService.executeMultiple,
			retrieveMultiple: DataService.retrieveMultiple,
			retrieveSingleById: (logicalName: string, id: string) => DataService.retrieveSingleById(metadata.objects.find(x => x.logicalName === logicalName) as IMetaObject, id),
			newId: () => newGuid(),
			userId: { id: metadata.user.id, name: "systemuser", label: metadata.user.name },
			metadata: metadata,
			modal: modal,
			// salesai and maybe others use these on the context...
			getRecord: pp?.getRecord,
			setRecord: pp?.setRecord,
		}
		return [createReactComponentFromScript(metadata, context, cc), context];
	}, [cc]);

	if (Component)
		return <Component context={context} {...props.componentProps} />
	
	if (props.fallback) {
		return props.fallback(props);
	}
	return <div>Component not found: {cc || "unknown"}</div>;
}