import React, { useMemo, useCallback, useContext, useState, ReactNode, useEffect } from "react";
import { IAssociatedListPanel, IFormContainerPanel, IFormWizardPanel, VirtualField } from "../../AppSchema";
import { Tabbar } from "../../components/Tabbar";
import { useSessionState } from "../../hooks/useSessionState";
import { IPanelProps } from "./PanelProps";
import { Panel } from "./Panel";
import { CustomizeContext } from "../customize/customizeContext";
import { useDragProps } from "../../components/draggable";
import { configurePanel } from "../Configure";
import { ModalContext } from "../../modal/Modal";
import { MetaContext, tryLocalize } from "../../AppState";
import { Fetch } from "shared/fetch";
import { DataService } from "../../service";
import { useDerivedState } from "../../hooks/useDerivedState";
import { TabbarWizard } from "../../components/TabbarWizard";

const makeAssociatedListLabel = async (tab: IAssociatedListPanel, id: string) => {
	
	if (!(id && id !== "0" && tab.field !== VirtualField)) {
		return undefined;
	}

	let [field, intersectEntity, lookup2] = tab.field.split(".");
	const alias = "g__" + intersectEntity;
	
	const q: Fetch = {
		entity: {
			name: tab.name,
			attributes: [{ attribute: "id", aggregate: "count", alias: "x" }],
		}
	}
	let fe = q.entity;

	if (intersectEntity) {
		q.entity.links = [{
			name: intersectEntity,
			from: "id",
			to: lookup2,
			alias: alias,
			type: "inner",
		}];
		fe = q.entity.links[0];
	}
	fe.filter = {
		conditions: [{ attribute: field, operator: "eq", value: id }]
	}
	try {
		//console.log("LOAD TAB COUNT:" + tab.name);
		const r = await DataService.retrieveMultiple(q) as { x: number }[];
		if (r && r.length > 0)
			return (" [" + r[0].x + "]");
	} catch (e) {
		console.log("Can't calculate count:" + e);
	}
}

export const TabsPanel = (props: IPanelProps) => {
	const panel = props.panel as IFormContainerPanel;
	const customize = useContext(CustomizeContext);
	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);
	const [render, setRender] = useState(0);
	const forceRender = () => setRender(x => x + 1);

	const panels = useMemo(() => {
		const runtimeTabProps = props.context.tabProps;
		let newPanels = panel.panels;
		if (customize.enabled || !runtimeTabProps)
			return newPanels;

		if (runtimeTabProps) {
			newPanels = newPanels.map(x => ({ ...x, ...runtimeTabProps[x.name] }))
		}
		return newPanels.filter(x => x.visible !== false);
	}, [customize.enabled, panel, panel.panels, props.context.tabProps]);
	
	const logicalName = props.context.meta.logicalName;
	const [initIndex, setInitIndex] = useSessionState(panel.name + "_" + logicalName + "_TabIndex", 0, [logicalName]);

	const [counts, setCounts] = useState({} as { [ke: string]: string });

	let tabs = useMemo(() => {
		return panels.map((x, i) => {
			if (x.type === "AssociatedList" && !x.label) {
				const baseName = counts[x.name] || tryLocalize(metadata, "@" + x.name + "+s", x.name);
				return baseName;
			} else if (x.type === "Fields" && !x.label && x.name === "fields") {
				return props.context.meta.displayName!;
			}
			return tryLocalize(metadata, x.label || x.name, x.name);
		});
	}, [panels, render, counts]);

	useEffect(() => {
		const calculateCounts = async () => {
			const c: { [key: string]: string } = {};
			let changed = false;
			for (let i = 0; i < panels.length; i++) {
				const x = panels[i];
				if (x.type === "AssociatedList" && !x.label) {
					const countLabel = await makeAssociatedListLabel(x as IAssociatedListPanel, props.context.id);
					if (countLabel) {
						const baseName = tryLocalize(metadata, "@" + x.name + "+s", x.name);
						c[x.name] = baseName + countLabel;
						changed = true;
					}
				}
			}
			if (changed) {
				setCounts(c)
			}
		};
		calculateCounts();
	}, [panels]);

	const renderChild = useCallback((i: number) => {
		if (i >= panels.length) {
			return undefined; // customize add field
		}
		const tab = panels[i];
		return <Panel context={props.context} panel={tab} />
	}, [render, props.context, panels]);

	// in customize mode we don't override tab runtime properties
	const customizeMovePanel = useCallback((dragIndex: number, dropIndex: number) => {
		const selected = panel.panels[initIndex];
		const field = panel.panels.splice(dragIndex, 1)[0];
		panel.panels.splice(dropIndex, 0, field);
		setInitIndex(panel.panels.indexOf(selected));
		forceRender();
	}, [panel.panels, initIndex]);

	const dragEvents = useDragProps(true, customizeMovePanel);

	const configureClick = (e: React.MouseEvent) => {
		configurePanel(modal, metadata, props.context.meta, panel, e, forceRender);
	}

	let renderTabItem: (name: string, index: number, arr: string[]) => ReactNode = undefined as any;
	if (customize.enabled) {
		tabs = tabs.concat(["[New Tab]"]);

		renderTabItem = (x, y, selfArr) => {
			let de = dragEvents;
			if (y === selfArr.length - 1) {
				de = { ...de, onDragStart: undefined as any };
				return <div style={{fontSize:"20px", height:"1em", padding:"0 1em"}} className="formItemCustomizeAdd" {...de} data-kind="objectfield" data-drag_index={y} onClick={configureClick}><i className="fa fa-plus"></i></div>
			}
			return <div {...de} data-kind="tabpanel" data-drag_index={y}>
				{x}
				<span className="formItemCustomize" onClick={configureClick}><i className="fa fa-gears" /></span>
			</div>
		};
	}

	if (tabs.length === 0) return (<></>);
	let TabbarElement = Tabbar;
	let otherProps: any = {}

	const isWizard = panel.customComponentParameters === "Wizard";
	if (isWizard) {
		const panel = props.panel as IFormWizardPanel;
		TabbarElement = TabbarWizard;
		otherProps.onNextPage = async (index: number) => {
			const service = props.context.getService("executeCommand");
			
			if (panel.wizardNextCommand && index > initIndex) {
				const cmd = panel.wizardNextCommand + "." + index;
				service.executeCommand(cmd);
			}

			const r = props.context.getRecord();
			props.context.setRecord({ ...r, process_stage_index: index });
			await service.executeCommand("CmdSave");
			setInitIndex(index);
		}
		otherProps.progress = props.context.getRecord()["process_stage_index"] || 0;
	}

	return (
		<TabbarElement tabs={tabs}
			className="TabsPanel"
			initIndex={initIndex}
			// header={panel.name}
			onSelectedChanged={setInitIndex}
			renderTabItem={renderTabItem}
			renderChild={renderChild}
			{...otherProps}
		/>)
}
