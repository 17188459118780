import { useCallback, useRef, useState } from "react";
import { useRender } from "./useRender";

export const useDerivedState = <T extends unknown>(initial: (T | (()=> T)), monitor: any[]): [T, (v: T | ((prev: T) => T)) => void] => {
	const r = useRef(monitor);
	const valueRef = useRef<T>(undefined as T);
	const forceRender = useRender();
	// console.log("Monitor:" + r.current[0] + "->" + monitor[0]);
	// console.log("initial:" + (initial as any)["name"]);
	// console.log("valueRef:" + (valueRef.current as any)["name"]);
	// different?
	if (valueRef.current === undefined || !r.current.every((x, i) => Object.is(x, monitor[i]))) {

		// if (valueRef.current !== undefined) {
		// 	for (let i = 0; i < monitor.length; i++) {
		// 		if (!Object.is(monitor[i], r.current[i])) {
		// 			console.log("deriveState changed arg:" + i);
		// 		}
		// 	}
		// }

		if (typeof initial === "function")
			valueRef.current = (initial as (() => T))();
		else
			valueRef.current = initial as T;
		r.current = monitor;
		// console.log("monitor changed");
	}
	const setValue = useCallback((value: T | ((prev: T) => T)) => {
		let newValue: any;
		if (typeof value === "function")
			newValue = (value as ((prev: T) => T))(valueRef.current);
		else
			newValue = value as T;
		if (!Object.is(valueRef.current, newValue)) {
			valueRef.current = newValue;
			forceRender();
		}
	}, [valueRef]);
	return [valueRef.current, setValue];
}