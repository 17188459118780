import { useCallback, useContext, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { IMetaObject, MetaPropertyFlags } from "shared/schema";
import { IForm, IFormContainerPanel, IFormFieldsPanel, IFormPanel, IList } from "../AppSchema";
import { MetaContext } from "../AppState";
import { Loading } from "../components/Loading";
import { PanelProps } from "../objectForm/panels/PanelProps";
// import CodeEditor from '@uiw/react-textarea-code-editor';

interface PanelPropsEx extends PanelProps {
	body: string;
}

const createScriptPanel = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const objectName = record.objectname as string;

	if (props.id !== "0" && !record["id"]) return <Loading />;

	if (!objectName)
		return <div>Select entity first.</div>
	
	const onChange = (e: any) => {
		props.setRecord({ ...record, body: e.target.value });
	}

	document.documentElement.setAttribute('data-color-mode', 'light')

	return (<div className="codeEditorDiv formBody" >
		 {/* <CodeEditor
        value={record.body}
        language="js"
        placeholder="Please enter JS code."
        onChange={onChange}
        padding={15}
        style={{
          fontSize: 12,
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace"
        }}
      /> */}
		 <textarea className="codeEditor formItem" style={{height:"100%", resize:"none"}} value={record.body} onChange={onChange} />
		</div>)
}

export const makeCustomizeScriptForm = (meta: IMetaObject) => {
	const form: IForm = {
		name: meta.logicalName,
		panel: {
			name: "main",
			type: "Split",
			gridColumns: "minmax(300px, 1fr) 5fr",
			panels: [
				{
					name: "fields",
					type: "Fields",
					fields: ["name", "objectname", "appid"].map(x => ({ name: x })),
				} as IFormFieldsPanel,
				{
					name: "Script",
					type: "Custom",
					createMethod:createScriptPanel
				} as IFormPanel
			]
		} as IFormContainerPanel,
		commands: [],
	};
	return form;
}
