import { useMemo, useCallback } from "react";
import { IMetaProperty, MetaPropertyType } from "shared/schema";
import { BaseDropDown } from "./BaseDropDown";
import SimpleGrid from "./SimpleGrid";

export const MultiSelectInputEx = (props: {
	value: any,
	onChange: (newValue: any) => void,
	propMeta: IMetaProperty,
	disabled?: boolean,
	getRecordProperty?: (name: string) => any,
	mode?: DropDownValueType;
}) => {

	const { propMeta, value } = props;
	const mode = props.mode || (propMeta.type === MetaPropertyType.Integer ? "bitmap" : "array");

	const selected = useMemo((): any[] => {
		if (mode === "array")
			return value || [];
		if (mode === "csv")
			return value ? value.split(',') : [];
		if (mode === "bitmap") {
			let r = [] as any[];
			for (let i = 0; i < 32; i++) {
				if ((value & (1 << i)) !== 0)
					r.push(i);
			}
			return r;
		}
		return []
	}, [value, mode]);

	const rows = useMemo(() => {
		// todo add value if missing...
		const isInt = propMeta.type === MetaPropertyType.Integer;
		const rows = (propMeta.options || []).map((x, i) => ({ label: x, name: isInt ? i : x, selected: false }));
		return rows;
	}, [propMeta]);

	const toggleSelected = (name: any, isSelected: boolean) => {
		const wasSelected = selected.indexOf(name) >= 0;
		let newSelected = selected;
		let value: any;
		if (wasSelected !== isSelected) {
			if (isSelected)
				newSelected = newSelected.concat([name]);
			else
				newSelected = newSelected.filter(x => x !== name);
		}
		if (mode === "array") {
			value = newSelected;
		} else if (mode === "csv") {
			value = newSelected.join(',');
		} else if (mode === "bitmap") {
			let r = 0;
			for (let o of newSelected)
				r = r | (1 << (+o));
			value = r;
		}
		props.onChange(value);
	}

	const getValueLabel = (name: any) => {
		let label = name;
		if (propMeta.type === MetaPropertyType.Integer && propMeta.options) {
			label = propMeta.options[+name] || name;
		}
		// fixme... rich options.
		return label;
	}

	const formatter = (row: any, fieldName: string, style: any) => {
		
		const isSelected = selected.indexOf(row.name) >= 0;
		const icon = <i className={"fa " + (isSelected ? "fa-circle-check" : "fa-regular fa-circle")}
			style={{ color: isSelected ? "blue" : "var(--fore-color)" }} />;

		const label = getValueLabel(row.name);		
		return <a href="#" style={{textDecoration:"none", padding:"2px", color:"var(--fore-color)", display:"block", width:"100%", height:"100%"}} onClick={e => { e.preventDefault(); toggleSelected(row.name, !isSelected); }}>{icon} {label}</a>;
	};
	
	const valueToLink = useCallback(() => {
		if (selected && selected.length) {
			const label = selected.map(x => getValueLabel(x)).join(" ");
			const url = "";
			return { label, url };
		}
		return undefined;
	}, [selected]);
	
	const key = useMemo(() => new Date().valueOf(), [props.propMeta]);
	
	return <BaseDropDown key={key} value={"1"}
		onChange={()=>{}}
		valueToLink={valueToLink}
		dropDownProps={{ rows: rows, formatter: formatter }}
		DropDownElement={MultiSelectInputDropDown}
	/>
}

type DropDownValueType = "csv" | "array" | "bitmap";

export const MultiSelectInputDropDown = (props: {
	search: string,
	onClick: (value: any) => void,

	rows: any[],
	formatter: (row: any, fname: string, style: any) => React.ReactNode;

	labelField?: string;
	isLoading?: boolean;
}) => {

	const { rows, formatter, search } = props;
	const labelField = props.labelField || "label";

	const s = search ? search.toLocaleLowerCase() : undefined;
	const r = rows.filter(r => !s || (r[labelField] as string).toLocaleLowerCase().indexOf(search) >= 0);

	return <SimpleGrid noHeader={true} fields={[labelField]} rows={r} formatter={formatter} noEdit={true} isLoading={props.isLoading} />
}