import { useContext, useCallback, useMemo, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { IForm, IFormFieldsPanel, IViewRecord, IList, IFormContainerPanel } from "../AppSchema";
import { MetaContext } from "../AppState";
import { BaseObjectForm } from "../objectForm/ObjectForm";
import { PanelProps } from "../objectForm/panels/PanelProps";
import { makeCustomizeListForm } from "./CustomizeList";
import { makeCustomizeScriptForm } from "./CustomizeScript";
import { makeCustomizeHomeForm } from "./CustomizeHome";
import { IMetaObject, MetaPropertyFlags } from "shared/schema";
import { makeCustomizeFormForm } from "./CustomizeForm";
import { download } from "../services/download";

interface PanelPropsEx extends PanelProps {
	body: any;
	isLoaded: boolean;
}

const makeInitialObjectForm = (objectName: string) => {
	return {
		name: objectName,
		commands: [{name: "CmdDelete"}, {name:"CmdSave"}],
		panel: {
			name: "main",
			type: "Split",
			panels: [
				{
					name: "fields",
					type: "Fields",
					fields: ["name"].map(x => ({ name: x })),
				} as IFormFieldsPanel,
				{
					name: "Associated",
					type: "Tabs",
					panels: [ 
					]
				} as IFormContainerPanel
			]
		} as IFormContainerPanel,
	};
}

export const useViewMeta = () => {
	const metadata = useContext(MetaContext);
	const meta = metadata.objects.find(x => x.logicalName === "inu_view") as IMetaObject;
	return useMemo(() => {
		const newMeta = { ...meta };
		newMeta.properties = newMeta.properties.map(x => {
			if (x.logicalName !== "objectname")
				return x;
			return {
				...x, flags: MetaPropertyFlags.OptionSet, options:
					metadata.objects.filter(x => x.type !== "many").map(x => x.logicalName)
			};
		});
		return newMeta;
	}, [meta]);
}


export const CustomizeViewObject = () => {
	const objectName = "inu_view";
	const { search } = useLocation();
	const id = useParams<string>()["id"] || "";

	const metadata = useContext(MetaContext);
	const meta = useViewMeta();
	if (!meta) throw Error("No Meta");

	const propsRef = useRef<PanelPropsEx>();

	const exportBody = (context: any) => {
		const body = propsRef.current?.body;
		if (body)
			download(body, meta.logicalName + "_" + context.record.name + ".json", "application/json");
	}

	const importBody = (context: any) => {
		const input = document.createElement("input");
		input.setAttribute("type", "file");
		input.setAttribute("accept", ".json");
		input.addEventListener("change", async () => {
			const props = propsRef.current;
			if (props && input.files && input.files[0]) {
				try {
					const text = await input.files[0].text();
					const json = JSON.parse(text);
					if (props.body.name)
						json.name = props.body.name;
					props.body = json;
					props.setRecord({ ...props.getRecord() });
				}
				catch (e) {
					alert("Error import file: " + e);
				}
			}
		});
		(input as any).showPicker();
	}

	const initialForm: IForm = {
		name: "customizeViewObject",
		commands: [
			{ name: "CmdExport", icon: "file-download", kind: "custom", compiledMethod: exportBody },
			{ name: "CmdImport", icon: "file-upload", kind: "custom", compiledMethod: importBody },
			{ name: "CmdClone" },
			{ name: "CmdDelete" },
			{ name: "CmdSave" }],
		panel: {
	
			name: "fields",
			type: "Fields",
			fields: ["kind", "name", "label", "objectname", "appid", "isprivate"].map(x => ({ name: x })),
		} as IFormFieldsPanel
	};
	const [form, setForm] = useState(initialForm);

	const onSave = useCallback((panelProps: PanelProps) => {
		const ext = panelProps as PanelPropsEx;
		const record = ext.getRecord();
		const v = (record as IViewRecord);
		if (!v.kind) {
			throw Error("Must choose a view object kind before save");
		}
		if (v.kind === "form" || v.kind === "view" || v.kind === "home") {
			record.body = JSON.stringify(ext.body);
		}
		metadata.needsRefresh = true;
	}, [id]);

	const onLoad = useCallback((panelProps: PanelProps) => {

		// loading = panel.id !== "0" && panel.getRecord().id === undefined
		const ext = panelProps as PanelPropsEx;
		propsRef.current = ext;
		const record = ext.getRecord();
		const v = (record as IViewRecord);
		let newForm: IForm|undefined = undefined;
		if (v.kind === "form") {	
			if (!ext.isLoaded) {
				newForm = makeCustomizeFormForm(ext.meta);
				ext.isLoaded = true;
			}
				
			let body = record.body;
			if (ext.body && ext.body.name !== record.objectname) {
				ext.body = undefined as any as IForm;
				body = undefined;
			}

			if (!ext.body && record.objectname) {
				ext.body = body ? JSON.parse(body) as IForm : makeInitialObjectForm(record.objectname);
			}
		}
		else if (v.kind === "view") {
			if (!ext.isLoaded) {
				newForm = makeCustomizeListForm(ext.meta);
				ext.isLoaded = true;
			}
			if (!ext.body) {
				const record = ext.getRecord();
				ext.body = (record.body ? JSON.parse(record.body) as IList : {
					name: objectName,
					columns: [],
					query: undefined as any,
					type: "Public"
				}) as any;
			}
		}
		else if (v.kind === "script" || v.kind === "dashboard" || v.kind === "chart") {
			if (!ext.isLoaded) {
				newForm = makeCustomizeScriptForm(ext.meta);
				ext.isLoaded = true;
			}
		}
		else if (v.kind === "home") {
			if (!ext.isLoaded) {
				newForm = makeCustomizeHomeForm(ext.meta);
				ext.isLoaded = true;
			}
			if (!ext.body) {
				const record = ext.getRecord();
				ext.body = (record.body ? JSON.parse(record.body) : { home: [] }) as any;
			}
		}
		if (newForm) {
			newForm.commands = initialForm.commands;
			setForm(newForm);
		}
	}, [id]);

	return <BaseObjectForm id={id} meta={meta} form={form} search={search} onLoad={onLoad} onSave={onSave}/>
}