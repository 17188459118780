import {useParams } from "react-router-dom";
import { IHomeItem, useGuiHome } from "../AppState";
import { Customizable } from "../objectForm/customize/customizeContext";
import { ObjectList } from "./ObjectList";

const PublicObjectList = (props: { homeItem?: IHomeItem }) => {
	let objectName = useParams<string>()["name"];
	const home = useGuiHome();

	let homeItem = props.homeItem;
	if (homeItem) {
		objectName = homeItem.name;
	} else {
		if (!objectName) throw Error("No Path");
		homeItem = home?.find(x => x.kind === "entity" && !x.uniqueName && x.name === objectName);
	}

	return <Customizable>
		<ObjectList key={objectName} objectName={objectName} initialView={homeItem?.initialView} allowedViews={homeItem?.allowedViews} />
	</Customizable>
}

export default PublicObjectList;