import { useRef, useCallback, useContext, useMemo, useEffect, useState } from "react";
import { IFormField } from "../../AppSchema";
import { MetaContext } from "../../AppState";
import { PanelProps } from "../panels/PanelProps";
import { FieldEditor } from "../panels/FieldsPanel";
import { Fetch, FetchCondition } from "shared/fetch";
import { DataService } from "../../service";


export const AggregateField = (props: PanelProps & { field: IFormField }) => {

	const metadata = useContext(MetaContext);
	const fieldConfig = (props.field.customComponent as string).split(';');
	const bodyField = props.field.name;
	const [lookupField, childEntity, targetField] = fieldConfig[1].split('.');
	const aggField = fieldConfig[2];
	const aggMethod = fieldConfig[3] || "sum";

	const extraConditions: FetchCondition[] = [];
	for (let i = 4; i < fieldConfig.length - 2; i+=3) {
		extraConditions.push({ attribute: fieldConfig[i], operator: fieldConfig[i + 1] as any, value: fieldConfig[i + 2] });
	}
	
	const childMeta = metadata.objects.find(x => x.logicalName === childEntity);
	//if (!childMeta) throw Error("Entity not found:" + childEntity);
	const aggMeta = childMeta?.properties.find(x => x.logicalName === aggField);
	//if (!aggMeta) throw Error("Field not found:" + childEntity + "." + aggField);

	const [value, setValue] = useState(undefined as any);

	useEffect(() => {
		if (props.id && props.id !== "0") {
			const q: Fetch = {
				entity: {
					name: childEntity,
					filter: {
						conditions: extraConditions.concat({ attribute: lookupField, operator: "eq", value: props.id }),
					},
					attributes: [{ attribute: aggField, aggregate: aggMethod as any, alias: "result" }]
				}
			}
			const exe = async () => {
				const results = await DataService.retrieveMultiple(q);
				if (results && results.length > 0 && results[0].result !== undefined)
					setValue(results[0].result);
			}
			exe();
		}
	}, [props.id]);

	if (!aggMeta) return <div>{"Field not found:" + childEntity + "." + aggField}</div>

	return FieldEditor(aggMeta, value, { enabled: false }, () => { });
}
