import { useCallback, useContext, useMemo, useState } from "react"
import { Fetch } from "shared/fetch"
import { ICommand } from "../AppSchema"
import { MetaContext } from "../AppState"
import { TitleBox } from "../components/TitleBox"
import { showFetchDialog } from "../customize/FetchDialog"
import { ModalContext } from "../modal/Modal"
import { BaseObjectList } from "../objectList/BaseObjectList"
import { exportFileFromList } from "./export"
import { PERMISSION_READ, getPermLevel } from "shared/permissions"


export const ExportComponent = (props: any) => {

	const makeSimpleFetch = (name: string): Fetch => {
		return { entity: { name: name, attributes: [{ attribute: "name" }, { attribute: "id" }], orders:[{attribute:"name"}] } }
	}

	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);

	const entities = useMemo(() => {
		return metadata.objects.filter(x =>
			x.type !== "many" &&
			getPermLevel(x.logicalName, metadata.permDict, PERMISSION_READ) > 0)
	}, []);

	const [q, setQuery] = useState<Fetch>(makeSimpleFetch(entities[0].logicalName));

	const changeEntity = useCallback((entity: string) => {
		setQuery(makeSimpleFetch(entity));
	}, [setQuery]);
	
	const onCommand = useCallback((cmd: ICommand) => {
		const cmdName = cmd.name;
		switch (cmdName) {
			case "CmdQuery":
				let f = JSON.parse(JSON.stringify(q));
				showFetchDialog(modal, f, () => setQuery(f));
				break;
			case "CmdExport":
				exportFileFromList(metadata, q, []);
				break;
		}
	}, [q, setQuery, modal]);

	const commands: ICommand[] = useMemo(() => {
		return [{ name: "CmdQuery", label: "Setup Query", icon:"filter" },
		{name:"CmdExport", label: "Export"}]
	},[]);

	return <div className="exportComponent objectListContainer">
		<TitleBox title="Export Records" commands={commands} onCommand={onCommand}>
			<div className="exportEntitySelect" style={{marginLeft:"1em"}}>
				<select onChange={e => changeEntity(e.target.value)}>
					{entities.map(x => {
						return <option key={x.logicalName} value={x.logicalName}>{x.displayName} [{x.logicalName}]</option>
					})}
				</select>
			</div>
		</TitleBox>
		{q && <BaseObjectList query={q} />}
	</div>
}
