import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "../components/Loading";
import { loginToServer } from "../services/fetchJson";
import { handleResponseAndNext, ILoginContext, LoginStateMachine } from "./LoginPanel";

export const MagicForm = (props: {}) => {
	const [q] = useSearchParams();
  
	const startContext = useMemo(() => ({
		url: "",
		organization: q.get("o"),
		email: q.get("u"),
		password: q.get("q"),
		reason: q.get("reason"),
		ret: q.get("ret"),
		next: (c: any) => { }
	}), [q]);
	
	return <div className='mainContainer'>
		<LoginStateMachine context={startContext as any} ChildElement={MagicFormInternal} />
	</div>
}


const MagicFormInternal = (props: { context: ILoginContext }) => {
	const navigate = useNavigate();

	const tryLogin = async (context: ILoginContext) => {
		const { organization, email, password } = context;
		if (organization && email && password) {

			await handleResponseAndNext(context, async (c) => {
				const resp = await loginToServer(organization, email, password, context.url);
				if (resp.ok) {
					navigate(context.ret || "/", { replace: true });
				}
				return resp;
			});
		} else {
			context.next({message: "ERR_MAGIC_BROKEN"}, context);
		}
	}

	const { context } = props
	useEffect(() => {
		if (context.reason === "USER_FORGOT" && context.password && context.email && context.organization) {
			context.next({ message: "ERR_MUST_CHANGE_PWD" }, context as any);
		} else {
			tryLogin(context as any);
		}
	}, []);

	return <Loading/>
}
