import React, { useCallback, useContext, useMemo } from "react";
import { IFormField } from "../../AppSchema"
import { makeRichOptionSpan } from "../../components/RichOptionSpan";
import { SelectInput } from "../../components/SelectInput";
import { ModalContext, showMenu } from "../../modal/Modal";
import { PanelProps } from "../panels/PanelProps"


export const StageField = (props: PanelProps & { field: IFormField }) => {

	const disabled = (props.field as any)?.enabled === false;
	const bodyField = props.field.name;
	const propMeta = props.meta.properties.find(x => x.logicalName === bodyField);
	if (!propMeta) throw Error("field not found");

	const { richOptions, layout } = useMemo(() => {

		let richOptions = propMeta.richOptions;
		let layout: string[][] | undefined = undefined;

		// [ Open > Scheduled > Done^] Done -> dropdown: completed/canceled
		const config = (props.field.customComponent as string).substring(10); // skip StageField
		if (config) {
			try {
				const p = JSON.parse(config);
				if (p.richOptions)
					richOptions = p.richOptions;
				if (p.layout)
					layout = p.layout;
			}
			catch (e) {
				console.log(e);
			}
		}

		if (!layout) {
			layout = richOptions?.map(x => ([x.value]));
		}
		return { richOptions, layout };
	}, [propMeta, props.field.customComponent]);

	const modal = useContext(ModalContext);

	const onButtonClick = useCallback(async (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		let button = e.target as HTMLElement;
		while (button && button.nodeName !== "BUTTON")
			button = button.parentElement as HTMLElement;
		const index = +((button && button.dataset["optionindex"]) || 0);
		const x = (layout as string[][])[index];
		let sel = x[0];
		if (x.length > 1) {
			const items = x.map(f => {
				const ro = richOptions?.find(z => z.value === f);
				return ro ? makeRichOptionSpan(ro) : f;
			})
			const i = await showMenu(modal, e.target as HTMLElement, items);
			sel = x[i];
		}
		let record = props.getRecord();
		props.setRecord({ ...record, [bodyField]: sel });
	}, [props.getRecord, props.setRecord, modal, layout, richOptions]);

	const value = props.getRecord()[bodyField] || (propMeta?.defaultValue);

	return <div style={{ display: "grid" }}><div className="stageField">
		{layout && layout.map((x, j) => {
			const isSelected = x.indexOf(value) >= 0;
			const className = isSelected ? " selected" : " not-selected";
			const buttonValue = isSelected ? value : x[0];
			// if (x.length > 1000) {
			// 	return <SelectInput value={v} opts={x} richOpts={richOptions?.filter(r => x.indexOf(r.value) >= 0) || []} onChange={e => {
			// 		let record = props.getRecord();
			// 		props.setRecord({ ...record, [bodyField]: e });
			// 	}} />
			// } else
			{
				const ro = richOptions?.find(z => z.value === buttonValue);
				const buttonText = ro ? makeRichOptionSpan(ro) : buttonValue;

				let borderColor: string | undefined = undefined;
				let backgroundColor: string | undefined = "fff8";
				if (isSelected) {
					let c = (ro && ro.color) || "#0071eb";
					switch (c) {
						case "red": c = "#f00"; break;
						case "green": c = "#080"; break;
					}
					borderColor = c;
					if (c.startsWith("#")) {
						backgroundColor = c + ((c.length === 4) ? "1" : "10"); // turn #aaa -> #aaa4 
					}
				}

				return <button key={x[0]} style={{ padding: "2px 5px", position: "relative", marginLeft: j > 0 ? "-10px" : "", color: borderColor }} className={"buttonReset buttonOuter " + className}
					data-optionindex={j} onClick={onButtonClick} disabled={disabled}>
					{isSelected && <ChevronBackground start={j === 0} end={j === layout.length - 1} back={backgroundColor} border={borderColor} />}
					{buttonText}
				</button>
			}
		})}
	</div>
	</div>
}

const ChevronBackground = (props: { border: any, back: any, start?: boolean, end?: boolean }) => {
	const { border, back, start, end } = props;

	return <>
		{!start && <svg viewBox="0 0 10 10" preserveAspectRatio="none" style={{ position: "absolute", left: 0, top: 0, width: "10px", height: "100%" }}>
			<polyline points="10,0 0,0 9,5 0,10 10,10" fill={back} stroke={border} strokeWidth="0.5px" />
		</svg>}
		{start && <svg viewBox="0 0 10 10" preserveAspectRatio="none" style={{ position: "absolute", left: 0, top: 0, width: "10px", height: "100%" }}>
			<polyline points="10,0 1,0 1,10 10,10" fill={back} stroke={border} strokeWidth="0.5px" />
		</svg>}
		{!end && <svg viewBox="0 0 10 10" preserveAspectRatio="none" style={{ position: "absolute", right: 0, top: 0, width: "10px", height: "100%" }}>
			<polyline points="0,0 9,5 0,10" fill={back} stroke={border} strokeWidth="0.5px" />
		</svg>}
		{end && <svg viewBox="0 0 11 10" preserveAspectRatio="none" style={{ position: "absolute", right: 0, top: 0, width: "10px", height: "100%" }}>
			<polyline points="0,0 10,0 10,10 0,10" fill={back} stroke={border} strokeWidth="0.5px" />
		</svg>}
		<svg viewBox="0 0 10 10" preserveAspectRatio="none" style={{ position: "absolute", right: "10px", left: "10px", width: "calc(100% - 20px)", top: 0, height: "100%" }}>
			<polyline points="0,0 10,0 10,10 0,10" fill={back} stroke="#00000000" strokeWidth="0.5px" />
			<line x1="0" y1="0" x2="10" y2="0" stroke={border} strokeWidth="0.5px"></line>
			<line x1="0" y1="10" x2="10" y2="10" stroke={border} strokeWidth="0.5px"></line>
		</svg>
	</>
}