import { IAppConfig } from "../AppSchema";
import { writeErrorLog } from "../service";


export const CALENDAR_FILIP_ID = "AAMkAGE0ODhlNTBhLWE5MjUtNGRkNy05ZGMxLWJkNGI3Y2YwMmY4ZQBGAAAAAADw-IazrPfPSbfwPf1xkkQfBwBvmc9IX_7GTLpHtZFu-JP0AAAAAAEGAABvmc9IX_7GTLpHtZFu-JP0AAAISsdZAAA=";

export const listEventsGraph = 
"https://graph.microsoft.com/v1.0/me/calendars/AAMkAGE0ODhlNTBhLWE5MjUtNGRkNy05ZGMxLWJkNGI3Y2YwMmY4ZQBGAAAAAADw-IazrPfPSbfwPf1xkkQfBwBvmc9IX_7GTLpHtZFu-JP0AAAAAAEGAABvmc9IX_7GTLpHtZFu-JP0AAAISsdZAAA=/events";

export const listMyCalendars = "https://graph.microsoft.com/v1.0/me/calendars";

export const calendarView = "GET /me/calendars/{id}/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}";


export interface IUserCalendar {
	id: string;
	name: string;
	color?: string;
	canEdit?: boolean;
}

export const getUserOutlookCalendars = async (tokenRef: ITokenRef) => {
	const access_token = await getAccessToken(tokenRef);
	const jsonText = await executeGetRequest(access_token, "me/calendars");
	const json = JSON.parse(jsonText);
	return json.value as IUserCalendar[];
}

export interface IUserCalendarEvent {
	id: string;
	name: string;
	start: string;
	end: string;
	url?: string;
	calendarId?: string;
}

export const getUserOutlookEvents = async (tokenRef: ITokenRef, start: Date, end: Date, calendars: string[]) => {
	const access_token = await getAccessToken(tokenRef);
	
	const events: IUserCalendarEvent[] = [];
	for (const calId of calendars) {
		const request = "me/calendars/" + calId + "/calendarView?startDateTime=" + start.toISOString() + "&endDateTime=" + end.toISOString() + "&$top=500";
		const jsonText = await executeGetRequest(access_token, request);
		const json = JSON.parse(jsonText);
		const outlookEvents = json.value as any[];
		for (const outlookEvent of outlookEvents) {
			let event: IUserCalendarEvent = {
				id: outlookEvent.id,
				start: outlookEvent.start.dateTime + "Z",
				end: outlookEvent.end.dateTime + "Z",
				name: outlookEvent.subject,
				calendarId: calId,
				url: outlookEvent.webLink
			};
			events.push(event);
		}
	}
	return events;
}

export interface ITokenRef {
	current?: string;
}

const getAccessToken = async (tokenRef: ITokenRef) => {
	if (!tokenRef.current) {
		const token = await fetch("/api/azure/accesstoken");
		const j = await token.json();
		tokenRef.current = j["access_token"];
	}
	return tokenRef.current!;
}
//DELETE /drives/{drive-id}/items/{item-id}
const executeGetRequest = async (access_token: string, request: string) : Promise<string> => {
	return new Promise((res, rej) => {
			
		var createCORSRequest = function (method: string, url: string) {
			var xhr = new XMLHttpRequest();
			if ("withCredentials" in xhr) {
				// Most browsers.
				xhr.open(method, url, true);
			}
			return xhr;
		};
  
		var url = "https://graph.microsoft.com/v1.0/"+request;
		//var url = "https://onedrive.live.com/drives"
		var method = 'GET';
		var xhr = createCORSRequest(method, url);
  
		xhr.onload = function () {
			// Success code goes here.
			const text = xhr.responseText;
			//console.log(text);
			res(text);
		};
  
		xhr.onerror = function () {
			const text = xhr.responseText;
			console.log(text);
			rej(new Error(text));
			// Error code goes here.
		};
  
		try {
			xhr.setRequestHeader('Authorization', "Bearer " + access_token);
			xhr.send();
		}
		catch (e) {
			rej(e)
		}
	})
};


export const executeGraphRequest = async (access_token: string, request: string, method: string = "GET", body?: any, headers?: any, throwOnHttpError?: boolean) : Promise<string> => {
	return new Promise((res, rej) => {
			
		var createCORSRequest = function (method: string, url: string) {
			var xhr = new XMLHttpRequest();
			if ("withCredentials" in xhr) {
				// Most browsers.
				xhr.open(method, url, true);
			}
			return xhr;
		};
  
		var url = (request && request.startsWith("https://")) ? request : ("https://graph.microsoft.com/v1.0/" + request);
		//var url = "https://onedrive.live.com/drives"
		method = method || "GET";
		var xhr = createCORSRequest(method, url);
  
		xhr.onload = function () {
			// Success code goes here.
			const text = xhr.responseText;
			//console.log(text);
			if (xhr.status >= 400) { // 404 not found or 500 errors
				console.log("ERROR executeGraphRequest:" + text);
				writeErrorLog("OneDrive", "Request [" + request + "] failed: " + text);
				if (throwOnHttpError)
					rej(new Error(text));
				// fixme: actually throw, but first we need to fix callers
			}
			res(text);
		};
  
		xhr.onerror = function () {
			const text = xhr.responseText;
			console.log(text);
			writeErrorLog("OneDrive", "Request [" + request + "] failed: " + text);
			rej(new Error(text));
			// Error code goes here.
		};
  
		try {
			xhr.setRequestHeader('Authorization', "Bearer " + access_token);
			if (headers) {
				for (let kv of Object.entries(headers))
					xhr.setRequestHeader(kv[0], kv[1] as string);
			}
			if (body)
				xhr.send(body);
			else
				xhr.send();
		}
		catch (e) {
			rej(e)
		}
	})
};
