"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FetchToSql = exports.ValueList = void 0;
const schema_1 = require("shared/schema");
class ValueList {
    constructor(meta) {
        this.sqliteParams = false;
        this.values = [];
        this.meta = meta;
    }
    makeParam(paramName) {
        return this.sqliteParams ? "?" : "@" + paramName;
    }
}
exports.ValueList = ValueList;
class FetchToSql {
    static translate(fetch, metadata, options) {
        var _a;
        const aliasedMeta = FetchToSql.prepareMeta(fetch, metadata);
        FetchToSql.updateUserConditions(fetch.entity, options.userId);
        const isMany = ((_a = metadata[fetch.entity.name]) === null || _a === void 0 ? void 0 : _a.type) === "many";
        const condValues = new ValueList(aliasedMeta);
        condValues.sqliteParams = options.sqlite === true;
        //const count = fetch.pageSize || fetch.count;
        const topPart = " "; // count ? " TOP " + count + " " : " ";
        const selectPart = FetchToSql.generateSelect(fetch, aliasedMeta);
        const fromPart = FetchToSql.generateFrom(fetch, condValues);
        const wherePart = FetchToSql.generateWhere(fetch.entity, "__PRIMARY__", condValues);
        const orderPart = isMany ? "" : FetchToSql.generateOrder(fetch, aliasedMeta);
        const groupPart = FetchToSql.generateGroup(fetch.entity);
        const keyword = fetch.distinct ? "SELECT DISTINCT " : "SELECT ";
        let sql = keyword + topPart + selectPart + fromPart + wherePart + groupPart + " " + orderPart;
        if (fetch.pageSize) {
            const skip = fetch.page && fetch.page > 1 ? (fetch.page - 1) * fetch.pageSize : 0;
            if (options.sqlite)
                sql += ` LIMIT ${fetch.pageSize} OFFSET ${skip}`;
            else
                sql += ` OFFSET ${skip} ROWS FETCH NEXT ${fetch.pageSize} ROWS ONLY`;
        }
        return { sql: sql, values: condValues };
    }
    static updateUserConditions(entity, userId) {
        const fixFilter = (f, userId) => {
            if (f.conditions) {
                for (const cond of f.conditions) {
                    if (cond.operator === "eq-userid" || cond.operator === "ne-userid") {
                        cond.operator = cond.operator === "eq-userid" ? "eq" : "ne";
                        cond.value = userId;
                    }
                }
            }
            if (f.filters) {
                for (const ff of f.filters) {
                    fixFilter(ff, userId);
                }
            }
        };
        if (entity.filter) {
            fixFilter(entity.filter, userId);
        }
        if (entity.links) {
            for (const link of entity.links) {
                FetchToSql.updateUserConditions(link, userId);
            }
        }
    }
    static prepareMeta(fetch, metadata) {
        const aliasedMeta = Object.assign({}, metadata);
        const handleLink = (links) => {
            if (links) {
                for (const l of links) {
                    aliasedMeta[l.alias] = aliasedMeta[l.name];
                    handleLink(l.links);
                }
            }
        };
        handleLink(fetch.entity.links);
        return aliasedMeta;
    }
    static generateOrder(fetch, metadata) {
        let orders = fetch.entity.orders || [];
        const isAggregate = fetch.entity.attributes && fetch.entity.attributes.findIndex(x => !!x.aggregate) >= 0;
        if (isAggregate && orders.length === 0) {
            return " ";
        }
        if (!isAggregate && orders.findIndex(o => o.attribute === "id") < 0) {
            orders = orders.concat({ attribute: 'id' });
        }
        let sb = " ORDER BY ";
        const meta = metadata[fetch.entity.name];
        for (const o of orders) {
            const propMeta = meta.properties.find(x => x.logicalName === o.attribute);
            if (o.alias) {
                let aliasedOrder = o.alias;
                // orderBy j1__regionid => ordering by linked field that is lookup? order by lookup label.
                const pp = o.alias.split("__");
                if (!propMeta && pp.length === 2) {
                    const linkedEntity = metadata[pp[0]];
                    if (linkedEntity) {
                        const linkedProp = linkedEntity.properties.find(x => x.logicalName === pp[1]);
                        if (linkedProp && linkedProp.type === schema_1.MetaPropertyType.Lookup)
                            aliasedOrder = "[" + o.alias + ".label]";
                    }
                }
                sb += aliasedOrder;
            }
            else {
                let name = "__PRIMARY__." + o.attribute;
                if (propMeta && propMeta.type === schema_1.MetaPropertyType.Lookup &&
                    fetch.entity.attributes &&
                    fetch.entity.attributes.findIndex(x => x.attribute === o.attribute && !x.entityName) >= 0) {
                    name = "[" + o.attribute + ".label]";
                }
                sb += name;
            }
            sb += o.descending ? " DESC," : ",";
        }
        sb = sb.substring(0, sb.length - 1);
        return sb;
    }
    static generateGroup(entity, sqlite = false, entityAlias = "__PRIMARY__.") {
        let sb = "";
        for (const attr of entity.attributes || []) {
            if (attr.groupBy) {
                if (!sb)
                    sb += " GROUP BY ";
                else
                    sb += ", ";
                // Remember the SELECT alias link_alias + "__" + column_name is not available in group by. we have to fully qualify the name
                sb += FetchToSql.makeGroupByExpression(attr, entityAlias + attr.attribute);
            }
        }
        if (entity.links) {
            for (const link of entity.links) {
                sb += " " + this.generateGroup(link, sqlite, link.alias + ".");
            }
        }
        return sb;
    }
    static generateCondition(cond, entityName, alias, condValues) {
        const getMonday = (d) => {
            d = new Date(d);
            let day = d.getDay(), diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        };
        //let paramIndex = condValues.values.length;
        const getParam = () => {
            const pname = "P" + condValues.values.length;
            const meta = condValues.meta[cond.entityName || entityName];
            if (!meta)
                throw new Error("Object not found: " + entityName);
            const ptype = meta.properties.find(x => x.logicalName == cond.attribute);
            if (!ptype)
                throw new Error("Property not found: " + cond.attribute);
            let value = cond.value;
            if (ptype.type === schema_1.MetaPropertyType.DateTime) {
                if (value && typeof value === "string" && value.startsWith("@@")) {
                    const pp = value.split(/\+/);
                    let date = new Date();
                    switch (pp[0]) {
                        case "@@now": break; // date = date
                        case "@@today":
                            date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                            break;
                        case "@@month":
                            date = new Date(date.getFullYear(), date.getMonth(), 1);
                            break;
                        case "@@year":
                            date = new Date(date.getFullYear(), 0, 1);
                            break;
                        case "@@week":
                            date = getMonday(date);
                            break;
                    }
                    for (let i = 1; i < pp.length; i++) {
                        let s = pp[i];
                        let m = 1;
                        if (s[0] === "-") {
                            m = -1;
                            s = s.substring(1);
                        }
                        //y,m,d,h
                        let u = s.at(-1);
                        let num = +s.substring(0, s.length - 1) * m;
                        switch (u) {
                            case "y":
                                date.setFullYear(date.getFullYear() + num);
                                break;
                            case "m":
                                date.setMonth(date.getMonth() + num);
                                break;
                            case "d":
                                date.setDate(date.getDate() + num);
                                break;
                            case "h":
                                date.setHours(date.getHours() + num);
                                break;
                        }
                    }
                    if (ptype.flags && (ptype.flags & (schema_1.MetaPropertyFlags.DateOnly | schema_1.MetaPropertyFlags.UTC)) !== 0) {
                        date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                        // isoString will remove the timezone again
                    }
                    value = date.toISOString();
                }
            }
            condValues.values.push({ name: pname, type: ptype, value: value });
            //paramIndex++;
            return condValues.makeParam(pname);
        };
        const op = cond.operator;
        const prefix = cond.entityName || alias;
        const attrName = prefix ? (prefix + "." + cond.attribute) : cond.attribute;
        let sb = attrName;
        switch (op) {
            case "null":
                sb += " IS NULL";
                break;
            case "not-null":
                sb += " IS NOT NULL";
                break;
            case "eq":
                sb += "=" + getParam();
                break;
            case "ne":
                sb += "<>" + getParam() + " OR " + attrName + " IS NULL";
                break;
            case "lt":
                sb += "<" + getParam();
                break;
            case "le":
                sb += "<=" + getParam();
                break;
            case "gt":
                sb += ">" + getParam();
                break;
            case "ge":
                sb += ">=" + getParam();
                break;
            case "like":
                sb += " LIKE " + getParam();
                break;
            case "not-like":
                sb += " NOT LIKE " + getParam() + " OR " + attrName + " IS NULL";
                break;
            default:
                throw new Error("Unsupported operator: " + op);
        }
        return sb;
    }
    static generateWhere(entity, alias, condValues, keyword = " WHERE ") {
        const isEmptyFilter = (f) => {
            return (!f || ((!f.conditions || f.conditions.length === 0) && (!f.filters || f.filters.every(isEmptyFilter))));
        };
        let f = entity.filter;
        if (!f || isEmptyFilter(f))
            return "";
        let sb = keyword + " (";
        const handleFilter = (f) => {
            let fop = (f.type && f.type.toLowerCase() === "or") ? " or " : " and ";
            let first = true;
            if (f.conditions && f.conditions.length > 0) {
                for (const cond of f.conditions) {
                    if (!first) {
                        sb += fop;
                    }
                    first = false;
                    sb += "(" + FetchToSql.generateCondition(cond, entity.name, alias, condValues) + ")";
                }
            }
            if (f.filters && f.filters.length > 0) {
                for (const ff of f.filters) {
                    if (isEmptyFilter(ff))
                        continue;
                    if (!first) {
                        sb += fop;
                    }
                    first = false;
                    sb += "(";
                    handleFilter(ff);
                    sb += ")";
                }
            }
        };
        handleFilter(f);
        sb += ")";
        return sb;
    }
    static generateFrom(fetch, condValues) {
        const entityName = fetch.entity.name;
        let sb = " FROM " + entityName + " AS __PRIMARY__ ";
        const handleLinks = (primaryAlias, links) => {
            for (const link of links) {
                const isOuter = (link.type === "outer");
                sb += " " + (isOuter ? "LEFT" : "INNER") + " JOIN " + link.name + " AS " + link.alias +
                    " ON (" + link.alias + "." + link.to + "=" + primaryAlias + "." + link.from;
                if (link.filter) {
                    sb += FetchToSql.generateWhere(link, link.alias, condValues, " AND ");
                }
                sb += ")";
            }
            for (const link of links) {
                if (link.links) {
                    handleLinks(link.alias, link.links);
                }
            }
        };
        if (fetch.entity.links) {
            handleLinks("__PRIMARY__", fetch.entity.links);
        }
        return sb;
    }
    static generateSelect(fetch, metadata) {
        let sb = "";
        FetchToSql.visitLinks(fetch.entity, "", (e, alias) => {
            if (e.attributes) {
                const meta = metadata[e.name];
                for (let a of e.attributes) {
                    if (sb.length > 0) {
                        sb += ", ";
                    }
                    // if (a.entityName) {
                    // 	sb += a.entityName + ".";
                    // }
                    const prefix1 = (alias || "__PRIMARY__") + "."; //alias ? alias + "." : "";
                    const prefix2 = alias ? alias + "__" : "";
                    const attrAlias = (a.alias || (prefix2 + a.attribute));
                    const propMeta = meta.properties.find(f => f.logicalName === a.attribute);
                    if (!a.aggregate && (propMeta === null || propMeta === void 0 ? void 0 : propMeta.type) === schema_1.MetaPropertyType.Lookup && propMeta.targets) {
                        let s = "", end = "";
                        if (a.groupBy) {
                            s = "MIN(";
                            end = ")";
                        }
                        sb += s + prefix1 + a.attribute + end + " AS [" + attrAlias + ".id], ";
                        sb += s + prefix1 + a.attribute + "Target " + end + " AS [" + attrAlias + ".name], ";
                        if (fetch.noLookupLabels)
                            sb += " 'NONE' AS [" + attrAlias + ".label] ";
                        else
                            sb += "(SELECT " + s + " name " + end + " FROM " + propMeta.targets[0] + " AS X WHERE X.id=" + (prefix1 || (e.name + ".")) + a.attribute + ") AS [" + attrAlias + ".label]";
                    }
                    else {
                        let attr;
                        if (propMeta && (propMeta.flags & schema_1.MetaPropertyFlags.Json) !== 0) {
                            if (prefix1) {
                                attr = "JSON_QUERY(" + prefix1 + a.attribute + ") AS " + attrAlias;
                            }
                            else {
                                attr = "JSON_QUERY(" + a.attribute + ") AS " + a.attribute;
                            }
                        }
                        else {
                            let expr = prefix1 + a.attribute;
                            if (a.aggregate) {
                                let method = "COUNT(";
                                switch (a.aggregate) {
                                    case "average":
                                        method = "AVG(";
                                        break;
                                    case "min":
                                        method = "MIN(";
                                        break;
                                    case "max":
                                        method = "MAX(";
                                        break;
                                    case "sum":
                                        method = "SUM(";
                                        break;
                                    case "countdistinct":
                                        method = "COUNT( DISTINCT ";
                                        break;
                                }
                                expr = method + expr + ")";
                            }
                            else if (a.groupBy) {
                                expr = FetchToSql.makeGroupByExpression(a, expr);
                            }
                            attr = expr + " AS " + attrAlias;
                        }
                        sb += attr;
                    }
                }
            }
        });
        return sb;
    }
    static makeGroupByExpression(a, expr) {
        switch (a.groupBy) {
            case "year":
                expr = "DATEPART(YEAR," + expr + ")";
                break;
            case "quarter":
                expr = "CONCAT(DATEPART(YEAR," + expr + "),'_',DATEPART(QUARTER," + expr + "))";
                break;
            case "month":
                expr = "FORMAT(" + expr + ", 'yyyy_MM')";
                break;
            case "week":
                expr = "CONCAT(DATEPART(YEAR," + expr + "),'_',RIGHT(CONCAT('00', DATEPART(WEEK, " + expr + ")), 2))";
                break;
            case "day":
                expr = "FORMAT(" + expr + ", 'yyyy_MM_dd')";
                break;
        }
        return expr;
    }
    static visitLinks(e, alias, visitor) {
        visitor(e, alias);
        if (e.links) {
            for (const link of e.links) {
                this.visitLinks(link, link.alias, visitor);
            }
        }
    }
}
exports.FetchToSql = FetchToSql;
