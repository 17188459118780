import React, { useContext, useMemo, useState } from "react";
import { Fetch } from "shared/fetch";
import { IExecuteRequest } from "shared/schema";
import { ICommand, IListColumn, MetaContext } from "../AppState";
import { IconButton } from "../components/IconButton";
import SimpleGrid from "../components/SimpleGrid"
import { TitleBox } from "../components/TitleBox";
import { useDerivedState } from "../hooks/useDerivedState";
import { ModalContext, showMenu, showWait } from "../modal/Modal";
import { configureFormFieldDialog } from "../objectForm/Configure";
import { useFetchQuery } from "../objectList/useFetchQuery"
import { DataService } from "../service";
import { getAvailableLanguages, processLocalization } from "../services/localize";
import { newGuid } from "../utils/guid";

const translateBatch = async (s: string[], source:string, target: string) => {

    let key = "d083edb9d7a541dab8e6ca38412efe7f";
	let endpoint = "https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=" + source + "&to=" + target + "&Subscription-Key=" + key;
	// const params = new URLSearchParams("");
	// params.append('api-version', '3.0');
	// params.append('from', 'en');
	// params.append('to', '[sk]');

    // location, also known as region.
    // required if you're using a multi-service or regional (not global) resource. It can be found in the Azure portal on the Keys and Endpoint page.
    let location = "germanywestcentral";

	const resp = await fetch(endpoint, {
		method: 'post',
		headers: {
			'Ocp-Apim-Subscription-Key': key,
			// location required if you're using a multi-service or regional (not global) resource.
			'Ocp-Apim-Subscription-Region': location,
			'Content-type': 'application/json',
			'X-ClientTraceId': newGuid(),
		},
		body: JSON.stringify(s.map(x => ({ text: x })))
	});
	if (resp.ok) {
		const result = await resp.json() as { translations: { text: string, to: string }[] }[];
		return result.map(x => (x.translations[0].text));
		//console.log(result);
	}
	return null;
}
const translate = async (s: string[], source: string, target: string) => {
	const results = [];
	//const batch = [...s];
	for (let i = 0; i < s.length; i+=100) {
		const batch = s.slice(i, i + 100);
		const r = await translateBatch(batch, source, target);
		if (r) {
			results.push(...r);
		} else {
			return null;
		}
	}
	return results;
}

export const InputAfterFocusLost = (props: {value: string, type: string, placeholder?:string, onChange: React.ChangeEventHandler<HTMLInputElement>}) => {
	const { value } = props;
	const [text, setText] = useDerivedState(value, [value]);

	return <input type="text" placeholder={props.placeholder} value={text} onChange={e => setText(e.target.value)}
		onBlur={e => {
			if (text !== props.value)
				props.onChange(e);
		}} />
}

export const CustomizeLocalization = () => {

	const metadata = useContext(MetaContext);
	const modal = useContext(ModalContext);

	const q = useMemo(() => {
		return {
			entity: {
				name: "inu_localize",
				attributes: ["id", "name", "lang", "text"].map(x => ({ attribute: x })),
				filter: {
					conditions:[{attribute:"name", operator:"not-null"}]
				},
				orders: [{ attribute: "name" }]
			}
		} as Fetch;
	}, []);

	const { rows, setRows, loading } = useFetchQuery(q, 5000);

	const locRows = useMemo(() => {
		// fixme: fetchServerMetadata
		const locMap = processLocalization(metadata, rows, true);
		const locRows = Object.values(locMap);
		locRows.sort((a, b) => a.name.localeCompare(b.name));
		return locRows;
	}, [rows]);

	const langs = getAvailableLanguages(metadata);
	const fields = ["name"].concat(langs.map(x => x.id)).concat("options");
	const listColumns: IListColumn[] = fields.map(x => ({ attribute: x, width: x === "options" ? "50px" : undefined }));

	const commands: ICommand[] = [
		//{ name: "CmdResetEnglish", label: "Reset", icon: "reset" },
		{ name: "CmdTranslate", label: "Translate", icon: "language" },
		{ name: "CmdTranslateAll", label: "Translate All", icon: "language" },
		{ name: "CmdSave", label: "", icon: "save" },
		//{ name: "CmdNew", label: "", icon: "plus" },
	];
	const onCommand = async (cmd: ICommand, e: any) => {
		if (cmd.name === "CmdResetEnglish") {
			/// *** will delete ALL english translations! this is a fix for older <2024 orgs.
			// const overridenMetadata = locRows.filter(x => x.en && x.en.id && (x.en as any).isMetadata).map(x => ({ id: x.en!.id!, name:x.name, text: x.en!.text }));
			// console.log(JSON.stringify(overridenMetadata));
			// const delCmd = overridenMetadata.map(x => ({ operation: "delete" as any, name: "inu_localize", object: { id: x.id } }));
			// DataService.executeMultiple(delCmd);
		} else if (cmd.name === "CmdTranslateAll") {
			const source = "en";
			const targetIndex = await showMenu(modal, e.target, langs.map(x => x.label));
			const target = langs[targetIndex].id;
			const texts: { [s: string]: string } = {};
			for (const x of locRows) {
				const f = x as any;
				const fs = f[source];
				const ft = f[target];
				if ((!ft || !ft.text) && (fs && fs.text)) {
					texts[fs.text] = fs.text;
				}
			}
			const t = Object.keys(texts).slice(0, 1000);
	
			if (t.length === 0) {
				alert("Nothing to translate");
				return;
			}
			const update = showWait(modal, { title: "Translating..." });
			const results = await translate(t, source, target);
			update(null);
			if (results) {
				const map = results.reduce((a, sk, i) => (a[t[i]] = sk, a), {} as any);
				for (const r of locRows) {
					const x = r as any;
					if (!x[target])
						x[target] = {};
					if (!x[target].text && x[source] && x[source].text) {
						x[target].text = map[x[source].text];
						x[target].isDirty = true;
					}
				}
			}
			render(x => !x);
		} else if (cmd.name === "CmdSave") {
			const objs: IExecuteRequest[] = [];
			for (const r of locRows) {
				const { name, ...rr } = r;
				//const langs = Object.values(rr).filter(x => x.isDirty);
				const langs = Object.keys(rr);
				for (const lang of langs) {
					const x = (rr as any)[lang];
					if (!x.isDirty)
						continue;
					x.isDirty = false;
					const req = {
						operation: x.id ? "update" : "create",
						name: "inu_localize",
						object: {
							name: name,
							text: x.text,
							lang: lang,
						} as any
					};
					if (!x.id)
						x.id = newGuid();
					req.object.id = x.id;
					objs.push(req as IExecuteRequest);
				}
			}
			if (objs.length > 0) {
				const update = showWait(modal, { title: "Saving " + objs.length + " records..." });
				await DataService.executeMultiple(objs);
				update(null);
			}
		}
	};

	const [_, render] = useState(false);

	// const configureClick = (e: React.MouseEvent, itemName: string) => {
	// 	const itemIndex = locRows.findIndex(x => x.name === itemName);
	// 	let item = itemIndex >= 0 ? locRows[itemIndex] :{ name: "textid", en: { isDirty: true, text: "text" } };

	// 	const fakeParent = itemIndex >= 0 ? { items: [item] } : { items: [] };
	// 	configureFormFieldDialog(modal, item, fakeParent, "items", ["name"], e.target as HTMLElement, () => {
	// 		if (fakeParent.items.length === 0) {
	// 			locRows.splice(itemIndex, 1);
	// 			//DataService.updateRecord("inu_localize", { id: item.});
	// 		} else {
	// 			if (itemIndex < 0)
					
	// 		}
	// 	});
	// }

	const [newItem, setNewItem] = useState({ name: "" });

	const formatter = (row: any, fieldName: string) => {
		if (row === newItem) {
			if (fieldName === "name") {
				return <InputAfterFocusLost type="text" placeholder="Add string" value={row.name} onChange={e => {
					row.name = e.target.value;
					render(x => !x);
				}} />
			}
			if (fieldName === "options") {
				return <IconButton icon="plus" onClick={e => {
					if (newItem.name) {
						locRows.push(newItem)
						setNewItem({ name: "" });
					}
					render(x => !x);
				}} />
			}
		}
		if (fieldName === "options")
			return "";// <IconButton icon="ellipsis-vertical" onClick={e => { }} />
		if (fieldName !== "name") {

			return <InputAfterFocusLost type="text" data-fieldlang={fieldName} value={row[fieldName]?.text || ""} onChange={e => {
				if (!row[fieldName])
					row[fieldName] = {};
				row[fieldName].text = e.target.value;
				row[fieldName].isDirty = true;
				render(x => !x);
			}} />
		}
		return row[fieldName] || "";
	}

	let filteredRows = locRows;
	const [search, setSearch] = useState("");
	if (search) {
		if (search[0] == "*" || search[0] == "%")
			filteredRows = locRows.filter(x => x.name.indexOf(search.substring(1)) >= 0);
		else
			filteredRows = locRows.filter(x => x.name.startsWith(search));
	}
	filteredRows = [newItem].concat(filteredRows);

	return <div className="objectListContainer">
		<TitleBox title="Localize" commands={commands} onCommand={onCommand} noSpacer={false} >
			<input style={{marginLeft:"10px"}} type="text" placeholder="Search..." value={search} onChange={e=>setSearch(e.target.value)} />
		</TitleBox>
		<div></div>
		<SimpleGrid listColumns={listColumns} rows={filteredRows} isLoading={loading} fields={fields} noEdit={true} formatter={formatter} />
	</div>
}