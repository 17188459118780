import React, { useContext, useMemo, useRef } from "react";
import { Fetch } from "shared/fetch";
import { IDictionary, IMetaProperty } from "shared/schema";
import { IAppConfig, IListColumn } from "../AppSchema";
import { getFetchAttributes, MetaContext } from "../AppState";
import { GridProps } from "../components/GridProps";
import { MosaicGrid } from "../components/MosaicGrid";
import SimpleGrid from "../components/SimpleGrid";
import { useDerivedState } from "../hooks/useDerivedState";
import { useCellFormatter } from "./useCellFormatter";
import { useEditableFormatter } from "./useEditableFormatter";
import { useFetchQuery } from "./useFetchQuery";

export interface ObjectListProps {
	query: Fetch;
	// fields?: string[];
	// columns?: string[];
	listColumns?: IListColumn[];
	noHeader?: boolean;
	onClick?: (obj: any) => void;
	disableLookupClick?: boolean;
	onSortClick?: (field: string) => void,
	onRenderCell?: (obj: any, field: string) => React.ReactNode,
	onSetRecord?: (newRecord: any) => void,
	onRowsChanged?: (rows: any[]) => void,
	onNewRecord?: React.MutableRefObject<((record: any) => void) | undefined>,
	editMode?: boolean;
	showMosaic?: boolean;
	renderHeader?: (index: number, header: string) => React.ReactNode;
	newParams?: { [key: string]: any };
	excludedColumns?: string;
}

export const prepareListColumns = (metadata: IAppConfig, q: Fetch, listColumns?: IListColumn[], resolveLabels: boolean = true) => {
	const allAttrs = getFetchAttributes(metadata, q);
	let attrs = allAttrs;
	//let listColumns = props.listColumns;
	if (listColumns && listColumns.length > 0) {
		listColumns = listColumns.map(x => ({ ...x })); // duplicate list columns.
		attrs = listColumns.map(x => x.attr || allAttrs.find(y => y.attribute === x.attribute)).filter(x => x !== undefined) as any;
	}
	else {
		attrs = attrs.filter(x => x.attribute !== "id");
		const nameIndex = attrs.findIndex(x => x.attribute === "name");
		if (nameIndex > 0)
			attrs = [attrs[nameIndex]].concat(attrs.filter((x, y) => y !== nameIndex));
		listColumns = attrs.map(x => ({ attribute: x.attribute }));
	}
	if (resolveLabels) {
		for (let i = 0; i < listColumns.length; i++) {
			const c = listColumns[i];
			if (!c.label) {
				c.label = (attrs[i]?.prop.displayName) || "ERR: " + c.attribute;
			}
		}
	}
	return { listColumns, attrs };
}

export const BaseObjectList = (props: ObjectListProps) => {

	const q = props.query;
	const metadata = useContext(MetaContext);
	const meta = metadata.objects.find(f => f.logicalName === q.entity.name);
	if (!meta) throw Error("");

	const [fields, attrsMeta, orderBy, listColumns] = useMemo(() => {
		
		let { listColumns, attrs } = prepareListColumns(metadata, q, props.listColumns);

		const forder = q.entity.orders?.find(x => !x.mandatory);
		const orderBy = forder && { field: forder.attribute, asc: !forder.descending };

		if (props.excludedColumns) {
			const exc = props.excludedColumns.split(';');
			listColumns = listColumns.filter(x => exc.indexOf(x.attribute) < 0);
		}
		const fields = listColumns.map(x => x.attribute);
		//const columns = listColumns.map(x => x.label as string);
		const attrsMeta = attrs.reduce((acc, curr) => (acc[curr.attribute] = curr.prop as any, acc), {} as IDictionary<IMetaProperty>);
		return [fields, attrsMeta, orderBy, listColumns];
	}, [q, metadata.objects, props.listColumns, props.excludedColumns]);

	const { rows, setRows, loading, onScroll } = useFetchQuery(q);

	const [selectedRecord, setSelectedRecord] = useDerivedState("", [props.editMode]);

	const formatterNormal = useCellFormatter({ listColumns, ...props }, meta, attrsMeta);
	const formatterEdit = useEditableFormatter(formatterNormal, { listColumns, ...props }, meta, attrsMeta,
		() => selectedRecord,
		setSelectedRecord,
		(id: string) => (rows.find(x => x.id === id)),
		(newRecord) => {
			if (props.onSetRecord)
				props.onSetRecord(newRecord);
			setRows(v => {
				const newRows = v.map(x => x.id === newRecord.id ? newRecord : x);
				if (newRecord.__operation === "delete")
					return newRows.filter(x => x !== newRecord);
				// if (props.onRowsChanged)
				// 	props.onRowsChanged(newRows);
				return newRows;
			})
		});
	const formatter = props.editMode ? formatterEdit : formatterNormal;

	if (props.onNewRecord) {
		props.onNewRecord.current = (newRecord) => {
			if (props.onSetRecord)
				props.onSetRecord(newRecord);
			setRows(old => [newRecord].concat(old));
			const rid = newRecord?.id;
			setSelectedRecord(rid);
			setTimeout(() => {
				const editor = document.querySelector("div.editCell[data-rid='" + rid + "']") as HTMLElement;
				if (editor) {
					const input = editor.querySelector("input, textarea") as HTMLInputElement;
					if (input) {
						input.select();
						input.focus();
					}
				}
			}, 300);
		}
	}

	const maybeLoading = useMaybeLoading(loading, rows, q, () => setRows([]));

	const showMosaic = props.showMosaic && listColumns.length > 2;
	const GridComponent = (showMosaic ? MosaicGrid : SimpleGrid) as (props: GridProps) => JSX.Element;

	return (
			<GridComponent isLoading={maybeLoading} noEdit={true} noHeader={props.noHeader}
			fields={fields} rows={rows} formatter={formatter}
			selectedRow={rows.find(x=>x.id===selectedRecord)}
			sortColumn={orderBy} onSortClick={props.onSortClick}
			listColumns={listColumns} attrsMeta={attrsMeta}
			onScroll={onScroll} showLoader={(q as any).hasMore !== false}
			renderHeader={props.renderHeader}
			/>
	);
}

// Prevent loading flickers. If we are re-loading, show stale data while new data loads.
// Force loading message after 3 seconds if still loading.
const useMaybeLoading = (loading: boolean, rows: any[], q: Fetch, clear: () => void) => {
	const loadingTimeout = useRef(0);
	const maybeLoading = loading && (!rows || rows.length === 0);
	if (!maybeLoading && loading && (q.page||0) <= 1) {
		if (loadingTimeout.current)
			window.clearTimeout(loadingTimeout.current);
		loadingTimeout.current = window.setTimeout(() => {
			console.log("maybe-loading-clear");
			clear();
		}, 2000);
		//console.log("LOAD IMG-none");
		//return undefined;
	} else if (loadingTimeout.current) {
		window.clearTimeout(loadingTimeout.current);
		loadingTimeout.current = 0;
	}
	// console.log(maybeLoading ? "LOAD IMG- loading" : "LOAD IMG- cat");
	// return (maybeLoading ?
	// 	<div style={{ gridColumn: "1/-1" }}><Loading /></div> :
	// 	<div style={{ textAlign: "center", color: "lightgray", margin: "50px", gridColumn: "1/-1" }}>
	// 		<div><i className='fa fa-cat' style={{ fontSize: "40px" }} /></div>
	// 		<div style={{ margin: "5px" }}></div>
	// 	</div>)
	return maybeLoading;
}