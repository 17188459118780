import { useCallback, useEffect, useRef } from "react";
import { IMetaProperty, MetaPropertyFlags } from "shared/schema";
import { IListColumn } from "../AppSchema";
import { GridProps } from "./GridProps";
import { Loading } from "./Loading";
import { useListLoader, useScrollRestore } from "./SimpleGrid";

export const MosaicGrid = (props: GridProps) => {

	const { rows, listColumns, isLoading, formatter, attrsMeta } = props;
	const loadingStyle = { textAlign: "center", gridColumnStart: "1", gridColumnEnd: "-1" } as React.CSSProperties;

	if (!listColumns || !attrsMeta) throw Error("list columns and attrsMeta are required!");

	const onItemClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
		let p = e.target as (HTMLElement | null);
		const rowDiv = e.currentTarget as HTMLElement;
		let rowIndex = rowDiv?.dataset["rowindex"];
		if (!rowDiv || rowIndex === undefined)
			return;
		
		while (p && p !== rowDiv) {
			if (p.tagName === "A")				
				return;
			p = p.parentElement;
		}

		const link = rowDiv.querySelector("div[data-field='name'] a") as HTMLElement;
		if (link)
			link.click();
	}, [rows]);

	const mainRef = useRef<HTMLDivElement>(null);

	const sessionKey = props.sessionKey || props.fields.join(';');
	const onScroll = useScrollRestore(mainRef, isLoading, sessionKey + "mosaic");
	const lastItemRef = useListLoader(mainRef, props.onScroll);

	useEffect(() => {
		const d = mainRef.current;
		d && d.classList.toggle("gridFull", rows && rows.length > 0);
	}, [mainRef, rows && rows.length > 0]);

	return <div className="mosaicGridMain" ref={mainRef} onScroll={onScroll}>
		{isLoading && <div key="loading" style={loadingStyle}><Loading /></div>}
		{!isLoading && rows && rows.map((x, i) => {
			let columnIndex = 0;
			let dataitemindex = { "data-rowindex": i };
			const rowStyle = {} as React.CSSProperties;
			//rowStyle.overflow = "hidden scroll";
			if (x === props.selectedRow)
				rowStyle.backgroundColor = "#0071eb20";
			
			let image: React.ReactNode = null;
			const rows: React.ReactNode[][] = [];
			let lastRow: React.ReactNode[] | null = null;
			let cols = listColumns.filter(x => x.mobileOrder !== "-1").map((x, i) => ({ order: x.mobileOrder !== undefined ? +x.mobileOrder : i, col: x }));
			cols.sort((a, b) => a.order - b.order);
			for (const col of cols) {
				const fieldName = col.col.attribute;
				const style = {};
				const fieldValue = formatter ? formatter(x, fieldName, style) : x[fieldName];
				const propMeta = attrsMeta[fieldName];
				const isImage = (propMeta.flags & (MetaPropertyFlags.Image | MetaPropertyFlags.File)) !== 0;
				const isWide = fieldName === "name" || isImage;

				const cell = createCell(fieldValue, style, col.col, propMeta);
				if (isImage) {
					image = cell;
				}
				
				if (isWide) {
					rows.splice(0, 0, [cell]);
					lastRow = null;
				} else {
					if (!lastRow || lastRow.length === 2)
						rows.push(lastRow = [])
					lastRow.push(cell);
				}
			}
			
			return (<div key={"itemRow" + i} className="mosaicGridItem" {...dataitemindex} style={rowStyle} onClick={onItemClick} >
				{/* <div style={{ gridColumn: "1/2", gridRow: "1/100" }} /> */}

				{rows.map((arr,jj) => {
					if (arr[0] === image) return image;
					return <div key={"gg"+jj} className="mosaicGridRow">
						{arr[0]}{arr[1]}
					</div>
				})}
			</div>)
		})}
		<div key="lastItemObserver" className="simpleGridLoader" ref={lastItemRef} style={{visibility:props.showLoader && rows && rows.length>0?"visible":"hidden" }} />
	</div>
}

const createCell = (fieldValue: any, style: any, col: IListColumn, propMeta: IMetaProperty) => {
	const fname = col.attribute;
	let text = (fieldValue !== undefined && fieldValue !== null) ? fieldValue : "";
	const isImage = (propMeta.flags & (MetaPropertyFlags.Image|MetaPropertyFlags.File)) !== 0;
	const isWideText = fname === "name";
	const showLabel = !isWideText && !isImage && col.mobileLabel !== " ";
	const childStyle = style as React.CSSProperties;
	let childClassName = "mosaicGridItemChild";
	if (isImage) {
		childStyle.justifyContent = "center";
		childStyle.gridColumn = "1/2";
		childStyle.gridRow = "1/100";
	}
	else {
		if (isWideText)
			childClassName += " mosaicGridWideText";
		childClassName += " mosaicGridItemText";
	}
	if (col.cssClass)
		childClassName += " " + col.cssClass;

	let label: any = undefined;
	if (showLabel) {
		if (text)
			label = <span className="mosaicGridItemLabel" >{col.mobileLabel || col.label || propMeta.displayName} -&nbsp;</span>
		else
			label = <span className="mosaicGridItemLabel" >———</span>//&nbsp;</span>
	}
					
	return <div key={"itemCol" + fname} className={childClassName} {...{ "data-field": fname }} style={childStyle}>
		{label}{text}
	</div>
}