import { useMemo, useState, useRef, useEffect, useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fetch, FetchFilter } from "shared/fetch";
import { ICommand } from "../AppSchema";
import { TitleBox } from "../components/TitleBox";
import { getSessionStateItem, setSessionStateItem, useSessionState } from "../hooks/useSessionState";
import { IModalContext, IModalParent, ModalContext, showMenu } from "../modal/Modal";
import { DataService } from "../service";
import { ICalendarEvent, addDays } from "./ICalendarEvent";
import { MonthCalendar } from "./MonthCalendar";
import { IUserCalendar, getUserOutlookCalendars, getUserOutlookEvents } from "./cloudCalendar";
import { WeekCalendar } from "./weekCalendar";
import { runCommandWithContext, tryCompileCommand } from "../command/runCommand";
import { MetaContext, checkPermissions } from "../AppState";
import { PERMISSION_CREATE } from "shared/permissions";

export const showCalendarDialog = (modal: IModalContext, props: any) => {

	const FetchDialog = (props: { innerProps: any, onSave: (record: any) => void, commandSite: IModalParent }) => {

		const cmds = [
			//{ name: "CmdSave", label: "Save", icon: "save" },
			{ name: "CmdClose", label: "Close", icon: "close" },
		];
	
		const onCommand = async (cmd: ICommand) => {
			// if (cmd.name === "CmdSave") {
			// 	props.onSave();
			// }
			props.commandSite.closeModal();
		}
		const onNewEventClick = (record: ICalendarEvent) => {
			const r = {
				name: record.name,
				[props.innerProps.startField]: record.start,
				[props.innerProps.endField]: record.end,
			}
			props.onSave(record);
			props.commandSite.closeModal();
		}

		return (<div className="objectListContainer" style={{ flex: "1 1 auto", display: "flex" }}>
			<TitleBox title={props.innerProps.title||"Select date"} commands={cmds} onCommand={onCommand} />
			<ObjectCalendar {...props.innerProps} onNewEvent={onNewEventClick} />
		</div>)
	}

	return new Promise<Date>((res, rej) => {

		modal.showModal({
			body: (p) => < FetchDialog {...p} />,
			bodyProps: {
				innerProps: props,
				onSave: res,
			},
			showTitle: false,
			id: "FetchDialog",
		})
	});
}

const getMeetingColorHardcord = (x: any) => {

	if (x.color)
		return x.color;
	
	//hardcoded for filip (todo make configurable)
	if (x.type) {
		if (x.type !== "Meeting")
			return "#cccccc";
		if (x.type === "Meeting" && x.status === "Canceled")
			return "#ff0000";
	}
	
	return "#0071eb";
}

export const ObjectCalendar = (props: {
	query: Fetch,
	startField?: string,
	endField?: string,
	initialView?: string,
	onNewEvent?: (record: any) => void
}) => {
	const metadata = useContext(MetaContext);

	const [savedDate, setSavedDate] = useSessionState("ObjectCalendar", (new Date()).toISOString(), []);
	const currentDate = new Date(savedDate);
	const setCurrentDate = (value: Date | ((prev: Date) => Date)) => {
		setSavedDate(oldValue => {
			if (typeof value === "function") {
				value = (value as ((prev: Date) => Date))(new Date(oldValue));
			}
			return value.toISOString();
		});
	};
	
	const startField = props.startField || "createdon";
	const endField = props.endField || startField;

	const cacheMin = -10;
	const cacheMax = 40

	const cachedMonth = currentDate.getFullYear() + "." + currentDate.getMonth();
	const q = useMemo(() => {
		const q = JSON.parse(JSON.stringify(props.query)) as Fetch;
		const startDate = addDays(new Date(currentDate.getFullYear(), currentDate.getMonth()), cacheMin);
		const endDate = addDays(new Date(currentDate.getFullYear(), currentDate.getMonth()), cacheMax);

		const dateFilter: FetchFilter = {
			conditions: [{ attribute: endField, operator: "ge", value: startDate.toISOString() },
			{ attribute: startField, operator: "le", value: endDate.toISOString() }]
		};
		if (q.entity.filter)
			q.entity.filter = { filters: [dateFilter, q.entity.filter] }
		else
			q.entity.filter = dateFilter;
		
		if (!q.entity.attributes) q.entity.attributes = [];
		for (const attrName of [startField, endField]) {
			if (!q.entity.attributes.find(x => x.attribute === attrName))
				q.entity.attributes.push({ attribute: attrName });
		}
		
		return q;
	}, [cachedMonth, props.query]);
	const [rows, setRows] = useState([] as ICalendarEvent[]);
	const [loading, setLoading] = useState(true);
	const [selectedOutlookCalendars, setSelectedOutlookCalendars] = useSessionState("!outlookCalendars", [] as string[], []);
	const showExchangeCals = (selectedOutlookCalendars && selectedOutlookCalendars.length > 0) || window.location.origin.indexOf('filip') >= 0;

	const outlookToken = useRef<string | undefined>(undefined);
	const outlookCalendars = useRef(getUserOutlookCalendars(outlookToken));

	const renderEvent = useMemo(() => {
		const defaultRenderCell = { type: "custom", name: props.query.entity.name + ".renderEvent" };
		if (tryCompileCommand(metadata, defaultRenderCell)) {
			const rc = (obj: any, field: string) => {
				const result = runCommandWithContext(metadata, defaultRenderCell, { obj, field }) as any;
				return result;
			}
			return rc;
		}
		return undefined;
	}, [props.query.entity.name]);

	const page = useRef(1);
	useEffect(() => {
		const loadData = async (pageIndex: number) => {
			console.log("ObjectCalendar.loadData");
			let newRows: any[];
			try {
				const result = await DataService.retrieveMultiple(q);
				newRows = result.map(x => ({
					...x,
					start: x[startField],
					end: x[endField],
					color: (renderEvent && renderEvent(x, "color")) || getMeetingColorHardcord(x),// "#ff00ff",//(colors[x.familyid] || (colors[x.familyid]=randomColor())),
					url: "/" + appId + "/edit/" + q.entity.name + "/" + x.id,
					customElement: renderEvent ? renderEvent(x, "name") : undefined
				}));
			}
			catch (err) {
				alert((err as Error).message);
				setLoading(false);
				return;
			}
			// multiple async calls started we are old:( abandon ship.
			if (pageIndex !== page.current) return;
	
			try {
				if (showExchangeCals) {
					let cals = await outlookCalendars.current;
					if (cals) {
						const startDate = addDays(new Date(currentDate.getFullYear(), currentDate.getMonth()), cacheMin);
						const endDate = addDays(new Date(currentDate.getFullYear(), currentDate.getMonth()), cacheMax);

						if (selectedOutlookCalendars.length > 0) {
							cals = cals.filter(x => selectedOutlookCalendars.indexOf(x.id) >= 0);
						} else {
							cals = getDefaultCalendars(cals);
						}

						const events: ICalendarEvent[] = await getUserOutlookEvents(outlookToken, startDate, endDate, cals.map(x => x.id));
						events.forEach(x => (x.canEdit = false, x.icon = "person", x.color = "#00a000"));
						newRows.push(...events);
					}
				}
			}
			catch (e) {
				console.log(e);
			}

			setRows((oldRows) => {
				if (pageIndex > 1)
					return oldRows.concat(newRows);
				else
					return newRows;
			});
			setLoading(false);
		}

		setLoading(true);
		page.current = 1;
		loadData(1);
	}, [q, selectedOutlookCalendars]);

	const appId = useParams<string>()["appid"];
	const navigate = useNavigate();

	let initialView = 4;
	if (props.initialView) {
		initialView = ["day", "3day", "workweek", "week", "month"].indexOf(props.initialView);
		if (initialView < 0)
			initialView = 4;
	}

	const [mode, setMode] = useSessionState("!calendarMode", initialView, []);
	const modal = useContext(ModalContext);
	const onChangeViewMode = async (sourceElement: HTMLElement) => {
		const newMode = await showMenu(modal, sourceElement, ["Day", "Three Day", "Working Week", "Week", "Month"]);
		setMode(newMode);
	}
	const _onCreateNew = useCallback((event: ICalendarEvent) => {
		const rec = {
			[startField]: event.start,
			[endField]: event.end,
			name: event.name
		};

		const init = new URLSearchParams({ init: JSON.stringify(rec) });
		const url = "/!/edit/" + props.query.entity.name + "/0";
		const to = { pathname: url, search: init.toString() };
		navigate(to);
	}, [props.query]);
	const onCreateNew = checkPermissions(metadata, {}, props.query.entity.name, PERMISSION_CREATE) ? _onCreateNew : undefined;

	const onMoveEvent = useCallback((event: ICalendarEvent) => {
		const rec = {
			id: event.id,
			[startField]: event.start,
			[endField]: event.end,
			//name: event.name
		};
		DataService.updateRecord(props.query.entity.name, rec, "update");
		
	}, [props.query]);

	const getDefaultCalendars = (cals: IUserCalendar[]) => cals.filter(x => (!x.name.match(/(holidays)|(birthdays)/i)));
	const selectCloudCalendars = async (e: any) => {
		try {
			const cals = await outlookCalendars.current;
			if (!cals) throw new Error("User has no outlook calendars");

			let selectedCals = getSessionStateItem("!outlookCalendars", getDefaultCalendars(cals).map(x => x.id));

			const index = await showMenu(modal, e.target, cals.map(x => ((selectedCals.indexOf(x.id) < 0) ? "○ " + x.name : "● " + x.name)));
			let idx = selectedCals.indexOf(cals[index].id);
			if (idx >= 0)
				selectedCals.splice(idx, 1);
			else
				selectedCals.push(cals[index].id);
			setSelectedOutlookCalendars(selectedCals);
			//setSessionStateItem("!outlookCalendars", selectedCals);
		}
		catch (e) {
			alert("Unable to find user calendars: " + e);
		}
	}

	const commands = useMemo(() => [
		{ name: "CmdCloud", icon: "brands fa-windows", label: ""},
		{ name: "CmdMode", icon: "eye", label: "" },
		{ name: "CmdBack", icon: "caret-left", label: "" },
		{ name: "CmdToday", icon: "today", label: "Today" },
		{ name: "CmdFwd", icon: "caret-right", label: "" }], []);
	const onCommand = useCallback((cmd: ICommand, e: any) => {
		switch (cmd.name) {
			case "CmdMode": onChangeViewMode(e.target); break;
			case "CmdToday": setCurrentDate(new Date()); break;
			case "CmdCloud": selectCloudCalendars(e); break;
		}
	}, []);

	switch (mode) {
		case 4:
			return <MonthCalendar initialDate={currentDate} onDateChanged={setCurrentDate} onChangeViewMode={onChangeViewMode}
				rows={rows}
				commands={commands}
				onCommand={onCommand}
				onNewEvent={props.onNewEvent || onCreateNew}
			/>
		default:
			return <WeekCalendar initialDate={currentDate} onDateChanged={setCurrentDate} onChangeViewMode={onChangeViewMode}
				startOfWeek={1}
				days={[1,3,5,7][mode]}
				rows={rows}
				commands={commands}
				onCommand={onCommand}
				onMoveEvent={onMoveEvent}
				onNewEvent={props.onNewEvent || onCreateNew}/>
	}
}
