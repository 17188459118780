import { useRef, useState, useEffect, useMemo } from "react";
import { IFormContainerPanel, IFormPanel, IFormSplitPanel } from "../../AppSchema";
import { useSizeObserver } from "../../hooks/useSizeObserver";
import { IPanelProps } from "./PanelProps";
import { Panel } from "./Panel";
import { TabsPanel } from "./TabsPanel";
import { useCustomizePanel } from "../Configure";

export const SplitPanel = (props: IPanelProps) => {
	const panel = props.panel as IFormSplitPanel;
	const { id, meta } = props.context;
	
	const minWidth = 900;

	const divRef = useRef<HTMLDivElement>(null);
	const [isVertical, setVertical] = useState(window.innerWidth < minWidth); // auto vertical for phones... don't wait for render
	const [isSingle, setSingle] = useState(false);

	//const isPhoneWidth = useMediaQuery("(max-width: 700px)");
	const updateSize = () => {
		if (divRef.current) {
			setVertical(divRef.current.offsetWidth < minWidth);
			setSingle(divRef.current.childElementCount <= 1);
		}
	}
	useSizeObserver(divRef, updateSize);
	// useEventListener('resize', e => updateSize());
	useEffect(() => updateSize(), [id, (props.context as any)["formName"]]);
	
	useEffect(() => {
		if (divRef.current) {
			divRef.current.scrollTo({ top: 0 });
		}
	}, [id]);

	const { customize, configureClick, dragEvents } = useCustomizePanel(props.context, panel);

	const fakePanel = useMemo(() => {
		const fakePanel = { type: "Tabs" as any, name: "tabs", panels: [] as any };
		const fakePanelArr: IFormPanel[] = [];
		for (const p of panel.panels) {
			if (p.type === "Tabs" || p.type === "Flex")
				fakePanelArr.push(...(p as IFormContainerPanel).panels);
			else
				fakePanelArr.push(p);
		}	
		fakePanel.panels = fakePanelArr;
		return fakePanel;
	}, [panel]);

	if (!customize.enabled && isVertical && fakePanel.panels.length > 1) {
		return <TabsPanel context={props.context} panel={fakePanel} />
	}

	const gridColumns = (panel.gridColumns || "2fr 3fr");

	return (
		<div ref={divRef} className="formSplitter" style={{
			gridTemplateColumns: (isVertical || isSingle) ? "1fr" : gridColumns,
			gridTemplateRows: (isVertical && !isSingle) ? "max-content calc(100vh - 120px)" : (customize.enabled?"auto 1fr":"1fr"),
			overflow: (isVertical && !isSingle) ? "scroll" : "hidden",
			position: customize.enabled ? "relative" : "initial",
			//alignItems: isVertical ? "stretch" : "flex-start"
		}}>
			<>
				{panel.panels.map(x => <Panel key={x.name} context={props.context} panel={x} />)}
				{customize.enabled && gridColumns.split(" ").map((f,x) => {
					const gridArea = "1/" + (x + 1) + "/2/" + (x + 2);
					const de = { ...dragEvents };
					if (!panel.panels[x])
						de.onDragStart = undefined as any;
					return <span {...de} style={{gridArea:gridArea}} className="formItemCustomize formSplitterConfig" data-kind="tabpanel" data-drag_index={x} draggable={true}
						onClick={configureClick}>{panel.panels[x]?.name||("Panel-"+x)} <i className="fa fa-gears" /></span>
				})}
			</>
		</div>
	)
}