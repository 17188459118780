import { Bounds, Map, Marker, Overlay } from "pigeon-maps"
import { useEffect, useRef, useState } from "react";
import { Fetch } from "shared/fetch"
import { AppLink } from "../components/AppLink";
import { useDerivedState } from "../hooks/useDerivedState"
import { useSessionState } from "../hooks/useSessionState";
import { DataService } from "../service";

export const ObjectMap = (props: { query: Fetch, onGpsChanged: (arr: number[]) => void }) => {
	
	const q = props.query;
	const [rows, setRows] = useState([] as any[]);
	const [loading, setLoading] = useState(true);

	const page = useRef(1);
	useEffect(() => {
		const loadData = async (pageIndex: number) => {
			console.log("ObjectMap.loadData");
			let newRows: any[];
			try {
				newRows = await DataService.retrieveMultiple(q);
			}
			catch (err) {
				alert((err as Error).message);
				setLoading(false);
				return;
			}
			// multiple async calls started we are old:( abandon ship.
			if (pageIndex !== page.current) return;
	
			setRows((oldRows) => {
				if (pageIndex > 1)
					return oldRows.concat(newRows);
				else
					return newRows;
			});
			setLoading(false);
		}

		setLoading(true);
		page.current = 1;
		loadData(1);
	}, [q]);

	const [center, setCenter] = useSessionState("MapCenter", [48.392898, 17.605240], [q.entity.name]);
	const [zoom, setZoom] = useSessionState("MapZoom", 11, [q.entity.name]);

	const onBoundsChanged = (p: { center: number[], bounds: Bounds, zoom: number, initial: boolean }) => {
		setCenter(p.center);
		setZoom(p.zoom);
		const bounds = p.bounds;
		props.onGpsChanged([bounds.ne[0], bounds.ne[1], bounds.sw[0], bounds.sw[1]]);
	};

	const baseUrl = "/edit/" + q.entity.name + "/";

	return (<div className="objectMapContainer">
		<Map defaultCenter={center as any} defaultZoom={zoom} onBoundsChanged={onBoundsChanged}>
			{rows.map(r => {
				return (
				
					<Overlay anchor={[+r.latitude, +r.longitude]} offset={[50, 40]}>
						<div className="mapItemOverlay" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							<AppLink className="mapItemText" to={baseUrl + r["id"]}  >{r.name}</AppLink>
							<div className="arrow-down" />
						</div>
					</Overlay>
				)
			})}
		</Map>
	</div>)
}