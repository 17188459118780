import HeroDog from '../HeroDog.png';
import "./web.css";

export const MainHeader = (props: { link?: string, linkName?: string }) => {

	const homeUrl = "https://www.inuko.net/";
	const propsLink = props.link === "/main" ? homeUrl : props.link;

	return (<div className='header'>
		<a href={homeUrl}>inuko.net</a>
		<span></span>
		{propsLink && <a href={propsLink}>{props.linkName}</a>}
	</div>);
}

export const MainFooter = () => {
	return <div className='footer'>
		<div className="footerAddress">
			<span>
				&copy;2022-2024 Inuko s.r.o<br />
				<a href="mailto:inuko@inuko.net">Contact Us</a><br />
				<a href="/privacy.html">Privacy Policy</a> | <a href="/privacy_cloud.html">Cloud</a>
			</span>
		</div>
		<div className="footerAddress">
			<span>
				Vajanskeho 5<br />
				Senec 90301<br />
				Slovakia EU<br />
			
			</span>
		</div>
		<div className="footerAddress">
			<span>
				ICO 54507006<br />
				VAT SK2121695400<br />
			</span>
	
		</div>
	</div>
}

export const MainPage = () => {

	window.location.href = "/main/";

	const InfoBox = (props: { icon: string, title: string, text: string, right?: string }) => {
	  const className = "infoBox " + (props.right ? "right" : "");
	  return (<div className={className}>
		<div className='infoImage'><i className={'fa fa-'+props.icon} /></div>
		<div className='infoTextBox'>
		  <div className="infoTitle">{props.title}</div>
		  <div className="infoText">{props.text.split('\\n').map(x=>(<div>{x}</div>))}</div>
		</div>
	  </div>);
	}
	const IndustryBox = (props: { icon: string, title: string }) => {
	  return <div className='industryBox'>
		<div className='industryImage'><i className={'fa fa-'+props.icon} /></div>
		<div className='industryText'>{props.title}</div>
	  </div>
	};
  
	return <div className='mainContainer'> <div className='main'>
		<MainHeader link="/login" linkName="Sign In"/>
	  <div className='mainHeroBkg'>
		<div className='mainHero'>
		  <div className='mainHeroLeft'>
			<div className='mainHeroDesc'>Professional Services Management</div>
			<div className='mainHeroClaim'>Never miss an appointment again!</div>
			<div className='mainHeroText'>Inuko solution is uniquely designed for professional service providers.<br/>Beautiful experience for your customers and easy management for you.</div>
			<a href="/trial" className='mainHeroButton'>Start Your Free Trial</a>
		  </div>
		  <img className='mainHeroImage' src={HeroDog}/>
		</div>
	  </div>
	  <div className="mainIndustry">
		<IndustryBox icon={"scissors"} title="Hair Saloon" />
		<IndustryBox icon={"beer"} title="Barber" />
		<IndustryBox icon={"spa"} title="Massage" />
		<IndustryBox icon={"paw"} title="Pet Grooming" />
		<IndustryBox icon={"mosquito"} title="Pest Control" />
		<IndustryBox icon={"broom"} title="Cleaning" />
		<IndustryBox icon={"faucet"} title="Water & Gas" />
		<IndustryBox icon={"tree"} title="Garden" />
		<IndustryBox icon={"bus-alt"} title="Transportation" />
		<IndustryBox icon={"tooth"} title="Dental Care" />
		<IndustryBox icon={"medkit"} title="Healtcare" />
		<IndustryBox icon={"wheelchair"} title="Social Services" />
	  </div>
	  <div className='mainInfo'>
		<InfoBox icon={"shopping-cart"} title="Time is Money" text="Quickly schedule the next appointment while on the phone.
  \nOr allow your customers to pick the services and date themselves!" />
		<InfoBox right={"1"} icon={"sms"} title="Always On Time" text="Setup 1,2,3 or more flexible reminders. One hour or one day, you decide.
  \nRemind your customers or your team-mates via Email, Push or SMS." />
   <InfoBox icon={"map-signs"} title="Getting there and back" text="You never get lost with a map.\nSee your destination and navigate with one click.
  \nWith job history, material tracking and instructions, the job gets done the first time." />
   <InfoBox right={"1"} icon={"globe"} title="End to End" text="From initial customer contact to invoices, we have you covered.\nCloud hosted & customized by us." />
   <InfoBox icon={"mobile-screen-button"} title="App for everyone" text="Offline app for on the go workers.\nPortal for customers self-service, building trust and loyalty.
  \nManagement dashboards
  \nSchedule board to keep appointments moving
  " />
	  </div>
	 
	 <MainFooter/>
	</div>
	</div>
	/*
	return <div className="mainTop">
	  <header>Inuko</header>
	  <div className="hero">
		<h1><span>Inuko</span><span>.net</span></h1>
		<h1>No more missed appointments!</h1>
	  <h2>Appointment management for professional service providers.</h2>
		<h2>From dog salons to home-security installers.</h2>
		</div>
	  <ul className="benefits">
		<li>Whether you are working from your premises</li>
		<li>Traveling and working at the client (or in your car or in/between)</li>
		<li>Never miss an appointment, with email or sms reminders and push notifications for you and your clients.</li>
		<li>Do it right the first time. </li>
		<li>Whole day covered. From first contact to payment (and recurring appointments plan)</li>
		<li>Stay connected app for your clients. Personalized portal for building customer loyalty and trust.</li>
	  </ul>
	  <div className="tellmore">
		Ok, tell me more.
	  </div>
	  <div className="trial">
		<NavLink to="/trial">Let me take it for a spin</NavLink>
	  </div>
	</div>
	*/
  }