import { useCallback, useEffect, useRef } from "react";

export const InputWithWrap = (props: {
	className?: string, divClassName?: string, type: string, value: string, rows?: number,
	disabled?: boolean,
	onChange: (text: string) => void,
	onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>,
	autoFocus?: boolean,
	placeholder?: string,
}) => {

	const divRef = useRef<HTMLDivElement>(null);

	const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
		let text = e.target.value;
		if (!props.rows || props.rows === 1) // single line.
			text = text.replace(/\r?\n|\r/g, "");
		props.onChange(text);
		if (divRef.current)
			divRef.current.dataset.value = text;
	}, [props.onChange, props.rows]);

	useEffect(() => {
		if (divRef.current) {
			divRef.current.dataset.value = props.value;
			
			if (props.autoFocus && !divRef.current.dataset.focused) {
				divRef.current.dataset.focused = "1";
				(divRef.current.firstElementChild as HTMLElement)?.focus();
			}
		}
	}, [divRef, props.value]);

	// useEffect(() => {
	// 	if (props.autoFocus && divRef.current)
	// 		(divRef.current.firstElementChild as HTMLElement)?.focus();
	// }, []);

	return <div ref={divRef} className={"wrapDiv " + props.divClassName}>
		{props.placeholder && !props.value && <span className="wrapPlaceholder">{props.placeholder}</span>}
		<textarea className={props.className + " wrapArea"}
			// onInput={onInput}
			disabled={props.disabled}
			rows={1}
			style={{ minHeight: ((props.rows || 1) * 18 + 4) + "px" }}
			onChange={onChange}
			onKeyDown={props.onKeyDown}
			value={props.value}>
		</textarea>
	</div>
}