import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Fetch, FetchFilter } from "shared/fetch";
import { IDictionary, ILookupValue, IMetaObject, IMetaProperty, MetaPropertyType } from "shared/schema";
import { IAppConfig, ICommand, IListColumn, IQuickFilter } from "../AppSchema";
import { MetaContext, tryLocalize } from "../AppState";
import { AppLink } from "../components/AppLink";
import { MosaicGrid } from "../components/MosaicGrid";
import SimpleGrid from "../components/SimpleGrid";
import { TitleBox } from "../components/TitleBox";
import { useSizeQuery } from "../hooks/useSizeObserver";
import { QuickFilterLookup, QuickFilterOptions } from "../objectList/QuickFilter";
import { DataService } from "../service"
import { runCommand } from "../command/runCommand";
import { useSessionState } from "../hooks/useSessionState";
import { useNavigate } from "react-router-dom";

export interface IWhatsNextRule {
	id?: string;
	name: string;
	label: string;
	description: string;
	objectName: string;
	related: string; // relatedChild.parentLookupField
	relatedMaxAge: number;
	nullRelatedMaxAge: number;
	statusField?: string;
	statusCode: string[];
	script?: string;
	canCreate?: boolean;
}
const makeFetch = (rule: IWhatsNextRule, today: Date, ownerId: string) => {
	
	const qf = {
		type: "or",
		filters: [] as FetchFilter[],
		conditions: [
		]
	};
	const q: Fetch = {
		entity: {
			name: rule.objectName,
			attributes: ["id", "name", "createdon"].map(x => ({ attribute: x })),
			filter: {
				filters: [qf as any],
				conditions: [],
			},
			orders: [{ attribute: "createdon", descending: true }]
		},
		count: 10,
		pageSize: 10,
		page: 1,
	};

	if (rule.related) {
		const pp = rule.related.split('.');
		q.entity.links = [{
			name: pp[0],
			type: "outer",
			alias: "relatedchild",
			to: pp[1],
			from: "id"
		}];
		let date = new Date(today.valueOf() - rule.relatedMaxAge * 86400 * 1000);
		qf.filters.push({
			conditions: [{ entityName: "relatedchild", attribute: "createdon", operator: "lt", value: date.toISOString() }],
		});

	}
	if (rule.nullRelatedMaxAge !== undefined) {
		let selfDate = new Date(today.valueOf() - rule.nullRelatedMaxAge * 86400 * 1000);
		qf.filters.push({
			conditions: [{ entityName: "relatedchild", attribute: "id", operator: "null" },
			{ attribute: "createdon", operator: "lt", value: selfDate.toISOString() }],
		});
	}
	let c = q.entity.filter?.conditions;
	if (c) {
		if (rule.statusCode && rule.statusCode.length > 0) {
			c.push({ attribute: rule.statusField || "statuscode", operator: "eq", value: rule.statusCode[0] });
		}
		if (ownerId) {
			c.push({ attribute: "ownerid", operator: "eq", value: ownerId })
		}
	}
	return q;
}

const StaticNext: IWhatsNextRule[] = [
	{
		name: "OpenLead",
		label: "Lead Followup",
		description: "Call lead. Qualify and/or update activity with call result.",
		objectName: "lead",
		related: "activity.objectid",
		relatedMaxAge: 7,
		nullRelatedMaxAge: 0.001,
		statusField: "stage",
		statusCode: ["New"]
	},
	{
		name: "AccountSuccess",
		label: "Account Relationship Building",
		description: "Call customer. Ask about satisfaction, introduce new updates, ask for referral and/or review",
		objectName: "account",
		related: "activity.objectid",
		relatedMaxAge: 90,
		nullRelatedMaxAge: 90,
		statusCode: []
	},
];

export interface INextRecord {
	id: string;
	name: string;
	createdon: string;
	objectName: string;
	rule: IWhatsNextRule;
}

const loadActivitiesWithReminders = async (today: Date, ownerId: string) => {

	const q: Fetch = {
		entity: {
			name: "activity",
			allattrs: true,
			filter: {
				conditions: [{ attribute: "scheduledstart", operator: "lt", value: today.toUTCString() }],
			}
		}
	}
	if (ownerId && q.entity.filter?.conditions) {
		q.entity.filter?.conditions.push({ attribute: "ownerid", operator: "eq", value: ownerId });
	}
	const results: INextRecord[] = [];
	const records = await DataService.retrieveMultiple(q);
	//fixme: optimize label loading...
	for (const r of records) {
		const objectId = r.objectid as ILookupValue;
		if (objectId && objectId.id) {
			const nameQuery: Fetch = {
				entity: {
					name: objectId.name,
					attributes: [{ attribute: "name" }, {attribute: "createdon"}],
					filter: { conditions: [{ attribute: "id", operator: "eq", value: objectId.id }] }
				}
			};
			const target = await DataService.retrieveMultiple(nameQuery) as any[];
			if (target && target.length > 0) {
				objectId.label = target[0].name;

				results.push({
					id: objectId.id, name: objectId.label, objectName: objectId.name, createdon: target[0].createdon,
					"rule": { objectName:"activity", label: "Activity Reminder", description: (r.body ? r.body.substring(0, 40).replaceAll("\n","")+"..." : "Activity") } as any,
				})
			}
		}
	}
	return results;
}

export const loadWhatsNext = async (
	metadata: IAppConfig,
	cancelToken: { cancel: boolean },
	today: Date,
	ownerId: string,
	record?: ILookupValue,
	canCreateDict?: {[name:string]: IWhatsNextRule},
) => {

	const service = DataService;
	
	const rulesQuery: Fetch = { entity: { name: "inu_nextrule", allattrs: true } };
	if (record) {
		rulesQuery.entity.filter = { conditions: [{ attribute: "objectname", operator: "eq", value: record.name }] };
	}

	const wr = await service.retrieveMultiple(rulesQuery) as any[];
	const whatsNext: IWhatsNextRule[] = (wr || []).map(x => ({
		id: x.id,
		name: x.name,
		label: x.label,
		description: x.description,
		objectName: x.objectname,
		related: x.related,
		relatedMaxAge: x.relatedmaxage,
		nullRelatedMaxAge: x.nullrelatedmaxage,
		statusField: x.statusfield,
		statusCode: x.statusvalues ? x.statusvalues.split(';') : [],
		script: x.script,
		canCreate: x.can_create
	}));
	if (cancelToken.cancel) return [];

	const records: INextRecord[] = [];//record ? [] : await loadActivitiesWithReminders(today, ownerId);

	for (const rule of whatsNext) {
		let recs: any[];
		if (rule.script) {
			const cmd: ICommand = { kind: "custom", name: rule.script };//, option: "count-only" };
			recs = await runCommand(cmd, () => record, undefined, () => { }, metadata, {showModal:null as any}, {nextList:{ownerId:ownerId}}) as any[];
		} else {
			const q = makeFetch(rule, today, ownerId);
			if (record) {
				q.entity.filter?.conditions?.push({ attribute: "id", operator: "eq", value: record.id });
			}
			recs = await service.retrieveMultiple(q) as { name: string, id: string, createdon: string }[];
		}
		if (canCreateDict && rule.canCreate)
			canCreateDict[rule.name] = rule;

		for (const rec of recs) {
			const copy = { ...rec, rule: rule, objectName: rule.objectName };
			records.push(copy);
		}
		if (cancelToken.cancel) return [];
	}

	return records;
}

export const NextList = (props: {}) => {

	const metadata = useContext(MetaContext);

	const userId = metadata.user;
	const [filter, setFilter] = useSessionState("nextListFilter", {
		"scheduled": ["today"],
		"ownerid": [JSON.stringify({ id: userId.id, name: "systemuser", label: userId.name })]
	} as { [name: string]: string[] }, []);
	const [loading, setLoading] = useState(true);
	const [records, setRecords] = useState([] as INextRecord[]);

	const canCreateDict = useRef({} as any);

	useEffect(() => {
		const cancelToken = { cancel: false };
		const exe = async () => {
			try {
				let today = new Date();
				if (filter.scheduled[0] === "tomorrow")
					today = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2, 0, 0, 0);
				else if (filter.scheduled[0] === "next-week")
					today = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 8, 0, 0, 0);
				const records = await loadWhatsNext(metadata, cancelToken, today, JSON.parse(filter.ownerid[0]).id, undefined, canCreateDict.current);
				if (!cancelToken.cancel) {
					setRecords(records);
					setLoading(false);
				}
			} catch (e) {
				console.log("whatsnext failed:" + e);
				setLoading(false);
			}
		};
		setLoading(true);
		exe();
		return () => { cancelToken.cancel = true; }
	}, [filter.scheduled, filter.ownerid]);

	// load whats next and records
	const [objectDict] = useState({} as { [name: string]: IMetaObject|undefined });

	const formatter = useCallback((row: INextRecord, field: string) => {
		if (field === "name") {
			let link = `/edit/${row.objectName}/${row.id}`;
			if (row.id === "0") {
				const newParams = { ...row } as any;
				delete newParams.id;
				delete newParams.createdon;
				delete newParams.objectName;
				delete newParams.rule;
				const pp = new URLSearchParams({ init: JSON.stringify(newParams) });
				link += "?" + pp.toString();
			}
			return <AppLink className="lookupLink" to={link}>{row.name || "Unknown"}</AppLink>
		} else if (field === "createdon") {
			if (row.createdon)
				return new Date(row.createdon).toLocaleDateString();
			return "";
		} else if (field === "objectName") {
			const objectName = row.objectName;
			if (objectDict[objectName])
				objectDict[objectName] = metadata.objects.find(x => x.logicalName === row.objectName);
			return row.rule.label;// + objectDict[objectName]?.displayName;
		} else if (field === "info") {
			const helpText = row.rule.description || "Help";
			// if (row.rule.id) {
			// 	return <AppLink className="lookupLink" to={`/edit/inu_nextrule/${row.rule.id}`}>{helpText}</AppLink>
			// }
			return helpText;
		}
		return (row as any)[field];
	}, []);

	const columns = useMemo(() => {
		return ["Category", "Name", "Created On", "Action"].map(x => tryLocalize(metadata, "nextlist." + x, undefined, x));
	}, []);
	
	let rows = records;

	const objects = records.map(x => x.rule.objectName).reduce((acc, name) => (acc[name] = name, acc), {} as { [name: string]: string });

	if (filter.category && filter.category.length > 0)
		rows = rows.filter(x => filter.category.indexOf(x.rule.objectName) >= 0);

	const [mainRef, isMobile] = useSizeQuery<HTMLDivElement>(420);

	const { attrsMeta, listColumns } = useMemo(() => {
		const attrsMeta: IDictionary<IMetaProperty> = {};
		attrsMeta["objectName"] = { logicalName: "objectName", type: MetaPropertyType.String, flags: 0 };
		attrsMeta["name"] = { logicalName: "name", type: MetaPropertyType.String, flags: 0 };
		attrsMeta["createdon"] = { logicalName: "createdon", type: MetaPropertyType.DateTime, flags: 0 };
		attrsMeta["info"] = { logicalName: "info", type: MetaPropertyType.String, flags: 0 };
		const listColumns: IListColumn[] = [
			{ "attribute": "objectName" }, { "attribute": "name" }, { "attribute": "createdon" }//, { "attribute": "info" }
		];
		listColumns.forEach(c => c.mobileLabel = " ");
		return { attrsMeta, listColumns };
	}, []);

	const navigate = useNavigate();
	const commands = Object.keys(canCreateDict.current).length > 0 ? [{ name: "CmdNew" }] : [];
	const onCommand = useCallback((cmd: ICommand, e: any) => {
		for (const kv of Object.entries(canCreateDict.current)) {
			const rule = kv[1] as IWhatsNextRule; //todo: init params
			navigate("/!/edit/" + rule.objectName + "/0");
			break;
		}
	}, [canCreateDict, navigate]);
	
	return <div ref={mainRef} className="objectListContainer">
		<TitleBox title={tryLocalize(metadata, "Msg.WhatsNext", "What's Next")} commands={commands} onCommand={onCommand}></TitleBox>
		<NextQuickFilter metadata={metadata} objectNames={Object.keys(objects)} filter={filter} setFilter={setFilter} />
		{!isMobile && <SimpleGrid noEdit={true} formatter={formatter} columns={columns} fields={["objectName", "name", "createdon", "info"]} rows={rows} isLoading={loading} />}
		{isMobile && <MosaicGrid formatter={formatter} fields={["objectName", "name", "createdon", "info"]} listColumns={listColumns} attrsMeta={attrsMeta} rows={rows} isLoading={loading}  />}
	</div>
}

const NextQuickFilter = (props: {
	metadata: IAppConfig,
	objectNames: string[],
	filter: { [name: string]: string[] },
	setFilter: (newFilter: { [name: string]: string[] }) => void
}) => {

	const filters: IQuickFilter[] = [{
		type: "date",
		objectName: "inu_whatsnextrule",
		propertyName: "scheduledstart",
		name: "scheduled",
		directOptions: ["today", "tomorrow", "next-week"]
	},
	{
		type: "lookup",
		objectName: "inu_whatsnextrule",
		propertyName: "ownerid",
		name: "ownerid",
		directOptions: ["systemuser"]
	},
	{
		type: "options",
		objectName: "inu_whatsnextrule",
		propertyName: "category",
		name: "category",
		//directOptions: props.objectNames //props.objectNames.map(x=>props.metadata.objects.find(y=>y.logicalName ===x)?.displayName || x),
	}];
	
	return <div className="quickFilterPanel">
		{filters.map((x, i) => {

			// const meta = metadata.objects.find(y => y.logicalName === x.objectName);
			// if (!meta) throw Error("Object not found:" + x.objectName);
			// const propMeta = meta.properties.find(y => y.logicalName === x.propertyName);
			// if (!propMeta) throw Error("Field not found: " + x.objectName + "." + x.propertyName);
			const propMeta = { logicalName: "", type: MetaPropertyType.String, flags: 0 } as any;
			if (x.name === "category") {
				propMeta.options = props.objectNames;
				propMeta.richOptions = props.objectNames.map(o => ({
					value: o,
					color: "#080",
					label: props.metadata.objects.find(y => y.logicalName === o)?.displayName || x
				}));
			}
			if (x.type === "lookup") {
				propMeta.type = MetaPropertyType.Lookup;
				propMeta.targets = x.directOptions;
				propMeta.displayName = tryLocalize(props.metadata, "systemuser");
			}
		
			const Element = x.type === "lookup" ? QuickFilterLookup : QuickFilterOptions;

			return <Element propMeta={propMeta} key={x.name} filter={x} onChange={(s,o)=>props.setFilter({...props.filter, [s.name]:o})} selected={props.filter[x.name]} />

			//return <QuickFilterOptions propMeta={propMeta} key={x.name} filter={x} onChange={(s,o)=>props.setFilter({...props.filter, [s.name]:o})} selected={props.filter[x.name]} />
		})}
	</div>
}