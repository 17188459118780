import { useContext, useEffect } from "react";
import { IMetaObject, IDictionary, IExecuteResult, MetaPropertyType, IMetaProperty } from "shared/schema";
import { IAppConfig, MetaContext } from "../AppState";
import { useDerivedState } from "../hooks/useDerivedState";
import { useRender } from "../hooks/useRender";
import { DataService } from "../service";

export const initializeRecord = (metadata: IAppConfig, search: string|undefined, meta: IMetaObject) => {
	let initRecord: IDictionary<any> = {};
	initRecord = {};
	for (const propMeta of meta.properties) {
		let defaultValue = propMeta.defaultValue;
		if (defaultValue) {
			// FIXME: convert type and special values (@@userid, @@now)
			initRecord[propMeta.logicalName] = defaultValue;
		}
	}
	if (search) {
		const params = new URLSearchParams(search);
		if (params.has("init")) {
			const props = JSON.parse(params.get("init") as string);
			for (const p in props) {
				if (props.hasOwnProperty(p)) {
					initRecord[p] = props[p];
				}
			}
		}
	}
	if (!initRecord["ownerid"] && meta.properties.find(x => x.logicalName === "ownerid")) {
		initRecord["ownerid"] = { id: metadata.user.id, name: "systemuser", label: metadata.user.name };
	}
	if (!initRecord["currencyid"] && meta.properties.find(x => x.logicalName === "currencyid")) {
		const cc = Object.values(metadata.currencyDict);
		const c = cc.find(x => x.isdefault) || cc[0];
		if (c)
			initRecord["currencyid"] = { id: c.id, name: "currency", label: c.name };
	}

	return initRecord;
}

export interface IFormState {
	id: string,
	meta: IMetaObject,
	loadRecord: (cancelToken?: { cancel: boolean }) => Promise<void>,
	saveRecord: ()=>Promise<void>,

	getRecord: () => any,
	setRecord: (record: any, partialRecord?: any, notDirty?: boolean)=>void,
	
	isNewRecord: boolean,
	loadedRecord: any,
	isDirty: boolean,
	loading: boolean,
	saving: boolean,
	render: () => void,

	// internal
	record: any,
	loadError?: string,
}

export const internalSetLoadedRecord = (self: IFormState, record: any) => {
	self.record = record || (null as any);
	self.loadedRecord = record ? { ...record } : null; // customizeList (maybe others) modify the record in place
	self.loading = false;
	self.isDirty = false;
	self.render();
	console.log("LOADED " + self.record?.name);
}

export const useFormState = (id: string, meta: IMetaObject, initData: string) => {

	const metadata = useContext(MetaContext);
	const sr = useRender();
	// const render = useCallback(() => {
	// 	sr(x => !x);
	// }, [sr]);

	const [state] = useDerivedState(() => {
		console.log("useRecord called:" + id + "meta:" + meta.logicalName);
		const isNewRecord = id === "0";
		const self: IFormState = {
			id: id,
			isNewRecord: isNewRecord,
			meta: meta,
			record: isNewRecord ? initializeRecord(metadata, initData, meta) : {},
			loadedRecord: {} as IDictionary<any>,
			loadError: undefined as string|undefined,
			isDirty: false,
			loading: !isNewRecord,
			saving: false,
			render: sr,
			getRecord: () => self.record,
			setRecord: (rec: any, partialRecord?: any, notDirty?: boolean) => {
				if (partialRecord) {
					self.record = { ...self.record, ...partialRecord };
				} else if (rec) {
					self.record = rec;
				}
				if (!notDirty && (rec || partialRecord))
					self.isDirty = true;
				self.render();
			},
			loadRecord: async (cancelToken?: { cancel: boolean }) => {
				self.loading = true;
				self.loadError = undefined;
				let record: any = undefined;
				try {
					record = await DataService.retrieveSingleById(self.meta, self.id);
				}
				catch (e) {
					self.loadError = "" + e;
					console.log(e);
				}
				if (cancelToken && cancelToken.cancel) {
					console.log("CANCELED " + record?.name);
					return;
				}
				internalSetLoadedRecord(self, record);
			},
			saveRecord: async () => {
				const saveData = async () => {
					self.saving = true;
					let savedRecord = self.record;
					if (self.loadedRecord) {
						savedRecord = {};
						for (const name in self.record) {
							if (self.record.hasOwnProperty(name) && (
								name === "id" || name === "versionnumber" || self.record[name] !== self.loadedRecord[name])) {
								savedRecord[name] = self.record[name];
							}
						}
					}
					self.render();
					try {
						const newId = await doUpdateRecord(self, savedRecord);
						console.log("RECORD SAVED");
						// await new Promise((res, rej) => {
						// 	setTimeout(() => res(0), 2000);
						// });
						self.isDirty = false;
						if (self.isNewRecord && newId) {
							self.record.id = newId;
						}
					}
					finally {
						self.saving = false;
						self.render();
					}
				};
				await saveData();
			}
		}

		return self;
	}, [id, meta, initData]);

	useEffect(() => {
		const cancelToken = { cancel: false };
		if (!state.isNewRecord) {
			state.loadRecord(cancelToken);
		}
		return () => { cancelToken.cancel = true; }
	}, [state]);

	return state;
}

export interface IMergeResult {
	patch: any;
	serverRecord: any;
	error?: string;
}

export const getMergedServerRecord = async (formState: IFormState): Promise<IMergeResult> => {
	const serverRecord = await DataService.retrieveSingleById(formState.meta, formState.record.id);
	return getMergedServerRecordWith(formState, serverRecord);
}

export const getMergedServerRecordWith = (formState: IFormState, serverRecord: any) => {
	const isEqual = (a: any, b: any, propMeta: IMetaProperty) => ((a === b ||
		(propMeta.type === MetaPropertyType.Lookup && a?.id === b?.id)));

	const orig = formState.loadedRecord;
	const thisRecord = formState.record;
	const patch: any = {};
	for (const propMeta of formState.meta.properties) {

		const propName = propMeta.logicalName;
		// we don't need this code because we never set this fields.
		// if we start then we need to ignore them.
		//if(propName === "modifiedby" || propName === "modifiedon" || propName === "versionnumber" )
		// continue;

		const originalValue = orig[propName];
		const newValue = thisRecord[propName];
		const serverValue = serverRecord[propName];
					
		const serverHasNewValue = !isEqual(originalValue, serverValue, propMeta);

		if (serverHasNewValue) {
			if (!isEqual(newValue, originalValue, propMeta) && !isEqual(newValue, serverValue, propMeta)) {
				return { patch: undefined, serverRecord, error: "This record was modified by " + serverRecord.modifiedby.label };
			}
			patch[propName] = serverValue;
		}
	}

	return { patch, serverRecord, error: undefined };
}

const doUpdateRecord = async (state: IFormState, savedRecord: any): Promise<any> => {
	const results = await DataService.executeMultiple([{ name: state.meta.logicalName, object: savedRecord }], false) as IExecuteResult[];
	const result = results[0];
	if (!result)
		throw new Error("Unknown Update Error");
	if (result.error) {
		if (savedRecord.id && result.error.startsWith("ERR_8001")) {
			console.log("Conflict on update -> resolving")
			const { patch, serverRecord, error } = await getMergedServerRecord(state);
			if (!error) {
				console.log("Conflict auto-resolved -> saving")
				// new record on the server did not modify any fields we care about.
				savedRecord["versionnumber"] = serverRecord["versionnumber"];
				return await doUpdateRecord(state, savedRecord);
			}
			console.log("Conflict on update -> cannot resolve:" + error);
		}
		throw new Error(result.error);
	}
	return result.result;
}