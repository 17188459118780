import { useRef, useState, useLayoutEffect, useCallback } from "react";
import { IMetaProperty, MetaPropertyFlags } from "shared/schema";
import { IFormFieldsPanel } from "../AppSchema";
import { useSizeObserver } from "../hooks/useSizeObserver";
import { FieldEditor } from "../objectForm/panels/FieldsPanel";
import { IFieldProps } from "../objectForm/panels/PanelProps";
import { IconButton } from "./IconButton";
import { useDragProps } from "./draggable";

export const getLanguage = () => {
	let winLang = window.navigator.language;
	if (winLang)
		winLang = winLang.substring(0, 2);
	else
		winLang = "en";
	return winLang;
}

const isEmptyValue = (value: any) => {
	return value === "" || value === undefined || value === null;
}

export const validateFieldSet = (metaDict: any, panel: IFormFieldsPanel, rec: any) => {
	let sb = "";
	for (const field of panel.fields) {
		const fieldName = field.name;
		const propMeta: IMetaProperty = metaDict[fieldName];
		if (!propMeta)
			continue;

		const isRequired = (((propMeta.flags & MetaPropertyFlags.Required) !== 0) || (field as any)?.required);

		if (isRequired && isEmptyValue(rec[fieldName])) {
			sb += "\n- " + (field.label || propMeta.displayName || propMeta.logicalName);
		}
	}
	if (sb) {
		let hdr = "Please provide a value for";
		switch (getLanguage()) {
			case "sk": hdr = "Zadajte hodnotu pre"; break;
			case "cs": hdr = "Zadejte hodnotu pro"; break;
			case "de": hdr = "Bitte einen Wert eingeben"; break;
		}
		const msg = hdr + ":" + sb;
		return msg;
	}
	return undefined;
}


const makeTooltip = (tooltipText: string) => {

	const onToolip = (e: React.MouseEvent) => {
		// const div = <div className="formItemTooltipText">{tooltipText}</div>
		// showMenu(modal, e.target as HTMLElement, [div]);
		window.alert(tooltipText);
	};

	if (tooltipText)
		return <i title={tooltipText} className="fa fa-circle-info" style={{ paddingLeft: "5px", color: "lightgray" }} onClick={onToolip} />
	return undefined;
}

export const SimpleFieldSet = (props: {
	id: string;
	getRecord: () => { [key: string]: any },
	setRecord: (r: any) => void,
	propsDict: { [name: string]: IMetaProperty },
	panel: IFormFieldsPanel,
	submitButton: string;
	onSubmit: () => void;
	disabled?: boolean;
	customize?: boolean;
	customizePanel?: (panel: IFormFieldsPanel) => void;
	configureClick?: (e: React.MouseEvent) => void;
	configureAddMultiple?: () => void;
	dragKind?: string;
}) => {

	const { id, getRecord, setRecord, panel, configureClick } = props;
	const dict = props.propsDict;
	const record = getRecord();
	const currency = undefined; // fixme!
	const customize = { enabled: props.customize === true };
	const isDisabled = props.disabled === true;
	//const panel = { minColumnWidth: 400, showLabel: false, fields: [] as IFormField[] };

	const divRef = useRef<HTMLDivElement>(null);
	const [columns, setColumns] = useState("1fr 1fr");

	const updateColumnsInternal = () => {
		if (divRef.current)
			setColumns(divRef.current.offsetWidth < (panel.minColumnWidth || 400) ? "1fr" : "1fr 1fr");
	}
	const updateColumns = () => {
		setTimeout(updateColumnsInternal, 0);
	}
	useSizeObserver(divRef, updateColumns);
	useLayoutEffect(updateColumnsInternal, [id, panel]);

	const getRecordProperty = useCallback((name: string) => {
		if (name === "currencyid") return currency;
		return getRecord()[name];
	}, [getRecord, currency]);

	const dragEvents = useDragProps(customize.enabled, (dragIndex, dropIndex) => {
		const field = panel.fields.splice(dragIndex, 1)[0];
		panel.fields.splice(dropIndex, 0, field);
		if (props.customizePanel)
			props.customizePanel(panel);
	}, [panel]);
	(dragEvents as any)["data-kind"] = props.dragKind || "objectfield";
	
	let ff = panel.fields;
	if (customize.enabled) {
		ff = ff.concat([{ "label": "", "name": "name", "type":"Field" }]);
	}

	return <div ref={divRef} className="formBody">
		<fieldset disabled={isDisabled} className="formGrid" style={{ gridTemplateColumns: columns, paddingTop: panel.showLabel ? "0px" : "5px" }}>
			{ff.map((f, y, selfArr) => {

				let de = dragEvents;
				const itemCustomize = customize.enabled && <span className="formItemCustomize" onClick={configureClick}><i className="fa fa-gears" /></span>;
				const formItemAction = "";
				const tooltipText = (f.tooltip || "")?.replaceAll("\\n", "\n");
				const tooltip = makeTooltip(tooltipText);
				let isSeparator = f.name === "-";
				let fieldLabel = f.label;
	
				if (!isSeparator) {
					if (!dict[f.name]) {
						if (customize) {
							isSeparator = true;
							fieldLabel = "ERR field not found:" + f.name
						} else {
							return undefined;
						}
					}
				}

				if (isSeparator) {
					const className = (f.cssClass || " ") + (customize.enabled ? " formItemSeparator formItemSeparatorCustomize" : " formItemSeparator");
					const content = fieldLabel && (fieldLabel[0] === "<" ? <span dangerouslySetInnerHTML={{ __html: fieldLabel }} /> : <span>{fieldLabel}</span>);
					return <div title={tooltipText} className={className} key={"separator" + y} {...de} data-drag_index={y}>{content}{itemCustomize}{tooltip}</div>
				}
				const x: IMetaProperty = dict[f.name];
				const fp: IFieldProps = {};
				const className = "formItem";
				const innerHtml =  <span>{fp.label || f.label || x.displayName || x.logicalName}</span>
			
				const key = f.name + "_" + y;
		
				const style = {} as React.CSSProperties;
				if (fp.visible === false)
					style.display = "none";
				if (f.gridColumn)
					style.gridColumn = f.gridColumn;
				if (f.gridRow) {
					style.gridRow = f.gridRow;
					style.alignSelf = "stretch";
				}
				if (isDisabled || f.disabled)
					fp.enabled = false;
				if (!fp.extraFetch)
					fp.extraFetch = f.extraFetch;
				fp.getRecordProperty = getRecordProperty;
				const formRequired = ((f as any).required || fp.required || (x.flags & MetaPropertyFlags.Required) !== 0) && isEmptyValue(record[x.logicalName]) ? <span title="Required" className="formRequired">*</span> : undefined;

				// customize handling
				if (customize.enabled && y === selfArr.length - 1) {
					de = { ...de, onDragStart: (e) => e.stopPropagation() };
					return <div key="customizeAdd" className="formItemCustomizeAdd" {...de} data-drag_index={y} style={{ gridTemplateColumns: "1fr 1fr", justifyItems: "center" }}>
						<span onClick={configureClick}  style={{ display: "flex", alignItems: "center", gap: "3px" }}>
							<i className="fa fa-plus"></i>
							<span style={{ fontSize: "14px" }}>Add Field</span>
						</span>
						{props.configureAddMultiple && <span onClick={props.configureAddMultiple} style={{ display: "flex", alignItems: "center", gap: "3px" }}>
							<i className="fa fa-list-check"></i>
							<span style={{ fontSize: "14px" }}>Add Multiple</span>
						</span>}
						{/* <i className="fa fa-circle-nodes" onClick={addMetaFieldClick}></i> */}
					</div>
				}

				return (<div className={className} key={key} style={style} {...de} data-drag_index={y}>
					<label title={tooltipText} htmlFor={x.logicalName} className="formLabel">{innerHtml}{itemCustomize}{formRequired}{formItemAction}{tooltip}</label>
					{/*f.customComponent && <CustomField context={props.context} field={{ ...f, ...(fp||{}) }}/> */}
					{!f.customComponent && FieldEditor(x, record[x.logicalName], fp, (newValue) => {
				
						const newRecord = { ...record };
						newRecord[x.logicalName] = newValue;
						setRecord(newRecord);
						//forceRender(x => !x);
					})}
					{fp.errorMessage && <div className="formError">{fp.errorMessage}</div>}
				</div>)
			})}
			{props.submitButton && <div className="formItem" style={{ gridColumn: "1/-1" }}>
				<div className="buttonField" key="submit" style={{ transition: "color 0.2s" }}>
					<IconButton icon={"paper-plane"} label={props.submitButton} onClick={props.onSubmit} className="surveySubmit" />
				</div>
			</div>}
		</fieldset>
	</div>
}
