import { ReactNode, useEffect, useRef } from "react"

export interface TabControlProps {
	left?: ReactNode;
	right?: ReactNode;
	items: string[];
	renderItem?: (item: string, index: number, arr: string[]) => React.ReactNode,
	selectedIndex: number;
	className?: string;
	onChange: (index: number) => void;
}

export const TabControl = (props: TabControlProps) => {

	const mainRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const mainDiv = mainRef.current as HTMLElement;
		let c = mainRef.current?.firstElementChild as HTMLElement;
		let i = 0;
		while (c) {
			if (i === props.selectedIndex) {
				if (c.offsetLeft < mainDiv.scrollLeft || c.offsetLeft + c.offsetWidth > mainDiv.scrollLeft + mainDiv.offsetWidth)
					mainDiv.scrollTo({ "left": c.offsetLeft, "behavior": "smooth" });
				break;
			}
			c = c.nextElementSibling as HTMLElement;
			i++;
		}
	},[mainRef, props.selectedIndex]);

	const renderItem = props.renderItem || (x => x);
	return (<div className={"tabControlMain " + (props.className || "")}>
		{props.left}
		<div className="tabControlContainer" ref={mainRef}>
			{props.items.map((x, i, arr) => {
				return (<a href="#" key={x}
					className={i === props.selectedIndex ? "tabControlItem tabControlItemSelected" : "tabControlItem"}
					onClick={e => { e.preventDefault(); props.onChange(i) }}
				>
					{renderItem(x, i, arr)}
				</a>);
			})}
		</div>
		{props.right}
	</div>);
}