import { Capacitor } from "@capacitor/core";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { MetaContext, tryLocalize } from "../AppState";
import { TitleBox } from "../components/TitleBox";
import { useSizeObserver } from "../hooks/useSizeObserver";
import { IModalContext, IModalParent, IModalProps, ModalContext } from "../modal/Modal";
import { DataService } from "../service";
import { getAvailableLanguages, getLanguage, setLanguage } from "../services/localize";
import { deleteDatabase } from "../services/offline/offlineService";
import { getSyncStatus, IProgressInfo, synchronize } from "../services/sync";
import { getColorScheme, setColorScheme } from "../utils/colorScheme";
import { LoginPanel } from "./LoginPanel";
import { IconButton } from "../components/IconButton";

const isElectron = () => {
	return (/electron/i.test(navigator.userAgent)) || Capacitor.isNativePlatform()
}

export const PopupHost = (props: {
	className?: string,
	caption: string,
	Element: (props: any) => any;
}) => {

	const modal = useContext(ModalContext);
	const showPopup = (e: React.MouseEvent) => {
		const rect = (e.target as HTMLElement).getBoundingClientRect();
		let y = rect.bottom;
		if (y + 100 > window.innerHeight)
			y = y - 250;
		modal.showModal({
			"body": (p) => <props.Element {...p} />,
			"bodyProps": {},
			"closeOnClickOutside": true,
			"showTitle": false,
			"anchor": { x: 10, y: y },
			"id": "profileMenu",
		});
	}

	//const caption = props.caption.split(' ').map(x => x.trim()).filter(x => !!x).map(x => x[0].toUpperCase()).join("");
	//const caption = "regdhdfh hdfh th hdh fhdhf thd gdfdg";
	const caption = props.caption;
	// By default the user text is hidden and the logo is clickable.
	return <div className={props.className}>
		<div className="profileButtonInner" onClick={showPopup}>
			<i className="appIcon" />
		</div>
		<div className="appMenuUser" onClick={showPopup}>{caption}</div>
		{!DataService.isOnline() && <div className="appOfflineSticker">Offline</div>}
	</div>
}

const showProgressDialog = (modal: IModalContext, progress: IProgressInfo) => {
	
	const ProgressForm = (props: { progress: IProgressInfo, commandSite: IModalParent }) => {

		const [content, setContent] = useState({ title: "Initializing", subTitle: "", percent:0 });

		props.progress.callback = (content: { title: string, subTitle: string, percent: number, close?: boolean }) => {
			//console.log("SYNC_PROGRESS:" + JSON.stringify(content));
			setContent({ ...content });
			if (content.close) {
				props.progress.callback = null as any;
				props.commandSite.closeModal();
			}
		};

		const title = { __html: content.title };

		return (<div className="objectListContainer" style={{ flex: "1 1 auto" }}>
			<TitleBox title="Synchronizing" onCommand={(c) => { }} />
			<div className="syncHeader" dangerouslySetInnerHTML={title}></div>
			<div className="syncHeader2">{content.subTitle}</div>
			<div style={{width:(content.percent+"%"), margin:"4px", height:"2px", backgroundColor:"navy"}} />
		</div>)
	}

	const pp: IModalProps = {
		body: (p) => < ProgressForm {...p} />,
		bodyProps: {
			progress: progress
		},
		showTitle: false,
		id: "dlgProgress",
		style: {margin:"0px"},
		anchor: { x: 20, y: window.innerHeight / 2 - 100, width: window.innerWidth - 40 }
	};
	modal.showModal(pp);
}

const ProfilePanel = (props: {commandSite: IModalParent}) => {

	const meta = useContext(MetaContext);
	const user = meta.user;
	const navigate = useNavigate();
	const location = useLocation();
	const appId = useParams<string>()["appid"];
	const modal = useContext(ModalContext);

	const onLogout = async () => {

		props.commandSite?.closeModal();

		if (Capacitor.isNativePlatform() && window.confirm("Delete Local Data?")) {
			await deleteDatabase();
		}

		window.localStorage.removeItem("electronLogin");
		await fetch("/api/logout", { method: "POST" });
		try {
			await fetch("/api/401"); // get a 401 so the basic auth is removed
		}
		catch {}
		navigate("/login?org=" + meta.orgName);
	}
	const onToggleOnline = async () => {
		await onSync();

		const newState = !DataService.isOnline();
		DataService.setOnline(newState);
		if (newState === false) { // offline 
			//await synchronize(meta);
			// TODO: show UI...
		}
		//await fetch("/api/logout", { method: "POST" });
		navigate(location);
	}


	const onSync = async () => {

		props.commandSite?.closeModal();

		const progressInfo: IProgressInfo = {} as any;
		showProgressDialog(modal, progressInfo);
		const msg = await synchronize(meta, progressInfo);

		if (progressInfo.callback)
			progressInfo.callback({ title: "", subTitle: "", percent: 0, close: true });
		
		if (msg)
			alert(msg);
	}

	const [syncStatus, setSyncStatus] = useState("Sync");
	useEffect(() => {
		const exe = async () => {
			const status = await getSyncStatus(meta.objects);
			setSyncStatus("Sync [" + status.recordToUpload + "]");
		};
		exe();
	});

	const [colors, setColors] = useState(getColorScheme());
	const onColorSchemeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const mode = +e.target.value;
		setColorScheme(mode);
		setColors(mode);
	}

	const lang = getLanguage(meta);
	const langs = getAvailableLanguages(meta);
	const onLanguageChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setLanguage(e.target.value);
		window.location.reload();
	}

	const openProfile = () => {
		props.commandSite?.closeModal();
		// navigate after the profilePanel modal was closed.
		window.setTimeout(() => { navigate("/!/edit/systemuser/" + user.id) }, 100);
	}

	return <div style={{padding:"10px", paddingTop:"0", display:"flex", flexDirection:"column", whiteSpace:"nowrap", gap:"4px"}}>
		<h1>{user.name}</h1>
		<span>{user.emailaddress}</span>
		<span>{meta.orgName}</span>
		{/* {meta.apps.map(app => {
			return appId === app ? <div key={app}><b>{app}</b></div> : 
			<NavLink key={app} className="profileLink" to={"/" + app + "/"}>{app}</NavLink>
		})} */}
		<select value={colors} onChange={onColorSchemeChanged}>
			{["System Colors", "Dark Mode", "Light Mode"].map((x, i) => {
				return <option key={i} value={i}>{x}</option>;
			})}
		</select>
		<select value={lang} onChange={onLanguageChanged}>
			{langs.map((x)=> {
				return <option key={x.id} value={x.id}>{x.label}</option>;
			})}
		</select>
		<div className="commandBox" style={{flexDirection:"row", gap:"5px", marginLeft: "-9px", justifyContent: "space-between"}}>
			<IconButton label={tryLocalize(meta, "CmdEditProfile")} icon="id-badge" onClick={openProfile} />
			<IconButton icon="door-open" label={tryLocalize(meta, "CmdLogout")} onClick={onLogout} />
		</div>
		{isElectron() &&
		<div className="commandBox" style={{flexDirection:"column", gap:"5px", marginLeft: "-9px", justifyContent: "space-between"}}>
			<IconButton style={{ marginTop: "20px" }} onClick={onToggleOnline} icon="globe" label={DataService.isOnline() ? "Go Offline" : "Go Online"} />
			<IconButton style={{ marginTop: "20px" }} onClick={onSync} icon="sync" label={syncStatus}/>
		</div>}
	</div>
}

export const ProfileButton = () => {
	
	const meta = useContext(MetaContext);
	const isAnonymous = meta.user.emailaddress === "anonymous@anonymous.com";

	return (<PopupHost className="profileButton" caption={meta.user.name} Element={isAnonymous ? LoginPanel : ProfilePanel} />)
/*
	const [isOpen, setOpen] = useState(false);
	const parentRef = useRef<HTMLDivElement>(null);
	const popupRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// If re-rendered close.
		setOpen(false);
	}, [props]);

	const toggleOpen = useCallback(() => {
		setOpen(!isOpen);
	}, [isOpen]);

	useSizeObserver(popupRef, () => {
		const popupDiv = popupRef.current;
		const parentDiv = parentRef.current;
		if (isOpen && popupDiv && parentDiv) {
			const rect = parentDiv.getBoundingClientRect();
			const popupRect = popupDiv.getBoundingClientRect();
			popupDiv.style.top = (rect.height) + "px";
			//popupDiv.style.right = "0px";
			popupDiv.style.left = (rect.width - popupRect.width) + "px"; //-207px"; // FIXME: unhardcode!			
		}
	});

	const newKey = new Date().valueOf();

	return (
		<div ref={parentRef} style={{display:"flex", position:"relative"}}>
			<button onClick={toggleOpen}>{props.userName||"Login"}</button>
			<div ref={popupRef} className="lookupDropDown" style={{ display: isOpen ? "block" : "none", width:"unset" }}>
				<LoginPanel key={newKey} />
			</div>
		</div>
	);
	*/
}
