import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ICommand, IFormPanel, IListColumn } from "../AppSchema";
import SimpleGrid from "../components/SimpleGrid";
import { IFormContext, IPanelProps, PanelPropsEventType } from "../objectForm/panels/PanelProps";
import { NumberInput } from "../components/NumberInput";
import { IconButton } from "../components/IconButton";
import { TitleBox } from "../components/TitleBox";
import { ModalContext, showMenu } from "../modal/Modal";
import { newGuid } from "../utils/guid";
import { formatMoneyValue, formatNumericValue } from "../formatters";
import { useDerivedState } from "../hooks/useDerivedState";
import { ILookupValue, MetaPropertyType } from "shared/schema";
import { download, downloadFileUrl } from "../services/download";
import { IPdfStyle, addGrid, addText, createGrid, renderDocument } from "../report/pdfReport";
import { Fetch, makeFetch } from "shared/fetch";
import { DataService } from "../service";
import { InputWithWrap } from "../components/WrapInput";
import { showConfirm } from "../modal/alerts";

const PRECISION = 0;


export const BudgetPanel = (props: IPanelProps) => {

	const saveCallback = useRef<any>(undefined);
	const [budgetLines, _setBudgetLines] = useState([] as IBudgetRow[]);
	const budgetLineTypes = useRef<IBudgetLineType[]>([]);
	useEffect(() => {
		const eventHandler = async (eventName: PanelPropsEventType) => {
			if (eventName === "beforeSave") {
				try {
					if (saveCallback.current)
						await saveCallback.current();
				}
				catch (e) {
					alert(e);
					return false;
				}
			}
			return true;
		}

		let cancel = false;
		const events = props.context.events || [];
		events.push(eventHandler);
		const unregister = () => events.splice(events.indexOf(eventHandler), 1);
		return () => {
			cancel = true;
			unregister();
		};
	}, [props.context.meta]);

	const setBudgetLines = useCallback((x: IBudgetRow[] | ((prev: IBudgetRow[]) => IBudgetRow[])) => {
		_setBudgetLines(oldIn => {
			let newIn = oldIn;
			if (x instanceof Function)
				newIn = x(oldIn);
			else
				newIn = x;
			if (newIn !== oldIn)
				props.context.setRecord({ ...props.context.getRecord() });
			return newIn
		});
	}, [_setBudgetLines]);
	
	saveCallback.current = useCallback(async () => {
		const reqs = budgetLines.filter(x => !!x.__op).map(x => {
			const { __op, ...obj } = x;
			return { operation: __op, name: "fi_budget_line", object: obj } as any;
		});
		if (reqs.length > 0)
			await DataService.executeMultiple(reqs);
	}, [budgetLines]);

	useEffect(() => {
		if (props.context.id !== "0") {
			const exe_rows = async () => {
				const q: Fetch = {
					entity: {
						name: "fi_budget_line",
						allattrs: true,
					}
				};
				if (props.context.meta.logicalName === "fi_family") {
					q.entity.links = [{
						name: "fi_family_member", from: "personid", to: "personid", alias: "fam",
						filter: {
							conditions:[{attribute:"familyid", operator:"eq", value:props.context.id}]
						}
					}]
				} else {
					q.entity.filter = { conditions: [{ attribute: "personid", operator: "eq", value: props.context.id }] }
				}

				const tq: Fetch = {
					entity: {
						name: "fi_budget_line_type",
						allattrs: true,
						orders: [{ attribute: "name" }]
					}
				};
				budgetLineTypes.current = await DataService.retrieveMultiple(tq);

				const rows = await DataService.retrieveMultiple(q) as { id: string, name: string, category: string, type: string, amount: string, personid: ILookupValue }[];
				await updateStateIncome(props.context, rows, budgetLineTypes.current);

				_setBudgetLines(rows);
			}

			exe_rows();
		} else {
			_setBudgetLines([]);
		}
	}, [props.context.id]);

	let budgetDiff = budgetLines.map(x => x.type === "out" ? - (+x.amount) : (+x.amount)).reduce(((agg, p) => (agg + p)), 0);
	const totalSum = formatNumericValue(PRECISION, budgetDiff);

	const isFamilyBudget = props.context.meta.logicalName === "fi_family";

	return <div style={{ display: "grid", gridTemplateColumns:"1fr 1fr", gridTemplateRows:"auto 1fr", overflow:"hidden" }}>
		<div style={{ gridColumn: "1/-1", display: "flex", alignItems: "Center", marginLeft: "12px" }}>
			<h1>Rozdiel:</h1><span style={{ marginLeft: "12px", fontWeight: "bold", flex:"1 1 auto" }}>{totalSum} €</span>
			<IconButton icon="file-csv" onClick={e => exportBudgetAsCSV(budgetLines, isFamilyBudget)} />
			<IconButton icon="file-pdf" onClick={e=>exportBudgetAsPdf(budgetLines, isFamilyBudget, props.context.getRecord()["name"])} />
		</div>
		<BudgetList types={budgetLineTypes.current} rows={budgetLines} setRows={setBudgetLines} title={"Príjmy mesačné"} context={props.context} type="in" />
		<BudgetList types={budgetLineTypes.current} rows={budgetLines} setRows={setBudgetLines} title={"Výdavky"} context={props.context} type="out"/>
	</div>
}

interface IBudgetRow {
	id: string;
	type: string;
	name: string;
	category?: string;
	amount: string;
	comment?: string;
	personid: ILookupValue;
	//personid?: string;
	__op?: string;
}

interface IBudgetLineType {
	id: string;
	name: string;
	category: string;
	type: string;
}

const BudgetList = (props: {
	rows: IBudgetRow[];
	setRows: React.Dispatch<React.SetStateAction<IBudgetRow[]>>;
	types: IBudgetLineType[];
	title: string;
	context: IFormContext;
	type: string;
}) => {
	const { rows, setRows, types } = props;
	const isFamilyBudget = props.context.meta.logicalName === "fi_family";

	const formatter = useCallback((row: IBudgetRow, fname: string, style: any) => {
		if (fname === "amount")
			return <NumberInput key={row.id} value={row.amount || "0"} onChange={(value) => {
				setRows(rr => {
					return rr.map(x => x === row ? { ...x, amount: value, __op: x.__op || "update" } : x);
				})
			}} precision={PRECISION.toString()} inputProps={{ id: "budget_" + row.id, style: { border: "none", textAlign:"right" } }} />
		if (fname === "actions") {
			return <IconButton icon={"trash"} label="" style={{ padding: "1px", height: "1em", width: "1.1em" }} onClick={e => {
				if (row.__op === "create")
					setRows(rr => rr.filter(x => x !== row));
				else
					setRows(rr => rr.map(x => x === row ? { ...x, __op: "delete" } : x));
			}} />
		}
		if (fname === "name") {
			let label = row.name || "";
			if (isFamilyBudget)
				label += " - " + row.personid?.label;
			return <div style={{display:"flex", flexDirection:"column", whiteSpace:"normal"}}><span>{label}</span><InputWithWrap type="text" value={row.comment || ""} onChange={e => {
				setRows(rr => rr.map(x => x === row ? { ...x, comment: e, __op: x.__op || "update" } : x))
			}} /></div>
		}
		return (row as any)[fname];
	}, [setRows]);

	const [focused, setFocus] = useState("");

	const modal = useContext(ModalContext);
	const commands = useMemo(() => isFamilyBudget ? [] : [{ name: "CmdAdd", icon: "plus", label: "" }], []);
	const onCommand = useCallback(async (cmd: ICommand, e: any) => {

		const currentTypes = types.filter(x => x.type === props.type);
		const index = await showMenu(modal, e.target, currentTypes.map(x => x.name));
		const newRow: IBudgetRow = {
			__op: "create",
			type: props.type,
			id: newGuid(),
			name: currentTypes[index].name,
			category: currentTypes[index].category,
			amount: "0",
			personid: { id: props.context.id, name: "fi_osoba", label: "" }
		};
		setRows(rows => {
			const newRows = rows.concat(newRow);
			//todo: set id as to-be-focused input.
			const opts: any = { "sensitivity": "base" };
			newRows.sort((a, b) => a.name.localeCompare(b.name, undefined, opts));
			return newRows;
		});
		setFocus("budget_" + newRow.id);
	}, [setRows, setFocus, props.types]);
	
	useEffect(() => {
		if (focused) {
			const e = document.getElementById(focused) as HTMLInputElement | null;
			if (e) {
				e.focus();
				setTimeout(() => { e.select(); }, 100);
			}
			setFocus("");
		}
	}, [focused]);
	
	const listColumns: IListColumn[] = [
		{ "attribute": "name", "label": "Názov" },
		{ "attribute": "amount", label: "Suma (€)", width: "70px" },
		{ attribute: "actions", label: " ", width: "25px" }];
	const attrsMeta = {"amount":{attribute:"amount", type:MetaPropertyType.Decimal}} as any;

	const rr = rows.filter(r => r.__op !== "delete" && r.type === props.type);
	const totalSum = formatNumericValue(PRECISION, rr.reduce((agg, p) => (agg + (+p.amount)), 0));

	return <div className="objectListContainer">
		<TitleBox title={props.title} noSpacer={true} commands={commands} onCommand={onCommand} >
			<div style={{flex:"1 1 auto", marginLeft:"12px", marginRight:"12px", fontWeight:"bold"}}>{totalSum}</div>
		</TitleBox>
		<SimpleGrid attrsMeta={attrsMeta} rows={rr} listColumns={listColumns} fields={["name", "amount", "actions"]} noEdit={true} formatter={formatter} />
		{/* <div style={{ display: "grid", gridTemplateColumns: "1fr 120px 50px" }}>
			<div className="simpleGridHeader" style={{paddingLeft: "11px"}}>Mesačné príjmy spolu v €</div>
			<div className="simpleGridHeader" style={{paddingLeft: "5px"}}>{totalSum}</div>
			<div className="simpleGridHeader"></div>
		</div> */}
	</div>
}

const budgetAsTable = (budgetLines: IBudgetRow[], isFamilyBudget: boolean) => {
	const income = budgetLines.filter(x => x.type === "in");
	const outcome = budgetLines.filter(x => x.type === "out");
	const incomeTotal = income.reduce((agg, p) => (agg + (+p.amount)), 0);
	const outcomeTotal = outcome.reduce((agg, p) => (agg + (+p.amount)), 0);
	
	const dummy: IBudgetRow = { id: "", name: "", amount: "", personid: { id: "", name: "", label: "" }, type: "" };
	const lines = [];
	const makeLabel = (row: IBudgetRow, isFamilyBudget: boolean) => {
		let label = row.name || "";
		if (isFamilyBudget && row.personid?.label)
			label += " - " + row.personid?.label;
		return label;
	}
	for (let i = 0; i < Math.max(income.length, outcome.length); i++) {
		let a = income[i] || dummy;
		let b = outcome[i] || dummy;
		lines.push([makeLabel(a, isFamilyBudget), a.category || "", a.amount, makeLabel(b, isFamilyBudget), b.category || "", b.amount]);
	}
	return { lines, incomeTotal, outcomeTotal };
}

const exportBudgetAsCSV = (budgetLines: IBudgetRow[], isFamilyBudget: boolean) => {
	const { lines, incomeTotal, outcomeTotal } = budgetAsTable(budgetLines, isFamilyBudget);
	let sb = "";
	sb += "Rozdiel;;" + (incomeTotal - outcomeTotal) + "\n";
	sb += "Príjmy;;" + incomeTotal + ";Výdavky;;" + outcomeTotal + "\n";
	for (const line of lines) {
		sb += line.join(';') + "\n";
	}
	download("\ufeff" + sb, "RodinnyRozpocet.csv", "text/csv");
}

const exportBudgetAsPdf  = async (budgetLines: IBudgetRow[], isFamilyBudget: boolean, familyName: string) => {
	const { lines, incomeTotal, outcomeTotal } = budgetAsTable(budgetLines, isFamilyBudget);
	
	const title = formatNumericValue(PRECISION, incomeTotal - outcomeTotal, "sk-SK");
	const hdr = ["Príjmy", "Výdavky"]//sb += "Prijmy;" + incomeTotal + ";Vydaje;" + outcomeTotal + "\n";
	lines.splice(0,0,["Názov", "Typ", "Suma", "Názov", "Typ", "Suma"]);

	const grayColor = "#333";
	const padding = [1, 5, 1, 5];
	const styles: { [key: string]: IPdfStyle } = {
		title: {
			margin: [2, 2, 20, 2],
			fontSize: 24,
			fontFamily: "roboto",
			color: "navy",
		},
		subTitle: {
			margin: [2, 2, 20, 2],
			fontSize: 20,
			fontFamily: "roboto",
			color: "navy",
		},
		tableHeader: {
			margin: [0, 0, 0, 0],
			padding: [2, 2, 7, 2],
			fontSize: 12,
			fontFamily: "roboto-Bold",
			color: "navy",
			vertTextAlign: "center",
			// borderColor: "black",
			// borderStyle: "solid",
			// borderThickness: [1, 1, 1, 1]
		},
		text: {
			margin: [0, 0, 0, 0],
			padding: [2, 2, 7, 2],
			fontSize: 12,
			fontFamily: "roboto",
			color: "black",
			vertTextAlign: "start",
			// borderColor: "black",
			// borderStyle: "solid",
			// borderThickness: [0, 1, 1, 1]
		}
	};
	const mainGrid = createGrid(0, 0, ["1fr"], ["auto"]);

	const titleGrid = addGrid(mainGrid, 0, 1, ["1fr", "1fr"], ["auto"]);
	const docTitle = new Date().toLocaleString("sk-SK", { "month": "short", "year": "numeric" }) + " Rozpočet " + familyName;
	addText(mainGrid, 0, 0, "title", docTitle);
	addText(titleGrid, 0, 1, "subTitle", "Rozdiel: " + title);
	addText(titleGrid, 0, 2, "subTitle", hdr[0] + " " + formatNumericValue(PRECISION, incomeTotal, "sk-SK"), {color:"green"});
	addText(titleGrid, 1, 2, "subTitle", hdr[1] + " " + formatNumericValue(PRECISION, outcomeTotal, "sk-SK"), {color:"red"});

	const itemsGrid = addGrid(mainGrid, 0, 2, ["3fr", "1fr", "1fr", "3fr", "1fr", "1fr"], ["auto"]);

	for (let y = 0; y < lines.length; y++) {
		const line = lines[y];
		for (let x = 0; x < line.length; x++) {
			let item = line[x];
			if (!item)
				continue;
			const className = y === 0 ? "tableHeader" : "text";
			let style: IPdfStyle = {};
			if ((x === 2 || x === 5) && item) {
				if (y > 0)
					item = formatNumericValue(PRECISION, +item, "sk-SK");
				style.horzTextAlign = "end";
				if (x === 2)
					style.margin = [0, 10, 0, 0];
			}
			if (x < 3) {
				style.color = y > 1 ? "#FF9CCC65" : "green";
			} else {
				style.color = y > 1 ? "#FF7276" : "red";
			}
			if((y & 1) === 0) {
				style.background = style.color;
				style.color = "white";
			}
			addText(itemsGrid, x, y, className, item, style);
		}
	}
	//addText()
	const blob = await renderDocument(mainGrid, styles, docTitle);
	download(blob as any, "RodinnyRozpocet.pdf", "application/pdf");
}

const updateStateIncome = async (context: IFormContext, budgetLines: IBudgetRow[], types: IBudgetLineType[]) => {
	const objectName = context.meta.logicalName;
	const objectId = context.id;

	const now = new Date();

	const kids_q: Fetch = {
		entity: {
			name: "fi_osoba",
			attributes: ["id", "name", "birthday", "child_bonus_end"].map(x => ({ attribute: x }))
		}
	};

	if (objectName === "fi_family") {
		kids_q.entity.links = [{
			name: "fi_family_member", from: "id", to: "personid", alias: "fam",
			filter: { conditions: [{ attribute: "familyid", operator: "eq", value: objectId }] }
		}];
	} else { 
		kids_q.entity.filter = {conditions: [{ attribute: "id", operator: "eq", value: objectId }] }
	}

	const reqs: any[] = [];

	const addRemoveBudgetLine = (isEntitled: boolean, person: { id: string, name: string }, amount: string, name: string) => {
		const currentLine = budgetLines.find(x => x.personid?.id === person.id && x.name === name);
		if (isEntitled !== (!!currentLine)) {
			if (isEntitled) {
				const req = {
					operation: "create",
					name: "fi_budget_line",
					object: {
						id: newGuid(),
						type: "in",
						personid: { id: person.id, name: "fi_osoba", label: person.name },
						amount: amount,
						name: name,
						category: types.find(x => x.name === name)?.category
					}
				}
				reqs.push(req);
				budgetLines.push(req.object);
			} else if (currentLine) {
				reqs.push({ operation: "delete", name: "fi_budget_line", object: { id: currentLine.id } });
				const i = budgetLines.indexOf(currentLine);
				budgetLines.splice(i, 1);
			}
		}
	}
	
	const day16 = new Date(now.getFullYear() - 17, now.getMonth(), now.getDate()); //16years including!
	const members = await DataService.retrieveMultiple(kids_q);

	const ef = makeFetch("fi_employment", undefined, [["enddate", "gt", now.toISOString()], ["type", "eq", "FamilyLeave"]]);
	ef.entity.filter!.filters = [{
		type: "or",
		conditions: members.map(x => ({ attribute: "personid", operator: "eq", value: x.id })),
	}];
	const familyLeaves = await DataService.retrieveMultiple(ef) as any[];

	for (const k of members) {
		let needChildBonus = false;
		if (k.child_bonus_end)
			needChildBonus = new Date(k.child_bonus_end) > now;
		else if (k.birthday && new Date(k.birthday) > day16)
			needChildBonus = true;

		addRemoveBudgetLine(needChildBonus, k, "60", "Prídavky na deti (aj v poukážkach)");

		const hasFamilyLeave = !!familyLeaves.find(x => x.personid?.id === k.id);
		addRemoveBudgetLine(hasFamilyLeave, k, "345", "Rodičovský príspevok/materská");
	}

	if (reqs.length > 0) {
		await DataService.executeMultiple(reqs);
		const opts: any = { "sensitivity": "base" };
		budgetLines.sort((a, b) => a.name.localeCompare(b.name, undefined, opts));
	}
}
/*
const calculateStateIncome = async (objectId: string, objectName: string) => {
	
	let numberOfKids = 0;
	const now = new Date();
	let members: any[];
	let familyLeave = 0;
	const wages: IBudgetRow[] = [];

	if (objectName === "fi_family") {
		const mf = makeFetch("fi_family_member", undefined, [["familyid", "eq", objectId]]);
		mf.entity.links = [{
			name: "fi_osoba",
			from: "personid",
			to: "id",
			alias: "mb",
			attributes: ["birthday", "id", "personal_budget"].map(x => ({ attribute: x }))
		}];
		members = await DataService.retrieveMultiple(mf) as any[]
		const day16 = new Date(now.getFullYear() - 17, now.getMonth(), now.getDate()); //16years including!
		numberOfKids = members.filter(x => x.mb__birthday && new Date(x.mb__birthday) > day16).length;

		for (const m of members) {
			if (m.mb__personal_budget) {
				const personalIncomes = parseBudget(m.mb__personal_budget).in;
				for (const wage of personalIncomes) {
					let incomeName = wage.name;
					// shorten names
					if (incomeName.startsWith("Mzda"))
						incomeName = "Mzda - ";
					else if (incomeName.startsWith("Rodičovský "))
						incomeName = "Rodičovský - "
					else
						incomeName += " - ";
						
					//wages.push({ personid: m.personid.id, name: incomeName + m.personid.label, amount: wage.amount, id: wage.id });	
				}
			}
		}

	} else {
		members = [{ mb__id: objectId }];

		const ef = makeFetch("fi_employment", undefined, [["enddate", "gt", now.toISOString()]]);
		ef.entity.filter!.filters = [{
			type: "or",
			conditions: members.map(x => ({ attribute: "personid", operator: "eq", value: x.mb__id })),
		}];
		const employments = await DataService.retrieveMultiple(ef) as any[];
		//maternalLeave = employments.filter(x => x.type === "MaternalLeave").length;
		familyLeave = employments.filter(x => x.type === "FamilyLeave").length;
	}

	return {
		numberOfKids,
		familyLeave,
		wages
	}
}
*/