import { Draggable, Map, Marker, Overlay, Point } from "pigeon-maps";
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { IFormField } from "../AppSchema";
import { IconButton } from "../components/IconButton";
import { InputWithWrap } from "../components/WrapInput";
import { ModalContext } from "../modal/Modal";
import { PanelProps } from "../objectForm/panels/PanelProps";
import { ICivicAddress, AddressPicker } from "./AddressPicker";

export const MapFormComponent = (props: PanelProps & { field: IFormField }) => {

	const modalSite = useContext(ModalContext);
	const r = props.getRecord() || {};
	const pos = [+(r.latitude || 0), +(r.longitude || 0)];

	const fields = (props.field.customComponent as string).split(';');
	const fieldmap = [
		"street", fields[1],
		"city", fields[2],
		"zip", fields[3],
		"country", fields[4],
		"latitude", "latitude",
		"longitude", "longitude",
	];
	
	const onSave = (addr: ICivicAddress) => {
		const record = { ...props.getRecord() };
		for (let i = 0; i < fieldmap.length; i += 2)
			record[fieldmap[i + 1]] = (addr as any)[fieldmap[i]];
		props.setRecord(record);
	}

	const onEditClick = () => {
		const record = props.getRecord();
		const addr: ICivicAddress = {} as any;
		for (let i = 0; i < fieldmap.length; i += 2)
			(addr as any)[fieldmap[i]] = record[fieldmap[i + 1]] || "";

		modalSite.showModal({
			id: "address",
			title: "Select Address",
			commands: [{ name: "CmdSave", label: "Save", icon: "save" }, { name: "CmdClose", label: "Close", icon: "close" }],
			bodyProps: { addr: addr, onSave: onSave },
			body: AddressPicker
		});
	};
	const onMarkerClick = (e: any) => {
		window.open("https://maps.google.com/maps?z=12&t=m&q=loc:" + pos[0] + "+" + pos[1]);
	}

	const mapRef = useRef<HTMLDivElement>(null);
	const [mapHeight, setMapHeight] = useState(30);
	useLayoutEffect(() => {
		if (mapRef.current?.parentElement)
			setMapHeight(mapRef.current.parentElement.getBoundingClientRect().height - 10);
	});

	return (<div ref={mapRef}><Map center={pos as any} mouseEvents={false} touchEvents={false} height={mapHeight} zoom={12}>
		<IconButton icon='edit' label="" onClick={onEditClick} style={{ background: "transparent", border: "none" }} />
		<Marker anchor={pos as any} onClick={onMarkerClick} />
	</Map>
	</div>
	)
}

export const AddressWithMapComponent = (props: PanelProps & { field: IFormField }) => {

	const { getRecord, setRecord, field } = props;
	const [componentName, userMapHeight] = field.customComponent!.split(';');

	const dp = {}; // fixme: enabled state fix.
	const value = getRecord()[field.name];
	
	const timer = useRef<number>(0);

	const onChange = useCallback((value: string) => {
		const obj = getRecord();
		setRecord({ ...obj, [field.name]: value });
		if (timer.current)
			window.clearTimeout(timer.current);
		timer.current = window.setTimeout(() => fetchGps(value), 1000);
		//fetchGps(value);
	}, [field, getRecord, setRecord]);

	const mapRef = useRef<HTMLDivElement>(null);
	const [mapHeight, setMapHeight] = useState((userMapHeight && +userMapHeight) || 94);
	// useLayoutEffect(() => {
	// 	if (mapRef.current)
	// 		setMapHeight(mapRef.current.getBoundingClientRect().height-5);
	// });

	const r = getRecord() || {};
	const pos = [+(r.latitude || 0), +(r.longitude || 0)];

	const fetchGps = async (q: string) => {
		//let q = value;
		q = encodeURIComponent(q);
		const resp = await fetch("https://nominatim.openstreetmap.org/search.php?q=" + q + "&format=jsonv2");
		const json = await resp.json() as { category: string, type: string, lat: string, lon: string }[];
		if (json && json.length > 0) {
			const newPos = json[0];
			const obj = getRecord();
			setRecord({ ...obj, "latitude": newPos.lat, "longitude": newPos.lon });
		}
	}

	return <div ref={mapRef} className="addressWithMap">
		
		<div  className="addressWithMapMap" style={{visibility:(pos[0]||pos[1])?"visible":"hidden"}}>
			<Map boxClassname="" center={pos as any} mouseEvents={true} touchEvents={false} height={mapHeight} zoom={9}>
				<Marker anchor={pos as any} />
			</Map>
		</div>
		<div className="addressWithMapText"><InputWithWrap {...dp} type="text" rows={5} value={value || ""} onChange={onChange} />
		</div>
	</div>
}