import React, { useContext, useRef, useState } from "react";
import { IMetaObject, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { IAssociatedListPanel, ICommand, IForm, IFormContainerPanel, IFormField, IFormFieldsPanel, VirtualField } from "../AppSchema";
import { getObjectChildren, loadGuiLists, MetaContext } from "../AppState";
import { IconButton } from "../components/IconButton";
import { ModalContext } from "../modal/Modal";
import { CustomizeContext } from "../objectForm/customize/customizeContext";
import { MainFormPanel } from "../objectForm/panels/MainFormPanel";
import { IFormContext, PanelProps } from "../objectForm/panels/PanelProps";
import { CommandsEditor } from "./CommandsEditor";
import { ConfigureFormField } from "./ConfigureFormField";
import { DragList } from "./DragList";
import { DropList } from "./DropList";
import { showFetchDialog } from "./FetchDialog";

/*

PROPERTIES | LIST OF FIELDS | LIST OF AVAILABLE + Separator
width
...
*/

interface IFieldsListProps extends PanelPropsEx {
	insertItemSite: any;
}

const FormFieldsList = (props: IFieldsListProps ) => {
	const metadata = useContext(MetaContext);
	const meta = metadata.objects.find(x => x.logicalName === props.body.name) as IMetaObject;

	const fieldsPanel = props.body && props.body.panel && (props.body.panel as IFormContainerPanel).panels.find(x => x.type === "Fields") as IFormFieldsPanel;

	const getLabel = (item: IFormField) => {
		const name = item.name;
		const propMeta = meta.properties.find(x => x.logicalName === name);
		if (propMeta) {
			return { label: item.label || propMeta.displayName || propMeta.logicalName, sub: name + " " + MetaPropertyType[propMeta.type] }
		}
		return { label: "", sub: "" }
	};
	const getName = (item: IFormField) => {
		return item.name;
	}
	const createItem = (name: string) => {
		const item: IFormField = {
			name: name,
			type: "Field",
		}
		return item;
	}

	const [_, render] = useState(false);

	const setItemProperty = (item: any, propName: string, value: any) => {
		item[propName] = value;
		render(x => !x);
	}

	const configureItem = (item: IFormField) => {
		return <ConfigureFormField item={item} attrs={["label", "required", "disabled", "gridColumn", "gridRow", "customComponent"]} />
	}

	return <DropList fields={fieldsPanel.fields} getLabel={getLabel} getName={getName} createItem={createItem}
		configureItem={configureItem} insertItemSite={props.insertItemSite} />
}

const FormAvailableFieldsList = (props: IFieldsListProps) => {

	const metadata = useContext(MetaContext);
	const meta = metadata.objects.find(x => x.logicalName === props.body.name) as IMetaObject;

	const separator: IMetaProperty = {
		logicalName: "-",
		type: MetaPropertyType.Guid,
		flags: 0
	};
	const fields = [separator].concat(meta.properties);
	return <DragList fields={fields} insertItemSite={props.insertItemSite} />
}

const createFormEditPanel = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const objectName = record.objectname as string;

	if (!objectName || !props.body)
		return <div>Select entity first.</div>

	const insertItemSite: any = {};
	
	return (<div className="formFieldsEditor">
		{/* <div>Properties</div> */}
		<FormFieldsList {...props} insertItemSite={insertItemSite} />
		<FormAvailableFieldsList {...props} insertItemSite={insertItemSite} />
	</div>)
}

const FormAssociatedList = (props: IFieldsListProps) => {
	const metadata = useContext(MetaContext);
	const modal = useContext(ModalContext);
	const meta = metadata.objects.find(x => x.logicalName === props.body.name) as IMetaObject;

	const assocPanel = props.body && props.body.panel && (props.body.panel as IFormContainerPanel).panels.find(x => x.type === "Tabs" && x.name === "Associated") as IFormContainerPanel;

	const getLabel = (item: any) => {
		const tab = item as IAssociatedListPanel;
		const meta = metadata.objects.find(x => x.logicalName == tab.name); 
		if (meta) {
			return { label: tab.label || meta.displayNamePlural || meta.logicalName, sub: tab.name + "." + tab.field }
		}
		return { label: "", sub: "" }
	};

	const getName = (item: any) => {
		const tab = item as IAssociatedListPanel;
		return tab.name + tab.field;
	}
	const createItem = (name: string) => {
		const d = name.indexOf(".");
		const fname = name.substring(0, d);
		const item: IAssociatedListPanel = {
			name: fname,
			field: name.substring(d + 1),
			type: "AssociatedList",
			label: metadata.objects.find(x => x.logicalName === fname)?.displayNamePlural
		}
		return item;
	}

	const configureItem = (item: IAssociatedListPanel) => {
		const views = loadGuiLists(metadata, item.name, "Public");
		const initField = { logicalName: "initialView", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: views.map(x => x.name) } as IMetaProperty;
		const allowedField = { logicalName: "allowedViews", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet|MetaPropertyFlags.MultiSelect, options: views.map(x => x.name) } as IMetaProperty;
		const configItem = <ConfigureFormField item={item} attrs={["label", "field", initField, allowedField]} />

		const setupExtraFilter = () => {
			const fetch = item.extraFetch || { entity: { name: item.name } };
			showFetchDialog(modal, fetch, () => item.extraFetch = fetch);
		}

		return <div>{configItem} <IconButton label="Extra Filter" icon="fa-filter" onClick={setupExtraFilter}/></div>
	}

	return <DropList title="Tabs" columns={"1fr"} fields={assocPanel.panels} getLabel={getLabel} getName={getName} createItem={createItem} configureItem={configureItem} insertItemSite={props.insertItemSite} />
}

const FormAvailableAssociatedList = (props: IFieldsListProps) => {

	const metadata = useContext(MetaContext);
	//const meta = metadata.objects.find(x => x.logicalName === props.body.name) as IMetaObject;
	const avail = getObjectChildren(metadata, props.body.name);

	const fields = avail.map(x => ({
		logicalName: x.meta.logicalName + "." + x.field,
		displayName: x.meta.displayNamePlural || x.meta.logicalName
	})).concat([...metadata.objects].sort((a, b) => a.logicalName.localeCompare(b.logicalName))
		.filter(x => x.type !== "many")
		.map(x => ({
			logicalName: x.logicalName + "." + VirtualField,
			displayName: x.displayNamePlural || x.logicalName
		})));
	
	return <DragList title="Available Tabs" fields={fields} insertItemSite={props.insertItemSite} />
}

const createAssociatedEditPanel = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const objectName = record.objectname as string;

	if (!objectName || !props.body)
		return <div>Select entity first.</div>

	const insertItemSite: any = {};
	
	return (<div className="formFieldsEditor">
		{/* <div>Properties</div> */}
		<FormAssociatedList {...props} insertItemSite={insertItemSite} />
		<FormAvailableAssociatedList {...props} insertItemSite={insertItemSite}/>
	</div>)
}

const createCommandsPanel = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const objectName = record.objectname as string;

	if (!objectName || !props.body)
		return <div>Select entity first.</div>
	
	const cmds = props.body.commands || [];
	const onCommandsChanged = (cmds: ICommand[]) => {
		props.body.commands = cmds;
		props.setRecord({ ...props.getRecord() }); //force render... hacky
	}
	const stdCommands = ["CmdDelete", "CmdShare", "CmdSave"];
	return (<CommandsEditor cmds={cmds} stdCommands={stdCommands} onCommandsChanged={onCommandsChanged} />);
}

const createEventHandlerPanel = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const objectName = record.objectname as string;

	if (!objectName || !props.body)
		return <div>Select entity first.</div>
	
	const events = props.body.events || {};
	const onEventChanged = (name:string, cmd:string) => {
		props.body.events = { ...props.body.events, [name]: cmd };
		props.setRecord({ ...props.getRecord() }); //force render... hacky
	}

	const eventTypes = ["change", "validate", "postsave"];

	return <form><fieldset className="formEventsPanel" >
		{eventTypes.map(eventName => {
			return (<div className="formItem">
				<label className="formLabel" htmlFor="eventName">Event '{eventName}'</label>
				<input type="text" value={(events as any)[eventName]||""} onChange={e => onEventChanged(eventName, e.target.value)} />
			</div>)
		})
		}
		</fieldset></form>
}
	
interface PanelPropsEx extends PanelProps {
	body: IForm;
}

const FormDesigner = (props: PanelPropsEx) => {

	const metadata = useContext(MetaContext);

	const [fakeProps, setFakeProps] = useState(() => {
		const record = {};
		const self: IFormContext & { record: any } = {
			record: {},
			events: [] as any,
			session: { version: 0 },
			id: "0",
			getRecord: () => self.record,
			setRecord: (r: any) => self.record = r,
			objectLabel: "New Form Design",
			meta: undefined as any,
			fieldProps: {},
			getService: (serviceId) => undefined,
		}
		return self;
	});

	const record = props.getRecord();
	const objectName = record.objectname as string;
	if (!objectName || !props.body)
		return <div>Select entity first.</div>
	
	fakeProps.meta = metadata.objects.find(x => x.logicalName === objectName) as any;

	return <div className="formDesigner formContainer">
		<CustomizeContext.Provider value={{ enabled: true, onSave: () => { } }}>
			<MainFormPanel panelProps={fakeProps} form={props.body} commands={props.body.commands} onCommand={c => { }} saving={false} />
		</CustomizeContext.Provider>
	</div>
}

export const makeCustomizeFormForm = (meta: IMetaObject) => {
	const form: IForm = {
		name: meta.logicalName,
		panel: {
			name: "main",
			type: "Split",
			gridColumns: "minmax(300px, 1fr) 5fr",
			panels: [
				{
					name: "fields",
					type: "Fields",
					fields: ["name", "label", "objectname", "appid", "isprivate"].map(x => ({ name: x })),
				} as IFormFieldsPanel,
				{
					name: "Details",
					type: "Tabs",
					panels: [
						{ name: "design", type: "Custom", label: "Designer", createMethod: FormDesigner },
					// 	{ name: "Associated", type: "Custom", label: "Associated", createMethod: createAssociatedEditPanel },
					// 	{ name: "Commands", type: "Custom", label: "Commands", createMethod: createCommandsPanel },
					// 	{ name: "Events", type: "Custom", label: "Events", createMethod: createEventHandlerPanel },
					 ]
				} as IFormContainerPanel
			]
		} as IFormContainerPanel,
		commands: [],
	};
	return form;
}
