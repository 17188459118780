import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Loading } from "../components/Loading"
import { TitleBox } from "../components/TitleBox";
import { ICommand, IFormField, IFormFieldsPanel } from "../AppSchema";
import { FieldEditor, FieldsPanel } from "../objectForm/panels/FieldsPanel";
import { IMetaObject, IMetaProperty, MetaPropertyFlags } from "shared/schema";
import { IFieldProps } from "../objectForm/panels/PanelProps";
import { useSizeObserver } from "../hooks/useSizeObserver";
import { useParams } from "react-router-dom";
import { IconButton } from "../components/IconButton";
import { SimpleFieldSet, getLanguage, validateFieldSet } from "../components/SimpleFieldSet";

export interface ISurveyRecord {
	id: string;
	templateid: string;
	accessid: string;
	data: string;
}

interface ISurveyRecordData {
	max_date: string;// iso date valid until
	organization: string;
	email: string;
	password: string;
}

export interface ISurveyTemplate {
	name: string;
	id: string;
	title: string;
	submit_button: string;
	object_name: string;
	meta_object: string; // JSON IMetaObject
	panel: string; // JSON IFormFieldsPanel
	flags: number; // SingleShot, MustAccept,
}

const fetchSurvey = async (orgName: string, key:string, data?: any) => {

	let init: any = undefined;
	if (data) {
		init = {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}
	}

	const resp = await fetch(window.location.origin + "/api/survey/" + orgName + "/" + key, init);
	if (!resp.ok) {
		let error = "Network Error";
		try {
			error = await resp.text();
		}
		catch {
		}
		throw new Error(error);
	}
	const d = await resp.json();
	return d;

	const hash = "";
/*
	// find record by hash ->
	const cookie = { body: "", maxdate: "" };
	try {
		const now = new Date();
		if (new Date(cookie.maxdate) < now)
			throw Error("Form Expired");

		const info = parseCookie(cookie.body);
		if (new Date(info.maxDate) < now)
			throw Error("Form Expired");
		const orgName = info.orgName;
		const user = info.userEmail;
		const password = info.password;
		const recordEntity = info.recordEntity;
		const recordId = info.recordId;

		const getUser(orgName, user, password);
	}
	catch (e) {
		alert(e);
	}*/
}

interface ISurveyData {
	meta: string;
	panel: string;
	title: string;
	submit_button: string;
	css_style: string;
}

const getStatusMessage = (err: string) => {
	const winLang = getLanguage();

	let index = ["SurveyCompleted", "SurveyExpired", "SurveyLinkInvalid"].indexOf(err);
	if (index < 0)
		index = 3;

	let msgTable = ["Thank you for filling out the form", "We are sorry, the form has expired", "We are sorry, you have entered the URL in a wrong format", "We are sorry, an error has occurred"];
	switch (winLang) {
		case "sk": msgTable = ["Ďakujeme za vyplnenie dotazníka", "Ľutujeme dotazník vypršal", "Ľutujeme zadali ste adresu v zlom tvare", "Ľutujeme nastala chyba"]; break;
		case "cz": msgTable = ["Děkujeme za vyplnění dotazníku", "Litujeme dotazník vypršel", "Litujeme zadali jste adresu ve špatném tvaru", "Litujeme nastala chyba"]; break;
		case "de": msgTable = ["Vielen Dank für das Ausfüllen des Fragebogens", "Es tut uns leid, der Fragebogen ist abgelaufen", "Es tut uns leid, Sie haben die Adresse im falschen Format eingegeben", "Es tut uns leid, es ist ein Fehler aufgetreten"]; break;		
	}
	return {
		message: msgTable[index],
		icon: ["fa-heart", "fa-hourglass-end", "fa-link-slash", "fa-triangle-exclamation"][index],
		color: ["red", "gray", "#ffb300", "#ffb300"][index],
		fulltext: index === 3 ? err : undefined,
	}
}

export const SurveyForm = (props: {}) => {
	const orgName = useParams<string>()["orgname"] || "";
	const key = useParams<string>()["key"] || "";
	
	const [data, setData] = useState<ISurveyData|undefined>(undefined);
	const [record, setRecord] = useState({} as any);

	const [isLoading, setLoading] = useState(true);
	const [statusMessage, setStatusMessage] = useState(undefined as any as { message: string, icon: string, color: string, fulltext?: string });
	const [validationStatus, setValidationStatus] = useState("");

	useEffect(() => {
		const exe = async () => {
			try {
				const data = await fetchSurvey(orgName, key);
				setData(data);
			
				if (data.css_style) {
					const id = "customCss_" + key;
					let style = document.getElementById(id) as HTMLStyleElement;
					if (!style) {
					  style = document.createElement('style');
					  style.type = 'text/css';
						style.innerHTML = data.css_style;
					  document.getElementsByTagName('head')[0].appendChild(style);
					}
					else {
						style.innerHTML = data.css_style;
					}
				}
			}
			catch (e: any) {
				setData(undefined);
				const err = getStatusMessage(e?.message);
				setStatusMessage(err);
			}
			
			setLoading(false);
		}
		exe();
	}, [orgName, key]);

	useLayoutEffect(() => {
		duplicteScrollableApp();
	});

	const { propsDict, panel } = useMemo(() => {
		if (data) {
			const metaObject = JSON.parse(data.meta) as IMetaObject;
			const panel = JSON.parse(data.panel)
			return {
				propsDict: metaObject.properties.reduce((agg, prop) => (agg[prop.logicalName] = prop, agg), {} as any),
				panel: panel,
			}
		} else {
			return { propsDict: undefined, panel: undefined };
		}
	}, [data]);

	const onSubmit = async () => {
		const err = validateFieldSet(propsDict, panel, record);
		if( err) {
			setValidationStatus(err);
			return;
		}

		setLoading(true);
		try {
			await fetchSurvey(orgName, key, record);
		}
		catch { }
		window.location.reload();
	}

	if (statusMessage) {
		return <div className="formContainer surveyContainer">
			<div className="surveyStatus">
				<i className={"surveyStatusIcon fa "+ statusMessage.icon} style={{color:statusMessage.color, fontSize:"80px"}} />
				<div className="surveyStatusMessage">{statusMessage.message}</div>
				<div className="surveyStatusFulltext">{statusMessage.fulltext||""}</div>
			</div>
		</div>
	}

	return <div className="formContainer surveyContainer">
		{isLoading && <Loading delay={false} />}
		<TitleBox title={<><span className="surveyLogo"></span><span>{data?.title || "..."}</span></>} onCommand={() => { }} />

		{validationStatus && <div className="surveyValidationStatus">{validationStatus}</div> }

		{data && <SimpleFieldSet id={"0"}
			disabled={isLoading}
			submitButton={isLoading ? "..." : data.submit_button}
			onSubmit={onSubmit}
			getRecord={() => record}
			setRecord={setRecord}
			propsDict={propsDict}
			panel={panel} />}
		
	</div>
}

const duplicteScrollableApp = () => {
	const app = document.getElementById("root") as HTMLElement;
	if (!app)
		return;
	const bs = app.style;
	// is list or form ?
	const content = app.lastElementChild as HTMLElement;
	if (window.innerWidth <= 500 && content && (content.classList.contains("formContainer") || content.classList.contains("objectListContainer"))) {
		app.classList.add("scrollableApp");
		const titleBox = document.getElementsByClassName("titleBox")[0] as HTMLElement;
		if (titleBox) {
			titleBox.style.position = "fixed";
			titleBox.style.top = "0px";
			titleBox.style.borderTop = "var(--inset-top) solid var(--back-color)";
			titleBox.style.left = "0px";
			titleBox.style.right = "0px";
			titleBox.style.zIndex = "2";
			//titleBox.style.backgroundColor = "white";
			(titleBox.style as any)["-webkit-backdrop-filter"] = "blur(80px)";
			(titleBox.style as any)["backdrop-filter"] = "blur(80px)";
		
			content.style.paddingTop = titleBox.clientHeight + "px"; // border is _not_ included in clientHeight
		}
		content.style.paddingBottom = "50px";
		bs.minHeight = "100vh";
	} else {
		app.classList.remove("scrollableApp");
		bs.position = "fixed";
		bs.height = "100%";
		bs.overflow = "hidden";
		bs.display = "grid";
	}
}