import { useEffect, useMemo, useRef, useState } from "react";
import { InputWithDropDown } from "./BaseDropDown";
import SimpleGrid from "./SimpleGrid";


export const AutoCompleteInput = (props: { keyName?: string, value: any, onChange: (value: any) => void, opts: string[], richOpts?: any[], disabled: boolean | undefined }) => {
	const { value, onChange } = props;
	const ro = props.richOpts;

	const keyName = props.keyName ? (window.location.pathname.split('/').slice(1, -1).join("/") + props.keyName) : "";

	const [isOpen, __setOpen] = useState(false);

	const hasUserValue = useRef(false);
	const originalValue = useRef(value);
	const updateUserValue = (value: string) => {
		if (keyName && value && value !== originalValue.current) {
			const userOptions = (window.localStorage.getItem(keyName) as string || "").split('\n').filter(x => !!x);
			if (hasUserValue.current) {
				userOptions.splice(0, 1);
				hasUserValue.current = false;
			}
			if (userOptions.indexOf(value) < 0 && props.opts.indexOf(value) < 0) {
				let text = [value].concat(userOptions).join('\n');
				window.localStorage.setItem(keyName, text);
				hasUserValue.current = true;
			}
		}
	}

	const setOpen = (open: boolean) => {
		if (!open)
			updateUserValue(value);
		__setOpen(open);
	}

	const setSearch = (value: string) => {
		if (!isOpen)
			updateUserValue(value);
		onChange(value);
	}

	useEffect(() => {
		hasUserValue.current = false;
	}, [keyName]);

	const rows = useMemo(() => {
		const rows = props.opts.map((x, i) => ({ label: (ro && ro[i]?.label) || x, name: x, userAdded: false }));
		return rows;
	}, [props.opts]);

	return <InputWithDropDown value={undefined}
		onChange={props.onChange}
		search={value as any}
		setSearch={setSearch}
		isOpen={isOpen}
		setOpen={setOpen}
		ValueElement={()=><></>}
		dropDownProps={{ rows: rows, keyName: keyName }}
		DropDownElement={AutoCompleteInputDropDown}
	/>
}

export const AutoCompleteInputDropDown = (props: {
	search: string,
	onClick: (value: any) => void,

	rows: any[],
	formatter: (row: any, fname: string, style: any) => React.ReactNode;

	labelField?: string;
	isLoading?: boolean;

	keyName: string;
}) => {

	const [__rows, setRows] = useState(props.rows);
	const { search } = props;
	const labelField = props.labelField || "label";
	const keyName = props.keyName;

	let userOptions: string[] = [];
	const userItemsText = (keyName && window.localStorage.getItem(keyName)) || "";
	if (userItemsText) {
		userOptions = userItemsText.split('\n');
	}
	const rows = __rows.concat(userOptions.map(x => ({ label: x, name: x, userAdded: true })));

	const s = search ? search.toLocaleLowerCase() : undefined;
	const r = rows.filter(r => {
		if (!s)
			return true;
		let label = r[labelField].toLocaleLowerCase();
		if (label.indexOf(s) >= 0)
			return true;
		label = label.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");
		if (label.indexOf(s) >= 0)
			return true;
		return false;
	});

	const formatter = (row: any, fieldName: string, style: any) => {
		
		return <div style={{display:"flex"}}>
			<a href="#" className="lookupLink" style={{ padding: "2px", display: "block", flex: "1 1 auto", height: "100%" }}
			onClick={e => { e.preventDefault(); props.onClick(row.name) }}>{row.label}</a>
			
			{row.userAdded && <i className="fa fa-trash" style={{ color: "red", padding: "2px 7px" }}
				onClick={e => {
						if (keyName) {
							const userOptions = (window.localStorage.getItem(keyName) as string || "").split('\n').filter(x => !!x);
							const index = userOptions.indexOf(row.name);
							userOptions.splice(index, 1);
							window.localStorage.setItem(keyName, userOptions.join('\n'));
						}
					setRows(r => r.filter(x => x !== row));
					}
				}/>}
			</div>;
	};

	return <SimpleGrid noHeader={true} fields={[labelField]} rows={r} formatter={formatter} noEdit={true} isLoading={props.isLoading} />
}