"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPermLevel = exports.PERMISSION_DELETE = exports.PERMISSION_UPDATE = exports.PERMISSION_READ = exports.PERMISSION_CREATE = exports.PERMISSION_LEVEL_ORG = exports.PERMISSION_LEVEL_BU = exports.PERMISSION_LEVEL_OWNER = exports.PERMISSION_LEVEL_NONE = exports.getReadLevel = exports.loadUserInfo = exports.parsePermissionsJson = void 0;
const parsePermissionsJson = (json) => {
    let body = JSON.parse(json);
    if (body.customOwners === undefined) { // old version upgrade
        body = {
            permissions: body,
            customOwners: []
        };
    }
    return body;
};
exports.parsePermissionsJson = parsePermissionsJson;
const loadUserInfo = (executeFetch, metadata) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const permDict = {};
    const q = {
        entity: {
            name: "inu_role",
            attributes: [{ attribute: "id" }, { attribute: "body" }, { attribute: "name" }, { attribute: "appid" }],
            links: [
                {
                    name: "inu_userroles",
                    from: "id",
                    to: "roleid",
                    alias: "rolenn",
                    filter: {
                        conditions: [{ attribute: "systemuserid", operator: "eq-userid" }]
                    }
                }
            ]
        }
    };
    const results = yield executeFetch(q);
    let apps = results.filter(x => !!x.appid).map(x => x.appid.label);
    apps = [...new Set(apps)]; // unique apps
    const owners = [];
    const teams = {};
    if (results.find(x => x.name === "admin")) {
        for (const n in metadata) {
            permDict[n] = 0x3333;
        }
        permDict["inu_metadata"] = 0x3333;
        if (apps.indexOf("Customize") < 0)
            apps.push("Customize");
    }
    else {
        let hasTeamPermission = false;
        for (const r of results) {
            const body = (0, exports.parsePermissionsJson)(r.body);
            if (body.customOwners)
                owners.push(...body.customOwners);
            const p = body.permissions;
            for (const logicalName in p) {
                if (!metadata[logicalName])
                    continue;
                const newPerm = p[logicalName];
                let oldPerm = permDict[logicalName] || 0;
                for (let j = 0; j < 4; j++) {
                    const selector = j * 4;
                    const x = oldPerm >> selector & 3;
                    const y = newPerm >> selector & 3;
                    if (y > x) {
                        oldPerm = (oldPerm & ~(15 << selector)) | y << selector;
                    }
                    // maybe some other role will bump this up to ORG level, but it only means we cached the teams and we don't need them.
                    if (y === exports.PERMISSION_LEVEL_BU)
                        hasTeamPermission = true;
                }
                permDict[logicalName] = oldPerm;
            }
        }
        if (hasTeamPermission && metadata["systemuser_teams"]) {
            const tq = {
                entity: {
                    name: "systemuser_teams",
                    attributes: [{ attribute: "teamid" }],
                    filter: { conditions: [{ attribute: "systemuserid", operator: "eq-userid" }] }
                },
                noLookupLabels: true,
            };
            const teamRecords = yield executeFetch(tq);
            if (teamRecords.length > 0) {
                for (const x of teamRecords) {
                    const tid = (_a = x.teamid) === null || _a === void 0 ? void 0 : _a.id;
                    if (tid) {
                        teams[tid] = tid;
                    }
                }
            }
        }
    }
    return { permissions: permDict, apps, owners, teams };
});
exports.loadUserInfo = loadUserInfo;
const getReadLevel = (name, permDict) => {
    return (0, exports.getPermLevel)(name, permDict, exports.PERMISSION_READ);
};
exports.getReadLevel = getReadLevel;
// NONE, OWNER, BUSINESS_UNIT, ORG
exports.PERMISSION_LEVEL_NONE = 0;
exports.PERMISSION_LEVEL_OWNER = 1;
exports.PERMISSION_LEVEL_BU = 2;
exports.PERMISSION_LEVEL_ORG = 3;
exports.PERMISSION_CREATE = 0;
exports.PERMISSION_READ = 1;
exports.PERMISSION_UPDATE = 2;
exports.PERMISSION_DELETE = 3;
const getPermLevel = (name, permDict, perm) => {
    const selector = perm * 4;
    return (permDict[name] || 0) >> selector & 3;
};
exports.getPermLevel = getPermLevel;
