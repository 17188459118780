import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Dictionary, IMetaObject, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { IAppConfig, IAssociatedListPanel, ICloudFilesPanel, ICommand, IFormContainerPanel, IFormField, IFormFieldsPanel, IFormFlexPanel, IFormPanel, VirtualField } from "../AppSchema";
import { loadGuiLists, getObjectChildren, MetaContext, humanize } from "../AppState";
import { getDropTarget, useDragProps } from "../components/draggable";
import { IconButton } from "../components/IconButton";
import SimpleGrid from "../components/SimpleGrid";
import { TitleBox } from "../components/TitleBox";
import { ConfigureFormField, makeArrayType, makeButtonType } from "../customize/ConfigureFormField";
import { showFetchDialog } from "../customize/FetchDialog";
import { IModalContext, IModalParent, IModalProps, ModalContext, showMenu } from "../modal/Modal";
import { simpleCellFormatter } from "../objectList/useCellFormatter";
import { newGuid } from "../utils/guid";
import { CustomizeContext } from "./customize/customizeContext";
import { FieldEditor } from "./panels/FieldsPanel";
import { IFieldProps, IFormContext } from "./panels/PanelProps";

const ConfigureField = (props: {
	attrs: (string|IMetaProperty)[],
	field: any,
	panel: {},
	arrayProp: string,
	gridClassName?: string,
	title?: string,
	commands?: ICommand[],
	onSave: () => Promise<void>, commandSite: IModalParent
}) => {
	const panel = props.panel;
	const original = useRef(props.field);
	const [field, setField] = useState(Object.assign({}, props.field));//useMemo(() => , []);
	const [isNew, setNew] = useState(false);

	const cmds = useMemo(() => props.commands || [
		{ name: "CmdCopy", label: "", icon: "copy" },
		{ name: "CmdRemove", label: "", icon: "trash" },
		{ name: "CmdSave", label: "", icon: "save" },
		// { name: "CmdClose", label: "", icon: "close" }
	], []);
	
	const onCommand = async (cmd: ICommand) => {
		const fields = (panel as any)[props.arrayProp] as any[];
		let index = -1;
		if (original.current)
			index = fields.indexOf(original.current);
		
		if (cmd.name === "CmdSave") {
			(panel as any)[props.arrayProp] = index < 0 ? fields.concat(field) : fields.slice(0, isNew ? index + 1 : index).concat(field).concat(fields.slice(index + 1));
			await props.onSave();

			setNew(false);
			setField(Object.assign({}, field));
			original.current = field;
		}
		else if (cmd.name === "CmdRemove") {
			(panel as any)[props.arrayProp] = fields.filter(x => x !== original.current);
			await props.onSave();
		}
		else if (cmd.name === "CmdCopy") {
			const json = JSON.stringify(field);
			await navigator.clipboard.writeText(json);
		}
		props.commandSite?.closeModal();
	};

	return (<div className="objectListContainer" style={{ flex: "1 1 auto" }}>
		<TitleBox title={props.title || "Customize"} commands={cmds} onCommand={onCommand} />
		<ConfigureFormField item={field} attrs={props.attrs} className={props.gridClassName} setItem={setField} />
	</div>)
}

export const configureFormFieldDialog = (modal: IModalContext, field: any,
	panel: any, arrayProp: string, attrs: (string|IMetaProperty)[], sourceElement: HTMLElement,
	onChange: () => void, gridClassName?: string, title?: string, commands?: ICommand[]) => {

	//const h = document.getElementById("root")?.offsetHeight + "px";
	const r = sourceElement.getBoundingClientRect();
	const w = 300;
	const h = 61 + attrs.length * 48;
	let x = r.x + 12;
	let y = r.y - (h / 2) | 0;
	if (x + w > window.innerWidth)
		x = (x - 12 > w) ? x - w - 12 : 0;
	
	if (y < 0)
		y = 0;
	else if (y + h > window.innerHeight)
		y = (h < window.innerHeight) ? window.innerHeight - h : 0;
	
	const pp: IModalProps = {
		//body: (p) => <div className="popupAppHeader" style={{left:"auto", right:"0",height:h, width:"300px", background:"white"}}>< ConfigureField {...p} /></div> ,
		body: p=>< ConfigureField {...p} />,
		bodyProps: {
			title: title,
			field: field,
			panel: panel,
			arrayProp: arrayProp,
			attrs: attrs,
			gridClassName: gridClassName,
			commands: commands,
			onSave: () => {
				//Object.assign(field, newField);
				onChange();
			} },
		id: "formFieldDialog",
		showTitle: false,
		anchor: { x: x, y: y , width: 300},
		//"anchor": { x: 0, y: 0 },
		style: { boxShadow: "rgb(0 0 0 / 53%) 0px 0px 10px 2px", margin:"0px", borderRadius: "4px" },
		closeOnClickOutside: true,
	};
	modal.showModal(pp);
}

const showExtraFetchDialog = (e: any, info: IMetaProperty, item: IAssociatedListPanel, modal: IModalContext) => {
	const fetch = item.extraFetch || { entity: { name: item.name } };
	showFetchDialog(modal, fetch, () => item.extraFetch = fetch);
};

export const configurePanel = async (modal: IModalContext, metadata: IAppConfig, parentMeta: IMetaObject, parentPanel: IFormContainerPanel, e: React.MouseEvent, onSave: () => void) => {
	e.preventDefault();
	e.stopPropagation();

	const configureItem = (e: React.MouseEvent, f: IFormPanel) => {
		let attrs: any[] = [
			{ logicalName: "type", type: MetaPropertyType.String, flags: 0, isDisabled: true },
			"name",
			"label",
			{ logicalName: "showLabel", type: MetaPropertyType.Boolean, flags: MetaPropertyFlags.OptionSet, options: ["Hidden", "Visble"] }
		];
		if (f.type === "AssociatedList") {
			const views = loadGuiLists(metadata, f.name, "Public", ["*"]);
			const nameField = { logicalName: "name", type: MetaPropertyType.String, flags: 0, isDisabled: true };
			const initField = { logicalName: "initialView", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: views.map(x => x.name) } as IMetaProperty;
			const allowedField = { logicalName: "allowedViews", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet | MetaPropertyFlags.MultiSelect, options: views.map(x => x.name) } as IMetaProperty;
			const fetchField = makeButtonType("extraFetch", "Extra Filter", (e, info, item) => showExtraFetchDialog(e, info, item, modal));
			attrs = [attrs[0], nameField, "label", "field", initField, allowedField, "excludedColumns", fetchField];
		}
		else if (f.type === "Split") {
			attrs.push("gridColumns");
		}
		else if (f.type === "Flex") {
			attrs.push("direction");
			attrs.push(makeArrayType("sizes"));
		}
		else if (f.type === "Fields") {
			attrs.push({ logicalName: "minColumnWidth", type: MetaPropertyType.Integer, flags: 0 });
		}
		else if (f.type === "CloudFiles") {
			attrs.push("rootFolder");
			attrs.push("folderField");
			attrs.push("fileFilter");
			attrs.push("emptyMessage");
		}
		else if (f.type === "Tabs") {
			attrs.push({
				logicalName: "customComponentParameters", type: MetaPropertyType.String,
				flags: MetaPropertyFlags.OptionSet, options: ["Normal", "Wizard"], defaultValue: "Normal"
			});
			attrs.push("wizardNextCommand");
		}
		else if (f.type === "Custom") {
			attrs.push("customComponent");
			attrs.push("customComponentParameters");
		}

		configureFormFieldDialog(modal, f, panel, "panels", attrs, e.target as HTMLElement, () => {
			onSave();
		});
	}

	const panel = parentPanel;
	const item = getDropTarget(e);
	const itemIndex = +(item.dataset["drag_index"] || 0);
	const f = panel.panels[itemIndex];
	if (f) {
		configureItem(e, f);
	} else {
		const menuSelection = await showMenu(modal, e.target as any, ["Add Fields Panel", "Add Associated List", "Add Other List", "Add Tabs", "Add Splitter", "Add Flex", "Add Cloud Files", "Add Custom Component", "Paste"]);
		let newPanel: IFormPanel | undefined = undefined;
		if (menuSelection === 0) {
			newPanel = { name: "Details", "type": "Fields", fields: [], label: "" } as IFormFieldsPanel;
		} else if (menuSelection === 1 || menuSelection === 2) {
			let list: { meta: IMetaObject, field: string };
			if (menuSelection === 1) {
				const availLists = getObjectChildren(metadata, parentMeta.logicalName);
				const menuItems = availLists.map(x => mm(x.meta.displayName || x.meta.logicalName, x.meta.logicalName + "." + x.field));
				if (menuItems.length === 0) {
					alert("No associated lists");
					return;
				}
				else {
					const listIndex = await showMenu(modal, e.target as any, menuItems);
					list = availLists[listIndex];
				}
			} else {
				const all = metadata.objects.filter(x => x.type !== "many");
				const virtIndex = await showMenu(modal, e.target as any, all.map(x => mm(x.displayName || x.logicalName, x.logicalName)));
				list = { meta: all[virtIndex], field: VirtualField };
			}
			newPanel = { name: list.meta.logicalName, "field": list.field, "type": "AssociatedList", "label": list.meta.displayNamePlural || list.meta.logicalName } as IAssociatedListPanel;
		} else if (menuSelection === 3) {
			newPanel = { name: "Tabs", "type": "Tabs", label: "", panels: [] } as IFormContainerPanel;
		} else if (menuSelection === 4) {
			newPanel = { name: "Split", "type": "Split", label: "", panels: [] } as IFormContainerPanel;
		} else if (menuSelection === 5) {
			newPanel = { name: "Flex", "type": "Flex", label: "", panels: [] } as IFormFlexPanel;
		} else if (menuSelection === 6) {
			newPanel = { name: "Files", "type": "CloudFiles" } as ICloudFilesPanel;
		} else if (menuSelection === 7) {
				newPanel = { name: "Custom", "type": "Custom" } as IFormPanel;
		} else if (menuSelection === 8) {
			const json = await navigator.clipboard.readText();
			try {
				const obj = JSON.parse(json);
				if (obj && obj.type && ["Tabs", "Fields", "AssociatedList", "Split", "Flex"].indexOf(obj.type) >= 0)
					newPanel = obj;
				else
					alert("Cannot paste object");
			}
			catch (e) {
				alert(e);
			}
		}
		if (newPanel)
			configureItem(e, newPanel);
	}
}

const mm = (title: string, sub: string) => {
	return <div style={{display:"flex", justifyContent:"space-between"}}><span>{title}</span><span style={{color:"gray", marginLeft:"2em"}}>{sub}</span></div>
}

export const useConfigureCommands = (
	customize: boolean,
	commands: ICommand[],
	parent: { commands?: ICommand[] },
	onSave: () => void,
	availableCommands?: string[],
):[ICommand[], any] => {
	
	const modal = useContext(ModalContext);
	
	const configureCommandClick = useCallback(async (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		const item = getDropTarget(e);
		const itemIndex = +(item.dataset["drag_index"] || 0);
		let f = (parent.commands || [])[itemIndex];

		if (!f) {
			availableCommands = availableCommands || ["CmdDelete", "CmdSave", "CmdShare"];
			const availCommands = availableCommands.map(x => ({ name: x } as ICommand)).concat([{ kind: "custom" as any, name: "Custom Command" }]);
			const c = await showMenu(modal, e.target as any, availCommands.map(x => "Add " + x.name).concat("Paste"));
			if (c === availCommands.length) {
				const json = await navigator.clipboard.readText();
				try {
					const obj = JSON.parse(json);
					if (obj && obj.name && (!obj.kind || obj.kind === "custom" || obj.kind === "std"))
						f = obj;
					else
						alert("Cannot paste object");
				}
				catch (e) {
					alert(e);
				}
			} else {
				f = availCommands[c];
			}
		}

		const attrs = ["kind", "name", "label", "icon", "option", "permissions"];
		parent.commands = parent.commands || [];
		configureFormFieldDialog(modal, f, parent, "commands", attrs, e.target as HTMLElement, () => {
			onSave();
		});
	}, [parent, onSave]);
	const dragDropCommand = useCallback((dragIndex: number, dropIndex: number) => {
		const arr = parent.commands || [];
		const field = arr.splice(dragIndex, 1)[0];
		arr.splice(dropIndex, 0, field);
		onSave();
	}, [parent.commands]);

	const cmdDrag = useDragProps(customize, dragDropCommand);

	let renderCommand: any = undefined;
	if (customize) {
		commands = (parent.commands || []).concat({ name: "" });

		renderCommand = (index: number, cmd: ICommand) => {

			if (index === commands.length - 1) {
				return <div  {...cmdDrag} onDragStart={undefined} onClick={configureCommandClick} className="formItemCustomizeAdd" draggable={true} data-kind="commanditem" data-drag_index={index} style={{ fontSize: "23px", height: "1.3em", width: "1.3em" }}>
					<i className="fa fa-plus"></i></div>
			}

			return <IconButton {...cmdDrag} icon="" label="" style={{ width: "fit-content" }} onClick={configureCommandClick} data-kind="commanditem" data-drag_index={index}>
				<i className={"fa fa-" + cmd.icon} />
				<span className="iconButtonLabel">{cmd.label || cmd.name}</span>
				<span style={{ position: "relative", top: "0", left: "0" }} className="formItemCustomize"><i className="fa fa-gears" /></span>
			</IconButton>
		}
	}
	return [commands, renderCommand];
}

export const useCustomizePanel = (context: IFormContext, panel: IFormContainerPanel) => {
	const { meta } = context;
	const customize = useContext(CustomizeContext);
	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);
	const [render, setRender] = useState(0);
	const forceRender = () => setRender(x => x + 1);

	const configureClick = useCallback((e: React.MouseEvent) => {
		configurePanel(modal, metadata, meta, panel, e, forceRender);
	}, [panel]);

	const customizeMovePanel = useCallback((dragIndex: number, dropIndex: number) => {
		const field = panel.panels.splice(dragIndex, 1)[0];
		panel.panels.splice(dropIndex, 0, field);
		forceRender();
	}, [panel.panels]);

	const dragEvents = useDragProps(customize.enabled, customizeMovePanel);

	return { customize, configureClick, dragEvents };
}

// const CustomizeHeader = (props: { onSave: () => void, children?: any }) => {
// 	const customize = useContext(CustomizeContext);
// 	if (customize.enabled) {
// 		return <div 
// 	}
// }

const ListEdit = (props: {
	title: string,
	opts: IEditListOptions,
	attrs: (string | IMetaProperty)[],
	objects: any[],
	setObjects: (objects: any[]) => void,
	commandSite: IModalParent
}) => {

	const mode = props.opts && props.opts.mode;

	//const { attrs } = props;
	const [attrs, attrsMeta, columns, fields] = useMemo(() => {
		const attrs = props.attrs.map(x => {
			if (typeof x === "string")
				return { logicalName: x, displayName: humanize(x), type: MetaPropertyType.String } as IMetaProperty;
			return x as IMetaProperty;
		});
		const attrsMeta = Dictionary.create(attrs, "logicalName");
		const columns = attrs.map(x => x.displayName || humanize(x.logicalName));
		columns.push("Actions");
		const fields = attrs.map(x => x.logicalName);
		fields.push("__actions");
		return [attrs, attrsMeta, columns, fields];
	}, [props.attrs]);
	const [objects, setObjects] = useState(props.objects.map(o => ({ ...o, __id: newGuid() })) || []);

	const cmds = useMemo(() => {
		const c = [
			// { name: "CmdCopy", label: "", icon: "copy" },
			// { name: "CmdRemove", label: "", icon: "trash" },
			{ name: "CmdAdd", label: "", icon: "plus" },
			{ name: "CmdSave", label: "", icon: "save" },
			{ name: "CmdClose", label: "", icon: "close" }
		]
		if (mode === "singleSelect")
			return [c[2]];
		return c;
	}, []);
	
	const onCommand = async (cmd: ICommand) => {
		// const fields = (panel as any)[props.arrayProp] as any[];
		// let index = -1;
		// if (original.current)
		// 	index = fields.indexOf(original.current);
		if (cmd.name === "CmdAdd") {
			setObjects(x => (x.concat({ __id: newGuid() })));
			return;
		}
		if (cmd.name === "CmdSave") {
			const savedObjs = objects.map(o => {
				const { __id, ...clean } = o;
				return clean;
			});
			props.setObjects(savedObjs);
		}
		props.commandSite.closeModal();
	}

	const cellFormatter = useCallback((row: any, fieldName: string, style: any) => {

		if (fieldName === "__actions") {
			if (mode === "singleSelect") {
				return <div style={{ display: "flex" }}>
					<IconButton icon={"check"} onClick={() => {
						props.setObjects([row]);
						props.commandSite.closeModal();
					}} />
				</div>
			}
			else {
				return <div style={{ display: "flex" }}>
					<IconButton icon={"close"} onClick={() => {
						setObjects(objs => {
							if (props.opts && props.opts.minCount) {
								if (objs.length <= props.opts.minCount) // minimum items.
									return objs;
							}
							return objs.filter(x => x !== row);
						});
					}} />
				</div>
			}
		}

		const value = row[fieldName];
		const propMeta = attrsMeta[fieldName];
		if (propMeta && !propMeta.isDisabled) { //&& !props.listColumns.find(x => x.attribute === fieldName)?.disabled) {
			const fp: IFieldProps = {
				getRecordProperty: (n) => (row[n])
			};
			const fe = FieldEditor(propMeta, value, fp, (newValue) => {
				const record = { ...row, [fieldName]: newValue };
				//setRecord(record);
				setObjects(objs => {
					return objs.map(x => x === row ? record : x);
				});
			});
			if (propMeta.type === MetaPropertyType.Lookup) // dont-wrap
				return fe;
			// todo move the focus handling to simpleList
			return <div className="editCell">{fe}</div>;
		}
		else if (propMeta && propMeta.type === MetaPropertyType.Lookup || fieldName === "name") {
			const label = value.label || "" + value;
			return <span style={{ whiteSpace: "nowrap", margin: "2px 5px" }}>{label}</span>
		}
		return simpleCellFormatter(row, fieldName, propMeta);
	}, []);

	// const keyMap = useRef(new Map<any, string>);
	// const rowKey = (row: any) => {
	// 	let key = keyMap.current.get(row);
	// 	if (!key) {
	// 		key = newGuid();
	// 		keyMap.current.set(row, key);
	// 	}
	// 	return key;
	// }

	return (<div className="objectListContainer" style={{ flex: "1 1 auto" }}>
		<TitleBox title={props.title} commands={cmds} onCommand={onCommand} />
		<SimpleGrid columns={columns} fields={fields}
			noEdit={true} rows={objects} formatter={cellFormatter} keyName={"__id"} />
	</div>)
}

export interface IEditListOptions {
	minCount?: number;
	mode?: "editList" | "singleSelect"
}

export const showEditObjectsDialog = (
	title: string,
	modal: IModalContext,
	opts: IEditListOptions,
	attrs: (string | IMetaProperty)[],
	objects: any[],
	setObjects: (objects: any[]) => void) => {
	
	const pp: IModalProps = {
		//body: (p) => <div className="popupAppHeader" style={{left:"auto", right:"0",height:h, width:"300px", background:"white"}}>< ConfigureField {...p} /></div> ,
		body: p => <ListEdit {...p} />,
		bodyProps: {
			title: title,
			attrs: attrs,
			opts: opts,
			//gridClassName: gridClassName,
			objects: objects,
			setObjects: setObjects
		},
		id: "EditObjectsDialog",
		showTitle: false,
		//anchor: { x: x, y: y, width: 300 },
		//"anchor": { x: 0, y: 0 },
		//style: { boxShadow: "rgb(0 0 0 / 53%) 0px 0px 10px 2px", margin: "0px", borderRadius: "4px" },
		//closeOnClickOutside: true,
	};
	modal.showModal(pp);
}