import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { IAppConfig } from "../../AppSchema";
import { base64DecToArr, bytesToHex } from "../../utils/base64";
import { fetchJson } from "../fetchJson";
import { getBlobUrlFromFileName, getFileNameFromBlobUrl, getOfflineFileUrl, getOnlineFileUrl } from "../getFileUrl";

export const saveBlobFile = async (metaContext: IAppConfig, maybeUrl: string, data: string) => {
	let blobName = getFileNameFromBlobUrl(maybeUrl) as string;

	if (blobName) {
		try {
			await Filesystem.deleteFile({
				path: metaContext.orgName + "/" + blobName,
				directory: Directory.Documents
			});
			console.log("deleted:" + blobName);
		}
		catch (e) {
			console.log("delete failed");
		}
	}
	//if (!blobName) { // always new random name.
	{
		const b = window.crypto.getRandomValues(new Uint8Array(50));
		const s = bytesToHex(b);
		blobName = s;
		if (data && data.indexOf("image/") > 0)
			blobName += ".image";
		else if (data && data.indexOf("application/pdf") > 0)
			blobName += ".pdf";
	}
	
	if (data) {
		const base64 = data.substring(data.lastIndexOf(',') + 1);
		await Filesystem.writeFile({
			path: metaContext.orgName + "/" + blobName,
			directory: Directory.Documents,
			data: base64
		});
		console.log("writen:" + blobName);
		return getBlobUrlFromFileName(blobName);
	}
	else {
		return null;
	}
}

export const downloadAndSaveBlobFiles = async (metaContext: IAppConfig, files: string[]) => {
	try {
		await Filesystem.mkdir({
			path: metaContext.orgName,
			directory: Directory.Documents,
		});
	}catch {} // ignore if exists
	for (const f of files) {
		const url = getOnlineFileUrl(metaContext, f);
		if (url) {
			//console.log("writing file:" + url);
			try {
				const resp = await fetch(url);
				//console.log("got file:" + url);
				const blob = await resp.blob();
				// fixme: do not convert blob, use plugin to convert it in native.
				const base64 = await convertBlobToBase64(blob);
				const fileName = getFileNameFromBlobUrl(f) as string;
				//console.log("writing file:" + fileName);
				await Filesystem.writeFile({
					path: metaContext.orgName + "/" + fileName,
					directory: Directory.Documents,
					data: base64
				});
			}
			catch (e) {
				console.log("ERROR WRITTING FILE:" + f + "ERR:" + JSON.stringify(e));
				throw e;
			}
		}
	}
}

 const convertBlobToBase64 = (blob: Blob) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
});

export const uploadBlobFiles = async (metaContext: IAppConfig, urls: string[]) => {
	for (const u of urls) {
		const url = getOnlineFileUrl(metaContext, u);
		const fileUrl = getOfflineFileUrl(metaContext, u);

		try {
			console.log("uploadBlobFile local file:" + fileUrl);
			
			try {
				//var blob = await (await fetch(fileUrl)).blob();
				const data64 = await Filesystem.readFile({
					path: metaContext.orgName + "/" + getFileNameFromBlobUrl(u),
					directory: Directory.Documents,
				});
				const arr = base64DecToArr(data64.data);
				var blob = new Blob([arr]);
				console.log("uploadBlobFile blob loaded");
			}
			catch (e) {
				console.log("uploadBlobFile read failed:" + e);
				throw e;
			}
			const opts = {
				headers: {
					'x-ms-blob-type': 'BlockBlob', // DONT FORGET TO ADD THIS HEADER IN AZURE CORS CONFIG!
				},
				method: "PUT",
				body: blob
			};
			const resp = await fetch(url, opts);
			console.log("uploadBlobFile blob success");
			if (!resp.ok) {
				console.log("upload failed:" + resp.text() + "STATUS: " + resp.statusText);
			}
		}
		catch (ex) {
			console.log("uploadBlobFile err:" + url + "ex:" + ex);
			}
	}
}

export const uploadTestFile = async () => {
	const arr: Uint8Array = Uint8Array.from([1, 2, 3, 4, 5]);
	var blob = new Blob([arr]);
	const url = "https://inuko.blob.core.windows.net/hoopers/f5f3a1d63fa1a377cc6487e47d76b51e08928f096af345dfbed0e7ef97c4230cea34c999aba1fa33b216d4b7ee190081cabc.image?sv=2021-10-04&se=2023-01-13T09%3A55%3A34Z&sr=c&sp=rw&sig=n%2B1wxyDvH513OANKyaTczXw5mRGtFawPsBxsP9OdgXY%3D";
	try {
		const opts = {
			headers: {
				'x-ms-blob-type': 'BlockBlob',
			},
			method: "PUT",
			body: blob,
		};
		const resp = await fetch(url, opts as any);
		if (!resp.ok) {
			console.log("upload failed:" + resp.text() + "STATUS: " + resp.statusText);
		}
	}
	catch (ex) {
		console.log("uploadBlobFile err:" + url + "ex:" + ex);
	}
}

(window as any)["xxx"] = uploadTestFile;