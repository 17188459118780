import { useContext, useEffect, useRef, useState, useCallback, useMemo } from "react"
import { IMetaProperty, MetaPropertyType } from "shared/schema";
import { ICommand, MetaContext } from "../AppState"
import { Loading } from "../components/Loading";
import SimpleGrid from "../components/SimpleGrid";
import { TitleBox } from "../components/TitleBox";
import { useDerivedState } from "../hooks/useDerivedState";
import { IModalContext, IModalParent, Modal, ModalContext } from "../modal/Modal";
import { importFile, ParsedFile, parseFile, parseJsonFile } from "./import";
import { download } from "./download";


export const showImportDialog = (modal: IModalContext, objectName: string, options?: string) => {
	
	let optionsObj = options as any;

	if (options && typeof options === "string") {
		optionsObj = undefined;
		try {
			optionsObj = JSON.parse(options);
		}
		catch (e) {
			console.log("showImportDialog invalid options:" + options);
		}
	}

	modal.showModal({
		id: "importDialog",
		showTitle: false,
		body: (props) => (<ImportComponent {...props} />),
		bodyProps: { objectName: objectName, objectSelectorVisible: false, ...optionsObj },
	});
}

export const ImportComponent = (props: {
	objectName?: string,
	commandSite?: IModalParent,
	objectSelectorVisible?: boolean,
	sampleFile?: string;
	sampleFileName?: string;
}) => {
	const metadata = useContext(MetaContext);
	const objects = useMemo(() => {
		return metadata.objects.filter(x => x.type !== "many").sort((a, b) => (a.displayName || a.logicalName).localeCompare(b.displayName || b.logicalName));
	}, []);
	
	const [objectName, setObjectName] = useState(props.objectName || objects[0].logicalName);
	const [fileContent, setFileContent] = useState(undefined as any as ParsedFile);
	const [loading, setLoading] = useState(false);
	const [ignoreExisting, setIgnoreExisting] = useState("0");
	const uniqueNames = useMemo(() => {
		const meta = metadata.objects.find(x => x.logicalName === objectName);
		if (meta && fileContent) {
			return fileContent.propMetas.filter(x => x && (x.logicalName === "id" || x.type === MetaPropertyType.String)) as IMetaProperty[];
			//return meta.properties.filter(x => x.logicalName === "id" || x.type === MetaPropertyType.String);
		}
		return [{ logicalName: "id", displayName: "" } as IMetaProperty];
	}, [objectName, fileContent]);

	const [uniqueName, setUniqueName] = useDerivedState((uniqueNames.find(x => x.logicalName === "name") && "name") ||
			(uniqueNames.find(x => x.logicalName === "id") && "id") || uniqueNames[0]?.logicalName, [uniqueNames]);

	const uploadRef = useRef<HTMLInputElement>(null);
	const onOpenFile = useCallback(() => {
		uploadRef.current?.click();
	}, [uploadRef]);

	const confirmDialog = (text: string) => {
		return window.confirm(text);
	}

	const onImportFile = async () => {
		if (fileContent) {
			try {
				setLoading(true);
				const results = await importFile(objectName, { uniqueField: ignoreExisting !== "2" ? uniqueName: "", ignoreExisting: ignoreExisting === "1", confirmDialog: confirmDialog }, fileContent);
				setLoading(false);
				const errors = results.filter(x => x.error).map(x => x.error).join('\n');
				const errCount = results.filter(x => x.error).length;
				let msg = "Imported: " + results.length + " records";
				if (errors) {
					msg += "\n\nErrors: " + errCount + "\n\n" + errors;
				}
				alert(msg);
			}
			catch (e) {
				alert(e);
			}
		}
	};

	const onUploadFile = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {
		const files = (e.target as HTMLInputElement).files;
		if (files && files.length > 0) {
			if (objectName) {
				const meta = metadata.objects.find(x => x.logicalName === objectName);
				if (meta) {
					setLoading(true);
					let fileContent: ParsedFile;
					const isJson = files[0].name.endsWith(".json");
					const text = await files[0].text();
					if (isJson) {
						fileContent = parseJsonFile(meta, text);
					} else {
						fileContent = parseFile(meta, text);
					}
					setLoading(false);
					setFileContent(fileContent);
				}
			}
		}
	}, [objectName, setLoading, setFileContent]);

	useEffect(() => {
		// if (objectName)
		// 	onImportClick();
	}, [objectName]);

	const cmds = useMemo(() => [{ name: "CmdDownloadSample", label: "Download Template", icon: "download" },
		{ name: "CmdOpenFile", label: "Upload File", icon: "upload" },
		{ name: "CmdImport", label: "Import", icon: "file-import" },
	].concat(props.commandSite ? [{ name: "CmdClose", label: "Close", icon: "close" }] : []) as ICommand[], []);
	cmds[0].enabled = !!objectName;
	cmds[1].enabled = !!objectName;
	cmds[2].enabled = !!fileContent && !!objectName;

	const onCommand = (cmd: ICommand) => {
		if (cmd.name === "CmdOpenFile")
			onOpenFile();
		else if (cmd.name === "CmdImport")
			onImportFile();
		else if (cmd.name === "CmdClose") {
			props.commandSite?.closeModal();
		}
		else if (cmd.name === "CmdDownloadSample") {
			let sampleFile = props.sampleFile;
			if (!sampleFile) {
				const meta = metadata.objects.find(x => x.logicalName === objectName);
				sampleFile = meta?.properties.map(x => x.logicalName)
					.filter(x => x !== "createdon" && x !== "createdby" && x !== "versionnumber" && x !== "modifiedon" && x != "modifiedby")
					.join(";");
			}
			if (sampleFile)
				download(sampleFile, props.sampleFileName || "import_" + objectName + ".csv", "text/csv");
		}
	};

	const fieldFormatter = (row: any, fname: string, style: any) => {
		const x = row[fname];
		
		if (x && x.id) {
			return Object.values(x).join(" ");
		}
		return x;
	}

	return <div className="objectListContainer" style={{flex:"1 1 auto"}}>
		<TitleBox title="Import Records" commands={cmds} onCommand={onCommand} />
		{loading && <Loading />}
		<fieldset className="loginForm" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
		{props.objectSelectorVisible !== false &&
			<div className="formItem">
				<label htmlFor="objectName" className="formLabel">Import Object</label>
				<select id="objectName" value={objectName} onChange={e => setObjectName(e.target.value)}>
					{objects.map(x => (<option value={x.logicalName} key={x.logicalName}>{x.displayName || x.logicalName}</option>))}
				</select>
			</div>
		}
		<div className="formItem">
			<label htmlFor="uniqueName" className="formLabel">Find Matching Records By</label>
			<select id="uniqueName" value={uniqueName} onChange={e=>setUniqueName(e.target.value)}>
			{uniqueNames.map(x => (<option value={x.logicalName} key={x.logicalName}>{x.displayName||x.logicalName}</option>))}
			</select>
		</div>
		<div className="formItem">
			<label htmlFor="ignoreExisting" className="formLabel">Matching Records</label>
			<select id="ignoreExisting" value={ignoreExisting} onChange={e => setIgnoreExisting(e.target.value)}>
				<option value="0">Update Existing</option>
					<option value="1">Skip Existing</option>
					<option value="2">Create New</option>
			</select>
		</div>
		</fieldset>
		<input ref={uploadRef} hidden={true} type="file" onChange={e => e.target.value = null as any} 
			onInput={onUploadFile} accept="text/csv, application/json" />
	
			{fileContent && <SimpleGrid noEdit={true} formatter={fieldFormatter} rows={fileContent.items} fields={fileContent.propMetas.filter(x=>!!x).map(x=>x?.logicalName as string)} />}
			<div style={{flex:"1 1 auto"}}></div>
	</div>
}
