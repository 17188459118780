"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetaPropertyFlagMax = exports.MetaPropertyFlags = exports.MetaPropertyType = exports.Dictionary = exports.Schema = void 0;
class Schema {
    generate(objs, options) {
        let sb = "";
        for (let o of objs) {
            sb += Schema.generateObject(o, options);
        }
        return sb;
    }
    static generateObject(o, options) {
        let sb = "CREATE TABLE " + o.logicalName + "(\n";
        Schema.ensureSystemFields(o);
        sb += Schema.generateProps(o, o.properties);
        sb = sb.substring(0, sb.length - 2) + "\n"; // remove last new line and coma
        if (options && options.sqlite && o.type === "many") {
            const fk = o.properties.filter(x => x.type === MetaPropertyType.Lookup);
            sb += ",UNIQUE(" + fk[0].logicalName + "," + fk[1].logicalName + ")\n";
        }
        sb += ");\n\n";
        return sb;
    }
    static generateProps(o, props) {
        let sb = "";
        for (let p of props) {
            sb += "\t" + p.logicalName + " " + Schema.mapPropType(p) + (p.logicalName === o.primaryKeyName ? " PRIMARY KEY DEFAULT newid()" : "") + ",\n";
            if (p.type === MetaPropertyType.Lookup) {
                sb += "\t" + p.logicalName + "Target NVARCHAR(200),\n";
            }
        }
        return sb;
    }
    static ensureSystemFields(o) {
        let pos = 0;
        const tryAdd = (props, prop) => {
            if (props.findIndex(x => x.logicalName === prop.logicalName) < 0) {
                props.splice(pos++, 0, prop);
            }
        };
        if (o.type === "many") {
            tryAdd(o.properties, { logicalName: "versionnumber", type: MetaPropertyType.VersionNumber, flags: 0 });
            return;
        }
        tryAdd(o.properties, { logicalName: "id", type: MetaPropertyType.Guid, flags: 0 });
        tryAdd(o.properties, { logicalName: "name", type: MetaPropertyType.String, flags: 0, max: "200" });
        tryAdd(o.properties, { logicalName: "createdon", type: MetaPropertyType.DateTime, flags: 0 });
        tryAdd(o.properties, { logicalName: "createdby", type: MetaPropertyType.Lookup, flags: 0, targets: ["systemuser"] });
        tryAdd(o.properties, { logicalName: "modifiedon", type: MetaPropertyType.DateTime, flags: 0 });
        tryAdd(o.properties, { logicalName: "modifiedby", type: MetaPropertyType.Lookup, flags: 0, targets: ["systemuser"] });
        tryAdd(o.properties, { logicalName: "statuscode", type: MetaPropertyType.Integer, flags: MetaPropertyFlags.OptionSet, options: ["1", "2"] });
        tryAdd(o.properties, { logicalName: "statecode", type: MetaPropertyType.Integer, flags: MetaPropertyFlags.OptionSet, options: ["0", "1"] });
        tryAdd(o.properties, { logicalName: "versionnumber", type: MetaPropertyType.VersionNumber, flags: 0 });
    }
    static generateUpdate(oldArr, newArr) {
        let sb = "";
        oldArr = oldArr.slice();
        newArr = newArr.slice();
        for (let o of newArr) {
            let oldObjIdx = oldArr.findIndex(x => x.logicalName === o.logicalName);
            if (oldObjIdx >= 0) {
                Schema.ensureSystemFields(o);
                // diff table
                let oldObj = oldArr[oldObjIdx];
                let oldProps = oldObj.properties.slice();
                let newProps = [];
                let alterProps = [];
                for (let p of o.properties) {
                    let oldIdx = oldProps.findIndex(x => x.logicalName === p.logicalName);
                    if (oldIdx >= 0) {
                        let oldProp = oldProps[oldIdx];
                        if (oldProp.type !== p.type) {
                            // Changed: leave in oldProps -> will drop	
                        }
                        else {
                            // Not Changed: remove from oldProps
                            if (oldProp.max !== p.max && p.type === MetaPropertyType.String) {
                                // NVARCHAR size changed.
                                alterProps.push(p);
                            }
                            oldProps.splice(oldIdx, 1);
                            continue;
                        }
                    }
                    newProps.push(p);
                }
                if (oldProps.length > 0) {
                    sb += "ALTER TABLE " + o.logicalName + " DROP COLUMN\n";
                    let dropColumnNames = oldProps.map(p => p.logicalName);
                    dropColumnNames = dropColumnNames.concat(oldProps.filter(p => p.type === MetaPropertyType.Lookup).map(p => p.logicalName + "Target"));
                    sb += dropColumnNames.join(',');
                    sb += ";\n\n";
                }
                if (alterProps.length > 0) {
                    for (const altProp of alterProps) {
                        sb += "ALTER TABLE " + o.logicalName + " ALTER COLUMN " +
                            Schema.generateProps(o, [altProp]);
                        sb = sb.substring(0, sb.length - 2);
                        sb += ";\n";
                    }
                    sb += "\n\n";
                }
                if (newProps.length > 0) {
                    sb += "ALTER TABLE " + o.logicalName + " ADD\n";
                    sb += Schema.generateProps(o, newProps);
                    sb = sb.substring(0, sb.length - 2);
                    sb += "\n;\n\n";
                }
                oldArr.splice(oldObjIdx, 1);
            }
            else {
                sb += Schema.generateObject(o);
            }
        }
        for (let o of oldArr) {
            sb += "DROP TABLE " + o.logicalName + " ;\n";
        }
        return sb;
    }
    static mapPropType(p) {
        switch (p.type) {
            case MetaPropertyType.DateTime:
                return "DATETIMEOFFSET";
            case MetaPropertyType.Money:
                return "MONEY";
            case MetaPropertyType.Decimal:
                return "DECIMAL(23,10)";
            case MetaPropertyType.Float:
                return "FLOAT";
            case MetaPropertyType.Integer:
                return "INT";
            case MetaPropertyType.Boolean:
                return "BIT";
            case MetaPropertyType.Guid:
            case MetaPropertyType.Lookup:
                return "UNIQUEIDENTIFIER";
            case MetaPropertyType.String:
                return p.max ? "NVARCHAR(" + ((+p.max)) + ")" : "NVARCHAR(max)";
            case MetaPropertyType.Binary:
                return "BINARY";
            case MetaPropertyType.VersionNumber:
                return "ROWVERSION";
            default:
                throw Error("Unknown property type:" + p.logicalName + " type:" + p.type);
        }
    }
}
exports.Schema = Schema;
class Dictionary {
    static create(arr, keyName, getKey) {
        const d = {};
        for (let element of arr) {
            if (keyName) {
                const objKey = element[keyName];
                d[objKey] = element;
            }
            else if (getKey) {
                const key = getKey(element);
                d[key] = element;
            }
            else {
                throw new Error("Please provide either keyName or getKey parameter!");
            }
        }
        return d;
    }
}
exports.Dictionary = Dictionary;
var MetaPropertyType;
(function (MetaPropertyType) {
    MetaPropertyType[MetaPropertyType["String"] = 0] = "String";
    MetaPropertyType[MetaPropertyType["Integer"] = 1] = "Integer";
    MetaPropertyType[MetaPropertyType["Decimal"] = 2] = "Decimal";
    MetaPropertyType[MetaPropertyType["Float"] = 3] = "Float";
    MetaPropertyType[MetaPropertyType["Money"] = 4] = "Money";
    MetaPropertyType[MetaPropertyType["DateTime"] = 5] = "DateTime";
    MetaPropertyType[MetaPropertyType["Guid"] = 6] = "Guid";
    MetaPropertyType[MetaPropertyType["Lookup"] = 7] = "Lookup";
    MetaPropertyType[MetaPropertyType["Boolean"] = 8] = "Boolean";
    MetaPropertyType[MetaPropertyType["Binary"] = 9] = "Binary";
    MetaPropertyType[MetaPropertyType["VersionNumber"] = 10] = "VersionNumber";
})(MetaPropertyType = exports.MetaPropertyType || (exports.MetaPropertyType = {}));
var MetaPropertyFlags;
(function (MetaPropertyFlags) {
    MetaPropertyFlags[MetaPropertyFlags["None"] = 0] = "None";
    MetaPropertyFlags[MetaPropertyFlags["MultiLine"] = 1] = "MultiLine";
    MetaPropertyFlags[MetaPropertyFlags["DateOnly"] = 2] = "DateOnly";
    MetaPropertyFlags[MetaPropertyFlags["UTC"] = 4] = "UTC";
    MetaPropertyFlags[MetaPropertyFlags["Required"] = 8] = "Required";
    MetaPropertyFlags[MetaPropertyFlags["Nullable"] = 16] = "Nullable";
    MetaPropertyFlags[MetaPropertyFlags["OptionSet"] = 32] = "OptionSet";
    MetaPropertyFlags[MetaPropertyFlags["MultiSelect"] = 64] = "MultiSelect";
    MetaPropertyFlags[MetaPropertyFlags["WriteIn"] = 128] = "WriteIn";
    MetaPropertyFlags[MetaPropertyFlags["Json"] = 256] = "Json";
    MetaPropertyFlags[MetaPropertyFlags["Url"] = 512] = "Url";
    MetaPropertyFlags[MetaPropertyFlags["Email"] = 1024] = "Email";
    MetaPropertyFlags[MetaPropertyFlags["Phone"] = 2048] = "Phone";
    MetaPropertyFlags[MetaPropertyFlags["Image"] = 4096] = "Image";
    MetaPropertyFlags[MetaPropertyFlags["File"] = 8192] = "File";
    MetaPropertyFlags[MetaPropertyFlags["Duration"] = 16384] = "Duration";
    MetaPropertyFlags[MetaPropertyFlags["AutoNumber"] = 32768] = "AutoNumber";
    MetaPropertyFlags[MetaPropertyFlags["Age"] = 65536] = "Age";
    MetaPropertyFlags[MetaPropertyFlags["Radio"] = 131072] = "Radio";
})(MetaPropertyFlags = exports.MetaPropertyFlags || (exports.MetaPropertyFlags = {}));
exports.MetaPropertyFlagMax = 18;
