import { useCallback, useMemo } from "react";

export const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
	const ds = (e.target as HTMLElement).dataset;
	e.dataTransfer.setData("drag_index_" + ds["drag_index"], "");
	e.dataTransfer.setData("drag_kind_" + ds["kind"], "");
}
export const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
	onDragOverInternal(e, (drag, drop, kind, elm) => {
		elm.style.backgroundColor = "#ccffcc";
	})
}
export const getDropTarget = (e: React.UIEvent) => {
	let target = e.target as HTMLElement;
	while (target && !target.draggable)
		target = target.parentElement as HTMLElement;
	return target;
}
export const onDragOverInternal =(e: React.DragEvent<HTMLDivElement>, canCopy:(dragIndex:number, dropIndex:number, kind:string, target: HTMLElement)=>void) => {

	let target = getDropTarget(e);
	if (!target)
		return;

	// if ((e.target as HTMLElement).draggable !== true)
	// 	return; // bubble.
	e.stopPropagation();
	//const target = e.currentTarget;

	let z = e.dataTransfer.types.find(x => x.startsWith("drag_index"));
	if (!z)
		return;
	
	const dragIndex = +z.substring("drag_index_".length);
	const dragKind = (e.dataTransfer.types.find(x => x.startsWith("drag_kind"))?.substring("drag_kind_".length)) || "";

	const dropIndex = +((target as HTMLDivElement).dataset["drag_index"] || 0);
	const dropKind = (target as HTMLDivElement).dataset["kind"];

	//console.log(`OVER ${dragIndex}|${dropIndex}|${dragKind}|${dropKind}`+(e.target as HTMLElement).innerHTML);

	if (dragIndex !== dropIndex && dropKind == dragKind) {
		e.dataTransfer.dropEffect = "copy";
		e.preventDefault();
		canCopy(dragIndex, dropIndex, dragKind, target);
	}
}
export const onDragEnter = (e: React.DragEvent) => {
	e.stopPropagation();
	// if ((e.target as HTMLElement).draggable === true)
	// 	e.stopPropagation();
	const target = getDropTarget(e);
	if (target) {
		target.dataset["drop_zone_enter_counter"] = "" + (+(target.dataset["drop_zone_enter_counter"] || "0") + 1);
		//console.log("ENTER "+(e.target as HTMLElement).innerHTML);
	}
}
export const onDragLeave = (e: React.DragEvent) => {
	//console.log("LEAVE "+(e.target as HTMLElement).innerHTML);
	e.stopPropagation();
	const target = getDropTarget(e);
	if (target) {
		const dragEnter = (+(target.dataset["drop_zone_enter_counter"] || "0") - 1);
		target.dataset["drop_zone_enter_counter"] = "" + dragEnter;
		if (dragEnter === 0)
			target.style.backgroundColor = "";
	}
	// if ((e.target as HTMLElement).draggable === true)
	// 	(e.target as HTMLElement).style.backgroundColor = "";
}

export const useDragProps = (draggable: boolean = true,
	executeDrop: (dragIndex: number, dropIndex: number, kind: string, target: HTMLElement) => void,
	monitor?: any[]
) => {

	const onDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		onDragOverInternal(e, async (dragIndex, dropIndex, kind, elm) => {
			if (kind) {
				onDragLeave(e);
				executeDrop(dragIndex, dropIndex, kind, elm);
			}
		})
	}, monitor ? monitor : [executeDrop]);

	const dragHandlers = useMemo(() => ({
		onDragEnter, onDragLeave, onDragOver, onDragStart, onDrop, draggable: draggable
	}), [draggable, onDrop]);
	
	return dragHandlers;
}