import { useState } from "react";
import { makeRichOptionSpan } from "./RichOptionSpan";

export const MultiSelectInput = (props: {value:any, onChange:(value:any)=>void, opts: string[], richOpts?: any[], disabled:boolean|undefined }) => {
	const [name] = useState(Math.random() * 1000000);
	const { value, opts, onChange } = props;
	const ro = props.richOpts;

	const addRemoveItem = (arr: any[], value: any, add: boolean) => {
		arr = Array.isArray(arr) ? arr : [];
		if (add)
			return arr.concat(value);
		else
			return arr.filter(x => x !== value);
	}

	return <div style={{ display: "flex", padding: "2px", flexWrap: "wrap" }}>{opts.map((x,i) => {
		const key = name + "_" + x;
		const isChecked = Array.isArray(value) && (value as any[]).find(v => v === x) !== undefined;
		return <label htmlFor={key} style={{display:"flex", paddingRight:"4px"}} key={key} >
			<input disabled={props.disabled} style={{ display: "inline" }} type="checkbox" name={key} id={key} checked={isChecked} key={key} value={x}
				onChange={e => onChange(addRemoveItem(value, x, e.target.checked))} />
			{ro && ro[i] ? makeRichOptionSpan(ro[i]) : (<span>{x}</span>)}
		</label>
	})}</div>
}