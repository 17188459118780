import { useCallback, useEffect, useRef, useState } from "react";
import { Fetch, mergeFetch } from "shared/fetch";
import { IAssociatedListPanel, IFormPanel, IList } from "../../AppSchema";
import { IconButton } from "../../components/IconButton";
import { formatMoneyValueWithCurrency } from "../../formatters";
import { useDerivedState } from "../../hooks/useDerivedState";
import { NumberInput } from "../../components/NumberInput";
import { checkSessionOnce, getSessionValue, IPanelProps, PanelPropsEventType, setSessionValue } from "./PanelProps";
import { DataService } from "../../service";
import { newGuid } from "../../utils/guid";
import { ObjectList } from "../../objectList/ObjectList";
import { ISalesLineItem } from "../../model/sales";

/*
1. button 
- show all / show cart
2. customizeFetch (event on list) / customizeList (if native component)
- add workorderline link (pricelistitem.productid or product.id)
- or just load the workorderlines separately (will be harder to show cart only...)
3. render
- show quantity from WOL as editable field
- if changed quantity -> add to modified list
- native can use renderCell override, otherwise add "events" to list
4. save? 
(native component can hook the PanelProps events)
*/

export const isProductListPanel = (panel: IFormPanel) => {
	const tab = panel as IAssociatedListPanel;
	if (tab.name === "product" && tab.field.endsWith(".productid"))
		return true;
	return false;
}

interface ILineItem extends ISalesLineItem {
	isNew: boolean,
	dirty: boolean,
	[x: string]: any
}

export const ProductListPanel = (props: IPanelProps) => {
	const context = props.context;
	const { meta, id } = context;
	const record = context.getRecord();
	const tab = props.panel as IAssociatedListPanel;
	const objectName = tab.name;
	const [field, intersectEntity, lookup2] = tab.field.split(".");
	const alias = "g__" + intersectEntity;

	const [lineItems, setLineItems] = useState([] as ILineItem[]);

	const [isManage, setManage] = useDerivedState(id === "0", [id]); // new record -> start with catalog
	const [_, setRender] = useState(false);
	const forceRender = () => setRender(x => !x);

	useEffect(() => {
		const loadLineItems = async () => {
			let items: any[] = [];
			if (id !== "0") {
				const q = { entity: { name: intersectEntity, allattrs: true, filter: { conditions: [{ attribute: field, operator: "eq", value: id }] } } } as Fetch;
				items = await DataService.retrieveMultiple(q);
			}
			console.log("PRODUCT_LIST_STATE LOADED:" + items.length);
			setLineItems(items as any);
			setSessionValue(context, tab.field, items);
		}
		
		const sessionItems = getSessionValue(context, tab.field, true);
		if (sessionItems) {
			setLineItems(sessionItems);
		} else {
			loadLineItems();
		}
	}, [id, meta, context.session.version]);

	if (checkSessionOnce(context, tab.field + "_event")) {
		const eventHandler = async (eventName: PanelPropsEventType) => {
			if (eventName === "afterSave") {
				const lineItems = getSessionValue(context, tab.field, true) as ILineItem[];
				if (!lineItems) return true;

				const parent = context.getRecord();
				const requests = lineItems
					.filter(x => x.dirty && (x.quantity > 0 || !x.isNew))
					.map(x => {
						const { isNew, dirty, ...obj } = x; // fixme: actually only copy quantity, price and ppu (woid, pid, etc.)
						obj[field] = { id: parent.id, name: context.meta.logicalName };
						const r = {
							name: intersectEntity,
							operation: (x.isNew ? "create" : (x.quantity === 0 ? "delete" : "update")) as any,
							object: obj as any,
						};
						return r;
					});
				if (requests.length > 0) {
					await DataService.executeMultiple(requests);
				}
			}
			return true;
		}
		context.events?.push(eventHandler);
	}

	const render = useCallback((row: any, field: string) => {
	
		let lineItem = lineItems.find(x => x[lookup2]?.id === row.id) as ILineItem;
		if (!lineItem) {
			lineItem = {
				id: newGuid(),
				[lookup2]: { id: row.id, name: objectName },
				quantity: 0,
				priceperunit: +(row["listprice"] || "0"),
				totalamount: 0,
				baseamount: 0,
				discount: 0,
				isNew: true,
				dirty: true,
			};
			lineItems.push(lineItem);
		}
		const updateQuantity = (add: number, quantity?: number) => {
			const prevPrice = +(lineItem.totalamount);
			lineItem.quantity = quantity !== undefined ? quantity : (+lineItem.quantity + add);
			lineItem.baseamount = lineItem.quantity * (+lineItem.priceperunit);
			lineItem.totalamount = lineItem.discount ? (lineItem.baseamount * ((100 - (+lineItem.discount))/100)) : lineItem.baseamount;
			lineItem.dirty = true;
			const r = context.getRecord();
			context.setRecord({ ...r, totalamount: +(r.totalamount || 0) - prevPrice + lineItem.totalamount });
			forceRender();
		};

		const quantityName = alias + "__quantity";
		if (field === quantityName) {
			const btnStyle = { flex: "1 1 auto", border: "0", background: "none", height: "1em", width:"1em", fontSize: "20px" };
			const isEmpty = !lineItem.quantity;

			const addElement = <IconButton label={isEmpty ? "Add" : ""} style={{ ...btnStyle, width:"fit-content", color: isEmpty ? "green" : "" }} icon="plus" onClick={(e) => {
				e.stopPropagation();
				updateQuantity(1);
			}} />
			const removeElement = <IconButton label="" icon="minus" style={btnStyle} onClick={(e) => {
				e.stopPropagation();
				updateQuantity(-1);
			}} />
			if (isEmpty) {
				return <div style={{ alignSelf: "flex-end", display: "flex" }} >{addElement}</div>;
			}
			const inputStyle = { border: 0, maxWidth: "40px", paddingLeft: "5px", justifySelf: "stretch" };
			const valueElement = <NumberInput value={lineItem.quantity} precision={"2"} inputProps={{ style: inputStyle }} onChange={value => {
				updateQuantity(0, +value);
			}} />
			return <div style={{ alignSelf: "flex-end", textAlign: "right", display: "flex", alignItems: "strech" }}>{addElement}{valueElement}{removeElement}</div>
		}
		else {
			if (field.startsWith(alias)) {
				if (field.endsWith("__priceperunit")) {
					const inputStyle = { border: 0, paddingLeft: "5px", textAlign: "right", justifySelf: "stretch" };
					return <NumberInput value={lineItem.priceperunit || ""} precision={"2"} inputProps={{ style: inputStyle }}
						onChange={value => {
							lineItem.priceperunit = +value || 0;
							updateQuantity(0);
						}}
						customFormatter={s=>formatMoneyValueWithCurrency(undefined, s)}
					/>
				}
					//return formatMoneyValueWithCurrency(undefined, lineItem.priceperunit);
				if (field.endsWith("__totalamount")) {
					const priceLabel = formatMoneyValueWithCurrency(undefined, lineItem.totalamount);
					if (lineItem.totalamount === 0)
						return lineItem.isNew ? "" : <span style={{ textDecoration: "line-through" }}>{priceLabel}</span>;
					return priceLabel;
				}
				if (field.endsWith("__discount")) {
					//return formatNumericValue(0, lineItem.discount);
					const inputStyle = { border: 0, maxWidth: "40px", paddingLeft: "5px", textAlign: "right", justifySelf: "stretch", background: "transparent" };
					const valueElement = <NumberInput value={lineItem.discount || ""} precision={"0"} inputProps={{ style: inputStyle }} onChange={value => {
						const newDiscount = (+value) || 0;
						const oldDicount = (+lineItem.discount) || 0;
						if (newDiscount !== oldDicount) {
							lineItem.discount = (+value) || 0;
							updateQuantity(0);
						}
					}} customFormatter={s => s ? "" + s + "%" : ""} />
					return <div style={{ alignSelf: "flex-end", textAlign: "right", display: "flex", alignItems: "strech" }}>
						{valueElement}
					</div>
				}
				if (field.endsWith("__baseamount"))
					return formatMoneyValueWithCurrency(undefined, lineItem.baseamount);
			}
		}
		return undefined;
	}, [lineItems]);

	const listRef = useRef<{ src: IList, dst: IList, isManage: boolean, lineItems: any } | undefined>();
	const onCustomizeList = useCallback((list: IList) => {
		if (!listRef.current || listRef.current.src !== list ||
			listRef.current.isManage !== isManage || listRef.current.lineItems !== lineItems) {

			const lineFields = ["quantity", "priceperunit", "baseamount", "discount", "totalamount"];
			const copy = JSON.parse(JSON.stringify(list)) as IList;
			if (!copy.columns || copy.columns.length === 0)
				copy.columns = [{ attribute: "name" }];
			copy.columns = copy.columns.concat(lineFields.map(x => ({ attribute: alias + "__" + x })));

			copy.commands = [{ kind: "custom",
				icon: isManage ? 'cart-shopping' : 'cart-plus',
				label:"",//label: isManage ? "Show Cart" : "Show Catalog",
				name: "CmdManage",
				compiledMethod: (a, b) => setManage(p => !p),
			}];

			const q = copy.query;
			mergeFetch(q, tab.extraFetch);

			q.entity.links = [{
				name: intersectEntity,
				from: "id",
				to: lookup2,
				alias: alias,
				attributes: [lookup2, "id"].concat(lineFields).map(x => ({ attribute: x })),
				type: "outer",
				filter: { conditions: [{ attribute: "id", operator: "null" }] }
			}];
			if (!isManage) {
				// fixme: merge filters.
				q.entity.filter = {
					conditions: lineItems.filter(x => !!x.quantity || !x.isNew).map(x => ({ attribute: "id", operator: "eq" as any, value: x[lookup2]?.id }))
						.concat([{ attribute: "id", operator: "null", value: "" }]),
					type: "or",
				}
			}
			listRef.current = { src: list, dst: copy, isManage: isManage, lineItems: lineItems };
		}
		return listRef.current.dst;
	}, [tab, listRef, isManage, lineItems]);

	return <ObjectList objectName={objectName} customizeList={onCustomizeList}
		onRenderCell={render}
		initialView={tab.initialView} allowedViews={tab.allowedViews} />
}
