import { useEffect, useRef } from "react";
import { Fetch } from "shared/fetch";
import { DataService } from "../service";
import { IFormState, getMergedServerRecordWith, internalSetLoadedRecord } from "./useFormState";
import { base64DecToArr } from "../utils/base64";

const getVersion = (ver: string) => {
	const arr = base64DecToArr(ver);
	let version = BigInt(0);
	for (let i = 0; i < arr.length; i++) {
		version = (version << BigInt(8)) | BigInt(arr[i]);
	}
	return version;
}

export const useFormChangeWatcher = (formState: IFormState, panelProps: { prevRecord: any, id: any }, showTooltip: () => void) => {
	const objectName = formState.meta.logicalName;

	const token = useRef(0);

	useEffect(() => {

		const changeHandler = (e: any) => {
			const counter = ++token.current;
			const asyncCheck = async () => {

				if (formState.saving || formState.loading) {
					console.log("SSE: reloading");
					return;
				}

				const r = formState.record;
				const verstr = r.versionnumber;
				const version = getVersion(verstr);

				const q: Fetch = {
					entity: {
						name: objectName,
						allattrs: true,
						filter: {
							conditions: [{ attribute: "id", operator: "eq", value: r.id },
							{ attribute: "versionnumber", operator: "ne", value: version.toString() }]
						}
					}
				}
				try {
					const recs = await DataService.retrieveMultiple(q) as any[];
					if (counter !== token.current || formState.saving || formState.loading) {
						console.log("SSE skip check: " + (counter !== token.current) + " | saving:" + formState.saving + " | loading:" + formState.loading);
						return;
					}

					const serverRecord = recs[0];
					if (!serverRecord) {
						console.log("SEE No object (deleted) or not changed");
					} else if (serverRecord.versionnumber === formState.record.versionnumber) {
						console.log("SEE No object (deleted) or not changed2");
					} else {

						console.log("SSE ObjectForm got changes - record modified");
						
						if (!formState.isDirty) {
							// Other option: full window.reload, not nice if the user is editing...
							panelProps.prevRecord = undefined;// prevRecord for changeHandler as undefined (re-run show/hide, enable fields etc.)
							internalSetLoadedRecord(formState, serverRecord);
						} else {
							const { patch, error } = getMergedServerRecordWith(formState, serverRecord);
							if (error) // Ignore the error, it will come up again when saving... 
								throw alert(error);
				
							formState.loadedRecord = serverRecord;
							// should we re-run changes and play with the prevRecord?
							// for now, let the change handler deal with incomming server changes.
							// the form is dirty anyway, and the order of changes should not matter
							formState.setRecord(undefined, patch, true);
						}

						showTooltip();
					}
				} catch (e) {
					console.log("ObjectForm got changes - error: " + e);
				}
			}
			asyncCheck();
		}

		const eventName = "objectChanged:" + objectName;
		if (formState.id !== "0") { // new record don't bother
			console.log("SEE ObjectForm watching for changes");
			window.addEventListener(eventName, changeHandler);
		}

		return () => window.removeEventListener(eventName, changeHandler);
	}, [objectName, formState]);
}

// export const useFormChangeWatcher = (formState: IFormState, panelProps: { session: { version: number }, watcher: Date }) => {
// 	// disabled for now
// 	// 1. better conflict message and actions
// 	// 2. if record is updated, show message
// 	// 3. think more about initial state fixups - panelProps.prevRecord is stale.
// }