import { useCallback, useContext, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { FetchEntity } from "shared/fetch";
import { Dictionary, IDictionary, IMetaObject, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { ICommand, IForm, IFormContainerPanel, IFormFieldsPanel, IFormSplitPanel, IList, IListColumn } from "../AppSchema";
import { getFetchAttributes, MetaContext } from "../AppState";
import { Loading } from "../components/Loading";
import { CustomizeContext } from "../objectForm/customize/customizeContext";
import { PanelProps } from "../objectForm/panels/PanelProps";
import { SingleObjectList } from "../objectList/ObjectList";
import { CommandsEditor } from "./CommandsEditor";
import { ConfigureFormField } from "./ConfigureFormField";
import { DragList } from "./DragList";
import { DropList } from "./DropList";
import { EntityNode } from "./FetchEditor";

interface PanelPropsEx extends PanelProps {
	body: IList;
}

const createFetchPanel = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const objectName = record.objectname as string;

	if(!props.body) return <Loading/>

	if (!objectName)
		return <div>Select entity first.</div>

	const body = props.body;
	let q = body.query;
	if (!body.query || body.query.entity.name !== objectName) {
		q = { entity: { name: objectName, attributes: [{ attribute: "name" }, { attribute: "id" }], orders: [{ attribute: "name" }] } };
		body.query = q;
	}

	return <EntityNode entity={q.entity} />
}


const createCommandsPanel = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const objectName = record.objectname as string;

	if (!objectName || !props.body)
		return <div>Select entity first.</div>
	
	const cmds = props.body.commands || [];
	const onCommandsChanged = (cmds: ICommand[]) => {
		props.body.commands = cmds;
		props.setRecord({ ...props.getRecord() }); //force render... hacky
	}
	
	const stdCommands = ["CmdNew", "CmdEdit", "CmdMap", "CmdCalendar", "CmdImport", "CmdExport"];
	return (<CommandsEditor cmds={cmds} stdCommands={stdCommands} onCommandsChanged={onCommandsChanged} />);
}

const ColumnsList = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const metadata = useContext(MetaContext);
	const objectName = record.objectname as string;

	if(!props.body || !props.body.query) return <Loading/>

	if (!objectName)
		return <div>Select entity first.</div>

	if (!props.body.columns) {
		props.body.columns = [];
	}
	const cols = props.body.columns;

	const r = getFetchAttributes(metadata, props.body.query);
	const propMetaDict = r.reduce((d, v) => (d[v.attribute] = v.prop, d), {} as IDictionary<IMetaProperty>);

	const getName = (x: IListColumn) => (x.attribute);
	const getLabel = (item: IListColumn) => {
		const name = item.attribute;
		const propMeta = propMetaDict[name];
		if (propMeta) {
			return { label: item.label || propMeta.displayName || propMeta.logicalName, sub: name + " " + MetaPropertyType[propMeta.type] }
		}
		return { label: "", sub: "" }
	};

	const mb = (name: string) => ({ logicalName: name, type: MetaPropertyType.Boolean, flags: 0 });

	const configureItem = (item: IListColumn) => {
		return <ConfigureFormField item={item} attrs={["label", "width", mb("disabled"), mb("nolink") ]} />
	}

	return (<DropList columns="1fr" configureItem={configureItem} fields={cols} getName={getName} getLabel={getLabel} createItem={name=>({attribute: name})} />);
}
const ColumnsAvailableList = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const metadata = useContext(MetaContext);
	const objectName = record.objectname as string;

	if(!props.body || !props.body.query) return <Loading/>

	if (!objectName)
		return <div>Select entity first.</div>

	const r = getFetchAttributes(metadata, props.body.query);

	const cols = r.map(({ attribute, prop }) => {
		return { ...prop, logicalName: attribute, displayName: prop.displayName || prop.logicalName };
	});

	return <DragList fields={cols} />
}

const createColumnsPanel = (props: PanelPropsEx) => {
	const record = props.getRecord();
	const objectName = record.objectname as string;

	if (!objectName || !props.body)
		return <div>Select entity first.</div>

	return (<div className="formFieldsEditor">
		{/* <div>Properties</div> */}
		<ColumnsList {...props} />
		<ColumnsAvailableList {...props}/>
	</div>)
}


const FormDesigner = (props: PanelPropsEx) => {

	const metadata = useContext(MetaContext);

	const record = props.getRecord();
	const objectName = record.objectname as string;
	if (!objectName || !props.body)
		return <div>Select entity first.</div>
	
	//fakeProps.meta = metadata.objects.find(x => x.logicalName === objectName) as any;

	const body = props.body;
	if (!body.query || body.query.entity.name !== objectName) {
		const q = { entity: { name: objectName, attributes: [{ attribute: "name" }, { attribute: "id" }], orders: [{ attribute: "name" }] } };
		body.query = q;
	}

	const listSelector = <h1>{record.label || record.name}</h1>

	return <div className="formDesigner">
		<CustomizeContext.Provider value={{ enabled: true, onSave: () => { } }}>
			<SingleObjectList selectedListBody={body} objectName={objectName} title={""} listSelector={listSelector}  />
		</CustomizeContext.Provider>
	</div>
}

export const makeCustomizeListForm = (meta: IMetaObject) => {
	const form: IForm = {
		name: meta.logicalName,
		panel: {
			name: "main",
			type: "Split",
			gridColumns: "minmax(300px, 1fr) 5fr",
			panels: [
				{
					name: "fields",
					type: "Fields",
					fields: ["name", "label", "objectname", "appid", "isprivate"].map(x => ({ name: x })),
				} as IFormFieldsPanel,
				{
					name: "Details",
					type: "Tabs",
					panels: [ 
						{ name: "design", type: "Custom", label: "Designer", createMethod: FormDesigner },
						// { name: "Fetch", type: "Custom", label: "Fetch", createMethod: createFetchPanel },
						// { name: "Columns", type: "Custom", label: "Columns", createMethod: createColumnsPanel },
						// { name: "Commands", type: "Custom", label: "Commands", createMethod: createCommandsPanel }
					]
				} as IFormContainerPanel
			]
		} as IFormSplitPanel,
		commands: [],
	};
	return form;
}
