import { Fetch, FetchCondition, FetchFilter } from "shared/fetch";
import { IDictionary, IExecuteRequest, ILookupValue, IMetaObject, IMetaProperty, MetaPropertyType } from "shared/schema";
import { IAppConfig } from "../AppSchema";
import { DataService } from "../service";
import { IDataService } from "./baseService";

const parseLine = (s: string, sep: string) => {
	const parts: string[] = [];
	let quoted = false; 
	let acc = "";

	for (let i = 0; i < s.length; i++) {
		const c = s[i];
		if (!quoted && c === sep) {
			parts.push(acc);
			acc = "";
			continue;
		}
		if (c === "\"") {
			if (!quoted) {
				quoted = true;
				continue;
			}
			else {
				if (s[i + 1] === "\"")
					i++;
				else {
					quoted = false;
					continue;
				}
			}
		}
		if (quoted && c === "\\") {
			if (s[i + 1] === "n") {
				i++;
				acc += "\n";
				continue;
			}
			if (s[i + 1] === "\\") {
				i++;
				acc += "\\";
				continue;
			}
		}
		acc += c;
	}
	parts.push(acc);

	return parts;
}

const isGuid = (s: string): boolean => {
	if (/^[0-9a-f]{32}$/i.test(s))
		return true;
	let pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	return pattern.test(s);
}

const fixNewLines = (input: string): string => {
	let output = "";
	let quoted = false;
	for (let i = 0; i < input.length; i++) {
		const c = input[i];
		if (c === "\r") {
			if (input[i + 1] !== "\n") {
				output += quoted ? "\\n" : "\n";
				continue;
			}
			continue;
		}
		if (quoted && c === "\"") {
			if (input[i + 1] === "\"") {
				output += "\"\"";
				i++;
				continue;
			}
			quoted = false;
		} else if (!quoted && c === "\"") {
			quoted = true;
		} else if (quoted && c === "\n") {
			output += "\\n";
			continue;
		}
		
		output += c;
	}
	return output;
} 

export const parseJsonFile = (meta: IMetaObject, content: string): ParsedFile => {
	const z = JSON.parse(content);
	const propMetas: { [name:string]: IMetaProperty } = {};
	if (z && z.length) {
		for (let i = 0; i < z.length; i++) {
			const x = z[i];
			if (x) {
				for (const key of Object.keys(x)) {
					if (!propMetas[key]) {
						propMetas[key] = meta.properties.find(p => p.logicalName === key)!;
					}
				}
			}
		}
	}
	return { items: z, propMetas: Object.values(propMetas) };
}

export const parseFile = (meta: IMetaObject, content: string): ParsedFile => {

	content = fixNewLines(content);

	let sep = ';';
	const lines = content.split('\n');
	const sepLine = lines[0]
	if (sepLine.startsWith("sep=")) {
		lines.splice(0, 1);
		sep = sepLine[4];
	}
	const header = parseLine(lines[0], sep);
	
	const propMetas: (IMetaProperty|undefined)[] = [];
	const targetLookup: IDictionary<number> = {};
	for (let i = 0; i < header.length; i++) {
		const name = header[i];
		const propMeta = meta.properties.find(x => x.logicalName === name || (x.displayName && x.displayName === name));
		if (name.endsWith("Target")) {
			targetLookup[name.substring(0, name.length - "Target".length)] = i;
		}
		propMetas.push(propMeta);
	}
	
	const items: IDictionary<string | ILookupValue>[] = [];
	for (let i = 1; i < lines.length; i++) {
		const pp = parseLine(lines[i], sep);

		const item = {} as any;
	
		for (let j = 0; j < propMetas.length; j++) {
			const propMeta = propMetas[j];
			if (!propMeta) continue;

			const value = pp[j];
			if (pp[j] === "") {
				item[propMeta.logicalName] = null;
			}
			else if (propMeta.type === MetaPropertyType.Lookup) {
				const targetIndex = targetLookup[propMeta.logicalName];
				let target = "";
				if (targetIndex !== undefined)
					target = pp[targetIndex];
				else if (propMeta.targets)
					target = propMeta.targets[0];
				item[propMeta.logicalName] = { id: value, name: target };
			}
			else if (propMeta.type === MetaPropertyType.DateTime) {
				if (value) {
					let dt: Date;
					let text = value.replaceAll(" ", "");
					const dateGroups = /(\d{1,2})\.(\d{1,2})\.(\d{4})/.exec(text);
				
					if (dateGroups)
						dt = new Date(Date.UTC(+dateGroups[3], (+dateGroups[2]) - 1, +dateGroups[1]));
					else
						dt = new Date(text);
					
					if (!Number.isNaN(dt.getTime())) {
						if (dt.getFullYear() < 1900) {
							console.log(lines[i]);
						}
						item[propMeta.logicalName] = dt.toISOString();
					}
				}
			}
			else if (propMeta.type === MetaPropertyType.String && propMeta.max) {
				const maxLength = +propMeta.max;
				item[propMeta.logicalName] = value?.substring(0, maxLength);
			}
			else {
				item[propMeta.logicalName] = value;
			}
		}
		items.push(item);
	}
	return { propMetas, items };
}

export interface ImportConfig {
	uniqueField?: string; // field name to use as record identity (id or name, but could be email too)
	ignoreExisting?: boolean; // yes - records found by the unique field will be _not_ imported, false - they will be updated
	confirmDialog?: (text: string) => boolean;
}

export interface ParsedFile {
	propMetas: (IMetaProperty | undefined)[]; // parsed header, elements can be null if they did not match a object field.
	items: IDictionary<string | ILookupValue>[];
}

const fetchIds = async (retrieveMultiple: (q: Fetch) => Promise<any[]>, objectName: string, uniqueName: string, values: string[], op:"eq"|"like") => {

	// remove duplicates, todo: case insensitive
	values = values.sort().filter((item, pos, ary) => {
		return pos === 0 || item !== ary[pos - 1];
	});

	const map: IDictionary<string> = {};

	for (let i = 0; i < values.length;) {
		const q: Fetch = {
			entity: {
				name: objectName,
				attributes: [{ attribute: "id" }],
				filter: { type: "or", conditions: [] }
			}
		};
		if (uniqueName !== "id")
			q.entity.attributes?.push({ attribute: uniqueName });
		
		const c = (q.entity.filter as any).conditions as FetchCondition[];
		const len = Math.min(i + 100, values.length);
		for (; i < len; i++) {
			c.push({ attribute: uniqueName, operator: op, value: values[i] });
		}

		const records = await retrieveMultiple(q);
		for (const r of records) {
			map[r[uniqueName]] = r.id;
		}
	}

	return map;
}

export const importFile = async (objectName: string, config: ImportConfig, file: ParsedFile) => {
	var service = DataService;

	//if( !config.uniqueField && file.propMetas.find(x=>x && x.logicalName === "id"))
	let items = file.items;

	const uniqueField = config.uniqueField;
	let existingRecords: IDictionary<string> = {};
	if (uniqueField) {
		const values = file.items.map(x => x[uniqueField] as string);
		existingRecords = await fetchIds(service.retrieveMultiple, objectName, uniqueField, values, uniqueField === "id" ? "eq" : "like");
	
		if (config.ignoreExisting) {
			items = items.filter(x => !existingRecords[x[uniqueField] as string]);
		}
	}

	const lookups: IDictionary<string[]> = {};

	for (const propMeta of file.propMetas) {
		if (!propMeta || propMeta.type !== MetaPropertyType.Lookup) continue;

		for (const item of items) {
			var link = item[propMeta.logicalName] as ILookupValue;
			if (!link || !link.id || isGuid(link.id)) continue;

			if (!lookups[link.name])
				lookups[link.name] = [];
			
			lookups[link.name].push(link.id);
		}
	}
	let existingLookups: IDictionary<string> = {};
	for (const kv of Object.entries(lookups)) {
		const r = await fetchIds(service.retrieveMultiple, kv[0], "name", kv[1], "like");

		existingLookups = { ...existingLookups, ...r };
	}

	for (const propMeta of file.propMetas) {
		if (!propMeta || propMeta.type !== MetaPropertyType.Lookup) continue;

		for (const item of items) {
			var link = item[propMeta.logicalName] as ILookupValue;
			if (!link || !link.id || isGuid(link.id)) continue;

			const foundId = existingLookups[link.id];
			if (foundId)
				link.id = foundId;
			else
				item[propMeta.logicalName] = null as any;
		}
	}

	const uploads: IExecuteRequest[] = [];
	let updateCount = 0;
	let createCount = 0;
	for (const item of items) {
		const upload: IExecuteRequest = { object: item, name: objectName, operation: "create" };
		if (uniqueField && !config.ignoreExisting) {
			const id = existingRecords[item[uniqueField] as string];
			if (id) {
				upload.operation = "update";
				if (uniqueField !== "id")
					upload.object.id = id;
			}
		}
		if (upload.operation === "create")
			createCount++;
		else
			updateCount++;

		uploads.push(upload);
	}

	if (config.confirmDialog) {
		const text = `Import will
Create ${createCount} record(s)
Update ${updateCount} record(s)
continue?`;
		if (!config.confirmDialog(text))
			return [];
	}

	return await DataService.executeMultiple(uploads);
}