import { IMetaObject } from "shared/schema";
import { IAppConfig, IForm, IFormContainerPanel, IFormField, IFormFieldsPanel } from "../AppSchema";
import { IFieldProps } from "../objectForm/panels/PanelProps";
import { generatedPdfFile } from "../command/clientApi/services";
import { simpleCellFormatterText } from "../objectList/useCellFormatter";
import { createGrid, addText, addGrid } from "../report/pdfReport";
import { download } from "./download";
import { Fetch, mergeFetch, applyMacros } from "shared/fetch";
import { DataService } from "../service";

export interface IExportRecordOptions {
	outputName?: string;
	title?: string;
	panelsFilter?: string[];
	// formName?: string;
}

export const exportRecordAsPdf = async (metadata: IAppConfig,
	form: IForm,
	customProps: { [key: string]: IFieldProps },
	meta: IMetaObject,
	record: any,
	options?: IExportRecordOptions) => {

	const styles = getStyles();
	const mainGrid = createGrid(0, 0, ["1fr"], ["auto"]);

	const docTitle = options?.title ?
		formatRecordString(metadata, options.title, meta, record) : 
		(meta.displayName + " " + record.name);
	addText(mainGrid, 0, 0, "title", docTitle);
	// const subTitleGrid = addGrid(mainGrid, 0, -1, ["1fr"], ["auto"]);
	// addText(subTitleGrid, 0, 0, "subTitle", record.name);

	const itemsGrid = addGrid(mainGrid, 0, -1, ["1fr"], ["auto"]);

	let row = 0;
	let childGrid: any = undefined;
	const endRow = () => {
		if (childGrid) {
			row++
			childGrid = undefined;
		}
	}

	const fieldsPanel = findFieldsPanel(form.panel as IFormContainerPanel, options?.panelsFilter);

	let sectionVisible = true
	for (const field of fieldsPanel.fields) {
		if (field.name === "-" || field.type === "Separator") {
			endRow();
			// fixme: combine with Filip pdf export
			sectionVisible = true;//!field.visibleFormula || (new Function("record", "return (" + field.visibleFormula + ")")(record));
			if (sectionVisible)
				addText(itemsGrid, 0, row++, "text", " ");
		} else if (sectionVisible && customProps[field.name]?.visible !== false) {
			if (field.gridColumn) {
				endRow();
			}
				
			let column = 1; // next column
			if (!childGrid) {
				childGrid = addGrid(itemsGrid, 0, row, field.gridColumn ? ["1fr"] : ["1fr", "1fr"], ["auto", "auto"]);
				column = 0;
			}
			const propMeta = meta.properties.find(x => x.logicalName === field.name)!;
			const label = field.label || (propMeta?.displayName || " ");
			let textValue = "";
			if (field.customComponent?.startsWith("ManyToManyField")) {
				textValue = await getManyToManyTextValue(field, record);
			} else {
				textValue = simpleCellFormatterText(record, field.name, propMeta, metadata);
			}
		
			addText(childGrid, column, 0, "label", label);
			addText(childGrid, column, 1, "text", textValue || " ");

			if (column === 1 || field.gridColumn) {
				endRow();
			}
		}
	}
	
	const blob = await generatedPdfFile(mainGrid, styles, docTitle);
	download(blob as any, options?.outputName || (docTitle + ".pdf"), "application/pdf");
}

const getManyToManyTextValue = async (field: IFormField, record: any) => {
	const fieldConfig = (field.customComponent as string).split(';');
	const [otherEntity, lookup1, intersectEntity, lookup2] = (fieldConfig[1] || "").split(".");

	const alias = "mm";
	const selectedName = alias + "__" + lookup1;

	const getQuery = (selectedOnly?: boolean) => {
		const fetch: Fetch = {
			entity: {
				name: otherEntity,
				attributes: ["id", "name"].map(x => ({ attribute: x })),
				links: [{
					name: intersectEntity,
					to: lookup2,
					from: "id",
					alias: "mm",
					type: selectedOnly ? "inner" : "outer",
					attributes: [{ attribute: lookup1 }],
					filter: {
						conditions: [{ attribute: lookup1, operator: "eq", value: record.id }]
					},
				}],
				orders: [{ attribute: "name" }]
			}
		};
		if (!record.id)
			fetch.entity.links = [];
		
		const extraFetch = field.extraFetch;
		if (!selectedOnly && extraFetch) {
			const mf = JSON.parse(JSON.stringify(extraFetch));
			mergeFetch(fetch, mf, true);
			applyMacros(fetch.entity, record);
		}
		return fetch;
	}


	const fetch = getQuery(true)
	const records = await DataService.retrieveMultiple(fetch);
	return records.map(x => x.name || "?").join(", ");
}

const findFieldsPanel = (panel: IFormContainerPanel, panelsFilter?: string[]) => {
	
	const panels: IFormFieldsPanel[] = [];

	const findFieldsPanelInternal = (panel: IFormContainerPanel, panels: IFormFieldsPanel[]) => {
		if (panel.panels) {
			for (const p of panel.panels) {
				if (p.type === "Fields") {
					if (panelsFilter === undefined || panelsFilter.indexOf(p.name) >= 0)
						panels.push(p as IFormFieldsPanel);
				} else if ((p as any).panels) {
					findFieldsPanelInternal(p as any, panels);
				}
			}
		}
	}

	findFieldsPanelInternal(panel, panels);
	if (panels.length === 1)
		return panels[0]
	const combined: IFormFieldsPanel = { "name": "fields", type: "Fields", fields: [] };
	for (const p of panels) {
		if (combined.fields.length > 0)
			combined.fields.push({ "name": "-", type: "Separator" });
		combined.fields.push(...p.fields);
	}
	return combined;
}

const getStyles = () => {
	const grayColor = "#eeeeee";
	const padding = [1, 5, 1, 5];
	const styles: { [key: string]: any } = {
		title: {
			margin: [2, 2, 5, 2],
			fontSize: 20,
			fontFamily: "roboto",
			color: "#aa171425",
		},
		subTitle: {
			margin: [2, 2, 5, 2],
			fontSize: 18,
			fontFamily: "roboto",
			color: "#aa171425",
		},
		subTitle2: {
			margin: [2, 2, 5, 2],
			fontSize: 14,
			fontFamily: "roboto",
			color: "#aa171425",
		},
		tableHeader: {
			margin: [0, 0, 0, 0],
			padding: [2, 2, 7, 2],
			fontSize: 12,
			fontFamily: "roboto-Bold",
			color: "navy",
			vertTextAlign: "center",
			// borderColor: "black",
			// borderStyle: "solid",
			// borderThickness: [1, 1, 1, 1]
		},
		text: {
			margin: [0, 0, 0, 0],
			padding: [2, 2, 2, 2],
			fontSize: 12,
			fontFamily: "roboto",
			color: "black",
			vertTextAlign: "start",
			// borderColor: "black",
			// borderStyle: "solid",
			// borderThickness: [0, 1, 1, 1]
		},
		label: {
			margin: [5, 0, 0, 0],
			padding: [2, 2, 2, 2],
			fontSize: 12,
			fontFamily: "roboto-Bold",
			color: "black",
			background: "#f5dfdf",
			horzTextAlign: "start",
			vertTextAlign: "start",
			horzAlign: "start",
			vertAlign: "start",
			// borderColor: "black",
			// borderStyle: "solid",
			// borderThickness: [0, 1, 1, 1]
		},
		boxStyle2: {
			padding: [2, 2, 2, 2],
			margin: [2, 2, 0, 2],
			fontSize: 12,
			fontFamily: "roboto-Bold",
			color: "black",
			horzTextAlign: "center",
			vertTextAlign: "start",
			borderColor: grayColor,
			//borderStyle: "dotted",
			borderThickness: [1, 1, 0, 1],
			background: grayColor,
		},
		boxStyle: {
			padding: [2, 2, 2, 2],
			margin: [0, 2, 2, 2],
			fontSize: 12,
			fontFamily: "roboto",
			color: "black",
			horzTextAlign: "center",
			vertTextAlign: "start",
			borderColor: grayColor,
			//borderStyle: "dotted",
			borderThickness: [0, 1, 1, 1],
			//background: grayColor,
		},
	};
	return styles;
}

export const formatRecordString = (metadata: IAppConfig, format: string, meta: IMetaObject, record: any) => {
	let sb = "";
	for (let i = 0; i < format.length; i++){
		const c = format[i];
		if (c === "{") {
			if (format[i + 1] === "{") {
				sb += "{"
				i++;
				continue;
			}
			const e = format.indexOf("}", i + 1);
			if (e < 0)
				throw Error("Invalid formatting string at index:" + i);

			const fieldName = format.substring(i + 1, e);
			const propMeta = meta.properties.find(x => x.logicalName === fieldName);
			if (propMeta) {
				sb += simpleCellFormatterText(record, fieldName, propMeta, metadata);
			}
			i = e;
			continue;
		}
		sb += c;
	}
	return sb;
}