import { Capacitor } from "@capacitor/core";
import { useSearchParams } from "react-router-dom";
import { humanize } from "../AppState";
import { LoginPanel, tryGetOrgName } from "./LoginPanel";
import { MainFooter, MainHeader } from "./MainPage";
import { SignupPanel } from "./SignupPanel";
import "./web.css";

export const LoginForm = (props: {}) => {

	const newKey = new Date().valueOf();
	const initOrg = tryGetOrgName();

	return (<div className="mainContainer"> <div className='main'>
		<MainHeader link="/main" linkName="Home"/>
		<div className='loginHeroBkg'>
			<div className='mainHero'>
				<div className="loginFormPanel">
					<div className="loginFormLeft">
						<div className="fa fa-paw loginFormIcon" />
						<span className="loginFormSingIn">{initOrg?humanize(initOrg):undefined} Sign In
						</span>
						{!Capacitor.isNativePlatform() && !initOrg && <span className="loginFormSignUp">Or start <a href="/trial">Your Free Trail</a> now.</span>}
					</div>
					<div className="loginFormContainer">
						<LoginPanel key={newKey} />
					</div>
				</div>
			</div>
		</div>
		<MainFooter/>
	</div>
	</div>
	);
}

export const SignupForm = (props: {}) => {

	const newKey = new Date().valueOf();
	const initOrg = tryGetOrgName();

	return (<div className="mainContainer"> <div className='main'>
		<MainHeader link="/main" linkName="Home"/>
		<div className='loginHeroBkg'>
			<div className='mainHero'>
				<div className="loginFormPanel">
					<div className="loginFormLeft">
					<div className="fa fa-paw loginFormIcon" />
						<span className="loginFormSingIn">{initOrg?humanize(initOrg):undefined} Sign&nbsp;Up
							<br /><span style={{ fontSize: "14px", lineHeight: "16px" }}>Or <a href="/login">login</a> if you have an account already.</span>
						</span>
					</div>
					<div className="loginFormContainer">
						<SignupPanel key={newKey} />
					</div>
				</div>
			</div>
		</div>
		<MainFooter/>
	</div>
	</div>
	);
}