import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useDerivedState } from "../hooks/useDerivedState";
import { useSizeObserver } from "../hooks/useSizeObserver";
import { TabControl } from "./TabControl";

export const Tabbar = (props: {
	tabs: string[],
	renderTabItem?: (item: string, index: number, arr: string[]) => React.ReactNode,
	initIndex?: number,
	className?: string,
	onSelectedChanged?: (selectedIndex: number) => void, 
	renderChild: (selectedIndex: number) => React.ReactNode,
	header?: React.ReactNode
}) => {
	const initIndex = props.initIndex || 0;
	const [selectedIndex, setSelectedIndex] = useDerivedState(initIndex, [initIndex, props.tabs]);

	// props changed?
	// if (selectedIndex >= props.tabs.length) {
	// 	selectedIndex = 0;
	// }
	const left = props.header ? (React.isValidElement(props.header) ? props.header : <div className="tabsHeaderTitle">{props.header}</div>) : undefined;
	const selectedChangedHandler = props.onSelectedChanged || setSelectedIndex;

	return (
		<div className={"tabsMain " + props.className}>
			<TabControl
				left={left}
				items={props.tabs}
				renderItem={props.renderTabItem}
				selectedIndex={selectedIndex}
				onChange={selectedChangedHandler}
			/>
			<div className="tabsContent">
				{/* {props.renderChild(selectedIndex)} */}
				<HorizontalScrollableContent itemsCount={props.tabs.length}
					selectedIndex={selectedIndex}
					onSelectedIndexChanged={selectedChangedHandler}
					renderChild={props.renderChild} />
			</div>
		</div>	
	);
}

const HorizontalScrollableContent = (props: {
	itemsCount: number,
	selectedIndex: number,
	onSelectedIndexChanged: (index: number) => void,
	renderChild: (index: number) => ReactNode
}) => {

	const [_, render] = useState(false);
	const { itemsCount, renderChild } = props;

	const items = Array<number>(itemsCount).fill(0, 0, itemsCount);

	const mainRef = useRef<HTMLDivElement>(null);

	const updateItemWidth = () => {
		const div = mainRef.current;
		if (!div) return;
		
		const width = div.parentElement?.offsetWidth;
		if (!width) return;

		let child = div.firstElementChild;
		while (child) {
			(child as HTMLElement).style.width = width + "px";
			child = child.nextElementSibling;
		}
	}

	const getIndexFromScrollPosition = () => {
		const div = mainRef.current;
		if (!div) return 0;
		const width = div.parentElement?.offsetWidth;
		if (!width) return 0;

		const selectedIndex = ((div.scrollLeft + width / 2) / width) | 0;
		return selectedIndex;
	}

	const onScroll = (e:any) => {
	
		const div = mainRef.current;
		if (!div) return;
		const width = div.parentElement?.offsetWidth;
		if (!width) return;

		const selectedIndex = getIndexFromScrollPosition();
		if (selectedIndex !== props.selectedIndex) {		
			props.onSelectedIndexChanged(selectedIndex);
		}
	}

	const visibleChildren = useRef([] as number[]);
	useEffect(() => {
		// fixme: if panels are changed due to visibility this might throw away some state
		// fixme: also resetting the scroll position is not a great idea...
		if (mainRef.current)
			mainRef.current.scrollLeft = 0;
		updateItemWidth();
		visibleChildren.current = [];
		//console.log("XxXXXXXXXXXXXX");
	}, [renderChild]);
	
	const selectedItem = props.selectedIndex;
	useEffect(() => {
			
		const div = mainRef.current;
		if (!div) return;
		const width = div.parentElement?.offsetWidth;
		if (!width) return;

		if (selectedItem !== getIndexFromScrollPosition()) {
			let child = div.firstElementChild;
			let i = selectedItem;
			while (child && i) {
				child = child.nextElementSibling;
				i--;
			}
			if (child) {
				div.scrollLeft = (child as HTMLElement).offsetLeft;
			}
			render(x => !x);
			//console.log("force render");
		}
	}, [mainRef, selectedItem, renderChild]);

	useSizeObserver(mainRef, () => {
		updateItemWidth();
		render(x => !x);
	});

	const updateVisibleChildren = () => {
	
		const div = mainRef.current;
		if (!div) return;
		const width = div.parentElement?.offsetWidth;
		if (!width) return;

		let child = div.firstElementChild;
		let winX = div.scrollLeft;
		let x = 0;
		let i = 0;
		while (child) {
			(child as HTMLElement).style.width = width + "px";
			child = child.nextElementSibling;

			if (x < winX + width && x + width > winX) {
				visibleChildren.current[i] = 1;
			}
			
			x += width;
			i++;
		}
	};
	updateVisibleChildren();

	return <div className="scrollableTabs" ref={mainRef} onScroll={onScroll} style={{gridTemplateColumns: items.map(x=>"1fr").join(" ")}}>
		{items.map((_,index) => {
			return <div key={"tab"+index} className="scrollableTabsItem" style={{width:mainRef.current?.parentElement?.offsetWidth || "auto"}}>
					{visibleChildren.current[index] && renderChild(index)}
				</div>
		})}
	</div>
}