import React, { useCallback } from "react";
import { MetaPropertyType } from "shared/schema";

export const DragList = (props: {
	fields: { logicalName: string, displayName?: string, type?: MetaPropertyType }[];
	title?: string;
	insertItemSite?: { insertItem?: (name: string) => void };
}) => {
	const { fields } = props;
	
	const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
		const propLogicalName = (e.target as HTMLElement).dataset["itemname"] as string;
		e.dataTransfer.setData("logicalname" + propLogicalName, "");
	}

	const onClick = useCallback((e: React.MouseEvent) => {
		if (e.detail >= 2 && props.insertItemSite?.insertItem) {
			const propLogicalName = (e.currentTarget as HTMLElement).dataset["itemname"] as string;
			if (propLogicalName) {
				props.insertItemSite?.insertItem(propLogicalName);
			}
		}

	}, [props.insertItemSite?.insertItem]);

	return (<div className="formFieldsAvailList">
		<div className="formFieldsListHeader">{props.title || "Available Fields"}</div>
		<div className="formBody" style={{}}>
			<div className="formGrid" style={{ gridTemplateColumns: "1fr" }}>
				{fields.map(propMeta => {
					const isSeparator = propMeta.logicalName === '-';
					return (<div key={propMeta.logicalName} className="formFieldsListItem formItem" data-itemname={propMeta.logicalName} draggable={true} onDragStart={onDragStart} onClick={onClick}>
						<div className={isSeparator ? "formSeparator" : "formLabel"}>{propMeta.displayName || propMeta.logicalName}</div>
						{!isSeparator && <div className="formFieldsListEditor">{propMeta.logicalName} {propMeta.type !== undefined && MetaPropertyType[propMeta.type]}</div>}
					</div>);
				})}
			</div>
		</div>
	</div>);
}
