import React from "react";
import { IMetaObject } from "shared/schema";
import { ICommand } from "../AppSchema";
import { IAppConfig } from "../AppState";
import { IModalContext } from "../modal/Modal";
import { DataService } from "../service";
import { newGuid } from "../utils/guid";
import { AppLink } from "../components/AppLink";

interface IScriptModule {
	[x: string]: () => void;
}

const MODULE_CACHE = {
	metadata: undefined as any,
	modules: {} as { [name: string]: IScriptModule }
}

const loadDynamicModule = (name:string, funcBody: string, metaContext: IAppConfig): IScriptModule => {

	if (MODULE_CACHE.metadata !== metaContext) {
		MODULE_CACHE.metadata = metaContext;
		MODULE_CACHE.modules = {};
	}

	if (!MODULE_CACHE.modules[name]) {

		const loadModuleInternal = (name: string) => {
			const script = metaContext.gui[0].objects.find(x => x.kind === "script" && x.name === name);
			if (!script)
				throw Error("Dynamic Module not found:" + name);
			const inner = loadDynamicModule(name, script.body, metaContext);
			return inner;
		}
	
		const moduleFactory = new Function("loadModule", funcBody);
		const m = moduleFactory(loadModuleInternal);
		MODULE_CACHE.modules[name] = m;
	}
	return MODULE_CACHE.modules[name];
}

const nop = () => {}

export const tryCompileCommand = (metadata: IAppConfig, cmd: ICommand) => {
	if (!cmd.compiledMethod) {
		const nameParts = cmd.name.split('.');
		const script = metadata.gui[0].objects.find(x => x.kind === "script" && x.name === nameParts[0]);
		if (script) {
			try {
				if (nameParts.length > 0) {
					const module = loadDynamicModule(nameParts[0], script.body, metadata);
					cmd.compiledMethod = module[nameParts[1]];
				}
				else {
					// ugly hack to prevent webpack transforming async function to function...
					const factory = eval("Object.getPrototypeOf(async function () { }).constructor");
					//const factory = Object.getPrototypeOf(async function () { }).constructor;
					cmd.compiledMethod = new factory("context", "React", "dynamicImport", script.body) as (context: any) => any;
				}
			} catch (e) {
				console.log("Method compile failed:" + cmd.name + "\nERR:" + e);
			}
		}
		if (!cmd.compiledMethod) {
			cmd.compiledMethod = nop;
		}
	}
	return cmd.compiledMethod !== nop;
}

export const runCommandWithContext = (metadata: IAppConfig, cmd: ICommand, context: any) => {
	tryCompileCommand(metadata, cmd);
	return cmd.compiledMethod!(context, React, dynamicImport);
}

const dynamicImport = (name: string) => {
	if (name === "AppLink")
		return AppLink;
	return import("./clientApi/" + name);
}

export const runCommand = async (cmd: ICommand, getRecord: () => any, prevRecord: any, setRecord: (newRecord: any) => void, metadata: IAppConfig, modalSite: IModalContext, extra: any = undefined, caller: string = "form") => {
	if (cmd.kind === "custom" && cmd.compiledMethod !== nop) {
		const context = {
			record: getRecord(),
			prevRecord: prevRecord,
			getRecord: getRecord,
			setRecord: setRecord,
			executeMultiple: DataService.executeMultiple,
			retrieveMultiple: DataService.retrieveMultiple,
			retrieveSingleById: (logicalName: string, id: string) => DataService.retrieveSingleById(metadata.objects.find(x => x.logicalName === logicalName) as IMetaObject, id),
			newId: () => newGuid(),
			userId: { id: metadata.user.id, name: "systemuser", label: metadata.user.name },
			showModal: modalSite?.showModal,
			cmdName: cmd.name,
			cmdOption: cmd.option,
			caller: caller,
			metadata: metadata,
			modal: modalSite,
			command: cmd,
		};
		if (extra) {
			Object.assign(context, extra);
		}

		const result = runCommandWithContext(metadata, cmd, context);
		if (typeof result === 'object' && typeof result.then === 'function') {
			//FIXME: show please wait or smt
			return await result;
			// hide please wait
		}
		return result;
	}
}

export const createReactComponentFromScript = (metadata: IAppConfig, context: any, componentName: string) => {
	const customComponentItem = metadata.gui[0].objects.find(x => x.kind === "script" && x.name === componentName);
	if (customComponentItem) {
		const factory = new Function("React", "context", "dynamicImport", customComponentItem.body);
		const component = factory(React, context, dynamicImport) as any;
		return component;
	}
	return undefined;
}