import { IExecuteRequest, IMetaObject } from "shared/schema";
import { IAppConfig, IListColumn } from "../AppSchema";
import { IModalContext, showMenu } from "../modal/Modal";
import { Fetch } from "shared/fetch";
import { configureFormFieldDialog } from "../objectForm/Configure";
import { DataService } from "../service";
import { executeExportCommand } from "./export";

export const onMultiActionsCommand = async (metadata: IAppConfig, modal: IModalContext, menuTarget: HTMLElement, meta: IMetaObject, changedRecords: any[], q: Fetch, listColumns: IListColumn[]) => {
	
	let menuOptions = ["Export", "Mass Edit", "Delete"];
	const selected = (changedRecords || []).filter(x => x.id[0] !== "!" && x.selection_checkbox === true);
	if (!selected.length)
		menuOptions = ["Export"];
	
	const cmd = await showMenu(modal, menuTarget, menuOptions); //todo: select all, deselect all ???
	if (cmd === 2) {
		const askYes = await showMenu(modal, document.body, [<h1 className="promptDialogLine" style={{ color: "red" }}>{"Do your really want to delete " + selected.length + " record(s)?"}</h1>, <h1 className="promptDialogLine">Cancel</h1>]);
		if (askYes === 0) {
			const requests = selected.map(x => ({ operation: "delete", object: { id: x.id }, name: meta.logicalName } as IExecuteRequest));
		
			try {
				await DataService.executeMultiple(requests);
				return true;
				//setChangedRecords([]);

				// const record = requests[0].object;
				// const postsave = { name: objectName + ".postsave", kind: "custom" as any };
				// await runCommand(postsave, () => record, record, (x) => { }, metadata, modal);

				// if (props.onCommand)
				// 	props.onCommand({ name: "CmdPostSave" });
			}
			catch (e) {
				alert(e);
			}
		}
	} else if (cmd === 0) {
		executeExportCommand(modal, metadata, menuTarget, q, listColumns, selected.map(x => x.id));
	} else if (cmd === 1) {
		await massEditDialog(modal, metadata, menuTarget, meta, q, selected.map(x => x.id));
		return true;
	} else {
		alert("todo");
	}
	return false;
}

export const massEditDialog = async (modal: IModalContext, metadata: IAppConfig, menuTarget: HTMLElement, meta: IMetaObject, q: Fetch, selectedIds: string[]) => {

	const sysProps = ["createdon", "createdby", "modifiedon", "modifiedby", "id", "statuscode","statecode","versionnumber"];
	const dialogMeta = meta.properties.filter(x => sysProps.indexOf(x.logicalName) < 0);

	dialogMeta.sort((a, b) => a.displayName!.localeCompare(b.displayName!, undefined, { "sensitivity": "base" }));

	//const dialogAssocMeta = 
	const assoc: any = {};
	
	for (const manyMeta of metadata.objects) {
		if (manyMeta.type !== "many") continue;

		for (const prop of manyMeta.properties) {
			if (prop.targets && prop.targets.indexOf(meta.logicalName) >= 0) {
				const fname = manyMeta.logicalName + "_" + prop.logicalName;
				const targetProp = manyMeta.properties.find(y => y !== prop)!;
				assoc[fname] = {
					name: manyMeta.logicalName,
					recordPropertyName: prop.logicalName,
					targetPropertyName: targetProp.logicalName // other prop
				}
				dialogMeta.push({ ...targetProp, logicalName: "__add__" + fname, displayName: "Add " + manyMeta.displayName! });
				dialogMeta.push({ ...targetProp, logicalName: "__del__" + fname, displayName: "Remove " + manyMeta.displayName! });
			}
		}
	}

	const parent = {
		fields: [{}]
	};

	const execute = async (fields: { [key: string]: any }) => {

		const manyRequests: IExecuteRequest[] = [];

		const fnames = Object.keys(fields);
		for (const fname of fnames) {
			let op = "";
			if (fname.startsWith("__add__"))
				op = "create";
			else if (fname.startsWith("__del__"))
				op = "delete";
			else
				continue;

			const lookupValue = fields[fname];
			delete fields[fname];

			if (!lookupValue) continue;

			const helper = assoc[fname.substring(7)];
			for (const recId of selectedIds) {
				manyRequests.push({
					operation: op as any, name: helper.name, object: {
						[helper.recordPropertyName]: { id: recId, name: meta.logicalName },
						[helper.targetPropertyName]: lookupValue
					}
				})
			}
		}

		try {
			await DataService.executeMultiple(manyRequests);
		} catch (e) {
			alert(e)
		}

		const requests = selectedIds.map(id => ({
			operation: "update" as any, name: meta.logicalName, object: {
				id: id,
				...fields
			}
		}));

		try {
			await DataService.executeMultiple(requests);
		} catch (e) {
			alert(e)
		}
	}

	// todo: (dis-)associate N:N

	return new Promise((res, rej) => {
		configureFormFieldDialog(modal, parent.fields[0], parent, "fields", dialogMeta, menuTarget, async () => {
			const opts = parent.fields[0];
			await execute(opts);
			res(true);
		}, undefined, "Mass edit records", [{ name: "CmdSave", label: "", icon: "save" }]);
	});
}