import React, { ReactNode, useContext, useLayoutEffect, useRef } from "react";
import { IAppConfig, ICommand } from "../AppSchema";
import { MetaContext, tryLocalize } from "../AppState";
import { useDerivedState } from "../hooks/useDerivedState";
import { useSizeObserver } from "../hooks/useSizeObserver";
import { ModalContext, showMenu } from "../modal/Modal";
import { IconButton } from "./IconButton";

const shortCmdLabel = (cmd: ICommand, longLabel: string) => {
	let label = cmd.label;
	if (!label && cmd.kind !== "custom") {
		switch (cmd.name) {
			case "CmdDelete":
			case "CmdCalendar":
			case "CmdChart":
			case "CmdMap":
			case "CmdSave":
			case "CmdSaveAndClose":
			case "CmdChangePassword":
			case "CmdEdit":
			// @ts-ignore
			case "CmdNew":
				label = "";
				break;
		}
	}
	if (label === "") return label;
	return longLabel;
}

const getCmdLabel = (metadata: IAppConfig, cmd: ICommand) => {
	let label = cmd.label;
	if (!label && cmd.kind !== "custom") {
		label = cmd.name;
	}
	return tryLocalize(metadata, label || cmd.name, cmd.name);
}
const getCmdIcon = (cmd: ICommand) => {
	if (cmd.icon)
		return cmd.icon;
	switch (cmd.name) {
		case "CmdEdit": return 'edit';
		case "CmdNew": return 'plus';
		case "CmdSaveAndClose":
		case "CmdSave": return 'save';
		case "CmdDelete": return 'trash';
		case "CmdClone": return "clone";
		case "CmdCalendar": return "calendar-days";
		case "CmdChart": return "chart-simple";
		case "CmdMap": return "map";
		case "CmdImport": return "file-import";
		case "CmdExport": return "file-export";
		case "CmdChangePassword": return "key";
		case "CmdPrint": return "print";
		default: return 'exclamation';
	}
}


export const TitleBox = (props: {
	title?: any, children?: any, noSpacer?: boolean;
	commands?: ICommand[],
	onCommand: (cmd: ICommand, e: React.MouseEvent) => void,
	disabled?: boolean,
	renderCommand?: (index: number, cmd: ICommand) => ReactNode,
	style?: any,
}) => {
	let commands = (props.commands || []).slice();
	if (commands.length > 0) {
		commands.splice(commands.length - 1, 0, ...[{ icon: "bars", label: "", name: "CmdMenu" }]);
	}
	const [visibleCommands, setVisibleCommands] = useDerivedState(commands.map(x => true), [props.commands]);
	const [cmdWidths] = useDerivedState({ current: undefined as any }, [props.commands]);
	const minBoxWidth = useRef(40);

	const mainRef = React.useRef<HTMLDivElement>(null);
	const updateVisibleCommands = () => {
		const e = mainRef.current;
		if (!e) return;

		const rect = e.getBoundingClientRect();
		const cmdBox = e.lastElementChild;
		const lastCmd = cmdBox?.lastElementChild;
		if (cmdBox && lastCmd) {
			if (!cmdWidths.current) { // measure
				cmdWidths.current = [];
				let c = cmdBox.firstElementChild;
				while (c) {
					const cmdWidth = Math.ceil(c.getBoundingClientRect().width) + parseInt(window.getComputedStyle(c).marginLeft) || 0;
					cmdWidths.current.push(cmdWidth);
					c = c.nextElementSibling;
				}
			}
			const widths = cmdWidths.current as number[]
			const menuButtonWidth = widths[widths.length - 2];
			let cmdBoxWidth = widths.reduce((agg, x) => x + agg, 0) - menuButtonWidth;
			let availSpace = rect.width - 150;
			if (availSpace < cmdBoxWidth) {
				// right most button + menu if more than one button
				const minButtonSpace = widths[widths.length - 1] + (commands.length > 1 ? menuButtonWidth : 0);
				let newVisibleCommands: boolean[];
				if (minButtonSpace > availSpace) {
					// full menu
					newVisibleCommands = commands.map(x => x.name === "CmdMenu");
					cmdBoxWidth = menuButtonWidth;
				}
				else {
					// remove commands until they fit
					cmdBoxWidth += menuButtonWidth;
					newVisibleCommands = commands.map(x => true);

					for (let j = commands.length - 3; cmdBoxWidth > availSpace && j >= 0; j--) {
						newVisibleCommands[j] = false;
						cmdBoxWidth -= widths[j];
					}
				}
				setVisibleCommands(newVisibleCommands);
			}
			else {
				setVisibleCommands(commands.map(x => x.name !== "CmdMenu"));
			}
			minBoxWidth.current = cmdBoxWidth;
		}
	};

	useLayoutEffect(updateVisibleCommands, [props.commands]);

	useSizeObserver(mainRef, (a, b) => updateVisibleCommands());

	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);

	const onCommand = async (cmd: ICommand, e: React.MouseEvent) => {
		if (props.disabled === true || cmd.enabled === false)
			return;
		if (cmd.name === "CmdMenu") {
			const cmds = commands.filter((x, i) => !visibleCommands[i]);
			const index = await showMenu(modal, e.target as HTMLElement, cmds.map(x => {
				const icon = getCmdIcon(x);
				const label = getCmdLabel(metadata, x);
				return <div>
					<i className={"fa fa-" + icon} /><span style={{margin:"0.3em"}}>{label}</span>
					</div>
			}));
			props.onCommand(cmds[index], e);
			return;
		}
		props.onCommand(cmd, e)
	}

	const ww = "" + (commands.find((c, i) => visibleCommands[i]) ? minBoxWidth.current : 0) + "px";

	return <div ref={mainRef} className="titleBox" style={props.style}>
		{props.title && <h1>{props.title}</h1>}
		{props.children}
		{!props.noSpacer && <div style={{ flex: "1 1 auto" }} />}
		<div className="commandBox" style={{minWidth:ww}}>
			{commands.filter((c, i) => visibleCommands[i]).map((cmd, cmdIndex) => {
				if (props.renderCommand) {
					return props.renderCommand(cmdIndex, cmd);
				}
				const title = getCmdLabel(metadata, cmd);
				const label = shortCmdLabel(cmd, title);
				return <IconButton title={title} key={cmd.name} disabled={props.disabled || cmd.enabled === false} icon={getCmdIcon(cmd)} label={label} onClick={(e) => onCommand(cmd, e)} />
			})}
		</div>
	</div>
}