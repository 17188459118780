
/**

  Save
  - Save Now !
  - [*   ] AutoSave
  -
  - Loaded: 1 hour ago
  - Saved: just now
  - Also opened by: [Karol]
  - 
  - History
  - 
  - [!] Revert to opened version
  - 
  - [!] Delete



 */

let instance: EventSource | undefined;
let restartParams: { orgName: string, start: Date } | undefined;

const handleSSEvent = (d: string, startDate: number) => {

	console.log("SSE EVENT: " + d);
	if (!d) return;

	const dispachChange = (objectName: string, t: number, startDate: number) => {
		if (+t < startDate) {
			console.log("SSE skipping old event");
			return;
		}
		window.dispatchEvent(new Event("objectChanged:" + objectName));
	}

	try {
		const wrapper = JSON.parse(d) as { path: string, data: any };
		if (!wrapper.data)
			return;

		if (wrapper.path === "/") { // aggregate event
			const changeObject = wrapper.data as { [key: string]: number };
			for (const key of Object.keys(changeObject))
				dispachChange(key, changeObject[key], startDate);
		} else {
			//dispachChange(wrapper.data, startDate);
		}
	}
	catch (ex) {
		console.log("SSE handle put exception: " + ex + "\ndata: " + d);
	}
}

const visibilityChanged = () => {
	if (document.visibilityState === "hidden") {
		if (instance) {
			instance.close();
			instance = undefined;
			if (restartParams)
				restartParams.start = new Date();
			console.log("SSE STOP " + new Date().toISOString());
		}
	} else if (document.visibilityState === "visible") {
		if (!instance && restartParams) {
			console.log("SSE RESTART " + new Date().toISOString());
			startPushListenService(restartParams.orgName, restartParams.start);
		}
	}
}

export const startPushListenService = async (orgName: string, start?: Date) => {

	console.log("SSE STARTING");
	try {
		const tokenResp = await fetch("/api/ssetoken"); 
		const token = await tokenResp.text();

		const startDate = start || new Date()
		const startTimeStamp = startDate.valueOf() / 1000;
		// const restartAt = (startDate.getUTCHours() * 60 + startDate.getUTCMinutes()) * 60 + startDate.getUTCSeconds() + 1;
		// window.setTimeout(() => startPushListenService(orgName), restartAt * 1000);
		restartParams = { orgName, start: startDate };

		document.removeEventListener("visibilitychange", visibilityChanged);
		document.addEventListener("visibilitychange", visibilityChanged);

		const url = "https://simple-bf05d-default-rtdb.europe-west1.firebasedatabase.app/" + orgName + "/.json?auth=" + token;
		const evtSource = new EventSource(url);
		evtSource.addEventListener('open', function (e) {
			console.log("SSE open");
			// Connection was opened.
		}, false);
		
		evtSource.onmessage = (event: any) => {
			const data = event.data;
			console.log("SSE EVENT: " + data);
		};
		evtSource.onerror = (event: any) => {
			const data = event.data;
			//console.log("ERROR SSE EVENT: " + data);
		}
		//magic goes here!
		evtSource.addEventListener("patch", function (e) {
			console.log("SSE Patch UP - " + e.data);
			handleSSEvent(e.data, startTimeStamp);
		}, false);
		//And here!
		evtSource.addEventListener("put", function (e) {
			console.log("SSE Put UP ");
			handleSSEvent(e.data, startTimeStamp);
		}, false);

		if (instance)
			instance.close();
		instance = evtSource;
	}
	catch (e) {
		console.log("ERROR testSSE " + e);
	}
}


const pushEvent = (records: any[]) => {
	/*

	org / change / account / googleAutoId / { timestamp, ids:[], too_many_changes}

	org / change / googleAutoId / { timestamp, objectName, ids:[], too_many_changes }
	*/
}