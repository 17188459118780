import { useCallback, useContext, useMemo } from "react";
import { IPanelProps } from "../objectForm/panels/PanelProps";
import { ModalContext } from "../modal/Modal";
import { IMetaObject, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { SimpleFieldSet } from "../components/SimpleFieldSet";
import { getDropTarget } from "../components/draggable";
import { configureFormFieldDialog } from "../objectForm/Configure";
import { useRender } from "../hooks/useRender";
import { IFormField, IFormFieldsPanel } from "../AppSchema";
import { IMetaPropertyEx } from "../customize/ConfigureFormField";


export const SurveyDesignPanel = (props: IPanelProps) => {
	
	const modal = useContext(ModalContext);
	const forceRender = useRender();

	const template = props.context.getRecord();

	const propsDict = useMemo(() => {
		if (template.meta) {
			const metaObject = JSON.parse(template.meta) as IMetaObject;
			return metaObject.properties.reduce((agg, prop) => (agg[prop.logicalName] = prop, agg), {} as any);
		}
	}, [template.meta]);

	const panel: IFormFieldsPanel = useMemo(() => {
		try {
			if (template.panel)
				return JSON.parse(template.panel);
		}
		catch (e) {
			console.log("Invalid survey field panel: " + e);
		}
		return { fields: [] };
	}, [template?.panel]);

	const customizePanel = useCallback((p: any) => {
		const prev = props.context.getRecord();
		props.context.setRecord({ ...prev, panel: JSON.stringify(p) });
	}, [props.context]);

	const getRecord = useCallback(() => {
		return {};
	}, []);

	const setRecord = useCallback((data: any) => {
	}, []);

	const configureClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		const item = getDropTarget(e);
		const itemIndex = +(item.dataset["drag_index"] || 0);
		const f = panel.fields[itemIndex] || { name: "name" };

		//const reqType = (n: string, on: string, off: string) => ({ logicalName: n, type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options:["", "1"], richOptions: [{ value: "", label: off }, { value: "1", label: on }] } as IMetaProperty);
		const requiredMeta: IMetaPropertyEx = {
			logicalName: "required", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet,
			options: ["Normal", "Required"],
			getEditValue: (s, item: IFormField) => ((item as any).required) ? "Required" : "Normal",
			setEditValue: (s, item: IFormField, value) => (item as any).required = value === "Required",
		};
		const wideMeta: IMetaPropertyEx = {
			logicalName: "Width", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet,
			options: ["Normal", "Wide"],
			getEditValue: (s, item: IFormField) => item.gridColumn ? "Wide" : "Normal",
			setEditValue: (s, item: IFormField, value) => item.gridColumn = value === "Wide" ? "1/-1" : "",
		};
		const nameMeta = { logicalName: "name", displayName: "Field", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: ["-"].concat(Object.keys(propsDict)) };
		//const attrs = [nameMeta, "label", reqType("disabled", "Disabled", "Normal"), "gridColumn", "gridRow", "customComponent", "cssClass", "tooltip"] as (string | IMetaPropertyEx)[];
		const attrs = [nameMeta, "label", "tooltip", wideMeta, requiredMeta];
		// attrs.push(makeButtonType("fetch", "Extra Fetch", (a, b, c) => showCustomizeFetchDialog(a, b, c, modal)));
		// attrs.push(makeButtonType("meta", "Schema", showCustomizeMetaDialog));
		configureFormFieldDialog(modal!, f, panel, "fields", attrs, e.target as HTMLElement, () => {
			//panel.fields = proxyPanel.fields.map(x => x === proxyField ? unproxyField(x) : x);
			forceRender();
			customizePanel(panel);
		}, "configureFieldsPanel", panel.fields.indexOf(f) >= 0 ? "Customize" : "Add");
	}

	const addMultiple = () => {

	}

	if (!panel || !propsDict)
		return <div></div>

	return <SimpleFieldSet id={"0"} getRecord={getRecord} setRecord={setRecord} propsDict={propsDict} panel={panel}
		customize={true}
		customizePanel={customizePanel}
		configureClick={configureClick}
		dragKind="surveyfield"
		submitButton="" onSubmit={()=>{}} />
}