import { useState, useCallback, useMemo, useRef } from "react";
import { Fetch, applyMacros, FetchFilter } from "shared/fetch";
import { IAssociatedListPanel, ICommand, IList, VirtualField } from "../../AppSchema";
import { Loading } from "../../components/Loading";
import { useDerivedState } from "../../hooks/useDerivedState";
import { IOuterEditContext, ObjectList } from "../../objectList/ObjectList";
import { DataService } from "../../service";
import { IPanelProps } from "./PanelProps";

const saveAssociated = async (primaryEntity:string, field: string, intersectEntity: string, secondaryEntity:string, lookup2: string, id: string, items: { id: string, op: "create" | "delete" }[]) => {
	for (let item of items) {
		let obj: any = {};
		obj[field] = { id: id, name: primaryEntity };
		obj[lookup2] = { id: item.id, name: secondaryEntity };
		await DataService.updateRecord(intersectEntity, obj, item.op)
	}
}

export const AssociatedTabPanel = (props: IPanelProps) => {
	const context = props.context;
	const { meta, id } = context;
	const record = context.getRecord();
	const objectLabel = record.name;
	const tab = props.panel as IAssociatedListPanel;
	let [field, intersectEntity, lookup2] = tab.field.split(".");
	const alias = "g__" + intersectEntity;
	// support for ChildEntity.fk -> ParentEntity.lookup with <fk>..<lookupfield>

	const  [isSaving, setSaving] = useState(false);
	const [isManage, setManage] = useDerivedState(false, [id]);
	const [selected, setSelected] = useDerivedState([] as { id: string, op: "create" | "delete" }[], [isManage]);

	const toggleSelected = useCallback((id: string, isSelected: boolean) => {
		setSelected(oldSel => {
			const newSel = oldSel.filter(x => x.id !== id);
			newSel.push({ id: id, op: isSelected ? "delete" : "create" });
			return newSel;
		});
	}, [setSelected]);

	const render = useCallback((row: any, field: string) => {
		if (isManage && field === "name") {
			 // WARN: this is not the final alias. The fetch will be merged and re-aliased.
			const baseName = alias + "__" + lookup2;
			const key = Object.keys(row).find(x => x.endsWith(baseName));
			let isSelected = !!(key && row[key] != null); //online is undefined, offline is null
			const item = selected.find(x => x.id === row.id);
			if (item)
				isSelected = item.op === "create";
			const icon = <i className={"fa " + (isSelected ? "fa-circle-check" : "fa-regular fa-circle")} style={{color:isSelected?"blue":"black"}}/>
			//const icon = <span>{isSelected ? "[X]" : "[ ]"}</span>;
			return <div onClick={(e => toggleSelected(row["id"], isSelected))}>{icon} {row["name"]}</div>;
		}
		return undefined;
	}, [isManage, selected, toggleSelected]);

	const onCommand = useCallback((cmd: any) => {
		if (cmd.name === "CmdManage") {
			setManage(prev => {
				if (prev) {
					// Save!
					setSelected(x => {
						if (x.length > 0) {
							const doSave = async () => {
								try {
									setSaving(true);
									await saveAssociated(meta.logicalName, field, intersectEntity, tab.name, lookup2, id, x);
								}
								catch (eror) {
									
								}
								setSaving(false);
							};
							doSave();
						}
						return [];
					});
				}
				return !prev;
			});
			return true;
		}

		return false;
	}, [context, setManage, setSelected, setSaving]);

	const [q, newParams, commands] = useMemo(() => {
		let q: Fetch = { entity: { name: tab.name, } };
		if (record && record.id && tab.extraFetch) {
			q = JSON.parse(JSON.stringify(tab.extraFetch));
			applyMacros(q.entity, record);
		}
		let fe = q.entity;
		let commands: ICommand[]|undefined;

		if (intersectEntity) {
			q.entity.links = [{
				name: intersectEntity,
				from: "id",
				to: lookup2,
				alias: alias,
				attributes: [{ attribute: lookup2 }],
				type: isManage ? "outer" : "inner"
			}];
			fe = q.entity.links[0];
			
			//commands = [isManage ? { icon: faSave, name: "CmdSave" } : { icon: faCheckDouble, label: "Add/Remove", name: "CmdManage" }];
			commands = [{ icon: isManage ? 'save' : 'check-double', name: "CmdManage", permissions: intersectEntity + ";0" }];
		}
	
		// prevent anything from loading while we save... hacky but works
		// alternative is to show Loading instead of ObjectList, or useMemo the ObjectList
		if (field !== VirtualField) {
			const lv = (lookup2 && !intersectEntity) ? record[lookup2]?.id : id;
			const filter: FetchFilter = {
				conditions: [{ attribute: field, operator: (!lv || isSaving) ? "null" : "eq", value: lv }]
			};
			if (fe.filter)
				fe.filter = { filters: [fe.filter, filter] };
			else
				fe.filter = filter;
		}

		const newParams = !intersectEntity ? {
			[field]: lookup2 ? record[lookup2] : { id: id, name: meta.logicalName, label: objectLabel }
		} : undefined;
		// inherit team!
		if (newParams && record["teamid"])
			newParams["teamid"] = record["teamid"];
		return [q, newParams, commands];
	}, [tab, id, isManage, isSaving, record]);

	if (newParams && !lookup2)
		newParams[field].label = objectLabel;

	const listChangedCommand = useRef<ICommand>({ name: context.meta.logicalName + ".listChanged", kind: "custom" });
	
	const outerEditContext = useMemo(() => {
		const self = {
			registered: false,
			register: () => {
				if (!self.registered) {
					self.registered = true;
					const eventHandler = async (eventName: any) => {
						if (eventName === "afterSave") {
							if (self.doSave)
								return await self.doSave();
						}
						return true;
					}
					context.events?.push(eventHandler)
				}
			},
			onChanged: (prevValue: any, value: any) => {
				self.register();
				context.setRecord({ ...context.getRecord() });
				(listChangedCommand.current as any).event = { name: tab.name, field: tab.field, prevChildren: prevValue, children: value };
				context.getService("executeCommand").executeCommand("", listChangedCommand.current);
				// runCommand(listChangedCommand.current, context.getRecord, (context as any).prevRecord, context.setRecord, metadata, modal, {
				// 	listChanged: { name: tab.name, field: tab.field, prevChildren: prevValue, children: value }
				// }, "AssociatedList");
			},
			doSave: undefined,
		} as IOuterEditContext
			
		return self;
	}, [context.events]);
	
	// New record?
	if (id === "0") return (<div>Save to see related records.</div>);
	if (!record.id) return <div><Loading /></div>;

	const excluded = tab.excludedColumns || field;

	return <ObjectList objectName={tab.name} mergedFetch={q} newParams={newParams}
		onRenderCell={render} commands={commands} onCommand={onCommand} ownerid={record.ownerid}
		initialView={tab.initialView} allowedViews={tab.allowedViews} excludedColumns={excluded} editContext={outerEditContext} />
}