import { Fetch } from "shared/fetch";
import { ICustomOwner } from "shared/permissions";
import { ICurrency, IDictionary, ILookupValue, IMetaProperty, IMetadata, IRichOption, IUser } from "shared/schema";

export interface IAppConfig extends IMetadata {
	refresh?: () => void;
	needsRefresh?: boolean;
	user: IUser;
	gui: IAppGui[];
	appId?: string;
	apps: string[];
	permDict: IDictionary<number>;
	customOwners: ICustomOwner[];
	currencyDict: IDictionary<ICurrency>;
	sas: string;
	orgName: string;
	maxAge?: Date;
	buildDate?: string;
	//localizationTable?: { [key: string]: string };
	//localize: (key: string, key2?: string) => string;
}

export interface IAppGui {
	objects: IViewRecord[];
	home: IHomeItem[];
	homeCache?: {
		items: IHomeItem[];
		appId: string;
	}
}

export interface IViewRecord {
	id: string;
	name: string;
	kind: "view" | "form" | "home" | "script" | "chart" | "dashboard"
	appid?: { id: string, name: string, label: string };
	objectname: string;
	isprivate?: boolean;
	body: string;
	label?: string;
	parsedBody?: IForm | IList;
}

export interface IHomeItem {
	kind: "entity" | "custom" | "separator";
	name: string;
	uniqueName?: string;
	label: string;
	url: string;
	extra?: string;
	initialView?: string; // todo extra object ListConfig shared with IAssociatedListPanel
	allowedViews?: string[];
	icon?: string;
	permissions?: string; // when should this home item be visible.
	visible?: boolean; // runtime -> calculated from permissions.
}

export interface IObjectGui {
	name: string; // logicalName of the object
	lists: IList[];
	forms: IForm[];
}

export interface IForm {
	name: string;
	panel: IFormPanel;
	commands: ICommand[];
	events?: { change?: string, validate?: string, postsave?: string };
}

export type FormPanelType = "Fields" | "Tabs" | "Split" | "Accordeon" | "AssociatedList" | "Flex" | "CloudFiles" | "Custom";
export interface IFormPanel {
	name: string;
	label?: string;
	type: FormPanelType;
	showLabel?: boolean;
	visible?: boolean;
	customComponent?: string;
	customComponentParameters?: string;
	createMethod?: (props: any) => any;
}

export interface IFormContainerPanel extends IFormPanel {
	panels: IFormPanel[];
}

export interface IFormWizardPanel extends IFormContainerPanel {
	wizardNextCommand?: string;
}

export interface IFormSplitPanel extends IFormContainerPanel {
	gridColumns?: string;
}
export interface IFormFlexPanel extends IFormContainerPanel {
	direction?: string;
	sizes?: string[];
}
export interface ICloudFilesPanel extends IFormPanel {
	rootFolder?: string;
	folderField?: string;
	fileFilter?: string; // only show files which match the filter.
	errorMessage?: string; // set from onchange Script
	emptyMessage?: string; // message shown when the root folder is not set or empty.
}

export const VirtualField = "@virtual";
export interface IAssociatedListPanel extends IFormPanel {
	field: string;
	initialView?: string;
	allowedViews?: string[];
	inlineEdit?: boolean;
	extraFetch?: Fetch;
	excludedColumns?: string; // ;separated field names
}

export type FormFieldType = "Field" | "Separator" | "Section";
export interface IFormField {
	name: string;
	label?: string;
	type: FormFieldType;
	// width?: number; // logical cell count of the parent 
	// height?: number;
	//required?: string;
	disabled?: string;
	gridColumn?: string;
	gridRow?: string;
	customComponent?: string;
	cssClass?: string;
	tooltip?: string;
	extraFetch?: Fetch; // extra filter for lookup fields. IMetaProperty options allow for direct child->field === parent->field comparison only.
}
export interface IFormFieldSection {
	fields: IFormField[];
	columns?: number;
	name?: string;
	label?: string;
}

export interface IFormFieldsPanel extends IFormPanel {
	fields: IFormField[];
	minColumnWidth?: number; // default 400
}

export interface IList {
	name: string;
	type: ListType;
	visual?: ListVisual;
	query: Fetch;
	columns: IListColumn[];
	commands?: ICommand[];
	quickFilters?: IQuickFilter[];
}

export type ListType = "Public" | "Associated" | "Lookup" | "Hidden";

export enum ListVisual {
	List = 0,
	Map = 1,
	Chart = 2,
	Calendar = 4,
}

export interface IListColumn {
	attribute: string;
	label?: string;
	width?: string; // 10px 2fr, etc.
	style?: string;
	disabled?: boolean; // not editable
	nolink?: boolean; // no lookup
	formName?: string; // form to use to open link or name field.
	role?: "title" | "date" | "subject"; 
	// [title]		[date]
	// [subject			 ]
	// ...
	// ...
	mobileOrder?: string; // -1 hidden, otherwise gravity of the item
	mobileLabel?: string; // undefined, empty-> use label, " " (space) -> no label, otherwise use it as label.
	cssClass?: string;
	attr?: { attribute: string, prop: IMetaProperty }; // for custom fields not in schema.
}

export interface IOption {
	value: string;
	label?: string;
	icon?: string;
	color?: string;
}

export interface IQuickFilter {
	type: "options" | "date" | "daterange" | "day" | "lookup" | "custom" | "owner" | "numericrange" | "text" | "number";
	name: string;
	label?: string;
	icon?: string;
	optionsFormat?: "radio" | "select";
	//clearLabel?: boolean; // Show a "clear" button that will disable all options.
	multiSelect?: boolean; // Whether more options can be enabled at the same time.
	mustSelect?: boolean; // Whether an option must be enabled at all times. First or the option below.
	defaultOption?: string; // if an options has to be always selected, and none is, choose this one.
	initialOption?: string;

	directOptions?: (string | IOption)[]; // write in options
	richOptions?: IRichOption[];

	includedOptions?: string[]; // excluded options
	excludedOptions?: string[]; // included options

	objectName: string; // the object name.
	propertyName: string; // the field name
	//alias?: string; // the link alias if a linked entity is to be filtered.

	// the custom method if this is a custom type quick-filter.
	//customMethod?: (enabled: string, options: (string | IOption)[]) => FetchFilter;

	extraFetch?: Fetch;
	customFetch?: Fetch;

	permissions?: string; // visible, same format as command.permissions

	mandatoryGroup?: string; // the group of filters of which at least one has to be set.
	minimumLength?: number; // minimum input length to apply filter.
}

export interface IChartSeries {
	aggregateField: string;
	aggregateMethod: string;
	color?: string;
}
export interface IChartGroup {
	field: string;
	method: string;
	color?: string;
}

export interface IChart {
	// aggregateField: string;
	// aggregateMethod: string;
	// groupField: string;
	// groupMethod: string;
	limit?: number; // top +, bottom -
	type?: string; //bar (default), line
	series: IChartSeries[];
	groups: IChartGroup[];
}

export interface IDashboardComponent {
	kind: "chart" | "list" | "filter" | "grid" | "simplechart" | "label";
	name: string;
	label?: string;
	extraFetch?: Fetch;
	gridArea: string;
	cssClass?: string;
}
export interface IDashboardComponentFilter extends IDashboardComponent {
	type: IMetaProperty;
	multiSelect?: boolean; // default true
	initialValue?: string[];
}
export interface IDashboardComponentList extends IDashboardComponent {
	listName: string; // or chartName
	objectName: string;
	initialView?: string;
	allowedViews?: string[];
}
export interface IDashboardComponentGrid extends IDashboardComponent {
	objects: IDashboardComponent[];
	gridColumns: string;
	gridRows: string;
	loaderScript?: string; // can provide data for SimpleCharts -> will ignore extraFetch
}
export interface IDashboardComponentSimpleChart extends IDashboardComponent {
	type: "bar" | "line" | "pie" | "gauge" | "number";
	//value?: { x: string, y: number }[];

	min?: number;
	max?: number;
	showPercent?: boolean; // assume max||sum(value.y) === total
}
export interface IDashboard extends IDashboardComponentGrid {
}

export interface ICommand {
	kind?: "std" | "custom";
	name: string;
	option?: string;
	label?: string;
	icon?: string;
	compiledMethod?: (context: any, React: any, apiLoader: any) => any;
	runState?: "running" | "scheduled";
	runHandle?: any;
	permissions?: string; //entity;PERMISSION_KIND;entity2;permission_kind
	enabled?: boolean;
	event?: any; // runtime params
}

// //get cloudPath for record or objectId is NULL ->
// // inu_cloudpath
// export interface ICloudPathRecord {
// 	//parentid?: ILookupValue;
// 	objectid?: ILookupValue;
// 	objectname: string;
// 	provider: "azure"; // azure, ...
// 	path: string;
// 	folderfield?: string; // default is id, can be other unique field (must not change!)
// }