import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { IChart, ICommand, IDashboard, IDashboardComponent, IDashboardComponentFilter, IDashboardComponentGrid, IDashboardComponentList, IDashboardComponentSimpleChart, IViewRecord, MetaContext } from "../AppState";
import { IRichOption, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { Fetch, applyMacros } from "shared/fetch";
import { Loading } from "../components/Loading";
import { DataService } from "../service";
import { ObjectChart, SingleObjectChart } from "./ObjectChart";
import { ResponsiveBar } from "@nivo/bar";
import { formatNumericValue } from "../formatters";
import { runCommand, runCommandWithContext } from "../command/runCommand";


export const DashboardForm = (props: {}) => {
	const name = useParams<string>()["name"] || "";
	const { search } = useLocation();

	const metadata = useContext(MetaContext);
	const viewRecord = metadata.gui[0].objects.find(x => x.kind === "dashboard" && x.name === name);
	
	return <Dashboard viewRecord={viewRecord!} />
}

const Dashboard = (props: { viewRecord: IViewRecord }) => {
	const d = (props.viewRecord.parsedBody as any || JSON.parse(props.viewRecord.body)) as IDashboard;
	const [macros, setMacros] = useState(() => {
		const init: { [name: string]: any } = {};
		recursiveInitFilters(d, init);
		return init;
	});
	
	const onFilterChanged = (name: string, value: any) => {
		setMacros(x => {
			return { ...x, [name]: value };
		});
	}
	return <DashboardGrid d={d} macros={macros} onChange={onFilterChanged} />
}
const recursiveInitFilters = (g: IDashboardComponentGrid, init: { [name: string]: any }) => {
	if (g.objects) {
		for (const o of g.objects) {
			if (o.kind === "filter") {
				const fo = o as IDashboardComponentFilter;
				if (fo.initialValue)
					init[fo.name] = fo.initialValue;
			} else if (o.kind === "grid") {
				recursiveInitFilters(o as IDashboardComponentGrid, init);
			}
		}
	}
}

interface IDashboardDataContainer {
	[name: string]: IDataPoint[];
}

const DashboardGrid = (props: { d: IDashboardComponentGrid, macros: any, onChange: (name: string, value: any) => void, parentData?: IDashboardDataContainer}) => {
	
	const { d, macros, onChange } = props;
	const parentData = props.parentData || {};

	const metadata = useContext(MetaContext);
	const [data, setData] = useState({} as IDashboardDataContainer);
	const cmd = useRef<ICommand>({ name: d.loaderScript || "", kind: "custom" });

	useEffect(() => {
		if (d.loaderScript) {
			//runCommand(cmd.current, { metadata, dashboard: d, setData: setData });
			runCommand(cmd.current, () => null, null, () => { }, metadata, undefined as any, { dashboard: d, setData: setData, macros: macros });
		}
	}, [d, macros]);

	return <div className={"dashboardMain objectListContainer " + (d.cssClass || "")} style={{ gridArea:d.gridArea, display: "grid", gridTemplateColumns: d.gridColumns, gridTemplateRows: d.gridRows, height: "100%",
    overflow: "hidden" }}>
		{d.objects.map(c => {
			if (c.kind === "filter") {
				return <DashbardFilter key={c.name} d={c as any} macros={macros} onChange={onChange} />
			} else if (c.kind === "chart") {
				return <DashboardChart key={c.name} d={c as any} macros={macros} />
			} else if (c.kind === "simplechart") {
				return <DashboardSimpleChart key={c.name} d={c as any} macros={macros} data={data[c.name] || parentData[c.name]} />
			} else if (c.kind === "grid") {
				return <DashboardGrid key={c.name} d={c as any} macros={macros} onChange={onChange} parentData={data} />
			} else if (c.kind === "label") {
				return <h2 style={{ gridArea: c.gridArea }} className={"dashboardLabel " + (c.cssClass || "")}>{c.label || c.name}</h2>
			}
		})}
	</div>
}

const applyMacros2 = (f: Fetch, rec: any) => {
	applyMacros(f.entity, rec);
}

const DashbardFilter = (props: { d: IDashboardComponentFilter, macros: any, onChange: (name: string, value: any) => void }) => {

	const prop = props.d.type;

	if ((prop.flags & MetaPropertyFlags.OptionSet) !== 0) {
		const ros = prop.richOptions || prop.options!.map(x => ({ value: x, label: x }));
		return <DashbardFilterOptions options={ros} d={props.d} value={props.macros[props.d.name]} onChange={props.onChange} />
	}
	if (prop.type === MetaPropertyType.Lookup) {
		return <DashbardFilterLookup {...props} />
	}
	if (prop.type === MetaPropertyType.DateTime) {
		return <DashboardFilterDateRange {...props} />
	}

	return <div>Unsupported</div>
}

const DashboardFilterDateRange = (props: { d: IDashboardComponentFilter, macros: any, onChange: (name: string, value: any) => void }) => {
	
	const { name, label, gridArea } = props.d;

	const onChangeStart = (e: any) => {
		const newValue = e.target.value;
		props.onChange(name + "_start", newValue);
	}
	const onChangeEnd = (e: any) => {
		const newValue = e.target.value;
		props.onChange(name + "_end", newValue);
	}
	
	return <div style={{ gridArea: gridArea, display: "flex", flexDirection: "column", overflow: "auto", border: "1px solid #eee", padding: "2px", margin: "1px", gap: "2px" }}>
		<h2>{label || name}</h2>
		<label><span>Datum Od </span><input type="date" onChange={onChangeStart} /></label>
		<label><span>Datum Do </span><input type="date" onChange={onChangeEnd} /></label>
	</div>
}

const DashbardFilterLookup = (props: { d: IDashboardComponentFilter, macros: any, onChange: (name: string, value: any) => void }) => {

	const [options, setOptions] = useState([] as IRichOption[]);
	const q = useRef<string>("");

	useEffect(() => {
		const f = JSON.parse(JSON.stringify(props.d.extraFetch!));
		applyMacros(f.entity, props.macros);
		const newQ = JSON.stringify(f);
		if (q.current !== newQ) {
			q.current = newQ;
			const exe = async () => {
				try {
					if (!f.entity.orders)
						f.entity.orders = [{ attribute: "name" }];
					const records = await DataService.retrieveMultiple(f);
					setOptions(records.map(x => ({ label: x.name, value: x.id })));
				}
				catch (e) {
					alert(e);
				}
			}
			exe();
		}
	}, [props.d, props.macros]);

	if (options.length === 0)
		return <Loading />
	else
		return <DashbardFilterOptions options={options} d={props.d} value={props.macros[props.d.name]} onChange={props.onChange} />
}

export const DashbardFilterOptions = (props: { options: IRichOption[], d: IDashboardComponentFilter, value: any, onChange: (name: string, value: any) => void }) => {
	
	const { name, label, gridArea } = props.d;

	const arr = props.value as string[] || [];
	
	return <div style={{ gridArea: gridArea, display: "flex", flexDirection: "column", overflow: "auto", border:"1px solid #eee", padding:"2px", margin:"1px" }}>
		<h2>{label || name}</h2>
		{props.options.map(x => {
			return <label><input type="checkbox" checked={arr.indexOf(x.value) >= 0} onChange={e => {
				const newValue = arr.filter(z => z !== x.value);
				if (e.target.checked)
					newValue.push(x.value);
				props.onChange(name, newValue);
			}}/>{x.label}</label>
		})}
	</div>
}

const DashboardChart = (props: { d: IDashboardComponentList, macros: any }) => {

	const metadata = useContext(MetaContext);

	const f = JSON.parse(JSON.stringify(props.d.extraFetch!));
	applyMacros(f.entity, props.macros);

	const chartFile = metadata.gui[0].objects.find(x => x.kind === "chart" && x.name === props.d.listName);
	const chart = chartFile!.parsedBody as any as IChart;
	// fixme... actually use ObjectChart and and pass initial, allowedViews and extraFetch instead.

	const meta = metadata.objects.find(x => x.logicalName === props.d.objectName)!;

	return <div>
		<SingleObjectChart query={f} setChartFilter={() => { }} chart={chart} setChart={() => { }} meta={meta}
			ChartSelector={() => undefined}  />
	</div>
}

interface IDataPoint {
	x_label: string;
	y0: number;
}

const DashboardSimpleChart = (props: { d: IDashboardComponentSimpleChart, macros: any, data?: IDataPoint[] }) => {
	const { d } = props;

	return <div className={"simpleChartCell " + (d.cssClass || "")} style={{gridArea:d.gridArea, display:"flex", flexDirection:"column", border:"1px solid #eee", padding:"2px", margin:"1px"}}>
		<DashboardSimpleChartData {...props} />
		<span className="simpleChartLabel">{d.label || d.name}</span>
	</div>
}

const DashboardSimpleChartData = (props: {d:IDashboardComponentSimpleChart, data?: IDataPoint[]}) => {
	const { d, data } = props;

	if (!data && !d.extraFetch)
	return <Loading />
	if (!data) {
		// load data
		return <div>0</div>;
	}

	if (d.type === "bar" || d.type === "line")
		return <SimpleBarChart data={data} />

	if (d.type === "number")
		return <div className="simpleChartValue">{data[0].y0}</div>

	if (d.type === "gauge") {
		if (data.length === 2) { // assume value, max
			const deg = (data[0].y0 / data[1].y0) * 360 | 0;
			const percent = ((data[0].y0 / data[1].y0) * 100 | 0) + "%";
			const value = data[0].y0;
			const color = (getComputedStyle(document.body).getPropertyValue("--acccent-color") || "#41aea7") + "80 "
			return <div className="simpleChartGauge gaugeOuter" style={{ background: "conic-gradient("+color + +deg+"deg, #0000000a 0deg)"}}>
				<div className="gaugeInner"><b>{value}</b><br/><span>{percent}</span></div>
			</div>
		}
	}
	return <div>0</div>
}

// todo: merge with ChartComponent and CommunityChart
const SimpleBarChart = (props: { data: IDataPoint[] }) => {

	const { data } = props;

	const formatValue = (v: any) => formatNumericValue(0, v);
	const keys = ["y0"];
	const theme = { textColor: "var(--fore-color)" };

	return <ResponsiveBar margin={{ top: 40, right: 20, bottom: 50, left: 60 }}
		data={data as any}
		theme={theme}
		// onClick={onChartBarClick}
		colors={() => "#0071ebff"}
		label={v => formatValue ? formatValue(v.value) : v.formattedValue}
		axisLeft={{
			"tickValues": 4,
			format: formatValue,
		}}
		axisBottom={{
			//   legend: 'day',
			//   legendOffset: -12,
			"tickSize": 5,
			"tickPadding": 5,
			"tickRotation": 15
		}}
		groupMode="grouped"
		keys={keys}
		indexBy="x_label"
	//legends={legends}
	//tooltipLabel={(v:any)=>props.getSeriesLabel(v.id, true)}
					
	/>
}