import { IDictionary, IMetaObject, IRichOption } from "shared/schema";
import { IFormPanel } from "../../AppSchema";
import { Fetch } from "shared/fetch";

export interface IFieldProps {
	visible?: boolean;
	enabled?: boolean;
	label?: string;
	errorMessage?: string;
	extraFetch?: Fetch;
	getRecordProperty?: (name: string) => any;
	options?: string[];
	richOptions?: IRichOption[];
	required?: boolean;
}
export type FieldProps = IDictionary<IFieldProps>;

export type PanelPropsEventType = "validate" | "beforeSave" | "afterSave";
export interface IFormContext {
	meta: IMetaObject;
	id: string;
	objectLabel: string;
	getRecord: () => any;
	setRecord: (record: any) => void;
	fieldProps: FieldProps;
	tabProps?: { [tabName: string]: IFormPanel };
	events?: ((eventName: PanelPropsEventType, context: IFormContext) => Promise<boolean>)[]; 
	session: { version: number, [name: string]: any };
	getService: (serviceId: string) => any;
}

export interface IPanelProps {
	context: IFormContext;
	panel: IFormPanel;
}

export interface PanelProps extends IFormContext {
	panel: IFormPanel;
	
}

export const getSessionValue = (ctx: IFormContext, name: string, currentVersion: boolean = true) => {
	const x = ctx.session[name] as { value: any, version: number };
	if (x && (!currentVersion || x.version === ctx.session.version))
		return x.value;
	return undefined;
}
export const setSessionValue = (ctx: IFormContext, name: string, value: any) => {
	ctx.session[name] = { value: value, version: ctx.session.version };
}
export const checkSessionOnce = (ctx: IFormContext, name: string) => {
	if (ctx.session[name])
		return false;
	ctx.session[name] = "SET_ONCE:" + new Date();
	return true;
}
