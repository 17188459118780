import { useCallback, useMemo } from "react";
import { BaseDropDown } from "../../components/BaseDropDown";
import { LookupDropDown } from "../LookupInput";
import { Fetch } from "shared/fetch";
import { ILookupValue, IMetaProperty } from "shared/schema";
import { TagList } from "./TagList";


export const TagLookupField = (props: {
	value: string,
	onChange: (newValue: any, optional?: any) => void,
	propMeta: IMetaProperty,
	disabled?: boolean,
	emptyText?: string,
	extraFetch?: Fetch,
	getRecordProperty?: (name: string) => any,
	canCreate?: boolean;
	canOpen?: boolean;
	focusOnClick?: boolean;
}) => {
	const tags = useMemo(() => {
		const t = (props.value || "").split(';').filter(x => !!x);
		return t;
	}, [props.value]);
	
	const valueToLink = useCallback((r: ILookupValue) => {
		if (tags && tags.length) {
			return <TagList tags={tags} onDelete={onRemoveItem} />
		}
		return undefined;
	}, [tags]);

	const key = useMemo(() => new Date().valueOf(), [props.propMeta]);

	const onRemoveItem = (tag: string) => {
		if (tag) {
			const index = tags.indexOf(tag);
			if (index >= 0)
				props.onChange(tags.filter((x, i) => i !== index).join(';'));
		}
	}

	const onAddItem = (value: ILookupValue, optional: any) => {
		const tag = value.label;
		if (tag && tags.indexOf(tag) < 0) {
			const newTags = tags.concat(tag);
			newTags.sort((a, b) => a.localeCompare(b));
			props.onChange(newTags.join(';'));
		}
	}

	return <BaseDropDown key={key} value={props.value} disabled={props.disabled}
		onChange={onAddItem}
		valueToLink={valueToLink}
		emptyText={props.emptyText}
		dropDownProps={{ propMeta: props.propMeta, getRecordProperty: props.getRecordProperty, canCreate: props.canCreate, extraFetch: props.extraFetch }}
		DropDownElement={LookupDropDown}
	/>
}