import { IAppConfig } from "../AppSchema"
import { tryLocalize } from "../AppState"
import { IModalContext, Modal, showMenu, showTooltip } from "./Modal"

export const showConfirm = async (metadata: IAppConfig, modal: IModalContext, messageId: string, red: boolean = true) => {
	return await showMenu(modal, document.body,
		[tryLocalize(metadata, messageId), tryLocalize(metadata, "CmdCancel")],
		{
			largeMenu: true,
			outsideClickIndex: 1,
			colors: red ? ["red"] : undefined,
		}) === 0;
}

export const showMessage = async (modal: IModalContext, message: string, red: boolean = true) => {
	return showMenu(modal, document.body, [<span style={{ whiteSpace: "pre-wrap" }}>{message}</span>, "OK"],
		{ largeMenu: true, colors: red ? ["red"] : undefined, });
}

export const showError = async (modal: IModalContext, e: any) => {
	showMessage(modal, e?.message || e || "Unkown error");
}

export const showTooltipInfo = (modal: IModalContext, metadata: IAppConfig, message: string) => {
	const msg = tryLocalize(metadata, message);
	showTooltip(modal, <span className="commandMessageContent"><i className="fa fa-check" style={{ color: "green" }} />{msg}</span>);
}