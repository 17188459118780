import { BrowserRouter, createBrowserRouter, createHashRouter, createRoutesFromElements, HashRouter, Navigate, NavLink, Outlet, Route, RouterProvider, Routes, To, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Header from './components/Header';
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IAppConfig, MetaContext, useGuiHome } from './AppState';
import PublicObjectList from './objectList/PublicObjectList';
import { ObjectForm } from './objectForm/ObjectForm';
import { Customize } from './customize/Customize';
import { Loading } from './components/Loading';
import { LoginForm, SignupForm } from './login/LoginForm';
import { DataService, initializeService } from './service';
import { fetchJson, isAppAuthorized, loginToServer } from './services/fetchJson';
import './App.css';
import { CustomizeViewObject } from './customize/CustomizeViewObject';
import { RoleEditorForm } from './customize/permissions/RolePermissions';
import { CustomizeMetadata } from './customize/Metadata';
import { ModalContext, ModalSite } from './modal/Modal';
import { TrialForm } from './login/TrialForm';
import { MainPage } from './login/MainPage';
import { ImportComponent } from './services/importDialog';
// import { parseAndPrintExpression } from './services/rollups';
import { AboutPage, HomePage, ServicePage, ServicesPage } from './web/home';
import { Capacitor } from '@capacitor/core';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { StatusBar, Style } from '@capacitor/status-bar';
import { MagicForm } from './login/MagicForm';
import { ExportComponent } from './services/exportPage';
import { ResponsiveBar } from '@nivo/bar';
import { applyColorScheme, getDarkMode } from './utils/colorScheme';
import { CustomizeLocalization } from './customize/CustomizeLocalization';
import { NextList } from './whatsnext/NextList';
import { ErrorBoundary } from 'react-error-boundary';
import { ServerCacheList } from './logging/ServerCache';
import { DashboardForm } from './objectChart/Dashboard';
import { ScriptComponent } from './command/ScriptComponent';
import { startPushListenService } from './services/serverSentEvents';
import { SurveyForm } from './survey/SurveyForm';

// const isElectron = window && window.process !== undefined;
// if (isElectron) {
//   const electron = window.require('electron');
//  // console.log(electron.getVersion());
// }
const isElectron = /electron/i.test(navigator.userAgent) || Capacitor.isNativePlatform();

let APP_ID = "";

// var SCROLL_RESTORE: { [x: string]: number } = {};
// const useAppScrollRestore = (sessionKey: string) => {
  
//   const sc = useRef<number>(0);
//   const body = document.body as HTMLElement;

// 	const location = useLocation();
// 	sessionKey = location.key + sessionKey;

// 	const onScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
// 		if (sc.current)
// 			window.clearTimeout(sc.current);
// 		sc.current = window.setTimeout(() => {
// 			//setSearchParams({ [sessionKey]: ""+(e.target as HTMLDivElement).scrollTop }, { replace: true });
// 			//setSessionStateItem(sessionKey, (e.target as HTMLDivElement).scrollTop);
// 			SCROLL_RESTORE[sessionKey] = (e.target as HTMLDivElement).scrollTop;
// 		}, 500);
// 	}, []);
// 	useLayoutEffect(() => {
// 		if (mainRef.current && !isLoading) {
// 			//const scrollPos = getSessionStateItem(sessionKey, 0);
// 			//const scrollPos = +(searchParams.get(sessionKey) || 0);
// 			const scrollPos = SCROLL_RESTORE[sessionKey] || 0;
// 			mainRef.current.scrollTo({ top: scrollPos, behavior: "auto" });
// 			console.log("restore scroll:" + scrollPos);
// 		}
// 	}, [isLoading]);
	
// 	return onScroll;
// }
// }

const AuthLayout = () => {
 
  let [meta, setMeta] = useState<IAppConfig>();

  const navigate = useNavigate();
  const location = useLocation();
  const appId = useParams<string>()["appid"];

  const refresh = useCallback((cancelToken?: { cancel: boolean }) => {
    const fetchMetadata = async () => {

      if (isElectron && !(await isAppAuthorized())) {
        navigate("/login");
        return;
      }

      const init = await initializeService();

      if (cancelToken && cancelToken.cancel) {
        console.log("refresh canceled");
        return;
      }

      if (!init) {
        let to: To;
        const isBaseDomain = window.location.origin === "http://localhost:3000" ||
                              window.location.origin === "https://inuko.net" ||
                              window.location.origin === "https://inuko.net/";
        if (isBaseDomain && (location.pathname === "/" || location.pathname === "/!/"))
          to = { pathname: "/main" };
        else
          to = { pathname: "/login", search: "returnUrl=" + location.pathname + location.search };
        navigate(to);
      }
      else {

        if (checkNewAppVersion(init)) {
          window.location.reload();
          return;
        }

        //init.refresh = refresh;
        //prepareMetadata(init);
        init.maxAge = new Date(new Date().valueOf() + 43200 * 1000); // 12 hours
        setMeta(init);
        console.log("metadata updated");

        startPushListenService(init.orgName);

        if (init.orgName === "zebra") {
          try {
            let text = window.sessionStorage.getItem("ERROR_LOG") || "";
            if (text) {
              errorHandler(new Error(text), "ERROR_LOG");
              window.sessionStorage.setItem("ERROR_LOG", "");
            }
          }
          catch { }
        }
      }
    };
    fetchMetadata();
  }, [location]); //

  //console.log("Render Auth Layout");
  useEffect(() => {
    const cancelToken = { cancel: false };
    refresh(cancelToken);
    
    return () => { cancelToken.cancel = true };
  }, []);

  if (meta && (meta.needsRefresh || (meta.maxAge && meta.maxAge < new Date()))) {
    meta = undefined;
    refresh();
  }

  if (meta) {
    let newAppId = appId || "!";
    if (appId === "!") {
      const savedAppId = sessionStorage.getItem("INUKO_APP_ID");
      if (savedAppId)
        newAppId = savedAppId;
    }
    
    if (newAppId === "!" || (meta.apps.indexOf(newAppId as string) < 0 && meta.apps.length > 0)) {
      newAppId = meta.apps[0];
    }
    if (!meta.appId) {
      meta.appId = newAppId;
      sessionStorage.setItem("INUKO_APP_ID", newAppId);
    }
    
    if (meta.appId !== newAppId) {
      meta.needsRefresh = true;
      //navigate("/"+appId+"/");
      meta = undefined;
      refresh();
    }
    if (appId !== newAppId) {
      const oldLoc = location.pathname.indexOf("/", 1);
      const to = { ...location, pathname: "/" + newAppId + location.pathname.substring(oldLoc) }
      navigate(to, { replace: true });
    }
  }

  const updateInsets = async () => {
    if (Capacitor.isNativePlatform()) {
      const safe = await SafeArea.getSafeAreaInsets();
      console.log(safe);
      const rootStyle = document.documentElement.style;
      rootStyle.setProperty('--inset-top', safe.insets.top + "px");
      rootStyle.setProperty('--inset-bottom', safe.insets.bottom + "px");

      StatusBar.setStyle({ style: getDarkMode() ? Style.Dark : Style.Light });
    }
  }

  useLayoutEffect(() => {
    const app = document.getElementById("root") as HTMLElement;
    if (app) {
      const bs = app.style;
      if (isElectron) {
        updateInsets();
      }

      // is list or form ?
      const content = app.lastElementChild as HTMLElement;
      if (window.innerWidth <= 500 && content && (content.classList.contains("formContainer") || content.classList.contains("objectListContainer"))) {
        app.classList.add("scrollableApp");
        const titleBox = document.getElementsByClassName("titleBox")[0] as HTMLElement;
        if (titleBox) {
          titleBox.style.position = "fixed";
          titleBox.style.top = "0px";
          titleBox.style.borderTop = "var(--inset-top) solid var(--back-color)";
          titleBox.style.left = "0px";
          titleBox.style.right = "0px";
          titleBox.style.zIndex = "2";
          //titleBox.style.backgroundColor = "white";
          (titleBox.style as any)["-webkit-backdrop-filter"] = "blur(80px)";
          (titleBox.style as any)["backdrop-filter"] = "blur(80px)";
         
          content.style.paddingTop = titleBox.clientHeight + "px"; // border is _not_ included in clientHeight
        }
        content.style.paddingBottom = "50px";
        bs.minHeight = "100vh";
      } else {
        app.classList.remove("scrollableApp");
        bs.position = "fixed";
        bs.height = "100%";
        bs.overflow = "hidden";
        bs.display = "grid";
      }
    }

    if (isElectron) {
      const el = window;
      //el.addEventListener('touchstart', onWindowTouchStart, { "once": true });
      console.log("onWindowTouchStart-Register")
     
    }

    if (meta) {
      tryInsertAdsBanner(meta);
    }
  })

  const onWindowTouchStart = (e: TouchEvent) => {
    console.log("onWindowTouchStart")
    const x = e.touches[0];
    if (x.clientX < 15) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      const el = window;
      el.addEventListener('touchend', onWindowTouchEnd);
      el.addEventListener('touchcancel', onWindowTouchCancel);
      el.addEventListener('touchmove', onWindowTouchMove);
    }
  }

  const onWindowTouchEnd = (e: TouchEvent) => {
    onWindowTouchCancel(e);
    const x = e.changedTouches[0];
    if (x.clientX > 50)
      window.history.go(-1);
  }

  const onWindowTouchCancel = (e: TouchEvent) => {
    const el = window;
    el.removeEventListener('touchend', onWindowTouchEnd);
    el.removeEventListener('touchcancel', onWindowTouchCancel);
    el.removeEventListener('touchmove', onWindowTouchMove);
    const appEl = document.getElementById("root") as HTMLElement;
    appEl.style.transform = "";
  }
  const onWindowTouchMove = (e: TouchEvent) => {
    e.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();
    
    const appEl = document.getElementById("root") as HTMLElement;
    appEl.style.transform = "translateX(" + e.touches[0].clientX + "px)";
  }

  useEffect(() => {
    if (meta) {
      const cssFiles = meta.gui[0].objects.filter(x => x.kind === "script" && x.appid?.label === meta!.appId && x.name.endsWith(".css"));
      for (const f of cssFiles) {
        const id = "customCss_" + f.id;
        let style = document.getElementById(id) as HTMLStyleElement;
        if (!style) {
          style = document.createElement('style');
          style.type = 'text/css';
          style.innerHTML = f.body;
          document.getElementsByTagName('head')[0].appendChild(style);
        }
        else {
          style.innerHTML = f.body;
        }
      }
    }
    const changeHandler = (e: any) => {
      if (meta) {
        meta.needsRefresh = true;
      }
    }
    const eventName = "objectChanged:" + "inu_metadata";
    window.addEventListener(eventName, changeHandler);
    return () => window.removeEventListener(eventName, changeHandler);
  }, [meta]);

  return (!meta ? (<div className='mainContainer'><Loading /></div>) : (
    <ModalContext.Provider value={{showModal:undefined as any}}>
      <MetaContext.Provider value={meta}>
        <Header showBack={isElectron} />
        <Outlet />
        <ModalSite />
      </MetaContext.Provider>
    </ModalContext.Provider>
  ));
};

const pageLoadedDate = new Date();

const checkNewAppVersion = (metadata: IAppConfig) => {
  try {
    const text = metadata.buildDate;
    if (text) {
      const date = new Date(text.trim());
      if (date && !isNaN(date.getTime()) && pageLoadedDate < date && date < new Date()) {
        return true;
      }
    }
  }
  catch (e) {
    console.log(e);
  }
  return false;
}

const tryInsertAdsBanner = (meta: IAppConfig) => {
  const adsConfig = meta.gui[0].objects.find(x => x.kind === "script" && x.name === "adsBanner");
  if (adsConfig) {
    let ads = document.getElementById("adsBanner");
    if (!ads) {
      const content = document.getElementById("root")?.lastElementChild as HTMLElement;
      const isFormOrList = content && (content.classList.contains("formContainer") || content.classList.contains("objectListContainer"));
      if (isFormOrList && content.firstElementChild?.id !== "adsBanner") {
        ads = document.createElement("div");
        ads.id = "adsBanner"
        ads.className = "adsBanner";
        ads.innerText = "Reklama";
        content.insertBefore(ads, content.firstChild);
      }
      else {
        return;
      }
    }
    try {
      let adsItems = (JSON.parse(adsConfig.body) as { img: string, link: string }[]).map(x => ({ ...x, order: Math.random() }));
      adsItems.sort((a, b) => a.order - b.order);
      ads.innerHTML = adsItems.map(x => '<a class="adsImage" style="background-image:url(' + x.img + ')" href="' + x.link + '?utm_source=hoopers" title="ads" target="_blank" />').join(""); 
    }
    catch {

    }
  }
}

const HomeIndex = () => {

  const metadata = useContext(MetaContext);
  const home = useGuiHome();
  const url = (home ? home.filter(x => x.kind !== "separator")[0].url : "") || "/login";

  return (<Navigate to={url} replace={true} />);
}

const RedirectToAppUrl = () => {
  //const meta = useContext(MetaContext);
  const location = useLocation();
  //const url = (meta ? meta.gui[0].home[0].url : "") || "/login";
  const newLocation = { ...location, pathname: "/!" + location.pathname };

  return (<Navigate to={newLocation} replace={true} />);
}

// const ExePage = (props: {}) => {
  
//   const [text, setText2] = useState("");

//   const setText = (s: string) => {
//     parseAndPrintExpression(s);
//     setText2(s);
//   }

//   return <div>
//     <textarea value={text} className="exeText" onChange={e=>setText(e.target.value)} />
//   </div>
// }

const errorHandler = (error: Error, context: string) => {

  if (window.location.hostname === "localhost") return; // DEBUG

  function recur(obj: any, visited = new WeakSet()) {
    if (visited.has(obj)) {
      return {}; // skip already visited object to prevent cycles
    }
    visited.add(obj); // add the current object to the visited set

    var result: any = {}, _tmp;
    for (var i in obj) {
      try {
        // enabledPlugin is too nested, also skip functions
        if (i === 'enabledPlugin' || typeof obj[i] === 'function') {
          continue;
        } else if (typeof obj[i] === 'object') {
          // get props recursively
          _tmp = recur(obj[i], visited);
          // if object is not {}
          if (Object.keys(_tmp).length) {
            result[i] = _tmp;
          }
        } else {
          // string, number or boolean
          result[i] = obj[i];
        }
      } catch (error) {
        // handle error, you can log it here if needed
        // console.error('Error:', error);
      }
    }
    return result;
  }

  try {
      // gather more runtime info
    let appInfo:any = {};
    const m = window.localStorage.getItem("userContext");//useContext(MetaContext);
    if (m) {
      const metadata = JSON.parse(m);
      appInfo = { user: metadata.user, appId: metadata.appId, orgName: metadata.orgName };
    }     
    const appError = { context: context, app:appInfo, navigator: recur(window.navigator), url: window.location.href, error: { message: error.message, stack: error.stack } };
    fetchJson("/api/apperror", appError, false);
  }
  catch { }
}

const ErrorPage = (props: { error?: Error, resetErrorBoundary?: any }) => {
  const error = props.error || { name: "Unknown error", message: "" };

  errorHandler(error, "Render Error");
  
  return (<div className="mainContainer"> <div className='main'><div className="magicContainer">
    <i className='fa fa-face-frown' style={{ fontSize: 40, color: "#eebb99" }}></i>
    <h1>Something went wrong {error.name||""}</h1>
    <span style={{ color: "red", fontWeight: "bold", margin: "auto", padding: "20px" }}>{error.message}</span>
    <span>Please contact support.</span>
  </div></div></div>);
}

const NoRoutePage = (props: {}) => {

  const page = useLocation().pathname;

  errorHandler(new Error("No Route Error " + page), "No Route Error");
 
  return (<div className="listContainer"><div className="magicContainer">
    <i className='fa fa-face-frown' style={{ fontSize: 40, color: "#eebb99" }}></i>
    <h1>Page not found</h1>
    <span style={{ color: "red", fontWeight: "bold", margin: "auto", padding: "20px" }}>{page}</span>
    <span>Please contact support.</span>
  </div></div>);
}

const CustomPage = (props: {}) => {
  const componentName = useParams<string>()["id"] || "";
 
  return <ScriptComponent customComponent={componentName} />
  //return React.createElement(component, { context: context });
}
const NamedPage = (props: {}) => {
  const componentName = useParams<string>()["id"] || "";
  const homeItems = useGuiHome();
  const home = homeItems?.find(x => x.uniqueName === componentName);
  if (!home)
    throw Error("HomeItem not found:" + componentName);
  if (home.kind === "entity")
    return <PublicObjectList homeItem={home} />
  
  throw Error("HomeItem kind not supported:" + componentName + " kind:" + home.kind);
}

const getRouter = () => {
  if (isElectron)
    return createHashRouter;
  else
    return createBrowserRouter;
}

const router = getRouter()(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorPage/>} >
      <Route path="/inuko" element={<HomePage />} />
      <Route path="/inuko/services" element={<ServicesPage />} />
      <Route path="/inuko/services/:name" element={<ServicePage />} />
      <Route path="/inuko/about" element={<AboutPage />} />
      <Route path="/main" element={<MainPage />} />
      {/* <Route path="/exe" element={<ExePage />} /> */}
      <Route path="/login" element={<LoginForm />} />
      <Route path="/trial" element={<TrialForm />} />
      <Route path="/signup" element={<SignupForm />} />
      <Route path="/magic" element={<MagicForm />} />
      <Route path="/ansu/:orgname/:key" element={<SurveyForm />} />
      <Route path="/" element={<RedirectToAppUrl />} />
      <Route path="/list/*" element={<RedirectToAppUrl />} />
      <Route path="/edit/*" element={<RedirectToAppUrl />} />
      <Route path="/:appid" element={<AuthLayout />} >
        <Route path="/:appid/import" element={<ImportComponent objectName="dog" />} />
        <Route path="/:appid/export" element={<ExportComponent objectName="dog" />} />
        <Route path="/:appid/whatsnext" element={<NextList />} />
        <Route path="/:appid/cache" element={<ServerCacheList />} />
        <Route path="/:appid/" element={<HomeIndex />} />
        <Route path="/:appid/list/:name" element={<PublicObjectList />} />
        <Route path="/:appid/edit/inu_role/:id" element={<RoleEditorForm />} />
        <Route path="/:appid/edit/:name/:id" element={<ObjectForm />} />
        <Route path="/:appid/edit/:name/:id/:form" element={<ObjectForm />} />
        <Route path="/:appid/dashboard/:name" element={<DashboardForm />} />
        <Route path='/:appid/customize' element={<Customize />} />
        <Route path="/:appid/customizemetadata" element={<CustomizeMetadata />} />
        <Route path="/:appid/customizelocalization" element={<CustomizeLocalization />} />
        <Route path="/:appid/edit/inu_view/:id" element={<CustomizeViewObject />} />
        <Route path="/:appid/custom/:id" element={<CustomPage />} />
        <Route path="/:appid/n/:id" element={<NamedPage />} />
        <Route path="*" element={<NoRoutePage />} />
      </Route>
    </Route>
  )
);

function App() {

  const isNative = isElectron || (window as any).cordova !== undefined;
  //const Router = isNative ? HashRouter : BrowserRouter;
  console.log("Using router:" + isNative);

  applyColorScheme();

  // FIXME: maybe only numeric in list. Unusable for normal text fields...
  // window.addEventListener("focusin", (e) => {
  //   const elm = e.target as any;
  //   if (elm.select) {
  //     setTimeout(() => elm.select(), 1);
  //   }
  // })

  // remove global handler
  // We need the global handler for when the application is re-deployed and the bundle js filename changes.
  // When a user goes back to an inactive tab of our app, the browser tries to "revive" it.
  // ***However the index.html is not reloaded, only re-executed.***
  // The browser thus tries to load a js script which is no longer there.
  window.removeEventListener("error", (window as any).unhandledErrorDefaultHandler);
  window.onerror = function (message, file, line, col, error) {
    //alert("Error occurred: " + error.message);
    errorHandler(error!, "Javascript Unhandled exception");
    return false;
 };
  
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
       <RouterProvider router={router} />
      {/*<Router>
      <Routes>
        <Route path="/inuko" element={<HomePage />} />
        <Route path="/inuko/services" element={<ServicesPage />} />
        <Route path="/inuko/services/:name" element={<ServicePage />} />
        <Route path="/inuko/about" element={<AboutPage />} />
        <Route path="/main" element={<MainPage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/trial" element={<TrialForm />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/magic" element={<MagicForm />} />
          <Route path="/" element={<RedirectToAppUrl />} />
          <Route path="/list/*" element={<RedirectToAppUrl />} />
          <Route path="/edit/*" element={<RedirectToAppUrl />} />
          <Route path="/:appid" element={<AuthLayout />} >
          <Route path="/:appid/import" element={<ImportComponent objectName="dog" />} />
          <Route path="/:appid/export" element={<ExportComponent objectName="dog" />} />
          <Route path="/:appid/fetch" element={<FetchTester />} />
          <Route path="/:appid/whatsnext" element={<NextList />} />
            <Route path="/:appid/" element={<HomeIndex />} />
            <Route path="/:appid/list/:name" element={<PublicObjectList />} />
            <Route path="/:appid/edit/inu_role/:id" element={<RoleEditorForm />} />
            <Route path="/:appid/edit/:name/:id" element={<ObjectForm />} />
            <Route path='/:appid/customize' element={<Customize />} />
          <Route path="/:appid/customizemetadata" element={<CustomizeMetadata />} />
          <Route path="/:appid/customizelocalization" element={<CustomizeLocalization />} />
          <Route path="/:appid/edit/inu_view/:id" element={<CustomizeViewObject />} />
          <Route path="*" element={<NoRoutePage/>} />
          </Route> 
        </Routes>
      </Router> */}
      </ErrorBoundary>
  );
}

const FetchTester = (props: {}) => {
  
  const [q, setQuery] = useState("");
  const [result, setResult] = useState("");
  const [data, setData] = useState([] as ({ x: any, y: any })[]);

  const onExecute = async () => {
    try {
      const fetch = JSON.parse(q);
      const results = await DataService.retrieveMultiple(fetch) as ({ x: any, y: any })[];
      const r = results.map(f => JSON.stringify(f)).join("\n");
      setData(results);
      setResult(r);
    }
    catch (e) {
      setResult("" + e);
    }
  }

  const barData = data.map(z => ({ ...z, x_label: z.x.label }));

  return <div>
    <textarea rows={10} value={q} onChange={e => setQuery(e.target.value)} />
    <button onClick={onExecute}>Execute</button>
    <div>{result}</div>
    <div style={{ height: "300px" }}>
      
      <ResponsiveBar margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        data={barData}
        // layout={"horizontal"}
      //   data={[
      //   {
      //     id: 'fake corp. A',
      //     data: data,
      //   },
      // ]}
        // xScale={{
        //   type: "point"
        // }}
        // yScale={{
        //   type: 'linear',
        // }}
        axisLeft={{
          legend: 'linear scale',
          legendOffset: 12,
        }}
        axisBottom={{
          legend: 'day',
          legendOffset: -12,
          "tickSize": 5,
          "tickPadding": 5,
          "tickRotation": 35
        }}
        keys={["y"]}
        indexBy="x_label"
      />
    </div>
  </div>
}

export default App;
