import { Fetch } from "shared/fetch";
import { IMetadata, MetaPropertyFlags } from "shared/schema";
import { IAppConfig } from "../AppSchema";
import { humanize } from "../AppState";

const LANG_STORAGE_KEY = "application-language";

export const getLanguage = (metadata: IMetadata) => {
	let lang = localStorage[LANG_STORAGE_KEY];
	if (!lang) {
		let winLang = window.navigator.language;
		if (winLang) {
			winLang = winLang.substring(0, 2);
			if (winLang === "sk" || winLang === "en") {
				lang = winLang;
			} else if (metadata) {
				const langs = getAvailableLanguages(metadata);
				lang = langs.find(x => x.id === winLang)?.id;
			}
		}
	}
	return lang || "en";
}
export const setLanguage = (langId: string) => {
	localStorage[LANG_STORAGE_KEY] = langId;
}
export const getAvailableLanguages = (metadata: IMetadata) => {
	return metadata.languages || [{ id: "en", label: "English" }, { id: "de", label: "German" }, { id: "sk", label: "Slovak" }];
}

export const fetchLocalization = async (fetchRows: (q: Fetch) => Promise<any[]>, metadata: IAppConfig, lang: string) => {
	const q = {
		entity: {
			name: "inu_localize",
			attributes: ["id", "name", "lang", "text"].map(x => ({ attribute: x })),
			filter: {
				type: "or",
				conditions: [{ attribute: "lang", operator: "eq", value: "en" }]
			},
			orders: [{ attribute: "name" }]
		}
	};
	
	if (lang && lang !== "en") {
		q.entity.filter.conditions.push({ attribute: "lang", operator: "eq", value: lang });
	}
	const rows = await fetchRows(q as Fetch) as { name: string, lang: string, text: string }[];
	//const map = processLocalization(metadata, rows);
	let locMap: { [s: string]: string } = {};
	for (const r of SYSTEM_LOC) {
		locMap[r.name] = r[lang] || r.en;
	}
	for (const r of rows) {
		if (!locMap[r.name] || r.lang === lang)
			locMap[r.name] = r.text;
	}
	return locMap;
}

export const processLocalization = (metadata: IAppConfig, rows: any, addDefaults: boolean = false) => {
	const locMap = {} as { [key: string]: { name: string, en?: { id?: string, text: string, isDirty?: boolean } } };
	if (addDefaults) {
		for (const meta of metadata.objects) {
			locMap[meta.logicalName] = { name: meta.logicalName, en: { text: meta.displayName || meta.logicalName } };
			locMap[meta.logicalName + "+s"] = { name: meta.logicalName + "+s", en: { text: meta.displayNamePlural || meta.logicalName } };
			for (const propMeta of meta.properties) {
				const propId = meta.logicalName + "." + propMeta.logicalName;
				locMap[propId] = { name: propId, en: { text: propMeta.displayName || humanize(propMeta.logicalName) } };
				if ((propMeta.flags & MetaPropertyFlags.OptionSet) !== 0 && propMeta.options) {
					for (const o of propMeta.options) {
						locMap[propId + "." + o] = { name: propId + "." + o, en: { text: o } };
					}
				}
			}
		}
	}
	for (const r of rows) {
		let h = locMap[r.name];
		if (addDefaults && h && r.lang === "en") {
			r.isMetadata = true;
		}
		if (!h)
			h = locMap[r.name] = { name: r.name };
		(h as any)[r.lang] = r;
	}
	return locMap;
}

// export const getSystemLabel = (id: string) => {
// 	let lang = localStorage[LANG_STORAGE_KEY];
// 	if (!lang) {
// 		let winLang = window.navigator.language;
// 		if (winLang) {
// 			lang = winLang.substring(0, 2);
// 		}
// 	}
// 	lang = lang || "en";
// 	const text = SYSTEM_LOC.find(x => x.name === id);
// 	return (text && (text[lang] || text["en"])) || id;
// }

const SYSTEM_LOC: { name: string, [key: string]: string }[] = [
	{
		name: "MsgAskDelete",
		en: "Are you sure you want delete this record?",
		sk: "Naozaj chcete odstrániť tento záznam?",
		cs: "Opravdu chcete tento záznam smazat?"
	},
	{
		name: "MsgRecordNotFound",
		en: "We apologize. The record does not exist or you do not have permission to access it.",
		sk: "Ospravedlňujeme sa. Záznam neexistuje alebo nemáte povolenie na prístup.",
		cs: "Omlouváme se. Záznam neexistuje nebo nemáte povolení k přístupu."
	},
	{
		name: "MsgRequiredFieldEmpty",
		en: "Please provide a value for",
		sk: "Zadajte hodnotu pre",
		cs: "Zadejte hodnotu pro"
	},
	{
		name: "MsgDiscardChanges",
		en: "Discard unsaved changes",
		sk: "Zahodiť neuložené zmeny",
		cs: "Zahodit neuložené změny"
	},
	{
		name: "MsgContinueEditing",
		en: "Continue editing",
		sk: "Pokračovať v úpravách",
		cs: "Pokračovat v úpravě"
	},
	{
		name: "MsgRecordUpdated",
		en: "Record was updated",
		sk: "Záznam bol aktualizovaný",
		cs: "Záznam byl aktualizován"
	},
	{
		name: "CmdSaveAndClose",
		en: "Save changes and continue",
		sk: "Uložiť zmeny a pokračovať",
		cs: "Uložit změny a pokračovat"
	},
	{ name: "MsgRecordSaved", en: "Record saved", sk: "Záznam bol uložený", cs: "Záznam byl uložen" },
	{ name: "CmdEditProfile", en: "My Profile", sk: "Môj profil", cs: "Můj profil" },
	{ name: "CmdCancel", en: "Cancel", sk: "Zrušiť", cs: "Zrušit" },
	{ name: "CmdCalendar", en: "Show Calendar", sk: "Zobraziť kalendár", cs: "Zobrazit kalendář" },
	{ name: "CmdChart", en: "Show Chart", sk: "Zobraziť graf", cs: "Zobrazit graf" },
	{ name: "CmdMap", en: "Show Map", sk: "Zobraziť mapu", cs: "Zobrazit mapu" },
	{ name: "CmdSave", en: "Save", sk: "Uložiť", cs: "Uložit" },
	{ name: "CmdClone", en: "Create Copy", sk: "Vytvoriť kópiu", cs: "Vytvořit kopii" },
	{ name: "CmdDelete", en: "Delete", sk: "Odstrániť", cs: "Smazat" },
	{ name: "CmdImport", en: "Import" },
	{ name: "CmdExport", en: "Export" },
	{ name: "CmdChangePassword", en: "Change Password", sk: "Zmeniť heslo", cs: "Změnit heslo" },
	{ name: "CmdEdit", en: "Edit", sk: "Upraviť", cs: "Upravit" },
	{ name: "CmdNew", en: "New", sk: "Nový záznam", cs: "Nový záznam" },
	{ name: "CmdManage", en: "Add/Remove", sk: "Upraviť", cs: "Upravit" },
	{ name: "CmdUndoDelete", en: "Undo Delete" },
	{ name: "CmdUpload", en: "Upload", sk: "Nahrať", cs: "Nahrát" },
	{ name: "CmdDownload", en: "Download", sk: "Stiahnuť", cs: "Stáhnout" },
	{ name: "CmdRename", en: "Rename", sk: "Premenovať", cs: "Přejmenovat" },
	{ name: "CmdHideIcons", en: "Hide icons", sk: "Skry náhľad", cs: "Skrýt náhled" },
	{ name: "CmdShowIcons", en: "Show icons", sk: "Ukáž náhľad", cs: "Ukaž náhled" },
	{ name: "CmdSend", en: "Send", sk: "Odoslať", cs: "Odeslat" },
	{ name: "CmdLogout", en: "Sign out", sk: "Odhlásiť", cz: "Odhlásit" },
	{ name: "CmdReply", en: "Reply", sk: "Odpovedať", cz: "Odpovedať" },
	{ name: "CmdAddComment", en: "Add a comment", sk: "Pridať komentár", cz: "Pridať komentár" },
	{ name: "MsgReplies", en: "Replies", sk: "Odpovedí", cz: "Odpovedí" },
	{ name: "MsgComments", en: "Comments", sk: "Komentárov", cz: "Komentárov"},
	{ name: "icon", en: "Icon", sk: "Náhľad", cs: "Náhled" },
	{ name: "name", en: "Name", sk: "Názov", cs: "Název" },
	{ name: "modifiedon", en: "Modified On", sk: "Upravené", cs: "Upraveno" },
	{ name: "size", en: "Size", sk: "Veľkosť", cs: "Velikost" },
	{ name: "actions", en: "Actions", sk: "Akcie", cs: "Akce" }
];