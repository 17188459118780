import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IconButton } from "../../components/IconButton";
import { MetaContext } from "../../AppState";
import { PERMISSION_UPDATE } from "shared/permissions";


export interface ICustomizeContext {
	enabled: boolean;
	onSave?: () => void;
}

export const CustomizeContext = createContext<ICustomizeContext>({ enabled: false, onSave: () => { } });

export const Customizable = (props: { children: any, onSave?:()=>void }) => {
	const [isCustomize, setCustomize] = useState(false);
	const metatada = useContext(MetaContext);
	useEffect(() => {
		const handler = (e: KeyboardEvent) => {
			if (e.ctrlKey && e.key === "e") {
				setCustomize(x => !x);
			}
		}
		if (metatada && (metatada.permDict["inu_view"] & PERMISSION_UPDATE) !== 0)
			document.addEventListener("keydown", handler);
		return () => {
			document.removeEventListener("keydown", handler);
		}
	}, []);

	return <CustomizeContext.Provider value={{ enabled: isCustomize, onSave: props.onSave }}>
		{props.children}
	</CustomizeContext.Provider>
}

export const useCustomizeClone = <T extends unknown>(obj: T): T => {
	const customize = useContext(CustomizeContext);
	const r = useRef<T>(undefined as unknown as T);
	if (!customize.enabled) return obj;
	if (!r.current) {
		console.log("form cloned")
		r.current = JSON.parse(JSON.stringify(obj)) as T;
	}
	return r.current;
}

export const CustomizeModeHeader = (props: { onSave?: () => void, style?: React.CSSProperties }) => {
	const customize = useContext(CustomizeContext);

	const onSave = props.onSave || (() => { });
	
	return customize && customize.enabled && <div className="customizeBanner" style={props.style}>
		<span style={{gridArea:"1/1/2/2", justifySelf:"center", alignSelf:"center"}}>CUSTOMIZE MODE</span>
		<IconButton style={{gridArea:"1/1/2/2",justifySelf:"flex-end", background:"none", margin:0, padding:0, border:"0", color:"white"}} label="" icon="save" onClick={onSave} />
	</div> || null;
}