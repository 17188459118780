import { Dictionary, ICurrency, IDictionary, IExecuteRequest, IExecuteRequests, IExecuteResult, ILookupValue, IMetadata, IMetaObject, MetaPropertyFlags, MetaPropertyType, Schema } from 'shared/schema';
import { getPermLevel, PERMISSION_CREATE, PERMISSION_DELETE } from 'shared/permissions';
import { Fetch } from 'shared/fetch';
import { FetchToSql } from "shared/fetchToSql";
import { IAppConfig } from '../../AppSchema';
import { SqlParam, DatabaseConnection } from './DatabaseConnection';
import { getFileNameFromBlobUrl } from '../getFileUrl';
import { downloadAndSaveBlobFiles, saveBlobFile } from './blobs';
import { newGuid } from '../../utils/guid';

type IObjectRecord = IDictionary<string | ILookupValue>

interface IContext {
	metaDict: IDictionary<IMetaObject>;
	permDict?: any;
	owners?: any;
	currencyDict?: any;
}
const SYS_ADMIN = "administrator";
interface IUser {
	id: string;
}

const ReadOnlyFields: IDictionary<boolean> = {
	"createdon": true,
	"modifiedon": true,
	"createdby": true,
	"modifiedby": true,
	"versionnumber": true
};


const TYPES = { NVarChar: "string", Decimal: "decimal", UniqueIdentifier:"guid", Binary:"blob", DateTime:"date" };

const getSqlType = (type: MetaPropertyType) => {
	return "";
}

let PermissionsCacheDate: any = null;
let CachedCurrency: any = null;

const onRecordChanged = async (db: any, user: any, operation: string, objectName: string, id: string, p2: any, record: any) => {
}

const checkPermissions = async (upload:any, prevRecord:any, user:any, db:any, idParam:any, meta:any, permDict:any, owners:any) => {	
}

const prepareUpdateParameters = (currencyDict: IDictionary<ICurrency>,
	meta: IMetaObject, record: any, prevRecord: IObjectRecord|undefined) => {
	const parameters: SqlParam[] = [];
	let idParam: SqlParam | undefined = undefined;

	let currencyId: string|undefined = undefined;

	for (const prop of meta.properties) {
		const name = prop.logicalName;
		if (ReadOnlyFields[name]) {
			continue;
		}
		if (record.hasOwnProperty(name)) {
			const p: SqlParam = {
				name: name,
				type: getSqlType(prop.type),
				value: record[name]
			};
			if (prop.type === MetaPropertyType.Lookup) {
				const lookup = p.value;
				if (lookup) {
					p.value = lookup["id"];
				}
				parameters.push({ name: p.name + "Target", type: TYPES.NVarChar, value: lookup ? lookup["name"] : null });
			}
			else if (prop.type === MetaPropertyType.DateTime) {
				// Date only?
				if (p.value && p.value.indexOf("T") < 0) {
					p.value = (new Date(p.value)).toISOString();
				}
				if (p.value && p.value.indexOf("Z") < 0) // assume UTC
					p.value = p.value + "Z";
			}
			/*
			else if (prop.type === MetaPropertyType.Money && !prop.logicalName.endsWith("_base")) {
				if (p.value !== undefined && p.value !== null && p.value !== "") {
					const num = +p.value;
					const baseName = prop.logicalName + "_base";
					const base = meta.properties.find(x => x.logicalName === baseName);
					if (base) {
						if (currencyId === undefined) {
							currencyId = "";
							const cid = record["currencyid"] || (prevRecord && prevRecord["currencyid"]) as ILookupValue;
							if (cid && cid.id) {
								currencyId = cid.id;
							}
							else {
								// if (record["id"]) {
								// 	// FIXME: once we load the whole record before update (for change actions/notify, security, audit etc. this will go away)
								// 	const r = await db.executeQuery("SELECT currencyid FROM " + meta.logicalName + " WHERE id=@P1", true, [{ name: "id", type: TYPES.NVarChar, value: record.id }]);
								// 	if (r && r.length > 0 && r[0].currencyid)
								// 		currencyId = r[0].currencyId;
							
								// }
							}
						}
						if (currencyId) {
							//get currency from cache.
							const recCurrency = currencyDict[currencyId];
							if (recCurrency) {
								const baseNum = num * recCurrency.exchangerate;
								parameters.push({ name: baseName, type: TYPES.Decimal, value: baseNum });
							}
						}
					}
				}
			}
			else if (prop.type === MetaPropertyType.Decimal && prop.logicalName.endsWith("_base")) {
				// base currency value, ignore.
				// TODO: we should have an override so this value can actually be modified
				continue;
			}
			*/
				
			if (p.value === undefined || p.value === null) {
				p.value = null;
				//p.type = TYPES.Null;
			}

			if (name === "id") {
				idParam = p;
			}
			else {
				parameters.push(p);
			}
		}
	}
	return { parameters, idParam };
}

const addUserParameter = (meta: IMetaObject, parameters: SqlParam[], propName: string, user: IUser) => {
	if (meta.properties.find(x => x.logicalName === propName) && !parameters.find(x => x.name === propName)) {
		parameters.push({ name: propName, type: TYPES.UniqueIdentifier, value: user.id });
		parameters.push({ name: propName + "Target", type: TYPES.NVarChar, value: "systemuser" });
	}
}

const internalUpdateRecord = async (db: DatabaseConnection, context: IContext, upload: IExecuteRequest, user: IUser, prevRecord?: IObjectRecord) => {
	const objectName = upload.name;
	const record = upload.object;
	let operation = upload.operation;
	
	const { metaDict, permDict, owners } = context;
	const meta = metaDict[objectName];
			
	if (!meta) throw Error("ObjectType not found:" + objectName);
	
	let { parameters, idParam } = prepareUpdateParameters(context.currencyDict, meta, record, prevRecord);
	
	if (meta.type === "many") {
		const [p1name, p1, p2name, p2] = parameters;
		if (!p1 || !p2) throw Error("Missing lookup parameters");
	
		if (user.id !== SYS_ADMIN) {
			const p = getPermLevel(objectName, permDict, operation === "delete" ? PERMISSION_DELETE : PERMISSION_CREATE);
			if (!p) throw new Error("User not authorized to " + "associate" + " entity:" + objectName);
		}
	
		if (operation === "delete") {
			await db.deleteRowWithId(objectName, [p1, p2]);
		}
		else {
			await db.insertRow(objectName, parameters);
		}
		await onRecordChanged(db, user, operation || "create", objectName, p1.value, null, record)
		//await writeAuditLog(db, user, operation || "create", objectName, p1.value);
		// FIXME: execute after a executeMultiple records, otherwise we will be reloading permissions like crazy.
		// After finishTransaction (if any), otherwise we could be raced and old permissions could be loaded.
		if (objectName === "inu_userroles") {
			PermissionsCacheDate = new Date();
		}
		return "";
	}
	
	await checkPermissions(upload, prevRecord, user, db, idParam, meta, permDict, owners);
	
	operation = operation || (idParam ? "update" : "create");
	
	const now = (new Date()).toISOString();
	parameters.push({ name: "modifiedon", type: TYPES.DateTime, value: now });
	addUserParameter(meta, parameters, "modifiedby", user);
	
	let result = "";
	if (operation === "create") {
		// CREATE. not a problem if client sends an ID.
		idParam = idParam || { name: "id", type: TYPES.UniqueIdentifier, value: newGuid() };
		parameters.push(idParam);
		parameters.push({ name: "createdon", type: TYPES.DateTime, value: now });
		addUserParameter(meta, parameters, "ownerid", user);
		addUserParameter(meta, parameters, "createdby", user);
		await db.insertRow(objectName, parameters);
	
		result = idParam.value;
	}
	else {
		const conds = [idParam as SqlParam];
		if (upload.object["versionnumber"])
			conds.push({ name: "versionnumber", type: TYPES.Binary, value: Buffer.from(upload.object["versionnumber"] as string, "base64") });
			
		let rowsAffected;
		if (operation === "delete")
			rowsAffected = await db.deleteRowWithId(objectName, conds);
		else
			rowsAffected = await db.updateRowWithId(objectName, conds, parameters);
				
		// if (rowsAffected === 0) {
		// 	// todo: if we implement optimisic update (check versionnumber not modified, we need to read again here)
		// 	throw Error("Record modified by someone else. Please redo your changes.");
		// }
	}
	
	if (objectName === "currency")
		CachedCurrency = undefined;
	
	await onRecordChanged(db, user, operation, objectName, idParam?.value, prevRecord, record);
	return result;
}

const computeHash = async(message: string) => {
	const encoder = new TextEncoder();
	const data = encoder.encode(message);
	const hash = await crypto.subtle.digest('SHA-256', data);
	return hash;
}

//var init = true;

const initializeDatabase = async (db: DatabaseConnection, metadata: IMetadata) => {
	db.executeSql("CREATE TABLE IF NOT EXISTS inu_system (body TEXT)");
	const sys = await db.query("SELECT * FROM inu_system", []);
	
	let init = true;
	if (sys && sys.values && sys.values.length > 0) {
		// check metadata hash and drop db if different.
		init = false;
	}
	
	if (init) {
		init = false;
		const objects = JSON.parse(JSON.stringify(metadata.objects)); // deep clone
		const sql = (new Schema()).generate(objects, { sqlite: true })
			.replaceAll("NVARCHAR(max)", "NVARCHAR(1000)")
			.replaceAll("DEFAULT newid()", "")
			.replaceAll("DECIMAL(23,10)", "FLOAT");

		// for (const tn of metadata.objects) {
		// 	await db.executeSql("DROP TABLE " + tn.logicalName);
		// }
		
		try {
			const tables = sql.split("CREATE");
			//console.log("TABLES:" + JSON.stringify(tables));
			for (const d of tables) {
				if (!d) continue;

				//console.log("test:" + d);
				await db.executeSql("CREATE " + d);
			}
			//sql = 
			await db.insertRow("inu_system", [{ "name": "body", value: "text", type: "" }]);
		}
		catch (e) {
			console.log("INIT DB FAILED:" + JSON.stringify(e));
		}
		//await db.executeSql(sql, []);
	}
}

let DB_INSTANCE: DatabaseConnection;
let waiters: ((c:DatabaseConnection)=>void)[] = []; 
const getDatabase = async(metaContext?: IAppConfig) => {
	if (!DB_INSTANCE) {
		DB_INSTANCE = new DatabaseConnection();
		DB_INSTANCE.inuse = true;
		await DB_INSTANCE.connect();
		console.log("FIRST_GET_DATABASE");
		if (metaContext)
			await initializeDatabase(DB_INSTANCE, metaContext);
		return DB_INSTANCE;
	}
	if (DB_INSTANCE.inuse) {
		const promise = new Promise<DatabaseConnection>((res, rej) => { 
			waiters.push(res);
		})
		console.log("WAIT_GET_DATABASE");
		return promise;
	}
	console.log("GET_DATABASE");
	DB_INSTANCE.inuse = true;
	return DB_INSTANCE;
}
const releaseDatabase = () => {
	if (waiters.length > 0) {
		console.log("RELEASE_DATABASE_RESOLVE");
		const next = waiters.splice(0, 1)[0];
		next(DB_INSTANCE);
	} else {
		console.log("RELEASE_DATABASE");
		DB_INSTANCE.inuse = false;
	}
}
export const deleteDatabase = async () => {
	const db = await getDatabase();
	// FIXME: what if there are waiters?
	db.dbConnection?.delete();
	DB_INSTANCE = undefined as any;
	waiters = [];
	//db.sqlite.
}

export const executeMultipleOffline = async (req: IExecuteRequests, metaContext: IAppConfig): Promise<IExecuteResult[]> => {
	const results: IExecuteResult[] = [];
	for (const record of req.records) {
		try {
			const result = await updateRecordOffline(record.name, record.object, record.operation, metaContext);
			results.push({ result: result || null, error: null });
		}
		catch (ex) {
			console.log("update error:" + ex);
			results.push({ result: null || null, error: "ERROR: " + ex });
		}
	}
	return results;
}

const updateRecordBlobs = async (db: DatabaseConnection, metaContext: IAppConfig, meta: IMetaObject, record: any, operation: string) => {
	for (const prop of meta.properties) {
		if ((prop.flags & (MetaPropertyFlags.File | MetaPropertyFlags.Image)) !== 0) {
			const propName = prop.logicalName;

			if (record.hasOwnProperty(propName)) {

				const data = record[propName];
				if (getFileNameFromBlobUrl(data)) {
					// not changed, remove the field
					delete record[propName];
				}
				else { // base64 data or null
					const f = await db.query("SELECT " + propName + " FROM " + meta.logicalName + " WHERE id=?", [record.id]);
					const prevUrl = f && f.values && f.values[0] && f.values[0][propName];
				
					record[propName] = await saveBlobFile(metaContext, prevUrl, data);
				}
			}
		}
	}
}

export const updateRecordOffline = async (objectName: string, record: any, operation: string | undefined, metaContext: IAppConfig) => {
	const db = await getDatabase(metaContext);
	try {
		const metaCache = Dictionary.create(metaContext.objects, "logicalName");
		const meta = metaCache[objectName];
		
		if ((!operation || operation === "create") && meta && meta.type !== "many" && !record["id"]) {
			operation = "create";
			record.id = newGuid();
		}

		try {
			await updateRecordBlobs(db, metaContext, meta, record, operation as string);
		}
		catch (ex) {
			console.log("error writing blobs");
			console.log(ex);
		}

		const req = { name: objectName, object: record, operation: operation as any };

		//throw Error("Wrting to DB!")
		await db.executeSql("CREATE TABLE IF NOT EXISTS inu_upload (body TEXT)");
		await db.insertRow("inu_upload", [{ name: "body", type: "", value: JSON.stringify(req) }]);

		return await internalUpdateRecord(db, { "metaDict": metaCache, permDict: metaContext.permDict }, req, metaContext.user);
	}
	finally {
		releaseDatabase();
	}
}

export const getUploadRecords = async (metaContext: IAppConfig) => {
	const metaCache = Dictionary.create(metaContext.objects, "logicalName");

	const db = await getDatabase(metaContext);
	try {
		const records = await db.query("SELECT * FROM inu_upload ORDER BY rowid", []);
		// fixme only succeded!
		// await db.beginTransaction();
		// await db.executeSql("DELETE FROM inu_upload");
		// await db.finishTransaction(true);
		db.executeSql("DROP TABLE inu_upload;")
		const upload = (records?.values?.map(x => JSON.parse(x.body) as IExecuteRequest)) || [];

		const uploadFiles = [];
		const fileMap = new Set<string>();

		for (let i = upload.length - 1; i >= 0; i--) {
			const req = upload[i];
			const record = req.object;

			delete record["versionnumber"]; // no conflict check, or only for first of the id, next updates must fail.
			
			const meta = metaCache[req.name];
			if (!meta)
				continue;
			
			for (const prop of meta.properties) {
				if ((prop.flags & (MetaPropertyFlags.File | MetaPropertyFlags.Image)) !== 0) {

					const propName = prop.logicalName;
					const value = record[propName] as string;
					if (getFileNameFromBlobUrl(value)) {

						delete record[prop.logicalName];

						const internalId = record.id + propName;
						if (!fileMap.has(internalId)) {
							fileMap.add(internalId);
							uploadFiles.push({ fileName: value, id: record.id, objectName: meta.logicalName, propName: propName });
						}
					}
				}
			}
		}
		return { upload, uploadFiles };
	}
	catch (e) {
		console.log(e);
		return { upload: [], uploadFiles: [] };
	}
	finally {
		releaseDatabase();
	}
}

export interface ISyncAnchor {
	name: string;
	rowversion: string;
}

export const getSyncAnchors = async (metaContext: IAppConfig) => {
	const db = await getDatabase(metaContext);
	try {
		const records = await db.query("SELECT name, rowversion FROM inu_syncanchor", []);
		return records?.values as ISyncAnchor[];
	}
	catch (e) {
		console.log(e);
		return [];
	}
	finally {
		releaseDatabase();
	}
}

export const writeSyncRecords = async (objectName: string, records: any[], metaContext: IAppConfig, minRowVersion: string) => {
	const db = await getDatabase(metaContext);
	try {
		const metaCache = Dictionary.create(metaContext.objects, "logicalName");

		//db.dbConnection?.executeTransaction()
		const meta = metaCache[objectName];

		const parameters: SqlParam[] = [];
		// init a dummy record, set all properties to null.
		for (const prop of meta.properties) {
			const name = prop.logicalName;
			if (ReadOnlyFields[name]) {
				continue;
			}
			const p: SqlParam = { name: name, type: getSqlType(prop.type), value: null };
			if (prop.type === MetaPropertyType.Lookup) {
				parameters.push({ name: p.name + "Target", type: TYPES.NVarChar, value: null });
			}
			parameters.push(p);
		}

		console.log("full sync:" + objectName);

		//await db.executeNonQuery("DELETE FROM " + objectName, []);

		// upsert
		const sql = "INSERT INTO " + objectName + " ( " +
			parameters.map((v) => v.name).join(",") + " ) VALUES (" +
			parameters.map((v, i) => "?").join(",") + ")" +
			" ON CONFLICT DO UPDATE SET " + parameters.map((v, i) => v.name + "=?").join(", ");
		
		// fixme: NN on conflict ignore.
		// fixme: try ?NNN for on conflict update so that we don't have to bind the params twice
	
		// actually write names to DB in case interrupted.
		const files: string[] = [];

		await db.beginTransaction();
		try {
			for (const r of records) {
				let sqlValues: any[] = [];
				for (const prop of meta.properties) {
					const name = prop.logicalName;
					if (ReadOnlyFields[name]) {
						continue;
					}
					let value = r[name];
					//const p: SqlParam = { name: name, type: getSqlType(prop.type), value: null };
					if (prop.type === MetaPropertyType.Lookup) {
						sqlValues.push(value ? value.name : null);
						if (value)
							value = value.id;
					}
					else if ((prop.flags & (MetaPropertyFlags.File | MetaPropertyFlags.Image)) !== 0) {
						if (value && getFileNameFromBlobUrl(value))
							files.push(value);
					}
					sqlValues.push(value === undefined ? null : value);
				}
				sqlValues = sqlValues.concat(sqlValues);
				await db.query(sql, sqlValues);
			}

			if (minRowVersion) {
				await db.executeSql("CREATE TABLE IF NOT EXISTS inu_syncanchor (name TEXT, rowversion TEXT)");
				const anchorId = { name: "name", type: "", value: meta.logicalName };
				await db.deleteRowWithId("inu_syncanchor", [anchorId]);
				await db.insertRow("inu_syncanchor", [anchorId, { name: "rowversion", type: "", value: minRowVersion }]);
			}
			await db.finishTransaction(true);
		}
		catch (e) {
			await db.finishTransaction(false);
			throw e;
		}

		await downloadAndSaveBlobFiles(metaContext, files);
	}
	finally {
		releaseDatabase();
	}
}

export const retrieveMultipleOffline = async (q: Fetch, metaContext: IAppConfig): Promise<any[]> => {
	const db = await getDatabase(metaContext);
	try {
		const metaCache = Dictionary.create(metaContext.objects, "logicalName");
		return await executeFetch(db, q, metaCache, { userId: metaContext.user.id });
	}
	finally {
		releaseDatabase();
	}
}

const executeFetch = async (db: DatabaseConnection, fetch: Fetch, metadata: IDictionary<IMetaObject>,
	options: { userId: string, parseJson?: boolean }) => {
	
	if (fetch.entity.allattrs) {
		fetch.entity.attributes = metadata[fetch.entity.name].properties.map(x => ({ attribute: x.logicalName }));
	}
	
	fetch.count = undefined;
	const { sql, values } = FetchToSql.translate(fetch, metadata, { ...options, sqlite: true } as any);

	const parameters = values.values.map(x => {
		// const type = getSqlType(x.type.type);
		// const ret: SqlParam = { name: x.name, type: type, value: x.value };
		// return ret;
		return x.value;
	}) as [];

	const result = await db.query(sql, parameters);
	if (result && result.values) {
		const records = result.values;//.slice(1);
		for (const r of records) {
			for (const name in r) {
				if (name.endsWith(".id")) {
					const baseLookupName = name.substring(0, name.length - 3);
					if (r[name])
						r[baseLookupName] = { id: r[name], name: r[baseLookupName + ".name"], label: r[baseLookupName + ".label"] } as ILookupValue;
					delete r[name];
					delete r[baseLookupName + ".name"];
					delete r[baseLookupName + ".label"];
				}
				else {
					// do not report null data
					if (r[name] === null || r[name] === undefined)
						delete r[name];
				}
			}
			// fixme: remove all NULL props.
		}
		return records;
	}
	return [];
}
