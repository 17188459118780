import React, { useCallback, useContext, useMemo, useRef } from "react";
import { IDictionary, IMetaObject, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { ICommand } from "../AppSchema";
import { MetaContext } from "../AppState";
import { ModalContext } from "../modal/Modal";
import { FieldEditor } from "../objectForm/panels/FieldsPanel";
import { runCommand } from "../command/runCommand";
import { IFormatterProps } from "./useCellFormatter";
import { IFieldProps } from "../objectForm/panels/PanelProps";
import { getRecordCurrency } from "../formatters";
import { ImageField } from "../objectForm/fields/ImageField";

export const useEditableFormatter = (baseFormattter: (row: any, fieldName: string, style: any) => any,
	props: IFormatterProps,
	meta: IMetaObject,
	attrsMeta: IDictionary<IMetaProperty>,
	getSelectedRecord: () => string,
	setSelectedRecord: (id:string) => void,
	getRecord: (id: string)=> any,
	setRecord: (newRow: any) => void,
) => {
	const modalSite = useContext(ModalContext);
	const metadata = useContext(MetaContext);

	const prevRecords = useRef([] as any[]);
	const callbackContext = useRef({ getRecord, getSelectedRecord });
	callbackContext.current = { getRecord, getSelectedRecord }

	const onFocus = useCallback((e: React.FocusEvent<HTMLDivElement>) => {
		const rid = e.currentTarget.dataset["rid"];
		if (rid)
			setSelectedRecord(rid);
	}, [setSelectedRecord]);

	const onRenderScript = useMemo(() => {
		return { kind: "custom", name: meta.logicalName + ".change" } as ICommand
	}, [meta.logicalName]);

	const { onRenderCell } = props;

	const formatter = useCallback((row: any, fieldName: string, style: any) => {

		const prevs = prevRecords.current;
		if (prevs.length === 0 || prevs[prevs.length - 1] !== row) { // run once per
			const prevIndex = prevs.findIndex(x => x.id === row.id);
			const prevRecord = prevs[prevIndex];

			if (prevRecord !== row) {
				// this is the new interface used by objectForm.
				const internalSetRecord: any = (fullRecord: any, partialRecord: any) => {
					if (fullRecord === undefined && partialRecord)
						fullRecord = { ...callbackContext.current.getRecord(row.id), ...partialRecord };
					setRecord(fullRecord)
				}
				runCommand(onRenderScript, () => callbackContext.current.getRecord(row.id), prevRecord, internalSetRecord,
					metadata, modalSite, { getSelectedRecord: () => callbackContext.current.getSelectedRecord(), setSelectedRecord }, "list");
			}
			if (prevIndex >= 0)
				prevs.splice(prevIndex, 1);
			prevs.push(row); // move to end, so that we run the script once per row, not once per cell
		}
		
		if (onRenderCell) {
			const result = onRenderCell(row, fieldName);
			if (result !== undefined)
				return result;
		}
		const getRecordProperty = (row: any, name: string) => {
			if (name === "currencyid")
				return getRecordCurrency(metadata, row);
			return row[name]
		}
		const value = row[fieldName];
		const propMeta = attrsMeta[fieldName];
		const listColumn = props.listColumns.find(x => x.attribute === fieldName);
		if (propMeta && !listColumn?.disabled) {
			let editCellClass = "editCell";
			const fp: IFieldProps = {
				getRecordProperty: (n) => getRecordProperty(row, n)
			}
			let fe: any = undefined;
			if (propMeta.type === MetaPropertyType.String && (propMeta.flags & (MetaPropertyFlags.Image | MetaPropertyFlags.File)) !== 0) {
				const field = { name: fieldName, type: "Field", customComponent: "ImageField;;;none" } as any;
				fe = <ImageField getRecord={() => row} setRecord={setRecord} field={field} meta={meta} />
				editCellClass = "editCell objectListImageCell";
			}
			else {
				fe = FieldEditor(propMeta, value, fp, (newValue) => {
					const record = { ...row, [fieldName]: newValue };
					setRecord(record);
				});
				if (propMeta.type === MetaPropertyType.Lookup) // dont-wrap
					return fe;
			}
			
			if ((listColumn?.cssClass || "").indexOf("listButtonDelete") >= 0 && getSelectedRecord() === row.id) {//style?.backgroundColor === "#0071eb20") {
				fe = <>{fe}<i className="editCellDelete fa fa-trash"
					onClick={e => setRecord({ ...row, __operation: "delete" })} /></>
			}
			
			// todo move the focus handling to simpleList
			return <div className={editCellClass} onFocus={onFocus} data-rid={row.id}>{fe}</div>;
		}
		else if (propMeta && propMeta.type === MetaPropertyType.Lookup || fieldName === "name") {
			const label = value.label || "" + value;
			return <span style={{ whiteSpace: "nowrap", margin:"2px 5px" }}>{label}</span>
		}
		return baseFormattter(row, fieldName, style);

	}, [attrsMeta, onRenderCell, baseFormattter, getRecord, getSelectedRecord]);
	
	return formatter;
}