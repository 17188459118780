import { Fetch } from "shared/fetch";
import { ICommand } from "../AppSchema";
import { TitleBox } from "../components/TitleBox";
import { IModalContext, IModalParent } from "../modal/Modal";
import { EntityNode } from "./FetchEditor";
import { download } from "../services/download";
import { useState } from "react";


export const showFetchDialog = (modal: IModalContext, fetch: Fetch,
	onSave: ()=>void) => {
	
	const FetchDialog = (props: { fetch: Fetch, onSave: () => void, commandSite: IModalParent }) => {

		const [fetchEntity, setFetchEntity] = useState(props.fetch.entity);

		const cmds = [
			{ name: "CmdImport", label: " ", icon: "file-upload" },
			{ name: "CmdExport", label: " ", icon: "file-download" },
			{ name: "CmdSave", label: "Save", icon: "save" },
			{ name: "CmdClose", label: "Close", icon: "close" },
		];
		
		const onCommand = async (cmd: ICommand) => {
			if (cmd.name === "CmdExport") {
				const q = JSON.stringify(props.fetch);
				download(q, "fetch.json", "application/json");
				return;
			}
			if (cmd.name === "CmdImport") {
				var input = document.createElement('input');
				input.type = 'file';
				input.accept = "application/json";
				input.onchange = (e: any) => {
					
					var reader = new FileReader();
					// here we tell the reader what to do when it's done reading...
					reader.onload = (readerEvent: any) => {
					   var content = readerEvent.target.result; // this is the content!
						//console.log( content );
						const f = JSON.parse(content) as Fetch;
						if (f && f.entity && f.entity.name === props.fetch.entity.name) {
							props.fetch.entity = f.entity;
							props.fetch.distinct = f.distinct;
							setFetchEntity(f.entity);
						}
					}

					var file = e.target.files[0];
					reader.readAsText(file, 'UTF-8');
				}
				input.click();
				return;
			}
			if (cmd.name === "CmdSave") {
				props.onSave();
			}
			props.commandSite.closeModal();
		}

		return (<div className="objectListContainer" style={{ flex: "1 1 auto", display:"flex" }}>
			<TitleBox title="Setup Query" commands={cmds} onCommand={onCommand} />
			<EntityNode entity={fetchEntity} />
		</div>)
	}

	modal.showModal({
		body: (p) => < FetchDialog {...p} />,
		bodyProps: {
			fetch: fetch,
			onSave: onSave,
		},
		showTitle: false,
		id: "FetchDialog",
	})
}